import React, { useContext } from "react";
import { roundPropertyLow } from "../../helpers";
import { UserContext } from "../../Context";

function SumCharacter({ user: userData, getCharacterSum, isRound = false }) {
  const user = useContext(UserContext);

  return (
    <>
      {isRound
        ? roundPropertyLow(
            getCharacterSum(userData ? userData : user)
          ).toLocaleString("ru")
        : getCharacterSum(userData ? userData : user).toLocaleString("ru")}
    </>
  );
}

export default React.memo(SumCharacter);
