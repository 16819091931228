import React, { useState } from "react";
import InputNumber from "../InputNumber/InputNumber";
import * as S from "./InventoryElem.styles";
import Reward from "../Reward/Reward";
import { roundConsumablesFixed } from "../../helpers";

function InventoryElem({
  elem,
  loading,
  isModalOpen,
  useItem,
  setIsPopupOpen = null,
  flex = "column",
  gap = 5,
  hide = true,
}) {
  const [count, setCount] = useState(1);

  function handleUse(elem, count, setIsPopupOpen) {
    let countLocal = count > elem.count ? elem.count : count;
    useItem(elem, countLocal, setIsPopupOpen);
  }

  return (
    <S.Wrapper>
      {elem.category === "hp" || elem.category === "energy" ? (
        <>
          <S.Rewards>
            <Reward
              button={hide}
              name={elem.category}
              count={elem.name}
              w={22}
            />
            <span>({elem.count} шт)</span>
          </S.Rewards>
        </>
      ) : (
        <>
          <span>{elem.name} </span>
          <span>
            ({roundConsumablesFixed(elem.count).toLocaleString("ru")} шт)
          </span>
        </>
      )}

      {/*{elem.time > 0 ? (*/}
      {/*  <span>Время:{secondsToMs(elem.time)}</span>*/}
      {/*) : (*/}
      {/*  <span>Моментально</span>*/}
      {/*)}*/}
      <S.BottomButton flex={flex} gap={gap}>
        <InputNumber min={1} max={elem.count} setCount={setCount} />
        <button
          onClick={
            !loading && !isModalOpen
              ? handleUse.bind(null, elem, Number(count), setIsPopupOpen)
              : null
          }
        >
          {count} шт
        </button>
      </S.BottomButton>
    </S.Wrapper>
  );
}

export default React.memo(InventoryElem);
