import React, { useCallback, useEffect, useRef, useState } from "react";

import * as S from "./ArenaTournament.styles";
import Popup from "../../components/Popup/Popup";
import { motion } from "framer-motion";
import { Close } from "../../App.styles";
import Reward from "../../components/Reward/Reward";
import frame3 from "../../img/Frame3.png";
import { ButtonsWrapper } from "../ActiveBoss/ActiveBoss.styles";
import { TopButton } from "../../panels/Tops/Tops.styles";

function popal(player, enemy) {
  const yklonenie = (
    3 +
    (enemy.evasion / (player.accuracy + enemy.evasion) - 0.5) * 250
  ).toFixed(1);

  let popadanie = 100 - yklonenie;
  if (popadanie > 97) {
    popadanie = 97;
  }
  if (popadanie < 30) {
    popadanie = 30;
  }
  const rand = (Math.random() * 100).toFixed(1);
  return popadanie - rand > 0;
}

function probil(player, enemy) {
  const block = (
    3 +
    (enemy.protection / (player.power + enemy.protection) - 0.5) * 250
  ).toFixed(1);
  let probitie = 100 - block;

  if (probitie > 97) {
    probitie = 97;
  }
  if (probitie < 30) {
    probitie = 30;
  }
  const rand = (Math.random() * 100).toFixed(1);
  return probitie - rand > 0;
}

function krit(player, enemy) {
  const krit = (
    3 +
    (player.power / (player.power + enemy.protection) - 0.5) * 250
  ).toFixed(1);
  let probitie = 100 - krit;
  if (probitie > 97) {
    probitie = 97;
  }
  if (probitie < 30) {
    probitie = 30;
  }
  const rand = (Math.random() * 100).toFixed(1);
  return probitie - rand <= 0;
}

function dealDamage(player) {
  const damage = player.force * 0.65;
  const rand1 = Math.floor(Math.random() * 11);
  const rand2 = Math.floor(Math.random() * 2);
  if (rand2) {
    return Math.floor(damage * (1 + rand1 * 0.01));
  } else {
    return Math.floor(damage * (1 - rand1 * 0.01));
  }
}

function ArenaTournament({
  user,
  app,
  setUser,
  isFetching,
  isModalOpen,
  setIsModalOpen,
  serverTime,
  setModalError,
  isLoading,
  setDisableMove,
  setIsFetching,
  setIsLoading,
  tournamentUsers,
  setEndTournamentOpen,
  setAdditionalPanel,
}) {
  const [isStatistic, setIsStatistic] = useState(false);
  const [isWin, setIsWin] = useState(false);
  const [log, setLog] = useState([]);
  const intervalRef = useRef(null);
  const scrollRef = useRef(null);
  const isStart = useRef(false);

  useEffect(() => {
    setAdditionalPanel("arenaTournament");

    return () => {
      setAdditionalPanel("");
    };
  }, []);

  useEffect(() => {
    if (
      !user.arenaTournament.isOver &&
      (user.arenaTournament.enemy?.hp <= 0 || user.arenaTournament.me?.hp <= 0)
    ) {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
      setIsWin(user.arenaTournament.enemy.hp <= 0);
      setIsModalOpen(true);
    }

    if (
      !user.arenaTournament.isOver &&
      !isStart.current &&
      user.arenaTournament.enemy?.hp > 0 &&
      user.arenaTournament.me?.hp > 0
    ) {
      fight(1600);
      isStart.current = true;
      setDisableMove(true);
    }
  }, [user]);

  function endFight() {
    const opponents = tournamentUsers.filter(
      (elem) => elem.email !== user.email
    );
    const points = isWin ? user.arenaTournament.enemy.rate : 0;
    if (user.arenaTournament.gamesDone + 1 < opponents.length) {
      const me = tournamentUsers.find((elem) => elem.email == user.email);
      const enemy = opponents[user.arenaTournament.gamesDone + 1];
      setIsFetching(true);
      setIsLoading(true);

      app
        .service("users")
        .patch(
          user._id,
          {
            "arenaTournament.me": {
              ...me,
              fullHp: me.hp,
            },
            "arenaTournament.enemy": {
              ...enemy,
              fullHp: enemy.hp,
            },
            field: serverTime,
            $inc: {
              "arenaTournament.gamesDone": 1,
              "arenaTournament.points": points,
            },
          },
          {
            query: {
              $select: ["_id", "email", "arenaTournament"],
            },
          }
        )
        .then((data) => {
          isStart.current = false;
          setIsModalOpen(false);
          setIsFetching(false);
          setIsLoading(false);
          setLog([]);
          setUser((prev) => ({ ...prev, ...data }));
        })
        .catch((e) => {
          setModalError(e);
          console.log(e);
          setIsFetching(false);
          setIsLoading(false);
        });
    } else {
      setIsFetching(true);
      setIsLoading(true);

      app
        .service("users")
        .patch(
          user._id,
          {
            "arenaTournament.isOver": true,
            "arenaTournament.me": {},
            "arenaTournament.enemy": {},
            "arenaTournament.maxPoints":
              user.arenaTournament.maxPoints <
              user.arenaTournament.points + points
                ? user.arenaTournament.points + points
                : user.arenaTournament.maxPoints,
            "arenaTournament.isStartTournament": false,
            "arenaTournament.history": [
              ...user.diceGameTournament.history,
              user.diceGameTournament.points,
            ],
            field: serverTime,
            $inc: {
              "arenaTournament.points": points,
            },
          },
          {
            query: {
              $select: ["_id", "email", "arenaTournament"],
            },
          }
        )
        .then((data) => {
          isStart.current = false;
          setIsModalOpen(false);
          setDisableMove(false);
          setIsFetching(false);
          setIsLoading(false);
          setUser((prev) => ({ ...prev, ...data }));
          setEndTournamentOpen(true);
        })
        .catch((e) => {
          setModalError(e);
          console.log(e);
          setIsFetching(false);
          setIsLoading(false);
          setDisableMove(false);
        });
    }
  }

  function move(player, enemy, isMe) {
    if (player.hp > 0 && enemy.hp > 0) {
      if (popal(player, enemy)) {
        if (probil(player, enemy)) {
          if (krit(player, enemy)) {
            const damage = dealDamage(player) * 2;
            if (isMe) {
              setLog((prev) => [
                ...prev,
                {
                  string: `Вы нанесли крит ${damage.toLocaleString("ru")}`,
                  isMe: true,
                },
              ]);
            } else {
              setLog((prev) => [
                ...prev,
                {
                  string: `Противник нанёс крит ${damage.toLocaleString("ru")}`,
                  isMe: false,
                },
              ]);
            }
            enemy.hp -= damage > enemy.hp ? enemy.hp : damage;
          } else {
            const damage = dealDamage(player);
            if (isMe) {
              setLog((prev) => [
                ...prev,
                {
                  string: `Вы нанесли ${damage.toLocaleString("ru")} урона`,
                  isMe: true,
                },
              ]);
            } else {
              setLog((prev) => [
                ...prev,
                {
                  string: `Противник нанёс ${damage.toLocaleString(
                    "ru"
                  )} урона`,
                  isMe: false,
                },
              ]);
            }
            enemy.hp -= damage > enemy.hp ? enemy.hp : damage;
          }
        } else {
          if (isMe) {
            setLog((prev) => [
              ...prev,
              { string: "Вы не пробили блок", isMe: true },
            ]);
          } else {
            setLog((prev) => [
              ...prev,
              { string: "Противник не пробил блок", isMe: false },
            ]);
          }
        }
      } else {
        if (isMe) {
          setLog((prev) => [
            ...prev,
            { string: "Вы промахнулись заклинанием", isMe: true },
          ]);
        } else {
          setLog((prev) => [
            ...prev,
            { string: "Противник промахнулся заклинанием", isMe: false },
          ]);
        }
      }
    } else {
      if (isMe) {
        setLog((prev) => [
          ...prev,
          { string: "Вы не можете продолжать", isMe: true },
        ]);
      } else {
        setLog((prev) => [
          ...prev,
          { string: "Противник не может продолжать", isMe: false },
        ]);
      }
    }
    return enemy;
  }

  function fight(ms) {
    intervalRef.current = setInterval(function tick() {
      round(!ms);
    }, ms);
  }

  const round = useCallback(
    (isFast) => {
      let me;
      let enemy;
      if (user.arenaTournament.me.speed >= user.arenaTournament.enemy.speed) {
        enemy = move(user.arenaTournament.me, user.arenaTournament.enemy, true);
        me = move(user.arenaTournament.enemy, user.arenaTournament.me, false);
      } else {
        me = move(user.arenaTournament.enemy, user.arenaTournament.me, false);
        enemy = move(user.arenaTournament.me, user.arenaTournament.enemy, true);
      }
      if (isFast) {
        if (user.arenaTournament.me.speed >= user.arenaTournament.enemy.speed) {
          while (enemy.hp > 0 && me.hp > 0) {
            enemy = move(
              user.arenaTournament.me,
              user.arenaTournament.enemy,
              true
            );
            me = move(
              user.arenaTournament.enemy,
              user.arenaTournament.me,
              false
            );
          }
        } else {
          while (enemy.hp > 0 && me.hp > 0) {
            me = move(
              user.arenaTournament.enemy,
              user.arenaTournament.me,
              false
            );
            enemy = move(
              user.arenaTournament.me,
              user.arenaTournament.enemy,
              true
            );
          }
        }
      }

      if (intervalRef.current || isFast) {
        app
          .service("users")
          .patch(
            user._id,
            {
              "arenaTournament.me.hp": me.hp,
              "arenaTournament.enemy.hp": enemy.hp,
              common: true,
            },
            {
              query: {
                $select: ["_id", "email", "arenaTournament"],
              },
            }
          )
          .then((data) => {
            setUser((prev) => ({ ...prev, ...data }));
          })
          .catch((e) => {
            setModalError(e);
            console.log(e);
          });
      }
    },
    [user]
  );

  const getResults = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = null;
    round(true);
  }, [user, serverTime]);

  return (
    <React.Fragment>
      <S.FightWrapper>
        <div>
          <S.Opponent faculty={user.arenaTournament.me?.faculty}>
            <div>
              <S.Lvl>
                <span>{user.arenaTournament.me?.lvl} уровень</span>
              </S.Lvl>

              <S.Avatar>
                <img
                  src={user.arenaTournament.me?.photo}
                  width={100}
                  height={100}
                  alt={"аватар"}
                />
              </S.Avatar>

              <S.Name>
                <span>
                  {user.arenaTournament.me?.orden?.tag
                    ? `[${user.arenaTournament.me?.orden?.tag}] `
                    : ""}
                </span>
                <span>
                  {user.arenaTournament.me?.nickName
                    ? user.arenaTournament.me?.nickName
                    : `${user.arenaTournament.me?.name?.firstName} ${user.arenaTournament.me?.name?.lastName}`}
                </span>
              </S.Name>

              <S.CharactersInfo>
                <S.ButtonValue width={110}>
                  <div>
                    {user.arenaTournament.me?.rate?.toLocaleString("ru")}
                  </div>
                </S.ButtonValue>
              </S.CharactersInfo>

              <S.HealthInfo>
                <div>
                  <span>
                    {user.arenaTournament.me?.hp?.toLocaleString("ru")}
                  </span>
                  <Reward button={true} name={"hp"} w={13} />
                </div>
                <S.ButtonPercent
                  isMe={true}
                  value={
                    ((user.arenaTournament.me?.hp || 0) /
                      (user.arenaTournament.me?.fullHp || 0)) *
                    100
                  }
                >
                  <div>
                    <div />
                  </div>
                </S.ButtonPercent>
              </S.HealthInfo>
            </div>
          </S.Opponent>
        </div>

        <div>
          <S.LogWrapper ref={scrollRef}>
            {log.length > 0 &&
              [...log].reverse().map((el, i) => {
                return (
                  <motion.div
                    style={{ color: el.isMe ? "#fcc382" : "#ff3232" }}
                    key={log.length - i}
                    initial={{ opacity: 0.3 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{ root: scrollRef }}
                  >
                    {el.string}
                  </motion.div>
                );
              })}
          </S.LogWrapper>
        </div>

        <div>
          <S.Opponent faculty={user.arenaTournament.enemy?.faculty}>
            <div>
              <S.Lvl>
                <span>{user.arenaTournament.enemy?.lvl} уровень</span>
              </S.Lvl>

              <S.Avatar>
                <img
                  src={user.arenaTournament.enemy?.photo}
                  width={100}
                  height={100}
                  alt={"аватар"}
                />
              </S.Avatar>

              <S.Name>
                <span>
                  {user.arenaTournament.enemy?.orden?.tag
                    ? `[${user.arenaTournament.enemy?.orden?.tag}] `
                    : ""}
                </span>
                <span>
                  {user.arenaTournament.enemy?.nickName
                    ? user.arenaTournament.enemy?.nickName
                    : `${user.arenaTournament.enemy?.name?.firstName} ${user.arenaTournament.enemy?.name?.lastName}`}
                </span>
              </S.Name>

              <S.CharactersInfo>
                <S.ButtonValue width={110}>
                  <div>
                    {user.arenaTournament.enemy?.rate?.toLocaleString("ru")}
                  </div>
                </S.ButtonValue>
              </S.CharactersInfo>

              <S.HealthInfo>
                <div>
                  <span>
                    {user.arenaTournament.enemy?.hp?.toLocaleString("ru")}
                  </span>
                  <Reward button={true} name={"hp"} w={13} />
                </div>
                <S.ButtonPercent
                  isMe={false}
                  value={
                    ((user.arenaTournament.enemy?.hp || 0) /
                      (user.arenaTournament.enemy?.fullHp || 0)) *
                    100
                  }
                >
                  <div>
                    <div />
                  </div>
                </S.ButtonPercent>
              </S.HealthInfo>
            </div>
          </S.Opponent>
        </div>
      </S.FightWrapper>
      <TopButton>
        {isStatistic ? (
          <S.Button
            width={100}
            onClick={!isLoading && !isFetching ? endFight : null}
          >
            <div>
              {user.arenaTournament.gamesDone + 1 < tournamentUsers.length - 1
                ? "Далее"
                : "Завершить"}
            </div>
          </S.Button>
        ) : (
          <S.Button
            disabled={!intervalRef.current}
            onClick={
              !isLoading && !isFetching && !isModalOpen && intervalRef.current
                ? getResults
                : null
            }
          >
            <div>Завершить досрочно</div>
          </S.Button>
        )}
      </TopButton>

      <Popup isOpen={isModalOpen && !isStatistic} w={317} h={217} back={frame3}>
        {!isWin && <Close onClick={!isFetching ? endFight : null} />}
        <b>Результат дуэли: {isWin ? "победа" : "поражение"}</b>
        <span>
          Получено очков:{" "}
          {isWin ? user.arenaTournament.enemy.rate.toLocaleString("ru") : 0}
        </span>
        <span>
          Проведено дуэлей: {user.arenaTournament.gamesDone + 1}/
          {tournamentUsers.length - 1}
        </span>
        <ButtonsWrapper>
          <S.Button
            width={100}
            onClick={() => {
              setIsStatistic(true);
            }}
          >
            <div>Статистика</div>
          </S.Button>
          <S.Button
            width={100}
            onClick={!isLoading && !isFetching ? endFight : null}
          >
            <div>
              {user.arenaTournament.gamesDone + 1 < tournamentUsers.length - 1
                ? "Далее"
                : "Завершить"}
            </div>
          </S.Button>
        </ButtonsWrapper>
      </Popup>
    </React.Fragment>
  );
}

export default React.memo(ArenaTournament);
