import React from "react";

import * as S from "./EnergyInfo.styles";
import energyImg from "../../img/EnergyBar.png";
import { roundConsumablesFixed } from "../../helpers";
import energyIcon from "../../img/EnergyProgressIcon.png";
import energyTimeIcon from "../../img/miniIcons/Time.png";

function EnergyInfo({ maxEnergy, energy, energyTime }) {
  return (
    <S.Wrapper>
      <S.EnergyProgress
        energy={energy.toLocaleString("ru")}
        value={(energy / maxEnergy) * 100}
      >
        <img src={energyImg} alt="шкала" width={194} height={20} />
        <span>
          {roundConsumablesFixed(energy).toLocaleString("ru")}/{maxEnergy}
        </span>
      </S.EnergyProgress>
      {/*<S.Icon src={energyIcon} width={32} height={32} />*/}
      <S.EnergyTime>
        <img src={energyTimeIcon} alt="время" width={14} />
        <span>{energyTime}</span>
      </S.EnergyTime>
    </S.Wrapper>
  );
}

export default React.memo(EnergyInfo);
