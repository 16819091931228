import React, { useEffect, useState } from "react";
import * as S from "./RaidDamagePanel.styles";
import Popup from "../Popup/Popup";
import frame4 from "../../img/Frame4.png";
import { roundProperty } from "../../helpers";
import { Close } from "../../App.styles";
import { Button } from "../../panels/Arena/Arena.styles";

function RaidDamagePanel({ allUsers, moveToUserRoom, email, name }) {
  const [open, setOpen] = useState(false);
  const [members, setMembers] = useState([]);
  const [damage, setDamage] = useState(0);

  useEffect(() => {
    if (allUsers.length > 0) {
      setMembers(
        allUsers
          .filter((elem) => elem.orden.name === name)
          .sort((a, b) => b.raidDamage - a.raidDamage)
      );
    }
  }, [allUsers]);

  useEffect(() => {
    if (members.length > 0) {
      setDamage(members.reduce((sum, cur) => sum + cur.raidDamage, 0));
    }
  }, [members]);

  function handleClickUser(user) {
    moveToUserRoom(user);
  }

  return (
    <S.Wrapper>
      <Button
        width={120}
        onClick={() => {
          setOpen((prev) => !prev);
        }}
      >
        <div>Рейдовый урон</div>
      </Button>

      <Popup isOpen={open} setIsOpen={setOpen} w={506} h={502} back={frame4}>
        <Close
          onClick={() => {
            setOpen(false);
          }}
        />
        <span>Общий урон: {roundProperty(damage).toLocaleString("ru")}</span>
        <S.Users>
          {open &&
            members.length > 0 &&
            members.map((userLocal, i) => {
              return (
                <div key={i}>
                  <S.User
                    isMe={userLocal.email == email}
                    onClick={
                      userLocal.email != email
                        ? handleClickUser.bind(null, userLocal)
                        : null
                    }
                  >
                    <span> {i + 1}. </span>
                    <span>
                      {userLocal.orden?.tag ? `[${userLocal.orden?.tag}] ` : ""}
                    </span>
                    <span>
                      {userLocal.nickName
                        ? userLocal.nickName
                        : userLocal.name?.firstName +
                          " " +
                          userLocal.name?.lastName}
                    </span>
                    <span>
                      {" "}
                      -{" "}
                      {roundProperty(userLocal.raidDamage).toLocaleString(
                        "ru"
                      )}{" "}
                    </span>
                    <span>
                      (
                      <S.UserRef
                        target="_blank"
                        href={`https://vk.com/id${userLocal.email}`}
                      >
                        {userLocal.name?.firstName +
                          " " +
                          userLocal.name?.lastName}
                      </S.UserRef>
                      )
                    </span>
                  </S.User>
                </div>
              );
            })}
        </S.Users>
      </Popup>
    </S.Wrapper>
  );
}

export default React.memo(RaidDamagePanel);
