import styled from "styled-components";
import imgMarker2 from "../../img/Marker2Dark.png";
import imgMarker5 from "../../img/Marker5Dark.png";

export const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: #f7b471;
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.isOpen ? "1" : "0")};
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  pointer-events: ${(props) => (props.isOpen ? "auto" : "none")};
  z-index: ${(props) => (props.isOpen ? "1" : "-1")};
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 800px;
  height: 518px;
  gap: 10px;

  & > div {
    padding: 20px 20px;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  background-image: url("${(props) => props.back}");
  background-size: cover;
`;

export const InnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
`;

export const OrdensWrapper = styled.div`
  padding-top: 10px;
  width: 100%;
`;

export const MyOrdenNames = styled.div`
  gap: 10px;
  display: grid;
  grid-template-columns: 0.6fr 2fr 1.4fr 0.6fr 1fr 0.8fr 0.8fr;
  padding: 0 5px;
  padding-right: 10px;
  text-align: center;
`;

export const Members = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow-y: scroll;
  margin-top: 10px;
  min-height: 250px;
  max-height: 250px;
  padding: 0 5px;
`;

export const Member = styled.div`
  line-height: 20px;
  gap: 10px;
  padding: 10px 5px;
  display: grid;
  align-items: center;
  text-align: center;
  grid-template-columns: 0.6fr 2fr 1.4fr 0.6fr 1fr 0.8fr 0.8fr;
  background-image: url("${(props) => (props.isMe ? imgMarker2 : imgMarker5)}");
  background-size: contain;
  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  & > span:nth-child(2) {
    cursor: ${(props) => (props.isMe ? "default" : "pointer")};
    color: #f7b471;
  }
  & > button {
    width: 100%;
  }
`;

export const ApplicationsNames = styled.div`
  gap: 10px;
  display: grid;
  grid-template-columns: 0.6fr 2fr 0.6fr 1fr 1fr 1fr;
  padding: 0 5px;
  padding-right: 10px;
  text-align: center;
`;

export const Applications = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
  overflow-y: scroll;
  min-height: 280px;
  max-height: 280px;
  padding: 0 5px;

  & > div {
    line-height: 20px;
    gap: 10px;
    padding: 10px 5px;
    display: grid;
    align-items: center;
    text-align: center;
    grid-template-columns: 0.6fr 2fr 0.6fr 1fr 1fr 1fr;
    background-image: url("${(props) =>
      props.isMe ? imgMarker2 : imgMarker5}");
    background-size: contain;
    & > span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    & > span:nth-child(2) {
      cursor: pointer;
      color: #f7b471;
    }
    & > button {
      width: 100%;
    }
  }
`;

export const Header = styled.div`
  font-size: 20px;
`;

export const Header2 = styled.div`
  display: flex;
  justify-content: center;
  font-size: 18px;
  margin-bottom: 10px;
`;

export const HeaderName = styled(Header2)`
  font-size: 22px;
`;

export const OrdensNames = styled.div`
  gap: 10px;
  display: grid;
  grid-template-columns: 0.6fr 3.3fr 1fr 1fr 1fr 0.6fr 1.2fr;
  padding: 0 5px;
  padding-right: 10px;
  text-align: center;
`;

export const Ordens = styled.div`
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
  min-height: 320px;
  max-height: 320px;
  padding: 0 5px;
`;

export const OrdensNamesRaid = styled(OrdensNames)`
  grid-template-columns: 0.6fr 3fr 1fr 1fr 2fr;
`;

export const Orden = styled.div`
  line-height: 20px;
  gap: 10px;
  padding: 10px 5px;
  display: grid;
  align-items: center;
  text-align: center;
  grid-template-columns: 0.6fr 3.3fr 1fr 1fr 1fr 0.6fr 1.2fr;

  background-image: url("${(props) => (props.isMy ? imgMarker2 : imgMarker5)}");
  background-size: contain;

  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  & > button {
    width: 100%;
  }
  transition: opacity 0.3s ease;
  &:hover {
    cursor: ${(props) => (props.isMy ? "default" : "pointer")};
    filter: ${(props) => !props.isMy && "brightness(1.25)"};
  }
`;

export const OrdenRaid = styled(Orden)`
  grid-template-columns: 0.6fr 3fr 1fr 1fr 2fr;
  line-height: 20px;
`;

export const MenuBottom = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  gap: 10px;
`;

export const MenuLeftBottom = styled(MenuBottom)`
  left: 0;
  right: unset;
`;

export const Resource = styled.div`
  display: flex;
  gap: 5px;

  & > input {
    flex: 1;
  }
`;

export const Resources = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const InputOrden = styled.input`
  width: 100%;
  border: none !important;
  border-bottom: 1px #413423 solid !important;
  background-color: rgba(255, 255, 255, 0) !important;
  outline: none !important;
  font-family: "Bellota-Regular";
  color: #ffcf82 !important;
  -moz-appearance: textfield !important;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  ::placeholder {
    color: #ffcf82 !important;
    text-transform: none;
  }
`;
