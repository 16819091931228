import React, { useEffect, useState } from "react";

import * as S from "./GameRoom.styles";
import Game21 from "../../components/Game21/Game21";
import DiceGame from "../../components/DiceGame/DiceGame";
import { CentredWrapper, Close } from "../../App.styles";
import game21 from "../../img/gameRoom/Game21Hover.png";
import diceGame from "../../img/gameRoom/DiceGameHover.png";
import diceGameTournamentIcon from "../../img/menuIcons/DiceGameTournament.png";
import game21TournamentIcon from "../../img/menuIcons/Game21Tournament.png";
import arrowLeft from "../../img/games/ArrowLeft.png";
import { TopPart } from "../../components/BossInterface/BossInterface.styles";
import imgTop from "../../img/boss/TopPart.png";
import { Avatar, MeTop, Place, TopButton, Tops } from "../Tops/Tops.styles";
import { Button } from "../../components/ActiveBoss/ActiveBoss.styles";
import { createPortal } from "react-dom";
import { MenuElemRight, MenuRight } from "../Home/Home.styles";
import rulesIcon from "../../img/menuIcons/Rules.png";
import Popup from "../../components/Popup/Popup";
import { MenuButtons } from "../../components/Collections/Collections.styles";
import frame9 from "../../img/Frame9.png";
import DiceGameTournament from "../../components/DiceGameTournament/DiceGameTournament";

import imgPlace1 from "../../img/Place1.png";
import imgPlace2 from "../../img/Place2.png";
import imgPlace3 from "../../img/Place3.png";
import Reward from "../../components/Reward/Reward";
import { secondsToHm } from "../../helpers";
import Game21Tournament from "../../components/Game21Tournament/Game21Tournament";
import { tournamentPrizes } from "../../info/data";

const iconsTournament = {
  diceGameTournament: diceGameTournamentIcon,
  game21Tournament: game21TournamentIcon,
};

const places = [imgPlace1, imgPlace2, imgPlace3];

const goldCount = [0, 100, 500];

const activeDices = 4;
const maxRolls = 6;
const maxGames = 40;

function GameRoom(props) {
  const [domReady, setDomReady] = React.useState(false);
  const [infoOpen, setInfoOpen] = React.useState(false);
  const [tournamentOpen, setTournamentOpen] = React.useState(false);
  const [tournamentTimeString, setTournamentTimeString] = React.useState("");
  const [activeTournament, setActiveTournament] = React.useState("");
  const [rule, setRule] = React.useState("game21");
  const [activeGame, setActiveGame] = useState("");
  const [meTop, setMeTop] = useState({ place: -1 });
  const [topUsers, setTopUsers] = useState([]);

  useEffect(() => {
    setDomReady(true);
    props.changeOptionsLocation("gameRoom");
  }, []);

  useEffect(() => {
    if (
      props.user.diceGameTournament.isStartTournament &&
      activeTournament === "diceGameTournament"
    ) {
      setGame("diceGameTournament");
    }
    if (
      props.user.game21Tournament.isStartTournament &&
      activeTournament === "game21Tournament"
    ) {
      setGame("game21Tournament");
    }
  }, [props.user, activeTournament]);

  useEffect(() => {
    if (tournamentOpen) {
      updateTop();
    }
  }, [tournamentOpen]);

  useEffect(() => {
    if (
      props.serverTime <
        props.admin.diceGameTournament.startTime +
          props.admin.diceGameTournament.time * 3600000 &&
      props.serverTime > props.admin.diceGameTournament.startTime
    ) {
      setActiveTournament("diceGameTournament");
      setTournamentTimeString(
        secondsToHm(
          (props.admin.diceGameTournament.startTime +
            props.admin.diceGameTournament.time * 3600000 -
            props.serverTime) /
            1000
        )
      );
    } else if (
      props.serverTime <
        props.admin.game21Tournament.startTime +
          props.admin.game21Tournament.time * 3600000 &&
      props.serverTime > props.admin.game21Tournament.startTime
    ) {
      setActiveTournament("game21Tournament");
      setTournamentTimeString(
        secondsToHm(
          (props.admin.game21Tournament.startTime +
            props.admin.game21Tournament.time * 3600000 -
            props.serverTime) /
            1000
        )
      );
    }
  }, [props.admin, props.serverTime]);

  useEffect(() => {
    if (props.allUsers.length > 0 && activeTournament) {
      const topsInfo = Object.entries(props.admin[activeTournament].users)
        .map((elem) => {
          const user = props.allUsers.find(
            (userLocal) => userLocal.email == elem[0]
          );
          return {
            _id: user?._id,
            email: user?.email,
            photo: user?.photo,
            rate: elem[1].rate,
            count: elem[1].count,
            points: elem[1].maxPoints,
            name: user?.name,
            nickName: user?.nickName,
            orden: user?.orden,
          };
        })
        .sort((a, b) => {
          if (b.points === a.points) {
            if (b.count === a.count) {
              return b.rate - a.rate;
            }
            return a.count - b.count;
          }

          return b.points - a.points;
        })
        .slice(0, 100);

      setTopUsers(topsInfo);

      const index = topsInfo.findIndex(
        (elem, i) => props.user.email == elem.email
      );
      if (index > -1) {
        setMeTop({
          place: index,
        });
      } else {
        setMeTop({
          place: -1,
        });
      }
    }
  }, [props.allUsers, props.admin, activeTournament]);

  useEffect(() => {
    if (activeGame) {
      // props.changeActiveMenuModal();
    }
  }, [activeGame]);

  function changeInfoOpen() {
    setInfoOpen((prevState) => !prevState);
  }

  function changeTournamentOpen() {
    setTournamentOpen((prevState) => !prevState);
  }

  function handleClickUser(user) {
    props.moveToUserRoom(user);
  }

  function changeRule(rule) {
    setRule(rule);
  }

  function setGame(name) {
    setActiveGame(name);
    props.setAdditionalPanel(name);
  }

  function updateTop() {
    if (activeTournament) {
      props.setIsFetching(true);
      props.app
        .service("admin")
        .get(props.user.admin, {
          query: {
            $select: [activeTournament],
          },
        })
        .then((adminData) => {
          props.setAdmin((prev) => ({ ...prev, ...adminData }));
          props.setIsFetching(false);
        })
        .catch((e) => {
          props.setModalError(e);
          console.log(e);
          props.setIsFetching(false);
        });
    }
  }

  function startTournament() {
    if (!activeTournament) {
      props.setModalError("Турнир закончился");
      console.log("Турнир закончился");
    } else {
      if (props.user[activeTournament].count >= 3) {
        props.setModalError("Лимит на участие в турнире");
        console.log("Лимит на участие в турнире");
      } else {
        if (props.user.gold >= goldCount[props.user[activeTournament].count]) {
          props.setIsFetching(true);
          props.app
            .service("users")
            .patch(
              props.user._id,
              {
                [`${activeTournament}.gamesDone`]: 0,
                [`${activeTournament}.points`]: 0,
                [`${activeTournament}.isStartTournament`]: true,

                $inc: {
                  [`${activeTournament}.count`]: 1,
                  gold: -goldCount?.[props.user[activeTournament].count],
                },
                field: props.serverTime,
              },
              {
                query: {
                  $select: ["_id", "email", `${activeTournament}`, "gold"],
                },
              }
            )
            .then((data) => {
              setActiveGame(activeTournament);
              setGame(activeTournament);
              setTournamentOpen(false);
              props.setUser((prev) => ({ ...prev, ...data }));
              props.setIsFetching(false);
            })
            .catch((e) => {
              props.setModalError(e);
              console.log(e);
              props.setIsFetching(false);
            });
        } else {
          props.setModalError("Недостаточно золота");
          console.log("Недостаточно золота");
        }
      }
    }
  }

  return (
    <>
      {(activeGame === "game21" || activeGame === "diceGame") && (
        <TopPart>
          <img src={imgTop} alt="интерфейс" />
        </TopPart>
      )}

      {activeGame === "diceGameTournament" ? (
        <CentredWrapper>
          <DiceGameTournament {...props} setActiveGame={setGame} />
        </CentredWrapper>
      ) : activeGame === "game21Tournament" ? (
        <CentredWrapper>
          <Game21Tournament {...props} setActiveGame={setGame} />
        </CentredWrapper>
      ) : activeGame === "game21" ? (
        <CentredWrapper>
          <TopButton>
            <Button minW={130} onClick={setGame.bind(null, "")}>
              <div>Назад в Игровую</div>
            </Button>
          </TopButton>
          <Game21 {...props} setActiveGame={setGame} />
        </CentredWrapper>
      ) : activeGame === "diceGame" ? (
        <CentredWrapper>
          <TopButton onClick={setGame.bind(null, "")}>
            <Button minW={130}>
              <div>Назад в Игровую</div>
            </Button>
          </TopButton>
          <DiceGame {...props} setActiveGame={setGame} />
        </CentredWrapper>
      ) : (
        <>
          <S.Place
            x={120}
            y={124}
            clip="polygon(30% 0, 65% 0, 55% 19%, 100% 50%, 100% 100%, 32% 100%, 0 100%, 0 47%, 39% 19%);"
            onClick={setGame.bind(null, "diceGame")}
          >
            <img src={diceGame} alt="кости" width={280} height={350} />
          </S.Place>
          <S.Place
            x={329}
            y={124}
            clip="polygon(25% 0, 61% 0, 50% 27%, 100% 61%, 100% 100%, 32% 100%, 0 100%, 0 28%, 36% 27%)"
            onClick={setGame.bind(null, "game21")}
          >
            <img src={game21} alt="21" width={470} height={430} />
          </S.Place>
        </>
      )}

      {domReady
        ? createPortal(
            <MenuRight>
              {(activeGame === "" ||
                activeGame === "diceGame" ||
                activeGame === "game21") && (
                <MenuElemRight name={"Правила"}>
                  <img width={80} height={80} src={rulesIcon} alt={"правила"} />
                  <div onClick={changeInfoOpen.bind(null, true)} />
                </MenuElemRight>
              )}
              {activeTournament &&
                (activeGame === "" ||
                  activeGame === "diceGameTournament" ||
                  activeGame === "game21Tournament") && (
                  <MenuElemRight name={"Турнир"}>
                    <img
                      width={80}
                      height={80}
                      src={iconsTournament[activeTournament]}
                      alt={"турнир"}
                    />
                    <div onClick={changeTournamentOpen.bind(null, true)} />
                  </MenuElemRight>
                )}
            </MenuRight>,
            document.querySelector("#menuRight")
          )
        : null}

      <Popup
        isOpen={tournamentOpen}
        setIsOpen={setTournamentOpen}
        w={768}
        h={557}
        back={frame9}
      >
        <Close
          onClick={() => {
            setTournamentOpen(false);
          }}
        />
        {activeTournament && tournamentOpen && (
          <S.Content>
            <div>
              <S.Header>
                Турнир по игре в{" "}
                {activeTournament === "game21Tournament" ? `"21"` : "кости"}
              </S.Header>
              <S.ColumnLeft>
                <span>До конца: {tournamentTimeString}</span>

                {activeTournament === "diceGameTournament" && (
                  <>
                    <span>
                      Цель турнира набрать наибольшее количество очков за{" "}
                      {maxGames} игр. В турнире можно принять участие не более
                      трёх раз
                    </span>

                    <span>
                      Приоритет при равных очках отдаётся игроку с меньшим
                      количеством попыток
                    </span>

                    <span>
                      Всем игрокам даётся {activeDices} активные кости и{" "}
                      {maxRolls} перебросов
                    </span>

                    <span>
                      Очки суммируются за все игры и зависят от комбинаций:
                    </span>

                    <S.Table1>
                      <tbody>
                        <tr>
                          <td scope="col">
                            <div>№</div>
                          </td>
                          <td scope="col">
                            <div>2 кости</div>
                          </td>
                          <td scope="col">
                            <div>3 кости</div>
                          </td>
                          <td scope="col">
                            <div>4 кости</div>
                          </td>
                        </tr>
                        <tr>
                          <td scope="col">
                            <div>1</div>
                          </td>
                          <td scope="col">
                            <div>1</div>
                          </td>
                          <td scope="col">
                            <div>2</div>
                          </td>
                          <td scope="col">
                            <div>4</div>
                          </td>
                        </tr>
                        <tr>
                          <td scope="col">
                            <div>2</div>
                          </td>
                          <td scope="col">
                            <div>2</div>
                          </td>
                          <td scope="col">
                            <div>4</div>
                          </td>
                          <td scope="col">
                            <div>8</div>
                          </td>
                        </tr>
                        <tr>
                          <td scope="col">
                            <div>3</div>
                          </td>
                          <td scope="col">
                            <div>4</div>
                          </td>
                          <td scope="col">
                            <div>8</div>
                          </td>
                          <td scope="col">
                            <div>16</div>
                          </td>
                        </tr>
                        <tr>
                          <td scope="col">
                            <div>4</div>
                          </td>
                          <td scope="col">
                            <div>6</div>
                          </td>
                          <td scope="col">
                            <div>12</div>
                          </td>
                          <td scope="col">
                            <div>24</div>
                          </td>
                        </tr>
                        <tr>
                          <td scope="col">
                            <div>5</div>
                          </td>
                          <td scope="col">
                            <div>8</div>
                          </td>
                          <td scope="col">
                            <div>16</div>
                          </td>
                          <td scope="col">
                            <div>32</div>
                          </td>
                        </tr>
                        <tr>
                          <td scope="col">
                            <div>6</div>
                          </td>
                          <td scope="col">
                            <div>10</div>
                          </td>
                          <td scope="col">
                            <div>20</div>
                          </td>
                          <td scope="col">
                            <div>40</div>
                          </td>
                        </tr>
                      </tbody>
                    </S.Table1>
                  </>
                )}

                {activeTournament === "game21Tournament" && (
                  <>
                    <span>
                      Цель турнира набрать наибольшее количество очков за{" "}
                      {maxGames} игр. В турнире можно принять участие не более
                      трёх раз
                    </span>

                    <span>
                      Приоритет при равных очках отдаётся игроку с меньшим
                      количеством попыток
                    </span>

                    <span>
                      Очки суммируются за все игры и зависят от расклада:
                    </span>

                    <S.Table2>
                      <tbody>
                        <tr>
                          <td scope="col">
                            <div>Расклад</div>
                          </td>
                          <td scope="col">
                            <div>Очки</div>
                          </td>
                        </tr>
                        <tr>
                          <td scope="col">
                            <div>поражение перебор</div>
                          </td>
                          <td scope="col">
                            <div>0</div>
                          </td>
                        </tr>
                        <tr>
                          <td scope="col">
                            <div>поражение недобор</div>
                          </td>
                          <td scope="col">
                            <div>2</div>
                          </td>
                        </tr>
                        <tr>
                          <td scope="col">
                            <div>поражение поровну</div>
                          </td>
                          <td scope="col">
                            <div>5</div>
                          </td>
                        </tr>
                        <tr>
                          <td scope="col">
                            <div>победа</div>
                          </td>
                          <td scope="col">
                            <div>10</div>
                          </td>
                        </tr>
                        <tr>
                          <td scope="col">
                            <div>победа с 21</div>
                          </td>
                          <td scope="col">
                            <div>15</div>
                          </td>
                        </tr>
                        <tr>
                          <td scope="col">
                            <div>победа 2 туза</div>
                          </td>
                          <td scope="col">
                            <div>20</div>
                          </td>
                        </tr>
                        <tr>
                          <td scope="col">
                            <div>победа 678/777</div>
                          </td>
                          <td scope="col">
                            <div>25</div>
                          </td>
                        </tr>
                        <tr>
                          <td scope="col">
                            <div>победа 5 картинок</div>
                          </td>
                          <td scope="col">
                            <div>30</div>
                          </td>
                        </tr>
                      </tbody>
                    </S.Table2>
                  </>
                )}
              </S.ColumnLeft>

              <S.ColumnBottom height={74}>
                {props.user[activeTournament].isStartTournament ? (
                  <span>Вы уже участвуете</span>
                ) : props.user[activeTournament].count >= 3 ? (
                  <span>Лимит на участие</span>
                ) : (
                  <>
                    <S.PriceCount>
                      <span>
                        Попытка {props.user[activeTournament].count + 1}:
                      </span>
                      {!props.user[activeTournament].count ? (
                        <span>Бесплатно</span>
                      ) : (
                        <Reward
                          name={"gold"}
                          count={`взнос ${
                            goldCount?.[props.user[activeTournament].count]
                          }`}
                          w={13}
                          button={true}
                        />
                      )}
                    </S.PriceCount>
                    <Button
                      minW={130}
                      onClick={!props.isFetching ? startTournament : null}
                    >
                      <div>Начать</div>
                    </Button>
                  </>
                )}
              </S.ColumnBottom>
            </div>
            <div>
              <S.Header>Рейтинг</S.Header>
              <S.ColumnRight>
                <S.Names>
                  <span>Место</span>
                  <span>Игрок</span>
                  <span>Ник</span>
                  <span>Очки</span>
                  <span>Награда</span>
                </S.Names>

                <Tops>
                  {topUsers.length > 0 &&
                    topUsers.map((user, i) => {
                      return (
                        <S.Top
                          isMe={user.email === props.user.email}
                          isWin={i < 3}
                          key={user.email}
                        >
                          <Place>
                            {i < 3 ? (
                              <img
                                src={places[i]}
                                width={38}
                                height={38}
                                alt="место"
                              />
                            ) : (
                              i + 1
                            )}
                          </Place>
                          <Avatar
                            isMe={user.email === props.user.email}
                            onClick={
                              user.email !== props.user.email
                                ? handleClickUser.bind(null, user)
                                : null
                            }
                          >
                            <img
                              src={user.photo}
                              width={35}
                              height={35}
                              alt="аватар"
                            />
                          </Avatar>
                          <div>
                            <span>
                              {user.orden?.tag ? `[${user.orden?.tag}] ` : ""}
                            </span>
                            <span>
                              {user.nickName
                                ? user.nickName
                                : `${user.name?.firstName} ${user.name?.lastName}`}
                            </span>
                          </div>
                          <span>{user.points}</span>

                          <S.PrizesTop>
                            {i === 0 && (
                              <Reward
                                count={"1"}
                                name={"rareClothes"}
                                button={true}
                                w={15}
                                g={4}
                              />
                            )}

                            {i === 1 && (
                              <Reward
                                count={"2"}
                                name={"commonClothes"}
                                button={true}
                                w={15}
                                g={4}
                              />
                            )}

                            {i === 2 && (
                              <Reward
                                count={"1"}
                                name={"commonClothes"}
                                button={true}
                                w={15}
                                g={4}
                              />
                            )}

                            {i < 10 ? (
                              <Reward
                                count={tournamentPrizes[i].gold}
                                name={"gold"}
                                button={true}
                                w={15}
                              />
                            ) : (
                              "—"
                            )}
                          </S.PrizesTop>
                        </S.Top>
                      );
                    })}
                </Tops>
              </S.ColumnRight>

              <MeTop>
                <S.Top meTop={true}>
                  <Place>
                    {meTop.place > -1 ? (
                      meTop.place < 3 ? (
                        <img
                          src={places[meTop.place]}
                          width={38}
                          height={38}
                          alt="место"
                        />
                      ) : meTop.place >= 100 ? (
                        "100+"
                      ) : (
                        meTop.place + 1
                      )
                    ) : (
                      "—"
                    )}
                  </Place>
                  <Avatar isMe={true}>
                    <img
                      src={props.user.photo}
                      width={35}
                      height={35}
                      alt="аватар"
                    />
                  </Avatar>
                  <div>
                    <span>
                      {props.user.orden?.tag
                        ? `[${props.user.orden?.tag}] `
                        : ""}
                    </span>
                    <span>
                      {props.user.nickName
                        ? props.user.nickName
                        : `${props.user.name?.firstName} ${props.user.name?.lastName}`}
                    </span>
                  </div>
                  <span>{props.user[activeTournament].maxPoints}</span>

                  <S.PrizesTop>
                    {meTop.place === 0 && (
                      <Reward
                        count={"1"}
                        name={"rareClothes"}
                        button={true}
                        w={15}
                        g={4}
                      />
                    )}

                    {meTop.place === 1 && (
                      <Reward
                        count={"2"}
                        name={"commonClothes"}
                        button={true}
                        w={15}
                        g={4}
                      />
                    )}

                    {meTop.place === 2 && (
                      <Reward
                        count={"2"}
                        name={"commonClothes"}
                        button={true}
                        w={15}
                        g={4}
                      />
                    )}

                    {meTop.place >= 0 && meTop.place < 10 ? (
                      <Reward
                        count={tournamentPrizes[meTop.place].gold}
                        name={"gold"}
                        button={true}
                        w={15}
                      />
                    ) : (
                      "—"
                    )}
                  </S.PrizesTop>
                </S.Top>
              </MeTop>
            </div>
          </S.Content>
        )}
      </Popup>

      <Popup
        isOpen={infoOpen}
        setIsOpen={setInfoOpen}
        w={768}
        h={557}
        back={frame9}
      >
        <Close
          onClick={() => {
            setInfoOpen(false);
          }}
        />

        <MenuButtons>
          <Button
            minW={130}
            disabled={rule === "game21"}
            onClick={changeRule.bind(null, "game21")}
          >
            <div>Игра в 21</div>
          </Button>
          <Button
            minW={130}
            disabled={rule === "diceGame"}
            onClick={changeRule.bind(null, "diceGame")}
          >
            <div>Игра в кости</div>
          </Button>
        </MenuButtons>

        <S.RulesContent>
          {rule === "game21" ? (
            <>
              <b>Правила</b>
              <S.RulesWrapper>
                <span>Набери больше очков, чем у крупье</span>
                <span>На руках можно иметь не более 5 карт</span>
                <span>Крупье побеждает при равных очках</span>
                <span>
                  Туз - 11 очков или 1 очко, король - 4 очка, дама - 3 очка,
                  валет - 2 очка, остальные - по номиналу
                </span>
              </S.RulesWrapper>

              <S.Game21Prize>
                <S.ImgArrowLeft width={150} src={arrowLeft} />
                <b>Призы</b>
                <S.ImgArrowRight width={150} src={arrowLeft} />
              </S.Game21Prize>

              <S.Game21PrizeBottom>
                <div>
                  <b>Обычные победы</b>

                  <S.PrizesWrapper>
                    <span>Победа с 21 очком - душа Жуткого Завхоза</span>
                    <span>Победа с 20 очками - душа Призрака Плаксы</span>
                    <span>Победа с 19 очками и менее - душа Полтергейста</span>
                  </S.PrizesWrapper>
                </div>

                <div>
                  <b>Особые комбинации</b>

                  <S.PrizesWrapper>
                    <span>Два туза - душа Кошмара при любом раскладе.</span>
                    <span>
                      Пять 'картинок', '678' или '777' - душа Зловещего Призрака
                      при любом раскладе.
                    </span>
                  </S.PrizesWrapper>
                </div>
              </S.Game21PrizeBottom>
            </>
          ) : rule === "diceGame" ? (
            <>
              <b>Правила</b>
              <S.RulesWrapper>
                <span>Собери 2 или более кости с одинаковым числом</span>
                <span>Перебрасывать можно только активные кости</span>
                <span>Неактивные кости также засчитываются</span>
                <span>Собрать можно две комбинации одновременно</span>
                <span>Чем выше число, тем ниже шанс его выпадения</span>
              </S.RulesWrapper>

              <b>Улучшения</b>
              <S.PrizesWrapper>
                <span>Со старта доступно 2 активные кости и 2 переброса</span>
                <span>3 переброса с 3 уровня прокачки</span>
                <span>3 активных кости с 10 уровня прокачки</span>
                <span>4 переброса с 50 уровня прокачки</span>
                <span>4 активных кости с 100 уровня прокачки</span>
                <span>5 перебросов с 250 уровня прокачки</span>
                <span>6 перебросов с 500 уровня прокачки</span>
              </S.PrizesWrapper>
            </>
          ) : null}
        </S.RulesContent>
      </Popup>
    </>
  );
}

export default React.memo(GameRoom);
