import React, { useEffect, useState } from "react";
import InputNumber from "../InputNumber/InputNumber";
import * as S from "./ShopElem.styles";
import Reward from "../Reward/Reward";

function ShopElem({
  gold,
  silver,
  maxCharacCount,
  damageTalents,
  elem,
  loading,
  isModalOpen,
  buyItem,
}) {
  const [count, setCount] = useState(1);
  const [hpPriceScale, setHpPriceScale] = useState(
    1 + Math.floor(maxCharacCount / 1000) * 0.5
  );

  useEffect(() => {
    const hpPriceScale = 1 + Math.floor(maxCharacCount / 1000) * 0.5;
    setHpPriceScale(hpPriceScale);
  }, [maxCharacCount]);

  return (
    <S.Wrapper>
      {elem.category === "soul" ? (
        <Reward name={"soul"} soul={elem.value} w={30} button={true} />
      ) : (
        <Reward
          font={14}
          name={elem.category}
          count={elem.name}
          w={30}
          button={true}
        />
      )}

      {/*{elem.time > 0 ? (*/}
      {/*  <span>Время:{secondsToMs(elem.time)}</span>*/}
      {/*) : (*/}
      {/*  <span>Моментально</span>*/}
      {/*)}*/}
      <InputNumber
        min={1}
        max={Math.floor(
          (elem.price.property === "gold" ? gold : silver) /
            Math.floor(
              elem.price.count *
                (elem.category === "hp" && elem.price.property === "silver"
                  ? (damageTalents.simpleTalents.hpSilverDiscount > 0
                      ? 1 - damageTalents.simpleTalents.hpSilverDiscount / 100
                      : 1) * hpPriceScale
                  : 1)
            )
        )}
        setCount={setCount}
      />
      <button
        onClick={
          !loading && !isModalOpen
            ? buyItem.bind(null, elem, Number(count))
            : null
        }
      >
        <Reward
          button={true}
          name={elem.price.property}
          count={
            Math.floor(
              elem.price.count *
                (elem.category === "hp" && elem.price.property === "silver"
                  ? (damageTalents.simpleTalents.hpSilverDiscount > 0
                      ? 1 - damageTalents.simpleTalents.hpSilverDiscount / 100
                      : 1) * hpPriceScale
                  : 1)
            ) * count
          }
          w={15}
        />
      </button>
    </S.Wrapper>
  );
}

export default React.memo(ShopElem);
