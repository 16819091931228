import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
`;

export const Top3 = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }
`;

export const TopLog = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${(props) => (props.isRaid ? "300px" : "150px")};

  background-color: rgba(0, 0, 0, 0.5);
  outline: 1px solid #281707;
  box-shadow: inset 0px 0px 0px 1px #281707;
  border: 2px solid #fc9800;
  border-radius: 15px;
  overflow: hidden;

  & > div:first-child {
    margin: 8px;

    height: ${(props) => (props.isRaid ? "240px" : "90px")};
    display: flex;
    flex-direction: column;
    gap: 4px;
    overflow: hidden;
    overflow-y: scroll;
    & > div {
      color: #fcc382;
      font-family: "Bellota-Regular";
      font-size: 13px;
      line-height: 17px;
      display: flex;
      justify-items: end;
      align-items: center;
      gap: 5px;
    }
  }

  & > div:nth-child(2) {
    border-top: 2px solid #fc9800;
    background-color: #3d2909;
    display: flex;
    flex: 1;
    align-items: center;
    & > div {
      margin: 0 8px;
      color: #fcc382;
      font-family: "Bellota-Regular";
      font-size: 13px;
      line-height: 17px;
      display: flex;
      justify-items: end;
      align-items: center;
      gap: 5px;
    }
  }
`;
