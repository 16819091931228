import React, { useRef, useState } from "react";
import * as S from "./FacultyTest.styles";

import { CentredWrapper } from "../../App.styles";
import podpisImg from "../../img/Podpis.png";
import griffImg from "../../img/gerb/Griff.png";
import puffImg from "../../img/gerb/Puff.png";
import kogtImg from "../../img/gerb/Kogt.png";
import slizImg from "../../img/gerb/Sliz.png";
import { getMultipleRandom } from "../../helpers";

const dataImages = [
  [
    { x: 89, y: 194, w: 154, h: 204 },
    { x: 238, y: 173, w: 173, h: 159 },
    {
      x: 294,
      y: 272,
      w: 294,
      h: 251,
      polygon:
        "polygon(59% 98%, 77% 72%, 96% 75%, 100% 55%, 52% 1%, 36% 1%, 18% 15%, 0 41%, 2% 60%, 39% 98%)",
    },
    { x: 444, y: 135, w: 270, h: 205 },
  ],
  [
    { x: 91, y: 192, w: 236, h: 183 },
    { x: 262, y: 371, w: 246, h: 142 },
    { x: 562, y: 155, w: 180, h: 237 },
    { x: 316, y: 170, w: 261, h: 209 },
  ],
  [
    { x: 250, y: 352, w: 193, h: 145, scale: 9 },
    { x: 509, y: 186, w: 196, h: 147, scale: 11 },
    { x: 307, y: 202, w: 196, h: 147, scale: -5 },
    { x: 106, y: 221, w: 196, h: 147, scale: -40 },
  ],
  [
    { x: 296, y: 188, w: 215, h: 256, scale: -21 },
    { x: 174, y: 333, w: 132, h: 178 },
    { x: 539, y: 89, w: 183, h: 332, scale: 7 },
    { x: 107, y: 157, w: 140, h: 173, scale: 32 },
  ],
];

const facultyNames = {
  gryffindor: "Храбрость",
  ravenclaw: "Мудрость",
  hufflepuff: "Верность",
  slytherin: "Хитрость",
};

const facultyImages = {
  gryffindor: griffImg,
  ravenclaw: kogtImg,
  hufflepuff: puffImg,
  slytherin: slizImg,
};

const facultyText = {
  gryffindor:
    "Благородные подвиги и храбрые поступки на веки сохранят твоё имя в истории нашего факультета!",
  ravenclaw:
    "Незыблемый интеллект и творческий подход помогут тебе решать все поставленные задачи!",
  hufflepuff:
    "Верность твоего сердца и честность намерений привела тебя в самую сплочённую семью Школы Магии!",
  slytherin:
    "По-настоящему великие свершения и предстоящая всеобъемлющая слава привела тебя к нам!",
};

const questionsArray = [
  {
    question: "Что тебе нужно для проведения свободного времени?",
    answers: [
      {
        value: "",
        faculty: "gryffindor",
      },
      {
        value: "",
        faculty: "ravenclaw",
      },
      {
        value: "",
        faculty: "hufflepuff",
      },
      {
        value: "",
        faculty: "slytherin",
      },
    ],
  },
  {
    question: "Какая палочка тебя точно послушается?",
    answers: [
      {
        value: "",
        faculty: "hufflepuff",
      },
      {
        value: "",
        faculty: "slytherin",
      },
      {
        value: "",
        faculty: "gryffindor",
      },
      {
        value: "",
        faculty: "ravenclaw",
      },
    ],
  },
  {
    question: "В какой комнате тебе было бы комфортно?",
    answers: [
      {
        value: "",
        faculty: "ravenclaw",
      },
      {
        value: "",
        faculty: "slytherin",
      },
      {
        value: "",
        faculty: "hufflepuff",
      },
      {
        value: "",
        faculty: "gryffindor",
      },
    ],
  },
  {
    question: "Какой предмет в Школе Магии тебе интереснее?",
    answers: [
      {
        value: "",
        faculty: "ravenclaw",
      },
      {
        value: "",
        faculty: "slytherin",
      },
      {
        value: "",
        faculty: "gryffindor",
      },
      {
        value: "",
        faculty: "hufflepuff",
      },
    ],
  },
];

const lastTestArray = [
  {
    isActive: -1,
    question: "Что ты больше всего ценишь в жизни?",
    answers: [
      {
        value: "Приключения",
        faculty: "gryffindor",
      },
      {
        value: "Знания",
        faculty: "ravenclaw",
      },
      {
        value: "Дружбу",
        faculty: "hufflepuff",
      },
      {
        value: "Самореализацию",
        faculty: "slytherin",
      },
    ],
  },
  {
    isActive: -1,
    question: "Кем бы ты хотел быть?",
    answers: [
      {
        value: "Лидером мнений",
        faculty: "slytherin",
      },
      {
        value: "Героем, защитником людей",
        faculty: "gryffindor",
      },
      {
        value: "Ученым, первооткрывателем",
        faculty: "ravenclaw",
      },
      {
        value: "Врачом, волонтером",
        faculty: "hufflepuff",
      },
    ],
  },
  {
    isActive: -1,
    question: "Ваши друзья обычно:",
    answers: [
      {
        value: "Умны и интересны в беседе",
        faculty: "ravenclaw",
      },
      {
        value: "Успешны, богаты и утонченны",
        faculty: "slytherin",
      },
      {
        value: "Преданы и не бросят в беде",
        faculty: "hufflepuff",
      },
      {
        value: "Настоящие и на всю жизнь",
        faculty: "gryffindor",
      },
    ],
  },
  {
    isActive: -1,
    question: "По характеру ты больше:",
    answers: [
      {
        value: "Умен и сообразителен, поддержишь любую беседу",
        faculty: "ravenclaw",
      },
      {
        value: "Всегда идешь к своей цели, невзирая на трудности",
        faculty: "gryffindor",
      },
      {
        value: "Уважаешь свои личные границы, зная себе цену",
        faculty: "slytherin",
      },
      {
        value: "Мягкий и добрый, готовы всем помогать",
        faculty: "hufflepuff",
      },
    ],
  },
];

function FacultyTest({
  app,
  setUser,
  _id,
  name,
  setIsFetching,
  setModalError,
  serverTime,
  testStage,
  setTestStage,
}) {
  const [faculty, setFaculty] = useState(null);
  const [activeNumber, setActiveNumber] = useState(1);
  const [testArray, setTestArray] = useState([...lastTestArray]);
  const [disableFinish, setDisableFinish] = useState(true);
  const facultyScore = useRef({
    gryffindor: 0,
    hufflepuff: 0,
    ravenclaw: 0,
    slytherin: 0,
  });

  function changeStage(stage) {
    setTestStage(stage);
  }

  function changeTestAnswer(question, number) {
    setTestArray((prev) => {
      const array = [...prev];
      array[question].isActive = number;
      if (array.findIndex((elem) => elem.isActive === -1) === -1) {
        setDisableFinish(false);
      }
      return array;
    });
  }

  function getFaculty() {
    setIsFetching(true);
    app
      .service("users")
      .patch(
        _id,
        {
          faculty: faculty,
          field: serverTime,
        },
        {
          query: {
            $select: ["_id", "email", "faculty"],
          },
        }
      )
      .then((data) => {
        setUser((prev) => ({ ...prev, ...data }));
        setIsFetching(false);
      })
      .catch((e) => {
        setModalError(e);
        setIsFetching(false);
        console.log(e);
      });
  }

  function getAnswer(faculty) {
    facultyScore.current[faculty] += 1;
    if (activeNumber === questionsArray.length) {
      changeStage(3);
    }
    if (activeNumber < questionsArray.length) {
      setActiveNumber((prev) => {
        return prev + 1;
      });
    }
  }

  function checkLastTest() {
    testArray.forEach((elem) => {
      facultyScore.current[elem.answers[elem.isActive].faculty] += 1;
    });

    const facultySorted = Object.entries(facultyScore.current).sort(
      ([, a], [, b]) => b - a
    );
    const maxScore = facultySorted[0][1];
    const faculty = getMultipleRandom(
      facultySorted.filter(([, score]) => score === maxScore)
    );
    setFaculty(faculty[0][0]);
  }

  return (
    <S.Wrapper>
      <CentredWrapper>
        <S.Content>
          {testStage === 2 ? (
            [1, 2, 3, 4].map((_, number) => {
              return (
                <React.Fragment key={(activeNumber - 1) * 4 + number}>
                  <S.QuestionText>
                    {questionsArray[activeNumber - 1].question}
                  </S.QuestionText>
                  <S.ImgAnswer
                    onClick={getAnswer.bind(
                      null,
                      questionsArray[activeNumber - 1].answers[number].faculty
                    )}
                    {...dataImages[activeNumber - 1][number]}
                  >
                    <div>
                      <img
                        src={require(`../../img/test/${activeNumber}/image/${
                          number + 1
                        }.png`)}
                        alt="тест"
                      />
                      <img
                        src={require(`../../img/test/${activeNumber}/hover/${
                          activeNumber === 3 ? "all" : number + 1
                        }.png`)}
                        alt="тест"
                      />
                    </div>
                  </S.ImgAnswer>
                </React.Fragment>
              );
            })
          ) : testStage === 1 ? (
            <S.StartWrapper>
              <S.StartHeader>
                Дорогой {`${name?.firstName} ${name?.lastName}`}
              </S.StartHeader>
              <div>
                Мы с гордостью приветствуем тебя в нашей школе, открывающей
                двери в безграничный мир магии.
              </div>
              <div>
                Поступи на один из четырех факультетов и достойно защищай его
                честь, принося ему ценные очки рейтинга.
              </div>
              <div>
                Стань частью существующего ордена или образуй новый, чтобы
                вместе с соратниками прославиться в честной схватке с самыми
                мощными врагами.
              </div>
              <div>
                Учись новым заклинаниям и набирайся полезных знаний на уроках
                магии, дополняя арсенал своей силы.
              </div>
              <div>
                Соревнуйся с друзьями, сражайся с босами и раскрой свою
                безупречность волшебника.
              </div>
              <div>
                Отправляйся собирать свои вещи и не опоздай на распределение по
                факультетам!
              </div>
              <S.Podpis>
                <img width={70} height={27} src={podpisImg} alt="подпись" />
                <S.LineSing />
              </S.Podpis>
              <S.StartSign>С уважением, директор Школы Магии</S.StartSign>

              <S.StartButton>
                <div onClick={changeStage.bind(null, 2)}>
                  Начать путешествие
                </div>
              </S.StartButton>
            </S.StartWrapper>
          ) : testStage === 3 && !faculty ? (
            <S.TestWrapper>
              {testArray.map((elem, i) => {
                return (
                  <S.QuestionTest key={i}>
                    <hr />
                    <span>{elem.question}</span>
                    <div>
                      {elem.answers.map((el, number) => {
                        return (
                          <S.TestAnswer
                            isActive={number === elem.isActive}
                            key={i * testArray.length + number}
                            onClick={changeTestAnswer.bind(null, i, number)}
                          >
                            <div />
                            <span>{el.value}</span>
                          </S.TestAnswer>
                        );
                      })}
                    </div>
                  </S.QuestionTest>
                );
              })}
              <S.StartButton
                disabled={disableFinish}
                onClick={!disableFinish ? checkLastTest : null}
              >
                <div>Отправиться в школу</div>
              </S.StartButton>
            </S.TestWrapper>
          ) : (
            <S.FinishWrapper>
              <div>Поздравляем с зачислением, студент!</div>
              <div>Волшебная шляпа считает, что твой факультет это:</div>
              <S.NameFaculty>{facultyNames[faculty]}</S.NameFaculty>
              <S.ImgFaculty
                width={135}
                height={138}
                src={facultyImages[faculty]}
                alt="факультет"
              />
              <S.LastTestTest>{facultyText[faculty]}</S.LastTestTest>
              <S.StartButton onClick={getFaculty}>
                <div>Отправиться в гостинную</div>
              </S.StartButton>
            </S.FinishWrapper>
          )}
        </S.Content>
      </CentredWrapper>
    </S.Wrapper>
  );
}

export default React.memo(FacultyTest);
