import React, { useEffect, useState } from "react";

import * as S from "./MoneyInfo.styles";

import goldImg from "../../img/book/Gold.png";
import silverImg from "../../img/book/Silver.png";
import essenceImg from "../../img/book/Essence.png";
import manaImg from "../../img/book/Mana.png";
import { roundConsumables } from "../../helpers";

function MoneyInfo({ gold, silver, essence, mana }) {
  const [goldData, setGoldData] = useState(roundConsumables(gold));
  const [silverData, setSilverData] = useState(roundConsumables(silver));
  const [essenceData, setEssenceData] = useState(roundConsumables(essence));
  const [manaData, setManaData] = useState(roundConsumables(mana));

  useEffect(() => {
    setGoldData((prev) => (gold === prev ? gold : roundConsumables(gold)));
  }, [gold]);

  useEffect(() => {
    setSilverData((prev) =>
      silver === prev ? silver : roundConsumables(silver)
    );
  }, [silver]);

  useEffect(() => {
    setEssenceData((prev) =>
      essence === prev ? essence : roundConsumables(essence)
    );
  }, [essence]);

  useEffect(() => {
    setManaData((prev) => (mana === prev ? mana : roundConsumables(mana)));
  }, [mana]);

  return (
    <S.Wrapper
      gold={gold.toLocaleString("ru")}
      silver={silver.toLocaleString("ru")}
      essence={essence.toLocaleString("ru")}
      mana={mana.toLocaleString("ru")}
    >
      <div>
        <img src={goldImg} width={18} height={18} alt={"золото"} />
        <span>{goldData}</span>
      </div>
      <div>
        <img src={silverImg} width={18} height={18} alt={"серебро"} />
        <span>{silverData}</span>
      </div>
      <div>
        <img src={essenceImg} width={18} height={18} alt={"эссенция"} />
        <span>{essenceData}</span>
      </div>
      <div>
        <img src={manaImg} width={18} height={18} alt={"эссенция"} />
        <span>{manaData}</span>
      </div>
    </S.Wrapper>
  );
}

export default React.memo(MoneyInfo);
