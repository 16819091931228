import React from "react";

import * as S from "./NickName.styles";

function NickName({ userName, nickName }) {
  return (
    <S.Wrapper>
      <span>
        {nickName
          ? nickName
          : !nickName
          ? `${userName?.firstName} ${userName?.lastName}`
          : "Ваш Никнейм"}
      </span>
    </S.Wrapper>
  );
}

export default React.memo(NickName);
