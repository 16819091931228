import styled from "styled-components";
import buttonPart from "../../img/boss/ButtonPart.png";

export const Damage = styled.div`
  position: absolute;
  left: 430px;
  top: 192px;
  width: 75px;
  height: 114px;
  cursor: pointer;
  opacity: 0;
  transition: 0.3s linear;
  &:hover {
    opacity: 1;
  }

  background-image: url("${(props) => props.back}");
  background-size: cover;
`;

export const BoxHover = styled.div`
  position: absolute;
  left: 329px;
  top: 325px;
  width: 141px;
  height: 175px;
  background-image: url("${(props) => props.back}");
  background-size: cover;

  cursor: pointer;
  opacity: 0;
  transition: 0.3s linear;
  &:hover {
    opacity: 1;
  }
`;

export const Essence = styled.div`
  display: flex;
  position: absolute;
  left: 124px;
  top: 341px;
  width: 200px;
  height: 171px;
  cursor: pointer;
  opacity: 0;
  transition: 0.3s linear;
  clip-path: polygon(0 0, 52% 0, 100% 50%, 100% 100%, 0 100%);

  &:hover {
    animation-play-state: paused;
    transition: 0.3s linear;
    opacity: 1 !important;
  }

  background-image: url("${(props) => props.back}");
  background-size: cover;
`;

export const Mana = styled.div`
  display: flex;
  position: absolute;
  left: 454px;
  top: 280px;
  width: 180px;
  height: 225px;
  cursor: pointer;
  opacity: 0;
  transition: 0.3s linear;
  clip-path: polygon(45% 0, 100% 0, 100% 50%, 100% 100%, 0 100%, 0 40%);
  &:hover {
    opacity: 1;
  }

  background-image: url("${(props) => props.back}");
  background-size: cover;
`;

export const Dobbi = styled.div`
  display: flex;
  position: absolute;
  left: 574px;
  top: 316px;
  width: 115px;
  height: 185px;

  background-image: url("${(props) => props.back}");
  background-size: cover;

  clip-path: polygon(
    50% 0,
    83% 6%,
    100% 60%,
    85% 100%,
    25% 100%,
    0 45%,
    10% 8%
  );
`;

export const DobbiHover = styled.div`
  display: flex;
  position: absolute;
  left: 574px;
  top: 316px;
  width: 115px;
  height: 185px;
  cursor: pointer;
  opacity: 0;
  transition: 0.3s linear;

  clip-path: polygon(
    50% 0,
    83% 6%,
    100% 60%,
    85% 100%,
    25% 100%,
    0 45%,
    10% 8%
  );

  &:hover {
    opacity: 1;
  }

  background-image: url("${(props) => props.back}");
  background-size: cover;
`;

export const Gold = styled.div`
  display: flex;
  position: absolute;
  left: 201px;
  top: 232px;
  width: 288px;
  height: 234px;
  cursor: pointer;
  opacity: 0;
  transition: 0.3s linear;
  clip-path: polygon(0 0, 52% 0, 100% 50%, 100% 100%, 0 100%);
  &:hover {
    opacity: 1;
  }

  background-image: url("${(props) => props.back}");
  background-size: cover;
`;

export const DamageBoard = styled.div`
  position: absolute;
  left: 430px;
  top: 192px;
  width: 75px;
  height: 114px;
  background-image: url("${(props) => props.back}");
  background-size: cover;
`;

export const Box = styled.div`
  position: absolute;
  left: 329px;
  top: 325px;
  width: 141px;
  height: 175px;
  background-image: url("${(props) => props.back}");
  background-size: cover;
`;

export const SaleWrapper = styled.div`
  position: absolute;
  left: 311px;
  top: 344px;
  width: 145px;
  height: 162px;
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 100% 100%, 0 100%, 0% 50%);

  background-image: url("${(props) => props.back}");
  background-size: cover;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 800px;
  height: 548px;
  gap: 10px;
  & > div {
    padding: 20px 20px;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }

  background-image: url("${(props) => props.back}");
  background-size: cover;
`;

export const Sale = styled.div`
  text-align: center;
  & > span {
    font-size: 16px;
  }
  display: flex;
  flex-direction: column;
`;

export const StockLvl = styled.div`
  & > span {
    font-size: 16px;
  }
  & > div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    & > div {
      display: flex;
      justify-content: center;
      gap: 5px;
      line-height: 1.5;
    }
  }
`;

export const PropertyWrapper = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 10px;
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;

    gap: 5px;
    & button {
      font-size: 12px;
    }
  }
`;

export const Price = styled.div`
  display: flex;
  justify-content: center;
  gap: 2px;
`;

export const BoxButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const Button = styled.div`
  cursor: ${(props) => !props.disabled && "pointer"};
  position: relative;
  transition: filter 0.15s linear;
  min-width: ${(props) => props.minW && `${props.minW}px`};
  backface-visibility: hidden;
  will-change: transform;
  &::before {
    aspect-ratio: 18 / 50;
    height: 130%;
    position: absolute;
    left: 0;
    top: 50%;
    content: "";
    background-image: url(${buttonPart});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transform: translate(0%, -50%);
  }
  &::after {
    aspect-ratio: 18 / 50;
    height: 130%;
    position: absolute;
    right: 0;
    top: 50%;
    content: "";
    background-image: url(${buttonPart});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transform: translate(0%, -50%) rotateZ(180deg);
  }
  &:hover {
    filter: ${(props) => !props.disabled && "brightness(1.2)"};
  }
  &:active {
    filter: ${(props) => !props.disabled && "brightness(0.9)"};
  }
  filter: ${(props) => props.isDone && "brightness(0.7)"};
  border-radius: 36px;
  box-shadow: 0px 0.5px 0px 1px #281707;
  background: ${(props) =>
    props.isActive
      ? `linear-gradient(0deg, rgba(255, 223, 175, 0.2), rgba(255, 223, 175, 0.2)), linear-gradient(180deg, rgba(78, 39, 0, 0) 0%, #4E2700 100%), linear-gradient(180deg, rgba(255, 217, 159, 0.1) 0%, rgba(255, 223, 176, 0) 42.19%), #6B3700;
`
      : props.disabled
      ? `linear-gradient(0deg, #301E0D, #301E0D), linear-gradient(180deg, rgba(78, 39, 0, 0) 0%, #4E2700 100%), linear-gradient(180deg, rgba(255, 217, 159, 0.1) 0%, rgba(255, 223, 176, 0) 42.19%), #6B3700;
`
      : `linear-gradient(180deg, rgba(78, 39, 0, 0) 0%, #4e2700 100%),
linear-gradient(
180deg,
rgba(255, 217, 159, 0.1) 0%,
rgba(255, 223, 176, 0) 42.19%
),
#6b3700`};
  & > div {
    display: flex;
    justify-content: center;
    box-shadow: 0px 0px 0px 1px #281707 inset;
    border: 2px solid #e98101;
    border-radius: 36px;
    padding: 2px 10px;
    color: #fcc382;
    font-family: "Bellota-Regular";
    font-size: 12px;
    line-height: 1.5;
    font-weight: 400;
  }
`;
