import styled from "styled-components";

export const NeedCollections = styled.div`
  width: 90%;
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 0px 1px #281707 inset;
  border: 2px solid #e98101;
  border-radius: 3px;
  padding: 5px;
  margin-bottom: 15px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 5px;
  padding-top: 10px;
  & > button {
    display: flex;
    gap: 5px;
    line-height: 15px;
  }
`;

export const BossesCup = styled.div`
  display: flex;
  position: absolute;
  left: 582px;
  top: 145px;
  cursor: pointer;
  opacity: 0;
  transition: 0.3s linear;
  &:hover {
    opacity: 1;
  }
  & > div {
    text-align: center;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    font-weight: 600;
    display: flex;
    gap: 3px;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translateX(-50%);

    color: #f7b471;
    font-size: 12px;
    transition: all 0.1s linear;
    padding: 3px 6px;
    background: rgba(17, 12, 9, 0.8);
    border-radius: 5px;

    outline: 1px solid #281707;
    box-shadow: inset 0px 0px 0px 1px #281707;
    border: 2px solid #fc9800;
    z-index: 1;
    width: max-content;

    & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
`;

export const ArenaCup = styled(BossesCup)`
  left: 688px;
  top: 206px;
`;

export const ClassroomsCup = styled(BossesCup)`
  left: 625px;
  top: 216px;
`;

export const GamesCup = styled(BossesCup)`
  left: 582px;
  top: 220px;
`;
