import React, { useState } from "react";

import * as S from "./Collections.styles";
import Collection from "../Collection/Collection";
import backImg from "../../img/Frame4.png";
import { Close } from "../../App.styles";
import { collectionsArray } from "../../info/data";
import CollectionsHistory from "../CollectionsHistory/CollectionsHistory";
import { Button } from "../../panels/Arena/Arena.styles";
import minusImg from "../../img/collection/Minus.png";
import {
  AddButton,
  CollectionElem,
  Element,
} from "../Collection/Collection.styles";
import { RewardWrapper } from "../DayReward/DayReward.styles";

function Collections({
  isOpen,
  setIsOpen,
  user,
  setUser,
  app,
  isFetching,
  setIsFetching,
  setIsLvlUpOpen,
  setCollectionName,
  setIsModal,
  setModalError,
  allUsers,
  moveToUserRoom,
  serverTime,
}) {
  const [activePanel, setActivePanel] = useState("collections");
  const [activeCollection, setActiveCollection] = useState(0);

  function changePanel(name) {
    setActivePanel(name);
  }

  function changeCollection(index) {
    setActiveCollection(index);
  }

  function handleClose() {
    setIsOpen("");
  }

  function removeNeedCollection(index) {
    setIsFetching(true);
    const needsArray = [...user.collectionsNeed];
    needsArray.splice(index, 1);
    app
      .service("users")
      .patch(
        user._id,
        {
          collectionsNeed: needsArray,
          field: serverTime,
        },
        {
          query: {
            $select: ["_id", "email", "collectionsNeed"],
          },
        }
      )
      .then((data) => {
        setUser((prev) => ({ ...prev, ...data }));
        setIsFetching(false);
      })
      .catch((e) => {
        setIsFetching(false);
        setModalError(e);
        console.log(e);
      });
  }

  return (
    <S.Wrapper isOpen={isOpen} onClick={handleClose}>
      <S.Content onClick={(e) => e.stopPropagation()} back={backImg}>
        {isOpen && (
          <div>
            <Close onClick={handleClose} />
            <S.TopWrapper>
              <S.MenuButtons>
                <Button
                  width={130}
                  disabled={activePanel === "collections"}
                  onClick={changePanel.bind(null, "collections")}
                >
                  <div>Коллекции</div>
                </Button>
                <Button
                  width={130}
                  disabled={activePanel === "history"}
                  onClick={changePanel.bind(null, "history")}
                >
                  <div>Посылки</div>
                </Button>
              </S.MenuButtons>
              <span>Просьбы о помощи</span>
              <S.NeedCollections>
                {[1, 2, 3, 4, 5].map((_, i) => {
                  return (
                    <Element key={i}>
                      <RewardWrapper key={i} light={false} w={65} bottom={15}>
                        {user.collectionsNeed[i]?.collectionIndex > -1 && (
                          <CollectionElem>
                            <img
                              src={require(`../../img/collection/${
                                user.collectionsNeed[i]?.collectionIndex
                              }/${user.collectionsNeed[i]?.elemIndex + 1}.png`)}
                              width={28}
                              height={28}
                              alt="коллекция"
                            />
                            <span />
                          </CollectionElem>
                        )}
                      </RewardWrapper>
                      <AddButton>
                        <Button
                          disabled={
                            !(user.collectionsNeed[i]?.collectionIndex > -1)
                          }
                          width={65}
                          onClick={
                            !isFetching &&
                            user.collectionsNeed[i]?.collectionIndex > -1
                              ? removeNeedCollection.bind(null, i)
                              : null
                          }
                        >
                          <div>
                            <img src={minusImg} width={12} alt="добавить" />
                          </div>
                        </Button>
                      </AddButton>
                    </Element>
                  );
                })}
              </S.NeedCollections>
            </S.TopWrapper>

            <S.Line />

            {activePanel === "history" ? (
              <CollectionsHistory
                me={user}
                allUsers={allUsers}
                collectionsHistory={user.collectionsHistory}
                moveToUserRoom={moveToUserRoom}
                handleClose={handleClose}
              />
            ) : (
              <S.Collections>
                <S.MenuButtons>
                  <Button
                    width={130}
                    disabled={activeCollection === 0}
                    onClick={changeCollection.bind(null, 0)}
                  >
                    <div>{collectionsArray[0].name}</div>
                  </Button>
                  <Button
                    width={130}
                    disabled={activeCollection === 1}
                    onClick={changeCollection.bind(null, 1)}
                  >
                    <div>{collectionsArray[1].name}</div>
                  </Button>
                </S.MenuButtons>
                <Collection
                  collectionIndex={activeCollection}
                  setModalError={setModalError}
                  collectionsNeed={user.collectionsNeed}
                  setCollectionName={setCollectionName}
                  setIsModal={setIsModal}
                  setIsFetching={setIsFetching}
                  collection={user.collections[activeCollection]}
                  setUser={setUser}
                  user={user}
                  id={user._id}
                  app={app}
                  setIsLvlUpOpen={setIsLvlUpOpen}
                  serverTime={serverTime}
                  {...collectionsArray[activeCollection]}
                />
              </S.Collections>
            )}
          </div>
        )}
      </S.Content>
    </S.Wrapper>
  );
}

export default React.memo(Collections);
