import styled from "styled-components";

export const Wrapper = styled.div`
  font-family: "Bellota-Regular";
  position: absolute;
  top: 14px;
  left: 617px;
  display: flex;
  gap: 5px;
  & div {
    position: relative;
    display: flex;
    align-items: end;
    gap: 1px;
    color: #f7b471;
    & span {
      min-width: 30px;
      max-width: 30px;
      color: #f7b471;
      font-size: 12px;
      font-weight: 600;
      text-shadow: 1px 1px #210326;
    }
    cursor: help;
  }
  & div:after {
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 80%);
    position: absolute;
    visibility: hidden;
    opacity: 0;
    color: #f7b471;
    font-size: 12px;
    transition: all 0.1s linear;
    padding: 3px 6px;
    background: rgba(17, 12, 9, 0.8);
    border-radius: 5px;

    outline: 1px solid #281707;
    box-shadow: inset 0px 0px 0px 1px #281707;
    border: 2px solid #fc9800;
    z-index: 1;
    width: max-content;
  }
  & div:hover:after {
    visibility: visible;
    opacity: 1;
    transform: translate(-50%, 100%);
  }
  & div:nth-child(1):after {
    content: "${(props) => `Золото: ${props.gold}`}";
  }
  & div:nth-child(2):after {
    content: "${(props) => `Серебро: ${props.silver}`}";
  }
  & div:nth-child(3):after {
    content: "${(props) => `Эссенции: ${props.essence}`}";
  }
  & div:nth-child(4):after {
    content: "${(props) => `Мана: ${props.mana}`}";
  }
`;
