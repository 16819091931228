import styled from "styled-components";
import buttonConnect from "../../img/ButtonConnect.png";

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 55px;
`;

export const Content = styled.div`
  position: relative;
  display: flex;
`;

export const ImgSpell = styled.div`
  display: flex;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};

  & > img {
    transition: 0.15s linear;
    filter: ${(props) => props.disabled && "brightness(0.8)"};
  }

  & > img:hover {
    filter: ${(props) =>
      props.disabled ? "brightness(0.8)" : "brightness(1.3)"};
  }

  & > img:hover + div {
    opacity: ${(props) => (props.disabled ? "0" : "1" + "" + "")};
    visibility: ${(props) => (props.disabled ? "hidden" : "visible")};
  }

  & > img:active {
    filter: ${(props) =>
      props.disabled ? "brightness(0.8)" : "brightness(0.9)"};
  }
`;

export const InfoSpell = styled.div`
  visibility: hidden;
  transition: 0.15s linear;
  display: flex;
  width: 150px;
  height: 155px;
  position: absolute;
  transform: translate(0, -100%);
  left: 50%;
  top: -12px;
  cursor: default;
  color: #ffe3c8;
  font-family: "Bellota-Regular";

  background: rgba(17, 12, 9, 0.7);
  border-radius: 10px;

  outline: 1px solid #281707;
  box-shadow: inset 0px 0px 0px 1px #281707;
  border: 2px solid #fc9800;

  & > div {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;

    & > div {
      display: flex;
      align-items: center;
      gap: 2px;
      font-size: 12px;
      line-height: 12px;
    }

    & > div:nth-child(1) {
      font-size: 15px;
      line-height: 15px;
      margin-bottom: 5px;
    }
    & > div > span {
      font-weight: 600;
    }
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
`;

export const ButtonsColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ButtonChange = styled.div`
  min-width: 30px;
  max-width: 30px;
  cursor: ${(props) => !props.disabled && "pointer"};
  position: relative;
  transition: filter 0.15s linear;
  backface-visibility: hidden;
  will-change: transform;
  &::after {
    visibility: ${(props) => props.islast && "hidden"};
    width: 12px;
    height: 8px;
    transform: translate(50%, -50%);
    position: absolute;
    right: -1px;
    top: 50%;
    content: "";
    background-image: url(${buttonConnect});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  &:hover {
    & > div {
      filter: ${(props) => !props.disabled && "brightness(1.2)"};
    }
  }
  &:active {
    & > div {
      filter: ${(props) => !props.disabled && "brightness(0.9)"};
    }
  }
  border-radius: 7px;
  box-shadow: 0px 0.5px 0px 1px #281707;
  background: ${(props) =>
    props.isActive
      ? `linear-gradient(0deg, rgba(255, 223, 175, 0.2), rgba(255, 223, 175, 0.2)), linear-gradient(180deg, rgba(78, 39, 0, 0) 0%, #4E2700 100%), linear-gradient(180deg, rgba(255, 217, 159, 0.1) 0%, rgba(255, 223, 176, 0) 42.19%), #6B3700;
`
      : props.disabled
      ? `linear-gradient(0deg, #301E0D, #301E0D), linear-gradient(180deg, rgba(78, 39, 0, 0) 0%, #4E2700 100%), linear-gradient(180deg, rgba(255, 217, 159, 0.1) 0%, rgba(255, 223, 176, 0) 42.19%), #6B3700;
`
      : `linear-gradient(180deg, rgba(78, 39, 0, 0) 0%, #4e2700 100%),
linear-gradient(
180deg,
rgba(255, 217, 159, 0.1) 0%,
rgba(255, 223, 176, 0) 42.19%
),
#6b3700`};
  & > div {
    backface-visibility: hidden;
    will-change: transform;
    transition: filter 0.15s linear;
    filter: ${(props) => props.isDone && "brightness(0.7)"};
    display: flex;
    justify-content: center;
    box-shadow: 0px 0px 0px 1px #281707 inset;
    border: 2px solid #e98101;
    border-radius: 7px;
    padding: 1px;
    color: #fcc382;
    font-family: "Bellota-Regular";
    font-size: 11px;
    line-height: 14px;
  }
`;

export const InputAttacks = styled.div`
  & > input {
    font-size: 11px;
    line-height: 14px;
    text-align: center;
    color: #fcc382;
    font-family: "Bellota-Regular";
    padding: 0 !important ;
    border: none !important;
    background-color: transparent !important;
    outline: none !important;
    min-width: 20px;
    max-width: 20px;

    -moz-appearance: textfield !important;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
    }
  }
`;
