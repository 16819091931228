import React, { useCallback, useEffect, useRef, useState } from "react";

import * as S from "./Game21Tournament.styles";
import Cards from "../Cards/Cards";
import Popup from "../Popup/Popup";
import { Close } from "../../App.styles";
import Reward from "../Reward/Reward";
import { RewardWrapper } from "../DayReward/DayReward.styles";
import frame3 from "../../img/Frame3.png";
import { Button } from "../../panels/Arena/Arena.styles";

const suits = ["Чирва", "Пики", "Бубны", "Крести"];
const values = [
  "Туз",
  "Король",
  "Дама",
  "Валет",
  "Десятка",
  "Девятка",
  "Восьмёрка",
  "Семёрка",
  "Шестёрка",
];

function createDeck() {
  const deck = [];
  suits.forEach((suit) => {
    return values.forEach((value) => {
      deck.push({
        suit,
        value,
      });
    });
  });
  return deck;
}

function shuffleDeck() {
  const deckShuffled = createDeck();
  for (let i = deckShuffled.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [deckShuffled[i], deckShuffled[j]] = [deckShuffled[j], deckShuffled[i]];
  }
  return deckShuffled;
}

function getCardNumericValue(card) {
  switch (card.value) {
    case "Туз":
      return 1;
    case "Валет":
      return 2;
    case "Дама":
      return 3;
    case "Король":
      return 4;
    case "Шестёрка":
      return 6;
    case "Семёрка":
      return 7;
    case "Восьмёрка":
      return 8;
    case "Девятка":
      return 9;
    case "Десятка":
      return 10;
    default:
      return 10;
  }
}

const prizes = {
  ace: 4,
  img: 5,
  comb678: 5,
  comb777: 5,
};

const maxGames = 40;

function game21TournamentTournament({
  setIsModalOpen,
  isModalOpen,
  user,
  app,
  setUser,
  setIsFetching,
  setActiveGame,
  isFetching,
  setModalError,
  serverTime,
  setAdmin,
}) {
  const [endTournamentOpen, setEndTournamentOpen] = useState(false);
  const [points, setPoints] = useState(0);
  const [dealerCards, setDealerCards] = useState(
    user.game21Tournament.dealerCards
  );
  const [playerCards, setPlayerCards] = useState(
    user.game21Tournament.playerCards
  );
  const [gameStarted, setGameStarted] = useState(user.game21Tournament.isGame);
  const [dealerScore, setDealerScore] = useState(0);
  const [playerScore, setPlayerScore] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deck, setDeck] = useState(user.game21Tournament.deck);
  const [isWin, setIsWin] = useState(false);
  const [prize, setPrize] = useState("");
  const isFirstDropRef = useRef(true);
  const gameOverRef = useRef(false);
  const dealerScoreRef = useRef(0);
  const playerScoreRef = useRef(0);
  const isWinRef = useRef(false);

  useEffect(() => {
    return () => {
      setActiveGame("");
    };
  }, []);

  useEffect(() => {
    if (user.game21Tournament.gamesDone >= maxGames) {
      setEndTournamentOpen(true);
    }
    if (!user.game21Tournament.isStartTournament) {
      setActiveGame("");
    }
  }, [user]);

  useEffect(() => {
    gameOverRef.current = gameOver;
    if (gameOver) {
      getDealerCards();
    }
  }, [gameOver]);

  useEffect(() => {
    setPlayerScore(getScore(playerCards, false));
  }, [playerCards]);

  useEffect(() => {
    setDealerScore(getScore(dealerCards, true));
  }, [dealerCards]);

  function getScore(cardArray, dealer) {
    let score = 0;
    let hasAce = false;
    let dblAce = 0;
    let fiveImg = 0;

    cardArray.forEach((card) => {
      score += getCardNumericValue(card);
      if (card.value === "Туз") {
        dblAce += 1;
        hasAce = true;
      }
      if (
        card.value === "Дама" ||
        card.value === "Король" ||
        card.value === "Валет" ||
        card.value === "Туз"
      ) {
        fiveImg += 1;
      } else {
        fiveImg -= 1;
      }
    });

    if (dblAce === 2 && cardArray.length === 2) {
      score = 21;
    }
    if (fiveImg === 5) {
      score = 21;
    }
    if (hasAce && score + 10 <= 21) {
      score += 10;
    }

    if (dealer) {
      dealerScoreRef.current = score;
    } else {
      playerScoreRef.current = score;
      checkForEndOfGame();
    }

    if (gameOverRef.current && dealer) {
      getDealerCards();
    }

    if (
      !isFirstDropRef.current &&
      cardArray.length > 0 &&
      !gameOverRef.current
    ) {
      setIsFetching(true);
      setLoading(true);
      app
        .service("users")
        .patch(
          user._id,
          {
            "game21Tournament.isGame": true,
            "game21Tournament.deck": deck,
            "game21Tournament.playerCards": dealer
              ? user.game21Tournament.playerCards
              : cardArray,
            "game21Tournament.dealerCards": !dealer
              ? user.game21Tournament.dealerCards
              : cardArray,
            field: serverTime,
          },
          {
            query: {
              $select: ["_id", "email", "game21Tournament"],
            },
          }
        )
        .then((data) => {
          setUser((prev) => ({ ...prev, ...data }));

          setIsFetching(false);
          setLoading(false);
        })
        .catch((e) => {
          setModalError(e);
          console.log(e);
          setIsFetching(false);
          setLoading(false);
        });
    }

    return score;
  }

  const checkWin = useCallback(() => {
    let dblAceDealer = 0;
    let dblAcePlayer = 0;
    let fiveImgDealer = 0;
    let fiveImgPlayer = 0;

    dealerCards.forEach((card) => {
      if (card.value === "Туз") {
        dblAceDealer += 1;
      } else {
        dblAceDealer -= 1;
      }
      if (
        card.value === "Дама" ||
        card.value === "Король" ||
        card.value === "Валет" ||
        card.value === "Туз"
      ) {
        fiveImgDealer += 1;
      } else {
        fiveImgDealer -= 1;
      }
    });

    playerCards.forEach((card) => {
      if (card.value === "Туз") {
        dblAcePlayer += 1;
      } else {
        dblAcePlayer -= 1;
      }
      if (
        card.value === "Дама" ||
        card.value === "Король" ||
        card.value === "Валет" ||
        card.value === "Туз"
      ) {
        fiveImgPlayer += 1;
      } else {
        fiveImgPlayer -= 1;
      }
    });

    if (playerScoreRef.current > 21) {
      setPoints(0);
      setIsWin(false);
      isWinRef.current = false;
    } else if (
      dealerScoreRef.current > 21 ||
      playerScoreRef.current > dealerScoreRef.current
    ) {
      if (playerScoreRef.current === 21) {
        setPoints(15);
      } else {
        setPoints(10);
      }
      setIsWin(true);
      isWinRef.current = true;
    } else {
      if (playerScoreRef.current === dealerScoreRef.current) {
        setPoints(5);
      } else {
        setPoints(2);
      }

      setIsWin(false);
      isWinRef.current = false;
    }
    if (playerCards.length === 2 && dblAcePlayer === 2) {
      setPrize("ace");
      setPoints(20);
      setIsWin(true);
      isWinRef.current = true;
    }
    if (fiveImgPlayer === 5) {
      setPoints(30);
      setPrize("img");
      setIsWin(true);
      isWinRef.current = true;
    }
    if (
      playerCards.length === 3 &&
      ["Шестёрка", "Семёрка", "Восьмёрка"].filter(
        (item) => !playerCards.map((elem) => elem.value).includes(item)
      ).length === 0
    ) {
      setPoints(25);
      setPrize("comb678");
      setIsWin(true);
      isWinRef.current = true;
    }
    if (
      playerCards.length === 3 &&
      playerCards.every((elem) => elem.value === "Семёрка")
    ) {
      setPoints(25);
      setPrize("comb777");
      setIsWin(true);
      isWinRef.current = true;
    }
    if (!isFirstDropRef.current) {
      setIsFetching(true);
      setLoading(true);
      app
        .service("users")
        .patch(
          user._id,
          {
            "game21Tournament.isGame": true,
            "game21Tournament.deck": deck,
            "game21Tournament.playerCards": playerCards,
            "game21Tournament.dealerCards": dealerCards,
            field: serverTime,
          },
          {
            query: {
              $select: ["_id", "email", "game21Tournament"],
            },
          }
        )
        .then((data) => {
          setUser((prev) => ({ ...prev, ...data }));

          setIsFetching(false);
          setLoading(false);
        })
        .catch((e) => {
          setModalError(e);
          console.log(e);
          setIsFetching(false);
          setLoading(false);
        });
    }
  }, [playerCards, dealerCards]);

  function getDealerCards() {
    if (
      dealerScoreRef.current < playerScoreRef.current &&
      playerScoreRef.current <= 21 &&
      dealerScoreRef.current <= 21 &&
      dealerCards.length < 5
    ) {
      isFirstDropRef.current = false;
      setDeck((prev) => {
        setDealerCards((prevState) => {
          return [...prevState, prev[prev.length - 1]];
        });
        return [...prev].slice(0, -1);
      });
    } else {
      checkWin();
      setTimeout(() => {
        setIsModalOpen(true);
      }, 400);
    }
  }

  function checkForEndOfGame() {
    let dblAceDealer = 0;
    let dblAcePlayer = 0;
    let fiveImgDealer = 0;
    let fiveImgPlayer = 0;

    dealerCards.forEach((card) => {
      if (card.value === "Туз") {
        dblAceDealer += 1;
      } else {
        dblAceDealer -= 1;
      }
      if (
        card.value === "Дама" ||
        card.value === "Король" ||
        card.value === "Валет" ||
        card.value === "Туз"
      ) {
        fiveImgDealer += 1;
      } else {
        fiveImgDealer -= 1;
      }
    });

    playerCards.forEach((card) => {
      if (card.value === "Туз") {
        dblAcePlayer += 1;
      } else {
        dblAcePlayer -= 1;
      }
      if (
        card.value === "Дама" ||
        card.value === "Король" ||
        card.value === "Валет" ||
        card.value === "Туз"
      ) {
        fiveImgPlayer += 1;
      } else {
        fiveImgPlayer -= 1;
      }
    });

    if (playerScoreRef.current >= 21) {
      setGameOver(true);
    } else if (
      (playerCards.length === 2 && dblAcePlayer === 2) ||
      playerCards.length === 5 ||
      dealerCards.length > 2
    ) {
      setGameOver(true);
    }
  }

  function endTournament() {
    setIsFetching(true);
    app
      .service("users")
      .patch(
        user._id,
        {
          "game21Tournament.maxPoints":
            user.game21Tournament.maxPoints < user.game21Tournament.points
              ? user.game21Tournament.points
              : user.game21Tournament.maxPoints,
          "game21Tournament.isStartTournament": false,
          "game21Tournament.history": [
            ...user.game21Tournament.history,
            user.game21Tournament.points,
          ],
          field: serverTime,
        },
        {
          query: {
            $select: [
              "_id",
              "email",
              "game21Tournament",
              "dayQuest",
              "summerSave",
            ],
          },
        }
      )
      .then((data) => {
        setUser((prev) => ({ ...prev, ...data }));
        app
          .service("admin")
          .patch(
            user.admin,
            {
              [`game21Tournament.users.${user.email}`]: {
                _id: user._id,
                maxPoints: data.game21Tournament.maxPoints,
                count: data.game21Tournament.count,
                rate: user.game21.countWins,
              },
            },
            {
              query: {
                $select: ["game21Tournament"],
              },
            }
          )
          .then((adminData) => {
            setAdmin((prev) => ({ ...prev, ...adminData }));
            setIsFetching(false);
          })
          .catch((e) => {
            setModalError(e);
            console.log(e);
            setIsFetching(false);
          });
      })
      .catch((e) => {
        setModalError(e);
        console.log(e);
        setIsFetching(false);
      });
  }

  function startGame(value) {
    if (value) {
      if (user.game21Tournament.gamesDone < maxGames) {
        const deck = shuffleDeck();
        const dealerCard1 = deck.shift();
        const dealerCard2 = deck.shift();
        const playerCard1 = deck.shift();
        const playerCard2 = deck.shift();
        setIsFetching(true);
        setLoading(true);

        app
          .service("users")
          .patch(
            user._id,
            {
              "game21Tournament.isGame": true,
              "game21Tournament.deck": deck,
              "game21Tournament.dealerCards": [dealerCard1, dealerCard2],
              "game21Tournament.playerCards": [playerCard1, playerCard2],
              $inc: {
                "dayQuest.game21TournamentGames": 1,
                "summerSave.results.game21TournamentGames": 1,
              },
              field: serverTime,
            },
            {
              query: {
                $select: [
                  "_id",
                  "email",
                  "game21Tournament",
                  "dayQuest",
                  "summerSave",
                ],
              },
            }
          )
          .then((data) => {
            setDeck(data.game21Tournament.deck);
            setGameStarted(value);
            setGameOver(false);
            setIsWin(false);
            setPlayerCards([playerCard1, playerCard2]);
            setDealerCards([dealerCard1, dealerCard2]);
            setUser((prev) => ({ ...prev, ...data }));

            setIsFetching(false);
            setLoading(false);
          })
          .catch((e) => {
            setIsFetching(false);
            setLoading(false);
            setModalError(e);
            console.log(e);
          });
      } else {
        setModalError("Вы уже сыграли все игры");
        console.log("Вы уже сыграли все игры");
      }
    } else {
      setGameOver(true);
    }
  }

  function endGame() {
    setIsFetching(true);
    setLoading(true);
    app
      .service("users")
      .patch(
        user._id,
        {
          "game21Tournament.isGame": false,
          "game21Tournament.deck": [],
          "game21Tournament.dealerCards": [],
          "game21Tournament.playerCards": [],
          $inc: {
            "game21Tournament.points": points,
            "game21Tournament.gamesDone": 1,
            "newQuest.results.game21": 1,
            "dayQuest.game21Win": isWin ? 1 : 0,
            "dayQuest.game21Combinations": prizes[prize] ? 1 : 0,
            "summerSave.results.game21Win": isWin ? 1 : 0,
            "summerSave.results.game21Aces": prize === "ace" ? 1 : 0,
            "achievements.game21.ace": prize === "ace" ? 1 : 0,
            "achievements.game21.img": prize === "img" ? 1 : 0,
            "achievements.game21.678": prize === "comb678" ? 1 : 0,
            "achievements.game21.777": prize === "comb777" ? 1 : 0,

            [`bosses.${
              prize.length > 0
                ? prizes[prize]
                : isWin && playerScore === 21
                ? 3
                : isWin && playerScore === 20
                ? 2
                : isWin && playerScore <= 19
                ? 1
                : 0
            }.souls`]:
              prize.length > 0
                ? 1
                : isWin && playerScore === 21
                ? 1
                : isWin && playerScore === 20
                ? 1
                : isWin && playerScore <= 19
                ? 1
                : 0,
            weakGamesPoints:
              prize.length > 0
                ? prizes[prize] * 20
                : isWin && playerScore === 21
                ? 25
                : isWin && playerScore === 20
                ? 15
                : isWin && playerScore <= 19
                ? 10
                : 0,
          },
          field: serverTime,
        },
        {
          query: {
            $select: [
              "_id",
              "email",
              "game21Tournament",
              "bosses",
              "dayQuest",
              "weakGamesPoints",
              "newQuest",
              "summerSave",
              "achievements",
            ],
          },
        }
      )
      .then((data) => {
        setUser((prev) => ({ ...prev, ...data }));

        setIsFetching(false);
        setLoading(false);
      })
      .catch((e) => {
        setIsFetching(false);
        setLoading(false);
        setModalError(e);
        console.log(e);
      })
      .finally(() => {
        isFirstDropRef.current = true;
        gameOverRef.current = false;
        setPlayerCards([]);
        setDealerCards([]);
        setGameStarted(false);
        setIsModalOpen(false);
        setPoints(0);
        setPrize("");
      });
  }

  function getPlayerCard() {
    isFirstDropRef.current = false;
    setDeck((prev) => {
      setPlayerCards((prevState) => [...prevState, prev[prev.length - 1]]);
      return [...prev].slice(0, -1);
    });
  }

  return (
    <S.Wrapper>
      <S.GamesInfo>
        <span>
          Сыграно игр: {user.game21Tournament.gamesDone}/{maxGames}
        </span>
        <span>Набрано очков: {user.game21Tournament.points}</span>
      </S.GamesInfo>

      <S.DialerWrapper>
        <S.DialerInfo>
          <S.Name>Крупье</S.Name>
          <S.Score>Очки: {dealerScore}</S.Score>
          {dealerCards.length > 0 &&
            dealerCards.map((card, i) => {
              return (
                <S.CardName key={i}>
                  {card.value} {card.suit}
                </S.CardName>
              );
            })}
        </S.DialerInfo>
        <S.DialerCards>
          <Cards cards={dealerCards} right="true" />
        </S.DialerCards>
      </S.DialerWrapper>
      <S.PlayerWrapper>
        <S.PlayerCards>
          <Cards cards={playerCards} bottom="true" />
        </S.PlayerCards>
        <S.PlayerInfo>
          <S.Name>Игрок</S.Name>
          <S.Score>Очки: {playerScore}</S.Score>
          {playerCards.length > 0 &&
            playerCards.map((card, i) => {
              return (
                <S.CardName key={i}>
                  {card.value} {card.suit}
                </S.CardName>
              );
            })}
        </S.PlayerInfo>
      </S.PlayerWrapper>

      {!gameStarted ? (
        <S.ButtonsWrapper>
          <S.MarkerButtonStyled
            onClick={!isModalOpen && !isFetching && !loading ? startGame : null}
          >
            <div>Начать игру</div>
          </S.MarkerButtonStyled>
        </S.ButtonsWrapper>
      ) : (
        !gameOver && (
          <S.ButtonsWrapper>
            <S.MarkerButtonStyled
              onClick={
                !isFetching && !loading && playerCards.length <= 5
                  ? getPlayerCard
                  : null
              }
            >
              <div>Взять ещё</div>
            </S.MarkerButtonStyled>
            <S.MarkerButtonStyled
              onClick={
                !isFetching && !loading ? startGame.bind(null, false) : null
              }
            >
              <div>Хватит</div>
            </S.MarkerButtonStyled>
          </S.ButtonsWrapper>
        )
      )}

      <Popup
        isOpen={isModalOpen}
        onClick={!isFetching && !loading ? endGame : null}
        w={317}
        h={217}
        back={frame3}
      >
        {!isWin && prize.length <= 0 && (
          <Close onClick={!isFetching && !loading ? endGame : null} />
        )}

        <b>Очки за игру: {points}</b>

        <b>Результат игры: {isWin ? "победа" : "поражение"}</b>

        {prize.length > 0 ? (
          <RewardWrapper light={false} w={65}>
            <div>
              <Reward
                name={"soul"}
                count={1}
                soul={prizes[prize]}
                w={28}
                showCount={true}
                round={true}
                font={13}
                reverseColumn={true}
                color={"#fdbb54"}
              />
            </div>
          </RewardWrapper>
        ) : isWin && prize.length === 0 && playerScore === 21 ? (
          <RewardWrapper light={false} w={65}>
            <div>
              <Reward
                name={"soul"}
                count={1}
                soul={3}
                w={28}
                showCount={true}
                round={true}
                font={13}
                reverseColumn={true}
                color={"#fdbb54"}
              />
            </div>
          </RewardWrapper>
        ) : isWin && prize.length === 0 && playerScore === 20 ? (
          <RewardWrapper light={false} w={65}>
            <div>
              <Reward
                name={"soul"}
                count={1}
                soul={2}
                w={28}
                showCount={true}
                round={true}
                font={13}
                reverseColumn={true}
                color={"#fdbb54"}
              />
            </div>
          </RewardWrapper>
        ) : (
          isWin &&
          prize.length === 0 &&
          playerScore <= 19 && (
            <RewardWrapper light={false} w={65}>
              <div>
                <Reward
                  name={"soul"}
                  count={1}
                  soul={1}
                  w={28}
                  showCount={true}
                  round={true}
                  font={13}
                  reverseColumn={true}
                  color={"#fdbb54"}
                />
              </div>
            </RewardWrapper>
          )
        )}

        {(isWin || prize.length) > 0 ? (
          <Button
            width={100}
            onClick={!isFetching && !loading ? endGame : null}
          >
            <div>Забрать</div>
          </Button>
        ) : (
          <Button
            width={100}
            onClick={!isFetching && !loading ? endGame : null}
          >
            <div>Закрыть</div>
          </Button>
        )}
      </Popup>

      <Popup isOpen={endTournamentOpen} w={317} h={217} back={frame3}>
        <b>Результаты</b>
        <span>Набрано очков: {user.game21Tournament.points}</span>
        <span>
          Лучший результат:{" "}
          {user.game21Tournament.points > user.game21Tournament.maxPoints
            ? user.game21Tournament.points
            : user.game21Tournament.maxPoints}
        </span>

        <Button width={100} onClick={!isFetching ? endTournament : null}>
          <div>Завершить</div>
        </Button>
      </Popup>
    </S.Wrapper>
  );
}

export default React.memo(game21TournamentTournament);
