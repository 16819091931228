import styled, { css } from "styled-components";

export const Cards = styled.div`
  position: absolute;
  top: ${(props) => props.x || 25}px;
  left: ${(props) => props.x || 14}px;

  ${(props) =>
    props.bottom &&
    css`
      top: unset;
      bottom: ${(props) => props.x || 3}px;
    `}

  ${(props) =>
    props.right &&
    css`
      left: unset;
      right: ${(props) => props.x || 14}px;
    `}
`;
