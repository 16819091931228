import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: end;
  font-size: 12px;
  justify-items: center;
  align-items: center;
  & button {
    width: 100%;
    font-size: 12px;
    display: flex;
    justify-content: center;
  }
  & input {
    width: 100%;
  }
`;

export const Rewards = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;
