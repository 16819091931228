import styled from "styled-components";

export const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.isOpen ? "1" : "0")};
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  pointer-events: ${(props) => (props.isOpen ? "auto" : "none")};
  z-index: ${(props) => (props.isOpen ? "1" : "-1")};
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 764px;
  height: 458px;
  gap: 10px;
  font-family: "Bellota-Regular";
  & > div {
    padding: 15px;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    & > div {
      display: flex;
      flex-direction: column;
      gap: 5px;
      align-items: center;
    }
  }
  background-image: url("${(props) => props.back}");
  background-size: cover;
`;

export const RightWrapper = styled.div`
  position: absolute;
  width: 186px;
  height: 418px;
  left: 18px;
  top: 19px;
`;

export const LocationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 100%;
  overflow-y: scroll;
  padding: 0 7px 0 2px;
`;

export const Floor = styled.div`
  text-align: center;
  min-width: 100%;
  max-width: 100%;
  color: #c6a76b;
  font-weight: 600;
  font-size: 15px;
`;

export const Location = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  text-align: center;
  min-width: 100%;
  max-width: 100%;
  padding: 5px 10px;
  background-color: ${(props) => (props.important ? "#c12f1d" : "#ae956e")};
  transition: filter 0.1s linear;
  cursor: pointer;
  backface-visibility: hidden;
  will-change: transform;
  &:hover {
    filter: brightness(1.2);
  }

  & > span:nth-child(1) {
    font-size: 13px;
    color: #0f0b08;
    font-weight: 600;
  }

  & > span:nth-child(2) {
    font-size: 12px;
    color: #0f0b08;
    font-weight: 500;
    font-style: italic;
  }
`;
