import styled from "styled-components";
import backMenu from "../../img/menuIcons/BackR.png";
import { Menu, MenuElem } from "../../App.styles";

export const Info = styled.div`
  display: flex;
  align-self: end;
  z-index: 1;
  & > div {
    font-size: 12px;
    background-color: #232121;
    position: absolute;
    padding: 5px 10px;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: ${(props) => (props.isPrize ? "center" : "start")};
    justify-content: center;
    visibility: hidden;
    gap: 4px;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  &:hover > div {
    visibility: visible;
    opacity: 1;
  }
`;

export const MenuRight = styled(Menu)`
  left: unset;
  right: 0;
`;

export const Rewards = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;

export const Clothes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  position: relative;
  padding: 10px;
  & > button {
    font-size: 13px;
    line-height: 15px;
    width: 100%;
  }
`;

export const MenuElemRight = styled(MenuElem)`
  background-image: url("${backMenu}");
  & > div {
    outline: ${(props) => props.time && "3px solid #e896037d"};
    left: unset;
    right: 50%;
    transform: translate(calc(50% + 1px), calc(-50% + 4px));
    &:after {
      left: unset;
      top: 0;
      right: 0;
      content: "${(props) =>
        props.time ? props.time : props.name ? `${props.name}` : ""}";
    }
    &:hover:after {
      content: "${(props) => (props.name ? `${props.name}` : "")}";
    }
  }
`;

export const BookHover = styled.div`
  position: absolute;
  left: 363px;
  top: 223px;
  width: 111px;
  height: 104px;
  cursor: pointer;
  opacity: 0;
  transition: 0.3s linear;
  &:hover {
    opacity: 1;
  }
  & > img {
    filter: ${(props) => (props.lvl ? "none" : "invert(1)")};
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  & > div {
    text-align: center;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    font-weight: 600;
    display: ${(props) => (props.lvl ? "none" : "flex")};
    flex-direction: column;

    justify-content: center;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translateX(-50%);

    color: #f7b471;
    font-size: 12px;
    transition: all 0.1s linear;
    padding: 3px 6px;
    background: rgba(17, 12, 9, 0.8);
    border-radius: 5px;

    outline: 1px solid #281707;
    box-shadow: inset 0px 0px 0px 1px #281707;
    border: 2px solid #fc9800;
    z-index: 1;
    width: max-content;
  }
`;

export const OwlHover = styled(BookHover)`
  position: absolute;
  left: 225px;
  top: 186px;
  width: 134px;
  height: 197px;
  cursor: pointer;
  opacity: 0;
  transition: 0.3s linear;
  clip-path: polygon(
    30% 0%,
    70% 0%,
    95% 30%,
    95% 90%,
    70% 100%,
    30% 100%,
    5% 90%,
    5% 30%
  );
`;

export const CupHover = styled(BookHover)`
  position: absolute;
  left: 680px;
  top: 266px;
  width: 93px;
  height: 96px;
  cursor: pointer;
  opacity: 0;
  transition: 0.3s linear;
`;

export const GrindWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.7fr 1fr;
  justify-items: center;
  align-items: center;
  gap: 10px;
  & > div {
    font-size: 13px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    & > img {
      height: 110px;
    }
    & span + img {
      height: auto;
    }
  }
`;

export const CharacBook = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MaxLvl = styled.div`
  font-size: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  & > img {
    height: 110px;
  }
`;

export const GridResult = styled.span`
  font-size: 24px;
  color: ${(props) =>
    props.result === "Успех"
      ? "#00ec00"
      : props.result === "Не повезло"
      ? "#efd803"
      : "#df0000"};
`;

export const NewYearPrize = styled.div`
  position: absolute;
  width: 65px;
  height: 72px;
  left: 310px;
  top: 415px;
  cursor: pointer;
  filter: brightness(0.9);
  transition: 0.3s linear;
  &:hover {
    filter: brightness(1.1);
  }

  & > img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
`;

export const BossesCup = styled.div`
  display: flex;
  position: absolute;
  left: 582px;
  top: 145px;
  cursor: pointer;
  opacity: 0;
  transition: 0.3s linear;
  &:hover {
    opacity: 1;
  }
  & > div {
    text-align: center;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    font-weight: 600;
    display: flex;
    gap: 3px;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translateX(-50%);

    color: #f7b471;
    font-size: 12px;
    transition: all 0.1s linear;
    padding: 3px 6px;
    background: rgba(17, 12, 9, 0.8);
    border-radius: 5px;

    outline: 1px solid #281707;
    box-shadow: inset 0px 0px 0px 1px #281707;
    border: 2px solid #fc9800;
    z-index: 1;
    width: max-content;

    & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
`;

export const ArenaCup = styled(BossesCup)`
  left: 688px;
  top: 206px;
`;

export const ClassroomsCup = styled(BossesCup)`
  left: 625px;
  top: 216px;
`;

export const GamesCup = styled(BossesCup)`
  left: 582px;
  top: 220px;
`;

export const LookWrapper = styled.div`
  padding: 0 15px;
  width: 100%;
  display: flex;
  gap: 5px;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const NickNameWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 10px;
  padding: 15px 10px 20px 10px;
  background: rgba(17, 12, 9, 0.8);
  border-radius: 3px;
  outline: 1px solid #281707;
  box-shadow: inset 0px 0px 0px 1px #281707;
  border: 2px solid #fc9800;
  color: #ffcf82;
  margin-bottom: 20px;
`;

export const NicknameButton = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
`;

export const LookHeader = styled.span`
  color: #ffcf82;
  font-size: 18px;
`;

export const InputNick = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;

  & input {
    text-align: center;
    width: 100%;
    border: none !important;
    background-color: rgba(255, 255, 255, 0) !important;
    outline: none !important;
    font-family: "Bellota-Regular";
    color: #ffcf82 !important;
    -moz-appearance: textfield !important;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
    }
    ::placeholder {
      color: #ffcf82 !important;
      text-transform: none;
    }
  }
`;

export const MaxSize = styled.span`
  color: #ffcf82;
  opacity: 0.7;
`;

export const SexWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;

  & > div {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 15px 10px 15px 10px;
    background: rgba(17, 12, 9, 0.8);
    border-radius: 3px;
    outline: 1px solid #281707;
    box-shadow: inset 0px 0px 0px 1px #281707;
    border: 2px solid #fc9800;
    color: #ffcf82;
  }
`;
