import React, { useCallback, useEffect, useState } from "react";

import * as S from "./Spell.styles";
import InfoDamage from "../InfoDamage/InfoDamage";

import { grindData, stockDamage } from "../../info/data";

import imgMana from "../../img/book/Mana.png";
import {
  ButtonChange,
  ButtonChangeWrapper,
  ButtonValue,
} from "../Healls/Healls.styles";
import InputNumber from "../InputNumber/InputNumber";

function Spell({
  grind,
  name,
  damage,
  krit,
  kritChance,
  mana,
  force,
  power,
  accuracy,
  isFetching,
  isModalOpen,
  dealDamage,
  setIsFetching,
  setLoading,
  damageTalents,
  bossDamage,
  userHp,
  userMana,
  setModalError,
  stockLvl,
  damageScales,
  damageBase,
  deffence,
  left,
  bottom,
  img,
  bossesInfo,
  setBossesInfo,
  serverTime,
  isRaid,
  spellNumber,
  lvl,
  currentSpell,
  setBossAuto,
}) {
  const [countAttacks, setCountAttacks] = useState(
    bossesInfo[`countAttack${spellNumber + 1}`] > 0
      ? bossesInfo[`countAttack${spellNumber + 1}`] > 3 && isRaid
        ? 3
        : bossesInfo[`countAttack${spellNumber + 1}`]
      : 1
  );

  useEffect(() => {
    setBossesInfo((prev) => ({
      ...prev,
      [`countAttack${spellNumber + 1}`]: countAttacks || 1,
    }));
  }, [countAttacks]);

  function changeCount(add) {
    setCountAttacks((prev) => {
      if (add) {
        if (isRaid && +prev + 1 > 3) {
          return 3;
        }
        if (+prev + 1 > 50) {
          return 50;
        }
        return +prev + 1;
      } else {
        if (+prev - 1 >= 1) {
          return +prev - 1;
        }
      }
      return +prev;
    });
  }

  function handleInput(e) {
    let value = e.target.value;
    if (value > 50) {
      if (value >= 1) {
        value = 50;
      } else {
        value = 1;
      }
    }
    setCountAttacks(value ? Math.abs(+Math.floor(value)) : "");
  }

  const calcInfoDamage = useCallback(() => {
    const scales = {};
    const grindValue = grindData["book"].bonus[grind["book"] - 1] / 100;
    damageTalents.characteristicTalents.forEach((elem) => {
      scales[elem.property] =
        1 + grindValue + Math.round(elem.step * elem.countDone * 100) / 10000;
    });

    const forceAll = Math.floor(
      Object.values(force).reduce((acc, cur, i) => {
        if (i < 2) {
          return acc + cur * scales.force;
        }
        return acc + cur;
      }, 0)
    );
    const powerAll = Math.floor(
      Object.values(power).reduce((acc, cur, i) => {
        if (i < 2) {
          return acc + cur * scales.power;
        }
        return acc + cur;
      }, 0)
    );
    const accuracyAll = Math.floor(
      Object.values(accuracy).reduce((acc, cur, i) => {
        if (i < 2) {
          return acc + cur * scales.accuracy;
        }
        return acc + cur;
      }, 0)
    );

    let stockScale = 1;

    if (stockLvl.damage === 0 && serverTime <= stockLvl.damageEnd) {
      stockScale = 1 + stockDamage[0].scale;
    }
    if (stockLvl.damage === 1 && serverTime <= stockLvl.damageEnd) {
      stockScale = 1 + stockDamage[1].scale;
    }
    if (stockLvl.damage === 2 && serverTime <= stockLvl.damageEnd) {
      stockScale = 1 + stockDamage[2].scale;
    }

    const damageCharac = {
      force: forceAll,
      power: powerAll,
      accuracy: accuracyAll,
    };

    const scaleCharac = damageScales.length === 1 ? 1 : 0.5;

    const damageSum = damageScales.reduce((acc, cur) => {
      return acc + damageCharac[cur] * scaleCharac;
    }, 0);

    return Math.floor(
      (damageSum * damage * (1 + 0.05 * (lvl - 1)) + damageBase) * stockScale
    );
  }, [grind]);

  function calcDamage({ damage, krit }) {
    const scales = {};
    const grindValue = grindData["book"].bonus[grind["book"] - 1] / 100;
    damageTalents.characteristicTalents.forEach((elem) => {
      scales[elem.property] =
        1 + grindValue + Math.round(elem.step * elem.countDone * 100) / 10000;
    });

    const forceAll = Math.floor(
      Object.values(force).reduce((acc, cur, i) => {
        if (i < 2) {
          return acc + cur * scales.force;
        }
        return acc + cur;
      }, 0)
    );
    const powerAll = Math.floor(
      Object.values(power).reduce((acc, cur, i) => {
        if (i < 2) {
          return acc + cur * scales.power;
        }
        return acc + cur;
      }, 0)
    );
    const accuracyAll = Math.floor(
      Object.values(accuracy).reduce((acc, cur, i) => {
        if (i < 2) {
          return acc + cur * scales.accuracy;
        }
        return acc + cur;
      }, 0)
    );

    const rand = (Math.random() * 100).toFixed(1);
    let stockScale = 1;
    if (stockLvl.damage === 0 && serverTime <= stockLvl.damageEnd) {
      stockScale = 1 + stockDamage[0].scale;
    }
    if (stockLvl.damage === 1 && serverTime <= stockLvl.damageEnd) {
      stockScale = 1 + stockDamage[1].scale;
    }
    if (stockLvl.damage === 2 && serverTime <= stockLvl.damageEnd) {
      stockScale = 1 + stockDamage[2].scale;
    }

    const damageCharac = {
      force: forceAll,
      power: powerAll,
      accuracy: accuracyAll,
    };

    const scaleCharac = damageScales.length === 1 ? 1 : 0.5;

    const damageSum = damageScales.reduce((acc, cur) => {
      return acc + damageCharac[cur] * scaleCharac;
    }, 0);

    return 100 - kritChance - rand > 0
      ? {
          value: Math.floor(
            (damageSum * damage * (1 + 0.05 * (lvl - 1)) + damageBase) *
              stockScale
          ),
          isKrit: false,
        }
      : {
          value: Math.floor(
            (damageSum * damage * (1 + 0.05 * (lvl - 1)) + damageBase) *
              stockScale
          ),
          krit: krit,
          isKrit: true,
        };
  }

  function handleClick() {
    let isAttack = true;
    if (userHp <= 0) {
      isAttack = false;
      setModalError("Недостаточно здоровья");
      setLoading(false);
      setIsFetching(false);
    }
    if (userMana < mana * (countAttacks || 1)) {
      isAttack = false;
      setModalError("Недостаточно маны");
      setBossAuto(false);
      setLoading(false);
      setIsFetching(false);
    }

    if (isAttack) {
      const damageInfo = calcDamage({
        damage: damage,
        krit,
      });
      setLoading(true);
      setIsFetching(true);
      dealDamage({
        damage: damageInfo,
        countAttacks: countAttacks || 1,
        mana: mana,
        deffence: deffence,
        bossDamage: bossDamage,
      });
    }
  }

  return (
    <S.Wrapper left={left} bottom={bottom}>
      <S.Content>
        <S.ImgSpell disabled={isFetching}>
          <img
            ref={currentSpell}
            onClick={!isFetching && !isModalOpen ? handleClick : null}
            src={img}
            width={50}
            height={50}
            alt={"заклинание"}
          />
          <S.InfoSpell>
            <div>
              <div>
                <span>{name}</span>
              </div>
              <div>
                <span>Крит:</span> {Math.round((krit - 1) * 100)}%
              </div>
              <div>
                <span>Снижение урона:</span> {Math.round((deffence - 1) * 100)}%
              </div>
              <div>
                <span>Стоимость: </span> {mana * (countAttacks || 1)}
                <img src={imgMana} width={14} height={14} alt="мана" />
              </div>
              <div>
                <span>Урон:</span>{" "}
                <InfoDamage
                  damage={damage}
                  countAttacks={countAttacks || 1}
                  calcInfoDamage={calcInfoDamage}
                />
              </div>
            </div>
          </S.InfoSpell>
        </S.ImgSpell>

        <ButtonChangeWrapper>
          <ButtonChange islast={true} onClick={changeCount.bind(null, true)}>
            <div>+1</div>
          </ButtonChange>
          <ButtonValue islast={false}>
            <S.InputAttacks>
              <input
                value={countAttacks}
                onChange={handleInput}
                type="number"
                max={50}
                min={1}
                placeholder={""}
                step={1}
              />
            </S.InputAttacks>
          </ButtonValue>
          <ButtonChange islast={false} onClick={changeCount.bind(null, false)}>
            <div>-1</div>
          </ButtonChange>
        </ButtonChangeWrapper>
      </S.Content>
    </S.Wrapper>
  );
}

export default React.memo(Spell);
