import React, { useCallback, useEffect, useRef, useState } from "react";

import * as S from "./ActiveBoss.styles";
import { roundProperty, roundPropertyLow, secondsToHm } from "../../helpers";

import Popup from "../Popup/Popup";
import Spell from "../Spell/Spell";
import Reward from "../Reward/Reward";
import frame1 from "../../img/Frame1.png";
import imgBars from "../../img/boss/HpFrame.png";
import imgHpBar from "../../img/HealthBar.png";
import imgHpBack from "../../img/boss/HpBack.png";
import handImg from "../../img/boss/Hand.png";
import winRibon from "../../img/boss/WinRibon.png";
import loseRibon from "../../img/boss/LoseRibon.png";
import bridge from "@vkontakte/vk-bridge";
import Damage from "../Dagame/Damage";
import DamageTop from "../DamageTop/DamageTop";
import Healls from "../Healls/Healls";
import { Close } from "../../App.styles";
import DamageShadow from "../DagameShadow/DamageShadow";
import {
  allClothes,
  bossesArray,
  bossesStages,
  spellsArray,
} from "../../info/data";
import frame6 from "../../img/Frame6.png";
import {
  BarsHp,
  BarsHpBack,
  BarsPart,
  Rewards,
  Time,
} from "./ActiveBoss.styles";
import { RewardWrapper } from "../DayReward/DayReward.styles";
import frame7 from "../../img/Frame7.png";
import FinishTopBosses from "../FinishTopBosses/FinishTopBosses";
import { Button } from "../../panels/Arena/Arena.styles";
import { ButtonLeft, ButtonRight } from "../Healls/Healls.styles";
import scrollButtonImg from "../../img/ScrollButton.png";
import timeIcon from "../../img/miniIcons/Time.png";

const types = {
  entity: "Существо",
  man: "Человек",
  ghost: "Призрак",
};

function ActiveBoss({
  isSolo,
  allHp,
  name,
  attack,
  bossKritChance,
  bossEvasionChance,
  leaveBoss,
  currentHp,
  rewards,
  endTime,
  serverTime,
  app,
  user,
  friends,
  setUser,
  damageInfo,
  damageTop,
  setIsFetching,
  isFetching,
  isModalOpen,
  setIsModalOpen,
  setModalError,
  useItem,
  allUsers,
  isShare,
  stage,
  number,
  bossExpScale,
  bossSilverScale,
  setBossLog,
  bossLog,
  damageLog = [],
  classType,
  bossesInfo,
  setBossesInfo,
  postImage,
  type,
  treasure,
  photos,
  souls = [],
  prizeShyt,
  manaCost = 1,
}) {
  const [clothesOpen, setClothesOpen] = useState([0, 0, 0, 0, 0, 0]);
  const [leftTimeString, setLeftTimeString] = useState("00ч:00м");
  const [leaveBossOpen, setLeaveBossOpen] = useState(false);
  const [endTopInfo, setEndTopInfo] = useState(false);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [bossAuto, setBossAuto] = useState(false);
  const [leftTime, setLeftTime] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const [isWin, setIsWin] = useState(false);
  const [currentBossHp, setCurrentBossHp] = useState(
    currentHp <= 0 ? 0 : currentHp
  );

  const [activePanel, setActivePanel] = useState("log");
  const [spellNumber, setSpellNumber] = useState(bossesInfo?.spellNumber || 0);

  const isEndRef = useRef(false);
  const scrollRef = useRef(null);

  const currentHpRef = useRef(currentHp);
  const currentSpell = useRef(null);
  const currentHeal = useRef(null);

  useEffect(() => {
    app.service("users").on("bosses", () => {
      app
        .service("users")
        .get(user._id, {
          query: {
            $select: [
              "_id",
              "email",
              "activeBoss",
              "damageTalents",
              "health",
              "mana",
              "allDamage",
              "weakDamage",
              "dayDamage",
              "raidDamage",
            ],
          },
        })
        .then((data) => {
          setUser((prev) => ({ ...prev, ...data }));
          setIsFetching(false);
        })
        .catch((e) => {
          setIsFetching(false);
          console.log(e);
        });
    });

    return () => {
      app.service("users").removeListener("bosses");
      setLoading(false);
      setIsFetching(false);
    };
  }, []);

  useEffect(() => {
    if (bossAuto) {
      if (!isEnd && user.health.current > 0 && currentSpell.current) {
        currentSpell.current?.click();
      } else if (!isEnd && user.health.current <= 0 && currentHeal.current) {
        currentHeal.current?.click();
      } else if (!isEnd && !currentHeal.current) {
        setBossAuto(false);
      }
    }
  }, [serverTime, user, isEnd, bossAuto]);

  useEffect(() => {
    if (!isEndRef.current && currentHpRef.current > 0 && serverTime) {
      timerFunc(serverTime);
    }
  }, [serverTime]);

  useEffect(() => {
    currentHpRef.current = currentHp;
    if (currentHp <= 0) {
      setCurrentBossHp(0);
      setIsWin(true);
      setIsEnd(true);
    } else {
      setCurrentBossHp(currentHp);
    }
  }, [currentHp]);

  useEffect(() => {
    isEndRef.current = isEnd;
    if (isEnd) {
      setLoading(false);
      setIsFetching(false);
      setIsModalOpen(true);
    }
  }, [isEnd]);

  useEffect(() => {
    if (leftTime <= 0 && leftTime !== null) {
      setIsEnd(true);
      setIsWin(false);
    }
  }, [leftTime]);

  useEffect(() => {
    const clothes = Object.entries(allClothes).filter(
      (elem) => elem[1]?.bossRandom && elem[1]?.bossRandom.number === number
    );

    const clothes1 = clothes.filter(
      (elem) =>
        elem[1]?.bossRandom.stage === 0 &&
        elem[1]?.bossRandom.isSolo === false &&
        user.clothes.isOpen.findIndex((el) => el === elem[0]) === -1
    ).length;

    const clothes2 = clothes.filter(
      (elem) =>
        elem[1]?.bossRandom.stage === 1 &&
        elem[1]?.bossRandom.isSolo === false &&
        user.clothes.isOpen.findIndex((el) => el === elem[0]) === -1
    ).length;

    const clothes3 = clothes.filter(
      (elem) =>
        elem[1]?.bossRandom.stage === 2 &&
        elem[1]?.bossRandom.isSolo === false &&
        user.clothes.isOpen.findIndex((el) => el === elem[0]) === -1
    ).length;

    const clothes4 = clothes.filter(
      (elem) =>
        elem[1]?.bossRandom.stage === 0 &&
        elem[1]?.bossRandom.isSolo === true &&
        user.clothes.isOpen.findIndex((el) => el === elem[0]) === -1
    ).length;

    const clothes5 = clothes.filter(
      (elem) =>
        elem[1]?.bossRandom.stage === 1 &&
        elem[1]?.bossRandom.isSolo === true &&
        user.clothes.isOpen.findIndex((el) => el === elem[0]) === -1
    ).length;

    const clothes6 = clothes.filter(
      (elem) =>
        elem[1]?.bossRandom.stage === 2 &&
        elem[1]?.bossRandom.isSolo === true &&
        user.clothes.isOpen.findIndex((el) => el === elem[0]) === -1
    ).length;

    setClothesOpen([
      4 - clothes1,
      4 - clothes2,
      4 - clothes3,
      4 - clothes4,
      4 - clothes5,
      4 - clothes6,
    ]);
  }, [user]);

  const changeNumber = useCallback((number) => {
    setSpellNumber(number);
    setBossesInfo((prev) => ({
      ...prev,
      spellNumber: number,
    }));
  }, []);

  function changeInfoOpen(info) {
    setIsInfoOpen(info);
  }

  function changeEndTop(info) {
    setEndTopInfo(info);
  }

  function timerFunc(serverTime) {
    let secs = Math.floor((endTime - serverTime) / 1000);
    if (secs <= 0) {
      secs = 0;
    }
    setLeftTime(secs);
    setLeftTimeString(secondsToHm(secs));
  }

  const dealDamage = useCallback(
    ({ damage, mana, countAttacks, deffence, bossDamage }) => {
      if (typeof damage === "function") {
        damage = damage();
      }

      const randEvasion = (Math.random() * 100).toFixed(1);
      const isEvasionBoss =
        100 -
          bossEvasionChance *
            (1 - user.damageTalents.simpleTalents.accuracyChance / 100) -
          randEvasion <=
        0;

      const randKrit = (Math.random() * 100).toFixed(1);
      const isKritBoss = 100 - bossKritChance - randKrit <= 0;

      app
        .service("users")
        .dealDamage({
          damage: {
            ...damage,
            value:
              damage.isKrit && !isEvasionBoss
                ? Math.floor(damage.value * damage.krit)
                : damage.value,
          },
          countAttacks: countAttacks,
          friends: [...friends, user.email],
          user: {
            _id: user._id,
            email: user.email,
          },
          mana: mana,
          bossDamage: {
            isKrit: isKritBoss,
            damage: Math.floor(bossDamage * (isKritBoss ? 1.5 : 1)),
          },
          isEvasionBoss: isEvasionBoss,
          deffence: deffence,
        })
        .catch((e) => {
          setModalError(e);
          setLoading(false);
          setIsFetching(false);
          console.log(e);
        })
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
          }, 700);
        });
    },
    [user]
  );

  function handleLeaveBoss({ isWin, leave = false }) {
    if (leave && user.leaveBoss >= 3) {
      setLeaveBossOpen(false);
      console.log("Вы сбегали слишком много за сегодня");
      setModalError("Вы сбегали слишком много за сегодня");
    } else {
      leaveBoss({
        isWin,
        ...rewards,
        exp: Math.floor(
          rewards.exp *
            (bossExpScale > 0 ? 1 + bossExpScale / 100 : 1) *
            (isSolo && type !== "location" ? 3 : 1)
        ),
        silver: Math.floor(
          rewards.silver *
            (bossSilverScale > 0 ? 1 + bossSilverScale / 100 : 1) *
            (isSolo && type !== "location" ? 3 : 1)
        ),
        treasure,
        leave,
        stage,
        isSolo,
        souls,
        prizeShyt,
      });
    }
  }

  function updateBosses() {
    setIsFetching(true);
    app
      .service("users")
      .get(
        user._id,
        {},
        {
          query: {
            $select: ["_id", "email", "activeBoss"],
          },
        }
      )
      .then((data) => {
        setUser((prev) => ({ ...prev, ...data }));
        setIsFetching(false);
      })
      .catch((e) => {
        setIsFetching(false);
        console.log(e);
      });
  }

  async function addWallPost() {
    setIsFetching(true);
    bridge
      .send("VKWebAppShowWallPostBox", {
        message: `Помогай бить Боссов, переходи по ссылке https://vk.com/app51651233#${user.email}`,
        attachments: postImage,
      })
      .then((data) => {
        if (data.post_id) {
          app
            .service("users")
            .patch(
              user._id,
              {
                "activeBoss.isShare": true,
                $inc: {
                  mana: 50,
                },
                common: true,
              },
              {
                query: {
                  $select: ["_id", "email", "activeBoss", "mana"],
                },
              }
            )
            .then((data) => {
              setUser((prev) => ({ ...prev, ...data }));
              setIsFetching(false);
            })
            .catch((e) => {
              setIsFetching(false);
              console.log(e);
            });
        } else {
          setIsFetching(false);
        }
      })
      .catch((e) => {
        setIsFetching(false);
        console.log(e);
      });
  }

  function changePanel(name) {
    setActivePanel(name);
  }

  function changeLog() {
    setBossLog((prev) => {
      if (!prev) {
        return "log";
      } else {
        return "";
      }
    });
  }

  function changeAuto() {
    setBossAuto((prev) => !prev);
  }

  return (
    <>
      <BarsPart>
        <img src={imgBars} alt="BossBars" width={358} height={55} />
      </BarsPart>
      <BarsHpBack>
        <img src={imgHpBack} alt="HpBar" width={271} height={22} />
      </BarsHpBack>
      <BarsHp value={(currentBossHp / allHp) * 100}>
        <img src={imgHpBar} alt="HpBar" width={193} height={18} />
        <span>
          {roundProperty(currentBossHp).toLocaleString("ru")}/
          {roundProperty(allHp).toLocaleString("ru")}
        </span>
      </BarsHp>

      <Time>
        <img src={timeIcon} alt="время" width={14} />
        <span>{leftTimeString}</span>
      </Time>

      <S.LogOpen>
        <S.Button
          disabled={bossAuto}
          active={true}
          onClick={changeAuto}
          minW={80}
        >
          <div>Автобой</div>
        </S.Button>
        <S.Button onClick={changeLog} minW={80}>
          <div>{bossLog ? "Бой" : "Лог"}</div>
        </S.Button>
      </S.LogOpen>

      <S.LogUsers bossLog={bossLog}>
        <S.Button
          disabled={activePanel === "log"}
          onClick={changePanel.bind(null, "log")}
          minW={80}
        >
          <div>Лог</div>
        </S.Button>
        <S.Button
          disabled={activePanel === "top"}
          onClick={changePanel.bind(null, "top")}
          minW={80}
        >
          <div>Топ</div>
        </S.Button>
      </S.LogUsers>

      <S.DamageLog bossLog={bossLog}>
        <DamageShadow damageInfo={damageLog} />
      </S.DamageLog>

      <S.LogUsersDamage bossLog={bossLog}>
        {activePanel === "log" && (
          <Damage
            damageInfo={damageInfo}
            allUsers={allUsers}
            scrollRef={scrollRef}
          />
        )}
        {activePanel === "top" && (
          <DamageTop
            name={user.name}
            nickName={user.nickName}
            orden={user.orden}
            email={user.email}
            damageTop={damageTop}
            allUsers={allUsers}
            allHp={allHp}
          />
        )}
      </S.LogUsersDamage>

      <S.InfoBoss>
        <S.Button
          minW={80}
          onClick={
            !loading && !isFetching && !isModalOpen ? updateBosses : null
          }
        >
          <div>Обновить</div>
        </S.Button>
        <S.Button minW={80} onClick={changeInfoOpen.bind(null, true)}>
          <div>Инфо</div>
        </S.Button>
        {isEnd && !isWin ? (
          <S.Button
            minW={80}
            onClick={
              !loading && !isFetching && !isModalOpen
                ? () => setLeaveBossOpen(true)
                : null
            }
          >
            <div>Выход</div>
          </S.Button>
        ) : isEnd && isWin ? (
          <S.Button
            minW={80}
            onClick={
              !loading && !isFetching
                ? handleLeaveBoss.bind(null, { isWin })
                : null
            }
          >
            <div>Выход</div>
          </S.Button>
        ) : (
          <S.Button
            minW={80}
            onClick={
              !loading && !isFetching && !isModalOpen
                ? () => setLeaveBossOpen(true)
                : null
            }
          >
            <div>Сбежать</div>
          </S.Button>
        )}
      </S.InfoBoss>

      <S.FirstSpell>
        <S.SpellWrapper>
          <div>
            <ButtonLeft
              onClick={changeNumber.bind(null, 0)}
              limit={spellNumber === 0}
            >
              <img width={24} height={24} src={scrollButtonImg} alt="вперёд" />
            </ButtonLeft>
            <ButtonRight
              onClick={changeNumber.bind(null, 1)}
              limit={spellNumber === 1}
            >
              <img width={24} height={24} src={scrollButtonImg} alt="назад" />
            </ButtonRight>
          </div>
        </S.SpellWrapper>

        {user.spells.active[classType].map((elem, i) => {
          return (
            elem > -1 &&
            i === spellNumber && (
              <Spell
                currentSpell={currentSpell}
                grind={user.grind}
                name={user.name}
                spellNumber={spellNumber}
                key={i}
                useItem={useItem}
                {...spellsArray[classType][elem]}
                mana={Math.floor(
                  spellsArray[classType][elem].mana *
                    manaCost *
                    (1 - user.damageTalents.simpleTalents.manaScale / 100)
                )}
                lvl={user.spells.lvl[classType]}
                userHp={user.health.current}
                userMana={user.mana}
                bossDamage={Math.floor(
                  attack *
                    (1 -
                      user.damageTalents.simpleTalents.bossDefenceScale / 100)
                )}
                damage={
                  spellsArray[classType][elem].damage *
                  (1 + user.damageTalents.simpleTalents.bossDamageScale / 100) *
                  (user.spells.done[classType][elem] /
                    spellsArray[classType][elem].countHave)
                }
                kritChance={user.damageTalents.simpleTalents.kritChance}
                currentHp={currentHp}
                isFetching={isFetching || loading || isEnd}
                isModalOpen={isModalOpen}
                dealDamage={dealDamage}
                force={user.characteristic.force}
                power={user.characteristic.power}
                accuracy={user.characteristic.accuracy}
                setIsFetching={setIsFetching}
                setLoading={setLoading}
                setModalError={setModalError}
                damageTalents={user.damageTalents}
                stockLvl={user.stockLvl}
                bossesInfo={bossesInfo}
                setBossesInfo={setBossesInfo}
                serverTime={serverTime}
              />
            )
          );
        })}
      </S.FirstSpell>

      <S.Heal>
        <S.SpellWrapper>
          <div />
        </S.SpellWrapper>
      </S.Heal>

      <S.Heal>
        <Healls
          currentHeal={currentHeal}
          inventory={user.inventory}
          useItem={useItem}
          isFetching={isFetching}
          bossesInfo={bossesInfo}
          setBossesInfo={setBossesInfo}
        />
      </S.Heal>

      <Popup
        isOpen={isInfoOpen}
        setIsOpen={setIsInfoOpen}
        w={436}
        h={320}
        back={frame6}
      >
        <Close onClick={changeInfoOpen.bind(null, false)} />
        <b>Информация о Боссе</b>
        <span>
          Тип: {type === "location" ? "Соло Босс на локации" : "Босс бестиария"}
          , Класс: {types[classType]}, Стадия:{" "}
          {stage === 0 ? "Обычный" : stage === 1 ? "Агрессивный" : "Проклятый"},
          Режим: {isSolo ? "Соло" : "Совместный"}
        </span>
        <span>
          Урон:{" "}
          {roundProperty(
            Math.floor(
              attack *
                (1 - user.damageTalents.simpleTalents.bossDefenceScale / 100)
            )
          ).toLocaleString("ru")}
          , Шанс крита: {Math.floor(bossKritChance)}%, Шанс уклонения:{" "}
          {Math.floor(bossEvasionChance)}%
        </span>

        {manaCost > 1 && <span>Стоимость заклинаний: {manaCost * 100}%</span>}

        {type === "dungeon" && (
          <span>
            Предметов совместно: 1) {clothesOpen[0]} из 4; 2) {clothesOpen[1]}{" "}
            из 4; 3) {clothesOpen[2]} из 4;
          </span>
        )}

        {type === "dungeon" && (
          <span>
            Предметов лично: 1) {clothesOpen[3]} из 4; 2) {clothesOpen[4]} из 4;
            3) {clothesOpen[5]} из 4;
          </span>
        )}

        <span>Награда:</span>

        <Rewards>
          <RewardWrapper light={false} w={65}>
            <div>
              <Reward
                name={"exp"}
                count={Math.floor(
                  rewards.exp *
                    (bossExpScale > 0 ? 1 + bossExpScale / 100 : 1) *
                    (isSolo && type !== "location" ? 3 : 1)
                )}
                w={28}
                showCount={true}
                round={true}
                font={13}
                reverseColumn={true}
                color={"#fdbb54"}
              />
            </div>
          </RewardWrapper>
          {rewards.silver && (
            <RewardWrapper light={false} w={65}>
              <div>
                <Reward
                  name={"silver"}
                  count={Math.floor(
                    rewards.silver *
                      (bossSilverScale > 0 ? 1 + bossSilverScale / 100 : 1) *
                      (isSolo && type !== "location" ? 3 : 1)
                  )}
                  w={28}
                  showCount={true}
                  round={true}
                  font={13}
                  reverseColumn={true}
                  color={"#fdbb54"}
                />
              </div>
            </RewardWrapper>
          )}
          {rewards.gold && (
            <RewardWrapper light={false} w={65}>
              <div>
                <Reward
                  name={"gold"}
                  count={rewards.gold}
                  w={28}
                  showCount={true}
                  round={true}
                  font={13}
                  reverseColumn={true}
                  color={"#fdbb54"}
                />
              </div>
            </RewardWrapper>
          )}

          {number < bossesArray.length - 1 && type !== "location" && (
            <RewardWrapper light={false} w={65}>
              <div>
                <Reward
                  name={"soul"}
                  count={1}
                  soul={number + 1}
                  w={28}
                  showCount={true}
                  round={true}
                  font={13}
                  reverseColumn={true}
                  color={"#fdbb54"}
                />
              </div>
            </RewardWrapper>
          )}
        </Rewards>
      </Popup>

      <Popup
        isOpen={leaveBossOpen}
        setIsOpen={setLeaveBossOpen}
        w={281}
        h={152}
        back={frame1}
      >
        <Close
          onClick={() => {
            setLeaveBossOpen(false);
          }}
        />
        <span>
          {isEnd ? <b>Вы хотите выйти?</b> : <b>Вы хотите сбежать?</b>}
        </span>
        {!isEnd && <span>(доступно сегодня: {3 - user.leaveBoss})</span>}
        <S.ButtonsWrapper>
          {isEnd ? (
            <Button
              onClick={
                !loading && !isFetching
                  ? handleLeaveBoss.bind(null, { isWin })
                  : null
              }
            >
              <div>Выйти</div>
            </Button>
          ) : (
            <Button
              onClick={
                !loading && !isFetching
                  ? handleLeaveBoss.bind(null, { isWin, leave: true })
                  : null
              }
            >
              <div>Сбежать</div>
            </Button>
          )}
        </S.ButtonsWrapper>
      </Popup>

      <Popup
        isOpen={isModalOpen}
        w={700}
        h={410}
        back={frame7}
        justify={"start"}
      >
        <S.EndFightWrapper>
          <img
            src={isWin ? winRibon : loseRibon}
            width={430}
            height={80}
            alt="лента"
          />
          <S.EndLeftPart>
            <img src={photos[number]} width={310} height={214} alt="фото" />

            {isWin && (
              <S.Hand src={handImg} width={140} height={154} alt="кровь" />
            )}

            <S.LoseText>{isWin ? "" : "Сбежал"}</S.LoseText>

            <S.BossName>
              <div>{name}</div>
            </S.BossName>

            <S.EndBossInfo>
              {bossesStages[stage]},{" "}
              {isSolo ? "Соло режим" : "Совместный режим"}
            </S.EndBossInfo>
          </S.EndLeftPart>
          <S.EndRightPart>
            <S.EndButtonsWrapper>
              <S.Button
                minW={120}
                disabled={!endTopInfo}
                onClick={changeEndTop.bind(null, false)}
              >
                <div>Мои результаты</div>
              </S.Button>
              <S.Button
                minW={120}
                disabled={endTopInfo}
                onClick={changeEndTop.bind(null, true)}
              >
                <div>Топ урона</div>
              </S.Button>
            </S.EndButtonsWrapper>

            {endTopInfo ? (
              <S.TopInfo>
                <div>Лучшие маги:</div>

                <FinishTopBosses
                  nickName={user.nickName}
                  photo={user.photo}
                  orden={user.orden}
                  name={user.name}
                  email={user.email}
                  damageTop={damageTop}
                  allUsers={allUsers}
                  allHp={allHp}
                />
              </S.TopInfo>
            ) : (
              <S.MeInfo>
                <div>Нанесённый урон:</div>

                <S.Avatar>
                  <img src={user.photo} width={70} height={70} alt={"аватар"} />
                </S.Avatar>

                <S.ButtonValue minW={70}>
                  <div>
                    {roundPropertyLow(
                      damageTop?.[user.email] || 0
                    ).toLocaleString("ru")}
                  </div>
                </S.ButtonValue>

                {isWin && (
                  <>
                    <div>Ваша награда за бой:</div>

                    <Rewards>
                      <RewardWrapper light={false} w={65}>
                        <div>
                          <Reward
                            name={"exp"}
                            count={Math.floor(
                              rewards.exp *
                                (bossExpScale > 0
                                  ? 1 + bossExpScale / 100
                                  : 1) *
                                (isSolo && type !== "location" ? 3 : 1)
                            )}
                            w={28}
                            showCount={true}
                            round={true}
                            font={13}
                            reverseColumn={true}
                            color={"#fdbb54"}
                          />
                        </div>
                      </RewardWrapper>
                      {rewards.silver && (
                        <RewardWrapper light={false} w={65}>
                          <div>
                            <Reward
                              name={"silver"}
                              count={Math.floor(
                                rewards.silver *
                                  (bossSilverScale > 0
                                    ? 1 + bossSilverScale / 100
                                    : 1) *
                                  (isSolo && type !== "location" ? 3 : 1)
                              )}
                              w={28}
                              showCount={true}
                              round={true}
                              font={13}
                              reverseColumn={true}
                              color={"#fdbb54"}
                            />
                          </div>
                        </RewardWrapper>
                      )}

                      {rewards.gold && (
                        <RewardWrapper light={false} w={65}>
                          <div>
                            <Reward
                              name={"gold"}
                              count={rewards.gold}
                              w={28}
                              showCount={true}
                              round={true}
                              font={13}
                              reverseColumn={true}
                              color={"#fdbb54"}
                            />
                          </div>
                        </RewardWrapper>
                      )}

                      {type === "location" && (treasure?.count || 0) > 0 && (
                        <RewardWrapper light={false} w={65}>
                          <div>
                            <Reward
                              name={"treasure"}
                              count={treasure?.count}
                              treasure={treasure?.lvl}
                              w={28}
                              showCount={true}
                              round={true}
                              font={13}
                              reverseColumn={true}
                              color={"#fdbb54"}
                            />
                          </div>
                        </RewardWrapper>
                      )}

                      {souls?.[0] > -1 && (
                        <RewardWrapper light={false} w={65}>
                          <div>
                            <Reward
                              name={"soul"}
                              count={prizeShyt === 0 ? 5 : 1}
                              soul={souls[0] + 1}
                              w={28}
                              showCount={true}
                              round={true}
                              font={13}
                              reverseColumn={true}
                              color={"#fdbb54"}
                            />
                          </div>
                        </RewardWrapper>
                      )}

                      {souls?.[1] > -1 && (
                        <RewardWrapper light={false} w={65}>
                          <div>
                            <Reward
                              name={"soul"}
                              count={prizeShyt === 0 ? 5 : 1}
                              soul={souls[1] + 1}
                              w={28}
                              showCount={true}
                              round={true}
                              font={13}
                              reverseColumn={true}
                              color={"#fdbb54"}
                            />
                          </div>
                        </RewardWrapper>
                      )}

                      {number < bossesArray.length - 1 &&
                        type !== "location" && (
                          <RewardWrapper light={false} w={65}>
                            <div>
                              <Reward
                                name={"soul"}
                                count={1}
                                soul={number + 1}
                                w={28}
                                showCount={true}
                                round={true}
                                font={13}
                                reverseColumn={true}
                                color={"#fdbb54"}
                              />
                            </div>
                          </RewardWrapper>
                        )}
                    </Rewards>
                  </>
                )}

                <S.EndButtonsWrapper>
                  {isWin && !isShare && (
                    <S.Button
                      minW={120}
                      onClick={!loading && !isFetching ? addWallPost : null}
                    >
                      <S.Rewards>
                        <span>Поделиться</span>
                        <Reward
                          name={"mana"}
                          count={"+50"}
                          w={14}
                          button={true}
                        />
                      </S.Rewards>
                    </S.Button>
                  )}

                  <S.Button
                    minW={120}
                    onClick={
                      !loading && !isFetching
                        ? handleLeaveBoss.bind(null, { isWin })
                        : null
                    }
                  >
                    <div>{isWin ? "Забрать награду" : "Выход"}</div>
                  </S.Button>
                </S.EndButtonsWrapper>
              </S.MeInfo>
            )}
          </S.EndRightPart>
        </S.EndFightWrapper>
      </Popup>
    </>
  );
}

export default React.memo(ActiveBoss);
