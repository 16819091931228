import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding: 0 5px;
  margin-bottom: 10px;

  & > div {
    width: 100%;
    gap: 5px;
    display: grid;
    align-items: center;
    text-align: center;
    justify-items: center;
    grid-template-columns: 0.6fr 1.5fr 1.2fr 0.6fr 1fr;
    font-size: 13px;
    line-height: 17px;
    color: #ffcf82;
    & > div {
      overflow: hidden;
      width: 100%;
      justify-content: center;
    }
  }
`;

export const Avatar = styled.span`
  cursor: ${(props) => (props.isMe ? "default" : "pointer")};
  display: flex;
  background: rgba(17, 12, 9, 0.7);
  outline: 1px solid #281707;
  box-shadow: inset 0px 0px 0px 1px #281707;
  border: 2px solid #fc9800;
`;
