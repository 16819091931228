import React, { useCallback, useEffect, useState } from "react";
import * as S from "./Garderob.styles";
import { allClothes, bossesStages, grindData } from "../../info/data";
import { Button } from "../../panels/Arena/Arena.styles";
import { TopPart } from "../BossInterface/BossInterface.styles";
import imgTop from "../../img/boss/TopPart.png";
import Reward from "../Reward/Reward";
import imgFrame from "../../img/garderob/Frame.png";
import prizeIcon from "../../img/garderob/Prize.png";
import eventIcon from "../../img/garderob/Event.png";
import treasureIcon from "../../img/garderob/Treasure.png";
import blockImg from "../../img/garderob/Block.png";
import shirtIcon from "../../img/garderob/Shirt.png";
import shoesIcon from "../../img/garderob/Shoes.png";
import broomIcon from "../../img/garderob/Broom.png";
import handsIcon from "../../img/garderob/Hands.png";
import headIcon from "../../img/garderob/Head.png";
import pantsIcon from "../../img/garderob/Pants.png";
import number1 from "../../img/garderob/Number1.png";
import number2 from "../../img/garderob/Number2.png";
import wandIcon from "../../img/garderob/Wand.png";
import Popup from "../Popup/Popup";
import { Close } from "../../App.styles";
import frame1 from "../../img/Frame1.png";

const names = {
  health: "Здоровье",
  force: "Сила магии",
  power: "Ярость",
  protection: "Сопротивление",
  accuracy: "Точность",
  evasion: "Ловкость",
  speed: "Скорость",
};

const categories = [
  {
    name: "head",
    img: headIcon,
  },
  {
    name: "shirt",
    img: shirtIcon,
  },
  {
    name: "hand",
    img: handsIcon,
  },
  {
    name: "pants",
    img: pantsIcon,
  },
  {
    name: "shoes",
    img: shoesIcon,
  },
  {
    name: "wand",
    img: wandIcon,
  },
  {
    name: "broom",
    img: broomIcon,
  },
];

function Garderob({
  setModalError,
  isFetching,
  setIsFetching,
  app,
  setUser,
  user,
  serverDate,
  changeOptionsLocation,
  getCharacterSum,
  moveLocation,
}) {
  const [hoverInfo, setHoverInfo] = useState(null);
  const [buyInfo, setBuyInfo] = useState(null);
  const [activeCategory, setActiveCategory] = useState("head");
  const [catalogClothes, setCatalogClothes] = useState([]);
  const [catalogClothesFiltered, setCatalogClothesFiltered] = useState([]);
  const [lvlClothes, setLvlClothes] = useState(0);
  const [filter1, setFilter1] = useState(false);
  const [filter2, setFilter2] = useState(false);

  useEffect(() => {
    changeOptionsLocation("garderob");
  }, []);

  useEffect(() => {
    // const newArray = Object.entries(allClothes)
    //   .filter((elem) => elem[1].category === activeCategory)
    //   .sort((a, b) => a[1]?.price?.count - b[1]?.price?.count)
    //   .map((elem, i) => {
    //     if (elem[1].isPrize || elem[1].treasure) return elem;
    //     return [
    //       elem[1].category + (i + 1),
    //       { ...elem[1], src: i + 1 + ".png" },
    //     ];
    //   });
    //
    // console.log(newArray);
    //
    // const obg1 = {};
    //
    // newArray.forEach((elem) => {
    //   obg1[elem[0]] = elem[1];
    // });
    //
    // console.log(obg1);

    if (activeCategory) {
      if (lvlClothes >= 0) {
        setCatalogClothes(
          setCatalog(
            Object.entries(allClothes).filter(
              (elem) =>
                elem[1].category === activeCategory &&
                elem[1].lvl === lvlClothes &&
                !elem[1].event &&
                !elem[1].isPrize
            )
          )
        );
      } else {
        setCatalogClothes(
          setCatalog(
            Object.entries(allClothes).filter(
              (elem) =>
                elem[1].category === activeCategory &&
                (elem[1].event || elem[1].isPrize)
            )
          )
        );
      }
    }
  }, [activeCategory, lvlClothes]);

  useEffect(() => {
    if (catalogClothes.length > 0) {
      if (filter1) {
        setCatalogClothesFiltered(
          setCatalog(
            catalogClothes.filter(
              (elem) => (!elem[1].isBuy && elem[1].isOpen) || elem[1].isBuy
            )
          )
        );
      } else if (filter2) {
        setCatalogClothesFiltered(
          setCatalog(
            catalogClothes.filter((elem) => !elem[1].isBuy && !elem[1].isOpen)
          )
        );
      } else {
        setCatalogClothesFiltered(setCatalog(catalogClothes));
      }
    }
  }, [filter1, filter2, catalogClothes]);

  useEffect(() => {
    setCatalogClothes((prev) => setCatalog(prev));
  }, [user]);

  function changeLvl(lvl) {
    setLvlClothes(lvl);
  }

  function changeFilter(number) {
    if (number === 1) {
      if (!filter2) {
        setFilter1((prevState) => !prevState);
      } else {
        setFilter1((prevState) => !prevState);
        setFilter2(false);
      }
    } else {
      if (!filter1) {
        setFilter2((prevState) => !prevState);
      } else {
        setFilter2((prevState) => !prevState);
        setFilter1(false);
      }
    }
  }

  function setCategory(name) {
    setActiveCategory(name);
  }

  function setHover(info) {
    setHoverInfo(info);
  }

  function setBuy(info) {
    setBuyInfo(info);
  }

  const setCatalog = useCallback(
    (array) => {
      const userCharacSum = getCharacterSum(user);

      const classroomsDone = user.classrooms.reduce(
        (acc, cur) => acc + cur.done,
        0
      );

      const collectionsDone = user.collections.reduce(
        (acc, cur) => acc + cur.done,
        0
      );

      return array.map((elem) => {
        elem[1].haveKill =
          user.bosses[elem[1].bossNeed?.number]?.totalKills || 0;
        elem[1].needKill = elem[1].bossNeed;

        elem[1].userCharacSum = userCharacSum;
        elem[1].classroomsDone = classroomsDone;
        elem[1].collectionsDone = collectionsDone;

        elem[1].isOpen = true;

        if (
          elem[1].bossRandom ||
          elem[1].event ||
          elem[1].diceGameTournament ||
          elem[1].game21Tournament
        ) {
          elem[1].isOpen =
            user.clothes.isOpen?.findIndex((el) => elem[0] === el) > -1;
        }
        if (elem[1].treasure || elem[1].isPrize) {
          elem[1].isOpen = false;
        }
        if (elem[1].lvlNeed) {
          elem[1].isOpen = user.lvl >= elem[1].lvlNeed;
        }
        if (elem[1].collectionsMoveNeed) {
          elem[1].isOpen =
            user.collectionsMoveCount >= elem[1].collectionsMoveNeed;
        }
        if (elem[1].collectionsGetNeed) {
          elem[1].isOpen =
            user.collectionsGetCount >= elem[1].collectionsGetNeed;
        }
        if (elem[1].candyMoveNeed) {
          elem[1].isOpen = user.candyMoveCount >= elem[1].candyMoveNeed;
        }
        if (elem[1].arenaWinsNeed) {
          elem[1].isOpen = user.arena.countWins >= elem[1].arenaWinsNeed;
        }
        if (elem[1].arenaDefWinsNeed) {
          elem[1].isOpen = user.arena.countDefWins >= elem[1].arenaDefWinsNeed;
        }
        if (elem[1].characSum) {
          elem[1].isOpen = userCharacSum >= elem[1].characSum;
        }
        if (elem[1].classroomsNeed) {
          elem[1].isOpen = classroomsDone >= elem[1].classroomsNeed;
        }
        if (elem[1].collectionsNeed) {
          elem[1].isOpen = collectionsDone >= elem[1].collectionsNeed;
        }
        if (elem[1].lvlDiceNeed) {
          elem[1].isOpen = user.diceGame.lvl >= elem[1].lvlDiceNeed;
        }
        if (elem[1].game21Need) {
          elem[1].isOpen = user.game21.countGames >= elem[1].game21Need;
        }

        if (elem[1].candyGetNeed) {
          elem[1].isOpen = user.candyGet >= elem[1].candyGetNeed;
        }
        if (elem[1].bossNeed) {
          elem[1].isOpen =
            (user.bosses[elem[1].bossNeed?.number]?.totalKills || 0) >=
            elem[1].bossNeed?.count;
        }

        if (elem[1].diceGameComb) {
          elem[1].isOpen =
            (user.achievements?.diceGame?.[elem[1].diceGameComb.property] ||
              0) >= elem[1].diceGameComb?.count;
        }

        if (elem[1].game21Comb) {
          elem[1].isOpen =
            (user.achievements?.game21?.[elem[1].game21Comb.property] || 0) >=
            elem[1].game21Comb?.count;
        }

        elem[1].isBuy =
          user.clothes.isBuy.findIndex((el) => el === elem[0]) > -1;

        const isGet = user.clothes.isGet.findIndex(
          (el) =>
            el.value === elem[1].src &&
            el.category === elem[1].category &&
            el.lvl === elem[1].lvl
        );
        if (isGet > -1) {
          elem[1].isGet = true;
          return elem;
        }
        elem[1].isGet = false;
        return elem;
      });
    },
    [user]
  );

  function getClothes({ name, category, lvl }) {
    setIsFetching(true);
    app
      .service("users")
      .patch(
        user._id,
        {
          "clothes.isGet": user.clothes.isGet.map((elem) => {
            if (elem.category === category) {
              elem.value = name;
              elem.lvl = lvl;
            }
            return elem;
          }),
          field: serverDate,
        },
        {
          query: {
            $select: ["_id", "email", "clothes"],
          },
        }
      )
      .then((data) => {
        setUser((prev) => ({ ...prev, ...data }));
        setIsFetching(false);
      })
      .catch((e) => {
        setIsFetching(false);
        setModalError(e);
        console.log(e);
      });
  }

  function dropClothes(category) {
    setIsFetching(true);
    app
      .service("users")
      .patch(
        user._id,
        {
          "clothes.isGet": user.clothes.isGet.map((elem) => {
            if (category === "all") {
              elem.value = "";
            } else if (elem.category === category) {
              elem.value = "";
            }
            return elem;
          }),
          field: serverDate,
        },
        {
          query: {
            $select: ["_id", "email", "clothes"],
          },
        }
      )
      .then((data) => {
        setUser((prev) => ({ ...prev, ...data }));
        setIsFetching(false);
      })
      .catch((e) => {
        setIsFetching(false);
        setModalError(e);
        console.log(e);
      });
  }

  function buyClothes({ price, name }) {
    let isTransaction = true;

    if (price.property === "gold" && user.gold < price.count) {
      isTransaction = false;
    }

    if (price.property === "silver" && user.silver < price.count) {
      isTransaction = false;
    }

    if (isTransaction) {
      setIsFetching(true);

      const buyClothes = [...user.clothes.isBuy, name];

      const newCharacteristic = { ...user.characteristic };

      for (const key in newCharacteristic) {
        newCharacteristic[key].armor = 0;
      }

      const clothes = Object.entries(allClothes).filter((elem) =>
        buyClothes.includes(elem[0])
      );

      clothes.forEach((elem) => {
        Object.entries(elem[1].characteristic).forEach((el) => {
          newCharacteristic[el[0]].armor += el[1];
        });
      });

      const scales = {};
      const grindValue = grindData["book"].bonus[user.grind["book"] - 1] / 100;
      user.damageTalents.characteristicTalents.forEach((elem) => {
        scales[elem.property] =
          1 + grindValue + Math.round(elem.step * elem.countDone * 100) / 10000;
      });

      const hp = Math.floor(
        Object.values(newCharacteristic.health).reduce((acc, cur, i) => {
          if (i < 2) {
            return acc + cur * 5 * scales.health;
          }
          return acc + cur * 5;
        }, 0)
      );

      app
        .service("users")
        .patch(
          user._id,
          {
            "health.max": hp,
            "clothes.isBuy": buyClothes,
            characteristic: newCharacteristic,
            $inc: {
              gold: price.property === "gold" ? -price.count : 0,
              silver: price.property === "silver" ? -price.count : 0,
            },
            field: serverDate,
          },
          {
            query: {
              $select: [
                "_id",
                "email",
                "health",
                "clothes",
                "characteristic",
                "gold",
                "silver",
              ],
            },
          }
        )
        .then((data) => {
          setUser((prev) => ({ ...prev, ...data }));
          setIsFetching(false);
          setBuyInfo(null);
        })
        .catch((e) => {
          setIsFetching(false);
          setModalError(e);
          console.log(e);
          setBuyInfo(null);
        });
    } else {
      setModalError("Недостаточно средств");
      console.log("Недостаточно средств");
      setBuyInfo(null);
    }
  }

  function openInfo(e, elem) {
    const elementWrapper = document
      .querySelector(".clothesWrapper")
      .getBoundingClientRect();
    const size = Math.round(elementWrapper.height / 2 + elementWrapper.top);
    const element = e.target.getBoundingClientRect();
    if (element.top >= size) {
      setHover({ elem, isTop: true });
    } else {
      setHover({ elem, isTop: false });
    }
  }

  function closeInfo(e) {
    if (!e?.relatedTarget?.classList?.contains("info")) {
      setHover(null);
    }
  }

  return (
    <>
      <TopPart>
        <img src={imgTop} alt="интерфейс" />
      </TopPart>

      <S.Back>
        <Button
          width={130}
          onClick={() => {
            moveLocation("home");
          }}
        >
          <div>Назад в Гостинную</div>
        </Button>
      </S.Back>

      <S.Lvls>
        <S.Lvl disabled={lvlClothes === 0} onClick={changeLvl.bind(null, 0)}>
          <div>
            <img src={number1} width={22} height={11} alt="" />
          </div>
        </S.Lvl>

        <S.Lvl disabled={lvlClothes === 1} onClick={changeLvl.bind(null, 1)}>
          <div>
            <img src={number2} width={22} height={11} alt="" />
          </div>
        </S.Lvl>

        <S.Lvl
          width={40}
          disabled={lvlClothes === -1}
          onClick={changeLvl.bind(null, -1)}
        >
          <div>
            <img src={eventIcon} width={11} height={11} alt="" />
          </div>
        </S.Lvl>
      </S.Lvls>

      <S.DropClothes>
        <Button
          width={110}
          onClick={!isFetching ? dropClothes.bind(null, "all") : null}
        >
          <div>Снять всё</div>
        </Button>
      </S.DropClothes>

      <S.ClothesWrapper className={"clothesWrapper"}>
        {catalogClothesFiltered.length > 0 &&
          catalogClothesFiltered.map((elem) => {
            return (
              <S.Clothes
                active={hoverInfo?.elem === elem}
                onMouseEnter={(e) => {
                  openInfo(e, elem);
                }}
                onMouseLeave={closeInfo}
                isClose={!elem[1].isOpen && !elem[1].isBuy}
                cantBuy={
                  !elem[1].isBuy &&
                  elem[1]?.price?.property &&
                  user[elem[1]?.price?.property] < elem[1]?.price?.count
                }
                isGet={elem[1].isGet}
                key={`${user.sex} ${elem[0]}`}
                onClick={
                  !isFetching
                    ? !elem[1].isBuy && elem[1].isPrize
                      ? null
                      : !elem[1].isBuy && elem[1].isOpen
                      ? elem[1]?.price?.property &&
                        user[elem[1]?.price?.property] >= elem[1]?.price?.count
                        ? setBuy.bind(null, {
                            price: elem[1].price,
                            name: elem[0],
                          })
                        : null
                      : elem[1].isGet && elem[1].isBuy
                      ? dropClothes.bind(null, elem[1].category)
                      : elem[1].isBuy
                      ? getClothes.bind(null, {
                          name: elem[1].src,
                          category: elem[1].category,
                          lvl: elem[1].lvl,
                        })
                      : null
                    : null
                }
              >
                <img src={imgFrame} width={90} height={95} alt="" />
                <div>
                  {elem[1].category === "broom" ||
                  elem[1].category === "wand" ? (
                    <img
                      width={50}
                      height={50}
                      src={require(`../../img/clothes/${elem[1].category}${
                        elem[1].lvl >= 1 ? `${elem[1].lvl + 1}` : ""
                      }/${elem[1].src}`)}
                      alt={elem[1].category}
                    />
                  ) : (
                    <img
                      width={50}
                      height={50}
                      src={require(`../../img/clothes/${elem[1].category}${
                        elem[1].lvl >= 1 ? `${elem[1].lvl + 1}` : ""
                      }/${user.sex}/${elem[1].src}`)}
                      alt={elem[1].category}
                    />
                  )}
                  <S.CategoryIcon>
                    {elem[1].event && (
                      <img src={eventIcon} width={15} height={15} alt="" />
                    )}
                    {elem[1].isPrize && (
                      <img src={prizeIcon} width={15} height={15} alt="" />
                    )}
                    {elem[1].treasure && (
                      <img src={treasureIcon} width={15} height={15} alt="" />
                    )}
                  </S.CategoryIcon>
                  <S.TextBottom>
                    {!elem[1].isOpen && !elem[1].isBuy ? (
                      <span>Недоступно</span>
                    ) : !elem[1].isBuy ? (
                      <Reward
                        name={elem[1]?.price?.property}
                        count={elem[1]?.price?.count}
                        w={14}
                        button={true}
                        round={true}
                      />
                    ) : elem[1].isGet ? (
                      <span>Используется</span>
                    ) : (
                      <span>Надеть</span>
                    )}
                  </S.TextBottom>
                </div>

                {!elem[1].isOpen && !elem[1].isBuy && (
                  <img width={90} height={95} src={blockImg} alt="" />
                )}
              </S.Clothes>
            );
          })}
      </S.ClothesWrapper>

      <S.Categories>
        {categories.map((elem, i) => {
          return (
            <S.Category
              key={i}
              disabled={activeCategory === elem.name}
              onClick={setCategory.bind(null, elem.name)}
            >
              <div>
                <img src={elem.img} width={28} height={28} alt="" />
              </div>
            </S.Category>
          );
        })}
      </S.Categories>

      <S.Filters>
        <div onClick={changeFilter.bind(null, 1)}>
          <S.Filter isActive={filter1}>
            <div />
          </S.Filter>
          <span>Показать доступные</span>
        </div>
        <div onClick={changeFilter.bind(null, 2)}>
          <S.Filter isActive={filter2}>
            <div />
          </S.Filter>
          <span>Показать недоступные</span>
        </div>
      </S.Filters>

      <S.InfoPopup
        visible={hoverInfo}
        className={"info"}
        isTop={hoverInfo?.isTop}
      >
        {hoverInfo && (
          <>
            <S.Charac className={"info"}>
              {Object.entries(hoverInfo.elem[1].characteristic).map(
                (elem, i) => {
                  return (
                    <span key={i} className={"info"}>
                      {names[elem[0]]}{" "}
                      {(hoverInfo.elem[1].isPrize &&
                        !hoverInfo.elem[1].isBuy) ||
                      (hoverInfo.elem[1].treasure && !hoverInfo.elem[1].isBuy)
                        ? "?"
                        : `+${elem[1]}`}
                    </span>
                  );
                }
              )}
            </S.Charac>
            <S.Info className={"info"}>
              {!(
                hoverInfo.elem[1].isBuy ||
                (hoverInfo.elem[1].isOpen &&
                  !hoverInfo.elem[1].isBuy &&
                  !hoverInfo.elem[1].isPrize) ||
                (hoverInfo.elem[1].isBuy && hoverInfo.elem[1].isPrize)
              ) ? (
                <span className={"info"}>
                  {hoverInfo.elem[1].isPrize
                    ? "С розыгрыша или рулетки"
                    : hoverInfo.elem[1].event
                    ? `Ивент "${hoverInfo.elem[1].event}"`
                    : hoverInfo.elem[1].game21Tournament
                    ? `С турнира по игре в "21"`
                    : hoverInfo.elem[1].diceGameTournament
                    ? `С турнира по игре в кости`
                    : hoverInfo.elem[1].treasure
                    ? `С сундука ${hoverInfo.elem[1].treasure.lvl + 1} уровня`
                    : hoverInfo.elem[1].lvlNeed
                    ? `Необходим ${hoverInfo.elem[1].lvlNeed} уровень`
                    : hoverInfo.elem[1].lvlDiceNeed
                    ? `Необходим уровень в кости (${user.diceGame.lvl}/${hoverInfo.elem[1].lvlDiceNeed})`
                    : hoverInfo.elem[1].game21Need
                    ? `Необходим игр в 21 (${user.game21.countGames}/${hoverInfo.elem[1].game21Need})`
                    : hoverInfo.elem[1].arenaDefWinsNeed
                    ? `Побед на арене в защите (${user.arena.countDefWins}/${hoverInfo.elem[1].arenaDefWinsNeed})`
                    : hoverInfo.elem[1].arenaWinsNeed
                    ? `Побед на арене в нападении (${user.arena.countWins}/${hoverInfo.elem[1].arenaWinsNeed})`
                    : hoverInfo.elem[1].classroomsNeed
                    ? `Необходимо пройти уроков (${hoverInfo.elem[1].classroomsDone}/${hoverInfo.elem[1].classroomsNeed})`
                    : hoverInfo.elem[1].collectionsNeed
                    ? `Необходимо собрать коллекций (${hoverInfo.elem[1].collectionsDone}/${hoverInfo.elem[1].collectionsNeed})`
                    : hoverInfo.elem[1].characSum
                    ? `Необходимо характеристик (${hoverInfo.elem[1].userCharacSum}/${hoverInfo.elem[1].characSum})`
                    : hoverInfo.elem[1].candyGetNeed
                    ? `Получить Драже 'Сюрприз' (${user.candyGet}/${hoverInfo.elem[1].candyGetNeed})`
                    : hoverInfo.elem[1].candyMoveNeed
                    ? `Отправить Драже 'Сюрприз' (${user.candyMoveCount}/${hoverInfo.elem[1].candyMoveNeed})`
                    : hoverInfo.elem[1].collectionsMoveNeed
                    ? `Отправить элементов коллекций (${user.collectionsMoveCount}/${hoverInfo.elem[1].collectionsMoveNeed})`
                    : hoverInfo.elem[1].collectionsGetNeed
                    ? `Получить элементов коллекций (${user.collectionsGetCount}/${hoverInfo.elem[1].collectionsGetNeed})`
                    : hoverInfo.elem[1].diceGameComb
                    ? `Собрать "${
                        hoverInfo.elem[1].diceGameComb.name
                      }" в "Кости" (${
                        user.achievements?.diceGame?.[
                          hoverInfo.elem[1].diceGameComb.property
                        ] || 0
                      }/${hoverInfo.elem[1].diceGameComb?.count})`
                    : hoverInfo.elem[1].game21Comb
                    ? `Собрать "${hoverInfo.elem[1].game21Comb.name}" в "21" (${
                        user.achievements?.game21?.[
                          hoverInfo.elem[1].game21Comb.property
                        ] || 0
                      }/${hoverInfo.elem[1].game21Comb?.count})`
                    : hoverInfo.elem[1].bossRandom
                    ? `С ${hoverInfo.elem[1].bossRandom.name} (${
                        bossesStages[hoverInfo.elem[1].bossRandom.stage]
                      }, ${
                        hoverInfo.elem[1].bossRandom.isSolo
                          ? "Лично"
                          : "Совместно"
                      })`
                    : hoverInfo.elem[1].needKill &&
                      `Сразить ${hoverInfo.elem[1].needKill.name} (${hoverInfo.elem[1].haveKill}/${hoverInfo.elem[1].needKill.count})`}
                </span>
              ) : hoverInfo.elem[1].isBuy ? (
                <span className={"info"}>В наличии</span>
              ) : (
                <span className={"info"}>Доступно к покупке</span>
              )}
            </S.Info>
          </>
        )}
      </S.InfoPopup>

      <Popup
        isOpen={buyInfo}
        setIsOpen={setBuyInfo}
        w={281}
        h={152}
        back={frame1}
      >
        <Close
          onClick={() => {
            setBuyInfo(null);
          }}
        />

        {buyInfo && (
          <>
            <b>Желаете приобрести предмет?</b>

            <Button
              disabled={isFetching}
              onClick={!isFetching ? buyClothes.bind(null, buyInfo) : null}
            >
              <Reward
                name={buyInfo?.price?.property}
                count={`Купить ${buyInfo?.price?.count}`}
                w={14}
                button={true}
              />
            </Button>
          </>
        )}
      </Popup>
    </>
  );
}

export default React.memo(Garderob);
