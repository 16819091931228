import React, { useCallback, useEffect, useRef, useState } from "react";
import bridge from "@vkontakte/vk-bridge";
import { View } from "@vkontakte/vkui";

import "@vkontakte/vkui/dist/vkui.css";
import "./index.css";

import * as S from "./App.styles";

import { feathers } from "@feathersjs/feathers";
import socketio from "@feathersjs/socketio-client";
import io from "socket.io-client";
import authentication from "@feathersjs/authentication-client";
import {
  getMultipleRandom,
  roundLvlExp,
  roundProperty,
  secondsToMs,
} from "./helpers";
import customParser from "./Parser/index.js";
import { UserContext } from "./Context.js";

import Home from "./panels/Home/Home";
import Loader from "./components/Loader/Loader";
import Friends from "./components/Friends/Friends";
import Bosses from "./panels/Bosses/Bosses";
import LvlInfo from "./components/LvlInfo/LvlInfo";
import LocationInfo from "./components/LocationInfo/LocationInfo";
import MoneyInfo from "./components/MoneyInfo/MoneyInfo";
import EnergyInfo from "./components/EnergyInfo/EnergyInfo";
import GameRoom from "./panels/GameRoom/GameRoom";
import Arena from "./panels/Arena/Arena";
import Collections from "./components/Collections/Collections";
import Popup from "./components/Popup/Popup";
import Map from "./components/Map/Map";
import UserRoom from "./panels/UserRoom/UserRoom";
import RefUsers from "./components/RefUsers/RefUsers";
import Shop from "./components/Shop/Shop";
import Inventory from "./components/Inventory/Inventory";
import MenuBottom from "./components/MenuBottom/MenuBottom";
import scrollButtonImg from "./img/ScrollButton.png";
import homeImg from "./img/menuIcons/Home.png";
import loadVideo from "./img/Load.mp4";
import testBackImg from "./img/TestBack.png";
import testStage1Img from "./img/TestStage1.png";
import testStage2Img from "./img/TestStage2.png";
import testStage3Img from "./img/TestStage3.png";
import frame5 from "./img/Frame5.png";
import frame3 from "./img/Frame3.png";
import frame2 from "./img/Frame2.png";
import frame1 from "./img/Frame1.png";
import moveVideo from "./img/MoveVideo.mp4";
import loadImg from "./img/LoadImg.jpg";
import movePoster from "./img/MovePoster.jpg";
import topsImg from "./img/Tops.jpg";
import topImg from "./img/menuIcons/TopIcon.png";
import mapImg from "./img/menuIcons/Map.png";
import bankImg from "./img/menuIcons/Bank.png";
import shopImg from "./img/menuIcons/Shop.png";
import bookImg from "./img/menuIcons/Book.png";
import bankBackImg from "./img/BankBack.jpg";
import garderobBackImg from "./img/GarderobBack.jpg";

import greenHouseImg from "./img/Greenhouse.jpg";
import potionLabImg from "./img/Potionlab.jpg";
import inventoryImg from "./img/menuIcons/Inventory.png";
import ordensImg from "./img/menuIcons/Ordens.png";
import collectionsImg from "./img/menuIcons/Collections.png";
import interfaceTop from "./img/main/Top.png";
import interfaceBottom from "./img/main/Bottom.png";
import interfaceLeft from "./img/main/Left.png";
import griffHomeImg from "./img/LoungeGriff.jpg";
import huffHomeImg from "./img/LoungePuff.jpg";
import ravenHomeImg from "./img/LoungeKogt.jpg";
import slizHomeImg from "./img/LoungeSliz.jpg";
import gameRoomImg from "./img/GameRoom.jpg";
import diceGameImg from "./img/DiceGame.jpg";
import game21Img from "./img/Game21.jpg";
import grassImg from "./img/Grass.jpg";
import potionImg from "./img/PotionRoom.jpg";
import bossesImg from "./img/BossesBack.jpg";
import shytImg from "./img/bosses/Shyt.jpg";
import trollImg from "./img/bosses/Troll.jpg";
import zlatoystImg from "./img/bosses/Zlatoyst.jpg";
import knightImg from "./img/bosses/Knight.jpg";
import demonImg from "./img/bosses/Demon.jpg";
import inspectorImg from "./img/bosses/Inspector.jpg";
import dvylikiyImg from "./img/bosses/Dvylikiy.jpg";
import slizImg from "./img/bosses/Sliz.jpg";
import symrakImg from "./img/bosses/Symrak.jpg";
import bunshiImg from "./img/bosses/Banshi.jpg";
import snegovikImg from "./img/bosses/Snegovik.jpg";
import snegovikiImg from "./img/bosses/Snegoviki.jpg";
import krisiImg from "./img/bosses/Krysyaki.jpg";
import piksiImg from "./img/bosses/Piksi.jpg";
import pivzImg from "./img/bosses/Pivz.jpg";
import mirtlImg from "./img/bosses/Mirtl.jpg";
import filchImg from "./img/bosses/Filch.jpg";
import boggartImg from "./img/bosses/Boggart.jpg";
import baronImg from "./img/bosses/Baron.jpg";
import abskurImg from "./img/bosses/Abskur.jpg";
import grumImg from "./img/bosses/Grum.jpg";
import krabGoilImg from "./img/bosses/KrabGoil.jpg";
import arenaImg from "./img/Arena.jpg";
import FriendsPhoto from "./components/FriendsPhoto/FriendsPhoto";
import NickName from "./components/NickName/NickName";
import Ads from "./components/Ads/Ads";
import FacultyTest from "./components/FacultyTest/FacultyTest";
import Reward from "./components/Reward/Reward";
import { Close } from "./App.styles";
import Bank from "./components/Bank/Bank";
import {
  allClothes,
  bossesArray,
  classRoomsArray,
  collectionsArray,
  dayPrizes,
  eventBossArray,
  grindData,
  raidBossesInfo,
  shytArray,
  soloBossArray,
  topsInfo,
  tournamentPrizes,
  weakDamagePrizes,
} from "./info/data";
import AdminPanel from "./components/AdminPanel/AdminPanel";
import Tops from "./panels/Tops/Tops";
import Guide from "./components/Guide/Guide";
import DayDamagePanel from "./components/DayDamagePanel/DayDamagePanel";
import RaidBoss from "./panels/RaidBoss/RaidBoss";
import GreenHouse from "./panels/GreenHouse/GreenHouse";
import PotionLab from "./panels/PotionLab/PotionLab";
import Ordens from "./components/Ordens/Ordens";
import Lounge from "./components/Lounge/Lounge";
import { useAudioPlayer } from "react-use-audio-player";
import Sound from "./components/Sound/Sound";
import Classroom from "./panels/Classroom/Classroom";
import Book from "./components/Book/Book";
import PracticRoom from "./components/PracticRoom/PracticRoom";
import SoloBoss from "./panels/SoloBoss/SoloBoss";
import DayReward from "./components/DayReward/DayReward";
import { RewardWrapper } from "./components/DayReward/DayReward.styles";
import CollectionFind from "./components/CollectionFind/CollectionFind";
import Guides from "./components/Guides/Guides";
import Garderob from "./components/Garderob/Garderob";
import LoungeGarderob from "./components/LoungeGarderob/LoungeGarderob";
import { Button } from "./panels/Arena/Arena.styles";
import Hammer from "hammerjs";
import EventBoss from "./panels/EventBoss/EventBoss";
import GroupInfo from "./components/GroupInfo/GroupInfo";
import Chat from "./components/Chat/Chat";
import frame8 from "./img/Frame8.png";
import Altar from "./components/Altar/Altar";
import Rating from "./components/Raiting/Raiting";
import axios from "axios";
import Shyt from "./panels/Shyt/Shyt";
import { ButtonsWrapper } from "./components/ActiveBoss/ActiveBoss.styles";

function android() {
  const el = document.querySelector("#root");
  const wrapper = document.querySelector("#global");

  let mc = new Hammer(el);

  let scale = 1;
  let isF = true;
  let diff = 0;

  mc.get("pinch").set({ enable: true });
  mc.get("pan").set({ direction: Hammer.DIRECTION_ALL, threshold: 1 });

  mc.on("pinch", function (ev) {
    if (ev.eventType === 4) {
      isF = true;
      diff = 0;
    }
    if (ev.eventType === 2 && isF) {
      diff = scale - 1;
      isF = false;
    }
    if (ev.eventType === 2 && !isF) {
      if (ev.scale + diff >= 1 && ev.scale + diff <= 2) {
        console.log(ev.changedPointers[0].movementX);

        if (scale <= ev.scale + diff) {
          wrapper.style.left += ev.changedPointers[0].movementX;
          wrapper.style.top += ev.changedPointers[0].movementY;
        } else {
          wrapper.style.left -= ev.changedPointers[0].movementX;
          wrapper.style.top -= ev.changedPointers[0].movementY;
        }
        scale = ev.scale + diff;
        el.style.transform = `scale(${scale})`;
      }
    }
  });

  mc.on("pan", function (ev) {
    let elemLeft = el.offsetLeft;
    let elemTop = el.offsetTop;

    if (ev.eventType === 2) {
      if (
        el.getBoundingClientRect().top + ev.srcEvent.movementY <= 0 &&
        el.getBoundingClientRect().bottom + ev.srcEvent.movementY >=
          wrapper.getBoundingClientRect().height
      ) {
        el.style.top = `${elemTop + ev.srcEvent.movementY}px`;
      }

      if (
        el.getBoundingClientRect().left + ev.srcEvent.movementX <= 0 &&
        el.getBoundingClientRect().right + ev.srcEvent.movementX >=
          wrapper.getBoundingClientRect().width
      ) {
        el.style.left = `${elemLeft + ev.srcEvent.movementX}px`;
      }

      if (
        el.getBoundingClientRect().top + ev.srcEvent.movementY < 0 &&
        el.getBoundingClientRect().bottom + ev.srcEvent.movementY <
          wrapper.getBoundingClientRect().height
      ) {
        if (ev.srcEvent.movementY > 0) {
          el.style.top = `${elemTop + ev.srcEvent.movementY}px`;
        }
      }

      if (
        el.getBoundingClientRect().top + ev.srcEvent.movementY > 0 &&
        el.getBoundingClientRect().bottom + ev.srcEvent.movementY >
          wrapper.getBoundingClientRect().height
      ) {
        if (ev.srcEvent.movementY < 0) {
          el.style.top = `${elemTop + ev.srcEvent.movementY}px`;
        }
      }

      if (
        el.getBoundingClientRect().left + ev.srcEvent.movementX > 0 &&
        el.getBoundingClientRect().right + ev.srcEvent.movementX >
          wrapper.getBoundingClientRect().width
      ) {
        if (ev.srcEvent.movementX < 0) {
          el.style.left = `${elemLeft + ev.srcEvent.movementX}px`;
        }
      }

      if (
        el.getBoundingClientRect().left + ev.srcEvent.movementX < 0 &&
        el.getBoundingClientRect().right + ev.srcEvent.movementX <
          wrapper.getBoundingClientRect().width
      ) {
        if (ev.srcEvent.movementX > 0) {
          el.style.left = `${elemLeft + ev.srcEvent.movementX}px`;
        }
      }
    }
  });
}

const faculty = {
  gryffindor: griffHomeImg,
  hufflepuff: huffHomeImg,
  ravenclaw: ravenHomeImg,
  slytherin: slizHomeImg,
};

const leftMenuArray = [
  {
    name: "Гостинная",
    property: "home",
    src: homeImg,
  },
  {
    name: "Карта",
    property: "map",
    src: mapImg,
  },
  {
    name: "Гримуар",
    property: "book",
    src: bookImg,
  },
  {
    name: "Лавка",
    property: "shop",
    src: shopImg,
  },
  {
    name: "Зал славы",
    property: "topsRoom",
    src: topImg,
  },
  {
    name: "Инвентарь",
    property: "inventory",
    src: inventoryImg,
  },
  {
    name: "Ордена",
    property: "ordens",
    src: ordensImg,
  },
  {
    name: "Коллекции",
    property: "collections",
    src: collectionsImg,
  },
  {
    name: "Банк",
    property: "bank",
    src: bankImg,
  },
];

const serverUrl = "https://magikshool.ru:3031/";

// http://localhost:3031/
// https://magikshool.ru:3031/

const socket = io(serverUrl, {
  transports: ["websocket"],
  origins: [
    "https://magikshool.ru:3031/",
    "https://magikshool.ru:3030/",
    "http://localhost:3031/",
  ],
  parser: customParser,
});

const clientVersion = "0.1.23";

const socketClient = socketio(socket);

const app = feathers();

app.configure(socketClient);
app.configure(authentication());

app.use("users", socketClient.service("users"), {
  methods: [
    "find",
    "get",
    "create",
    "update",
    "patch",
    "remove",
    "dealDamage",
    "dealRaidDamage",
    "arenaFight",
    "sendCollection",
    "addRefCount",
    "fetchYooMoney",
    "updateAdminInfo",
    "updateLimitsInfo",
    "updateTalentsInfo",
    "updateMagic",
    "sendCandy",
    "removeStock",
    "changeOrden",
    "sendResources",
    "raidBossClose",
    "messageAll",
    "messageOrden",
    "removeMessage",
    "getAllUsers",
  ],
});

const location = {
  test: {
    img: testBackImg,
  },

  home: {
    img: faculty,
  },

  bank: {
    img: bankBackImg,
  },

  garderob: {
    img: garderobBackImg,
  },

  greenHouse: {
    img: greenHouseImg,
  },

  potionLab: {
    img: potionLabImg,
  },

  topsRoom: {
    img: topsImg,
  },

  gameRoom: {
    img: gameRoomImg,
  },

  diceGameTournament: {
    img: diceGameImg,
  },

  game21Tournament: {
    img: game21Img,
  },

  diceGame: {
    img: diceGameImg,
  },

  game21: {
    img: game21Img,
  },

  potionRoom: {
    img: potionImg,
  },

  grassRoom: {
    img: grassImg,
  },

  bosses: {
    img: bossesImg,
  },

  shyt: {
    img: bossesImg,
  },

  soloBoss: {
    img: bossesImg,
  },

  eventBoss: {
    img: bossesImg,
  },

  raidBoss: {
    img: bossesImg,
  },

  arena: {
    img: arenaImg,
  },

  arenaTournament: {
    img: arenaImg,
  },

  userRoom: {
    img: faculty,
  },

  ["Шут"]: {
    img: shytImg,
  },

  ["Тролль"]: {
    img: trollImg,
  },

  ["Златоуст"]: {
    img: zlatoystImg,
  },

  ["Призрак Рыцаря"]: {
    img: knightImg,
  },

  ["Демон канализации"]: {
    img: demonImg,
  },

  ["Биомасса"]: {
    img: slizImg,
  },

  ["Инспектор"]: {
    img: inspectorImg,
  },

  ["Двуликий"]: {
    img: dvylikiyImg,
  },

  ["Сумрак"]: {
    img: symrakImg,
  },

  ["Банши"]: {
    img: bunshiImg,
  },

  ["Верховный маг холода"]: {
    img: snegovikImg,
  },

  ["Порождения холода"]: {
    img: snegovikiImg,
  },

  ["Сточные крысы"]: {
    img: krisiImg,
  },

  ["Пикси"]: {
    img: piksiImg,
  },

  ["Полтергейст"]: {
    img: pivzImg,
  },
  ["Призрак Плаксы"]: {
    img: mirtlImg,
  },
  ["Жуткий Завхоз"]: {
    img: filchImg,
  },
  ["Кошмар"]: {
    img: boggartImg,
  },
  ["Зловещий Призрак"]: {
    img: baronImg,
  },
  ["Обскур"]: {
    img: abskurImg,
  },
  ["Лже-маг"]: {
    img: grumImg,
  },
  ["Задиры"]: {
    img: krabGoilImg,
  },
};

const songs = getMultipleRandom([
  "https://magikshool.ru/Song1.mp3",
  "https://magikshool.ru/Song2.mp3",
  "https://magikshool.ru/Song3.mp3",
  "https://magikshool.ru/Song4.mp3",
]);

const search = window.location.search.slice(1);

function registerUser(userId) {
  return app.service("users").create({
    email: userId,
  });
}

function localLoginUser(userId) {
  return app.authenticate({
    strategy: "local",
    email: userId,
    hash: search,
  });
}

let availableScreenHeight = document.body.getBoundingClientRect().height || 700;

console.log(availableScreenHeight);

let scaleHeight = availableScreenHeight / 700;

if (window.location.search.split("&platform=")[1]?.split("&")[0] === "web") {
  scaleHeight = 1;
}

console.log(scaleHeight);

const App = () => {
  const [leftMenu, setLeftMenu] = useState([...leftMenuArray.slice(0, 4)]);
  const [isModalCollection, setIsModalCollection] = useState(false);
  const [isPopupCollection, setIsPopupCollection] = useState(false);
  const [energyTimeString, setEnergyTimeString] = useState("02:00");
  const [sortedProperty, setSortedProperty] = useState("lvl");
  const [activeMenuModal, setActiveMenuModal] = useState("");
  const [additionalPanel, setAdditionalPanel] = useState("");
  const [alreadyIsOpen, setAlreadyIsOpen] = useState(false);
  const [collectionElem, setCollectionElem] = useState(null);
  const [collectionName, setCollectionName] = useState(null);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [userFilter, setUserFilter] = useState("all");
  const [activePanel, setActivePanel] = useState("home");
  const [candyNameSent, setCandyNameSent] = useState("");
  const [lastPlace, setLastPlace] = useState("home");
  const [disableMove, setDisableMove] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLvlUpOpen, setIsLvlUpOpen] = useState(false);
  const [isPromoOpen, setIsPromoOpen] = useState(false);
  const [isConnected, setIsConnected] = useState(true);
  const [friendsArray, setFriendsArray] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [gameFriends, setGameFriends] = useState([]);
  const [sortedArray, setSortedArray] = useState([]);
  const [allFriends, setAllFriends] = useState([]);
  const [modalError, setModalError] = useState("");
  const [ownerRoom, setOwnerRoom] = useState(null);
  const [energyTime, setEnergyTime] = useState(0);
  const [serverDate, setServerDate] = useState(null);
  const [clickName, setClickName] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const [isAuth, setIsAuth] = useState(true);
  const [adminInfo, setAdminInfo] = useState(null);
  const [activePromo, setActivePromo] = useState(null);
  const [user, setUser] = useState(null);
  const [isVk, setIsVk] = useState(true);
  const serverDateRef = useRef(null);
  const timerEnergy = useRef(null);
  const timeShift = useRef(null);
  const userRef = useRef(null);
  const adminIdRef = useRef(null);
  const leftNumber = useRef(0);
  const leftMenuNumber = useRef(0);
  const [isMoving, setIsMoving] = useState(false);
  const [energy, setEnergy] = useState(null);
  const platform = useRef(
    window.location.search.split("&platform=")[1]?.split("&")[0]
  );
  const hash = useRef(window.location.search.split("?hash=")[1]?.split("&")[0]);
  const [myOrden, setMyOrden] = useState(null);
  const [songIndex, setSongIndex] = useState(0);
  const [isAdsPlay, setIsAdsPlay] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [testStage, setTestStage] = useState(1);
  const [bossLog, setBossLog] = useState("");
  const [bossesInfo, setBossesInfo] = useState({
    lastHeall: 0,
    countHeal: 1,
    lastBoss: 0,
    lastBossStage: 0,
    lastSoloBossStage: 0,
    countAttack1: 1,
    countAttack2: 1,
    spellNumber: 0,
    shytPrize: 0,
  });
  const [stageTop, setStageTop] = useState(1);
  const [isFirstClick, setIsFirstClick] = useState(false);
  const [messagesAll, setMessagesAll] = useState([]);
  const [messagesHelp, setMessagesHelp] = useState([]);
  const [messagesOrden, setMessagesOrden] = useState([]);
  const [chatUsers, setChatUsers] = useState({});

  const { load, pause, play, isReady, setVolume, mute, playing } =
    useAudioPlayer();
  const [activeTabBook, setActiveTabBook] = useState(0);
  const [isAltarOpen, setIsAltarOpen] = useState(false);
  const [bossRandomReward, setBossRandomReward] = useState([]);
  const moveVideoRef = useRef(null);

  function changeBossReward(reward) {
    setBossRandomReward(reward);
  }

  function handleAltarOpen(open) {
    setIsAltarOpen(open);
  }

  useEffect(() => {
    if (moveVideoRef.current) {
      if (isMoving) {
        moveVideoRef.current.load();
      } else {
        moveVideoRef.current.pause();
      }
    }
  }, [isMoving]);

  useEffect(() => {
    if (user && isLvlUpOpen) {
      app.service("users").removeStock({
        type: "newLvl",
        user_id: user.email,
        value: user.lvl,
        refEmail: user.myRef,
      });
    }
  }, [isLvlUpOpen, user]);

  useEffect(() => {
    if (user && timeShift.current !== null) {
      if (
        user.currentEnergy >=
        user.damageTalents.simpleTalents.maxValueEnergy +
          grindData["owl"].bonus[user.grind["owl"] - 1]
      ) {
        timeShift.current = 0;
        setEnergyTime(0);
        app.service("users").removeListener("timer");
      } else {
        app.service("users").removeListener("timer");
        app.service("users").on("timer", timerEnergyFunc.bind(null, user));
      }
      setEnergy(user.currentEnergy);
    }

    if (user) {
      const maxCharac = getMaxCharacter(user);
      if (maxCharac > user.maxCharacCount) {
        app
          .service("users")
          .patch(
            user._id,
            {
              maxCharacCount: maxCharac,
              common: true,
            },
            {
              query: {
                $select: ["_id", "email", "maxCharacCount"],
              },
            }
          )
          .then((data) => {
            setUser((prev) => ({ ...prev, ...data }));
          })
          .catch((e) => {
            setIsFetching(false);
            setModalError(e);
            console.log(e);
          });
      }
    }
  }, [user]);

  useEffect(() => {
    if (isFirstClick) {
      load(songs[songIndex], {
        autoplay: true,
        initialVolume: 0.25,
        html5: true,
        format: "mp3",
        onend: () => {
          setSongIndex((prev) => {
            return prev >= songs.length - 1 ? 0 : prev + 1;
          });
        },
      });
    }
  }, [songIndex, load, isFirstClick]);

  useEffect(() => {
    if (isReady && isConnected && user?.isSound && !isAdsPlay && isVisible) {
      if (!playing) {
        play();
      }
    } else {
      pause();
    }
  }, [
    isReady,
    isConnected,
    pause,
    play,
    user,
    playing,
    isAdsPlay,
    isVisible,
    isFirstClick,
  ]);

  function changeVisible() {
    setIsVisible(!document.hidden);
  }

  useEffect(() => {
    if (!hash.current) {
      hash.current = window.location.href.split("&hash")[1]?.slice(1);
    }

    if (
      platform.current === "html5_mobile" ||
      platform.current === "html5_android"
    ) {
      document.addEventListener("visibilitychange", changeVisible);
    }

    if (platform.current === "html5_android") {
      android();
    }

    app.io.on("disconnect", (e) => {
      console.log(e);
      setIsConnected(false);
      setIsFetching(false);
      console.log("Вы отключены от сервера", e);
      app.service("users").removeListener("timer");
    });

    socket.on("connect", () => {
      setIsConnected(true);
      console.log("Вы подключены к серверу");
    });

    socket.io.on("error", (e) => {
      console.log("Нет подключения к серверу", e);
      setIsFetching(false);
      setIsConnected(false);
      app.service("users").removeListener("timer");
    });

    async function fetchVkData() {
      let isVk = true;
      let userToken = { response: 0 };
      let usersVk = {
        response: {
          items: [],
        },
      };
      let userInfo = {};
      let isMember = { response: 0 };
      let isDon = { response: 0 };
      // 51651233
      // 51855419
      try {
        userToken = await bridge.send("VKWebAppGetAuthToken", {
          app_id: 51651233,
          scope: "friends,photos,groups",
        });
      } catch (e) {
        console.log(e);
      }

      try {
        userInfo = await bridge.send("VKWebAppGetUserInfo");
      } catch (e) {
        isVk = false;
        console.log(e);
      }
      console.log(userInfo);

      if (isVk) {
        setIsVk(true);
        try {
          usersVk = await bridge.send("VKWebAppCallAPIMethod", {
            method: "friends.get",
            params: {
              v: "5.199",
              access_token: userToken.access_token,
              count: 10000,
            },
          });
        } catch (e) {
          console.log(e);
        }

        console.log(usersVk);

        try {
          isDon = await bridge.send("VKWebAppCallAPIMethod", {
            method: "donut.isDon",
            params: {
              v: "5.199",
              access_token: userToken.access_token,
              owner_id: -221829804,
            },
          });
        } catch (e) {
          console.log(e);
        }

        console.log(isDon);

        try {
          isMember = await bridge.send("VKWebAppCallAPIMethod", {
            method: "groups.isMember",
            params: {
              v: "5.199",
              access_token: userToken.access_token,
              group_id: 221829804,
            },
          });
        } catch (e) {
          console.log(e);
        }

        console.log(isMember);
      } else {
        setIsVk(false);
      }

      setAllFriends(usersVk.response.items);
      return { ...userInfo, isDon, isMember };
    }

    async function authServer({
      id,
      first_name,
      last_name,
      photo_100,
      isDon,
      isMember,
    }) {
      let userData = null;
      let newUser = false;
      try {
        try {
          userData = await localLoginUser(id);
          console.log("localAuth");
        } catch (e) {
          console.log(e);
          if (e.message === "Invalid login") {
            try {
              await registerUser(id);
              userData = await localLoginUser(id);
              newUser = true;
              console.log("newUserAuth");
            } catch (e) {
              newUser = false;
              console.log(e);
            }
          }
        }
      } catch (e) {
        setModalError(e);
        console.log(e);
      }
      if (userData) {
        if (newUser && hash.current && !hash.current.includes("promo")) {
          app.service("users").addRefCount({
            userEmail: Number(hash.current),
            meEmail: userData.user.email,
            meId: userData.user._id,
            type: "add",
          });
        }
        userRef.current = userData.user;
        adminIdRef.current = userData.user.admin;
        app
          .service("admin")
          .get(userData.user.admin)
          .then((adminData) => {
            setAdminInfo(adminData);

            const promo = adminData.promo.find(
              (elem) => elem.code === hash.current
            );

            if (hash.current && hash.current.includes("promo") && promo) {
              if (
                promo.isDonate &&
                isDon?.response &&
                !userData.user.isGetPromoDonate
              ) {
                setActivePromo(promo);
                setIsPromoOpen(true);
              } else if (!userData.user.isGetPromo && !promo.isDonate) {
                setActivePromo(promo);
                setIsPromoOpen(true);
              }
            }

            const buyClothes = [...userData.user.clothes.isBuy];

            const buyClothesArray = Array.from(new Set(buyClothes));

            const newCharacteristic = { ...userData.user.characteristic };

            for (const key in newCharacteristic) {
              newCharacteristic[key].armor = 0;
            }

            const clothes = Object.entries(allClothes).filter((elem) =>
              buyClothesArray.includes(elem[0])
            );

            clothes.forEach((elem) => {
              Object.entries(elem[1].characteristic).forEach((el) => {
                newCharacteristic[el[0]].armor += el[1];
              });
            });

            Object.entries(newCharacteristic).forEach((el) => {
              newCharacteristic[el[0]].lvl = userData.user.lvl * 5;
            });

            const scales = {};
            const grindValue =
              grindData["book"].bonus[userData.user.grind["book"] - 1] / 100;
            userData.user.damageTalents.characteristicTalents.forEach(
              (elem) => {
                scales[elem.property] =
                  1 +
                  grindValue +
                  Math.round(elem.step * elem.countDone * 100) / 10000;
              }
            );

            const hp = Math.floor(
              Object.values(newCharacteristic.health).reduce((acc, cur, i) => {
                if (i < 2) {
                  return acc + cur * 5 * scales.health;
                }
                return acc + cur * 5;
              }, 0)
            );

            app
              .service("users")
              .patch(userData.user._id, {
                photo: photo_100,
                name: {
                  firstName: first_name,
                  lastName: last_name,
                },
                "health.current":
                  userData.user.health.current > hp
                    ? hp
                    : userData.user.health.current,
                "health.max": hp,
                characteristic: newCharacteristic,
                "clothes.isBuy": buyClothesArray,
                isMemberGroup: !!isMember.response,
                common: true,
              })
              .then((data) => {
                updateInfo(data);
              })
              .catch((e) => {
                setModalError(e);
                console.log(e);
              });
          })
          .catch((e) => {
            setModalError(e);
            console.log(e);
          });
      } else {
        setIsAuth(false);
      }
    }

    function updateInfo(user) {
      setActivePanel(user.lastPlace);
      setLastPlace(user.lastPlace);
      timerEnergy.current = user.endEnergyTime;

      if (
        user.currentEnergy >=
        user.damageTalents.simpleTalents.maxValueEnergy +
          grindData["owl"].bonus[user.grind["owl"] - 1]
      ) {
        setEnergyTimeString(
          secondsToMs(user.damageTalents.simpleTalents.timerEnergy)
        );
        timeShift.current = 0;
        setUser(user);
      } else {
        app.service("users").removeListener("timer");
        app.service("users").on("timer", timerEnergyFunc.bind(null, user));
      }

      axios(`${serverUrl}/getAll`, {})
        .then(({ data }) => {
          console.log(data);
          setIsConnected(true);
          setIsAuth(true);
          setAllUsers(
            data.map((user) => ({
              ...user,
              allCharac: getCharacterSum(user),
            }))
          );
        })
        .catch((e) => {
          setModalError(e);
          console.log(e);
        });
    }

    fetchVkData()
      .then((user) => {
        authServer(user)
          .then(() => {
            setIsLoadingData(false);
          })
          .catch((e) => {
            setModalError(e);
            console.log(e);
            setIsLoadingData(false);
          });
      })
      .catch((e) => {
        setModalError(e);
        console.log(e);
        setIsConnected(false);
        setIsLoadingData(false);
      });

    app.service("users").on("duplicate", (message) => {
      setModalError(message);
      console.log(message);
      setAlreadyIsOpen(true);
      setIsLoadingData(false);
      socket.disconnect();
    });

    app.service("users").on("endUpdateAdmin", () => {
      setIsFetching(true);
      app
        .service("admin")
        .get(adminIdRef.current)
        .then((adminData) => {
          setAdminInfo(adminData);
          setIsFetching(false);
        })
        .catch((e) => {
          console.log(e);
          setModalError(e);
          setIsFetching(false);
        });
    });

    app.service("users").on("endUpdateLocalAdmin", () => {
      setIsFetching(true);
      app
        .service("admin")
        .get(adminIdRef.current)
        .then((adminData) => {
          setAdminInfo(adminData);
          setIsFetching(false);
        })
        .catch((e) => {
          console.log(e);
          setModalError(e);
          setIsFetching(false);
        });
    });

    app.service("users").on("endUpdateLocalUser", () => {
      setIsFetching(true);
      app
        .service("users")
        .get(userRef.current._id)
        .then((data) => {
          setUser(data);
          setIsFetching(false);
        })
        .catch((e) => {
          setIsFetching(false);
          setModalError(e);
          console.log(e);
        });
    });

    app.service("users").on("endUpdateAllUsers", () => {
      setIsFetching(true);
      // app
      //   .service("users")
      //   .find({
      //     paginate: false,
      //     query: {
      //       $limit: 300000,
      //       $select: [
      //         "_id",
      //         "email",
      //         "sex",
      //         "name",
      //         "nickName",
      //         "lvl",
      //         "allExp",
      //         "characteristic",
      //         "allDamage",
      //         "photo",
      //         "damageTalents",
      //         "dayDamage",
      //         "raidDamage",
      //         "orden",
      //         "grind",
      //         "faculty",
      //         "weakTopWins",
      //         "weakDamage",
      //         "weakEssence",
      //         "weakEnergy",
      //         "weakBossesPoints",
      //         "weakGamesPoints",
      //         "game21",
      //         "diceGame",
      //         "arena",
      //         "classrooms",
      //         "field",
      //         "bosses",
      //         "soloBosses",
      //       ],
      //       $and: [
      //         {
      //           name: {
      //             $ne: null,
      //           },
      //           faculty: {
      //             $ne: "",
      //           },
      //         },
      //       ],
      //     },
      //   })

      // app
      //   .service("users")
      //   .getAllUsers({})

      axios(`${serverUrl}/getAll`)
        .then(({ data }) => {
          setAllUsers(
            data.map((user) => ({ ...user, allCharac: getCharacterSum(user) }))
          );
          setIsFetching(false);
        })
        .catch((e) => {
          setIsFetching(false);
          setModalError(e);
          console.log(e);
        });
    });

    app.service("users").on("endMoveCandy", (info) => {
      app
        .service("users")
        .find(
          {
            query: {
              email: info.email,
            },
          },
          {},
          {
            query: {
              $select: [
                "_id",
                "email",
                "gold",
                "candyMove",
                "candyMoveCount",
                "dayQuest",
                "summerSave",
              ],
            },
          }
        )
        .then(({ data }) => {
          setCandyNameSent(info.name);
          setUser((prev) => ({ ...prev, ...data[0] }));
          setIsFetching(false);
        })
        .catch((e) => {
          setIsFetching(false);
          setModalError(e);
          console.log(e);
        });
    });

    app.service("users").on("startUpdate", () => {
      console.log("startUpdateLimits");
      setIsFetching(true);
    });

    app.service("users").on("partUpdateLocalUser", ({ parts }) => {
      setIsFetching(true);
      app
        .service("users")
        .get(userRef.current._id, {
          query: {
            $select: [...parts],
          },
        })
        .then((data) => {
          setUser((prev) => ({ ...prev, ...data }));
          setIsFetching(false);
        })
        .catch((e) => {
          setIsFetching(false);
          setModalError(e);
          console.log(e);
        });
    });

    app.service("users").on("partUpdateUsers", ({ parts }) => {
      setIsFetching(true);
      app
        .service("users")
        .get(userRef.current._id, {
          query: {
            $select: [...parts],
          },
        })
        .then((data) => {
          setUser((prev) => ({ ...prev, ...data }));
          setIsFetching(false);
        })
        .catch((e) => {
          setIsFetching(false);
          setModalError(e);
          console.log(e);
        });
    });

    app.service("users").on("getTime", ({ time }) => {
      serverDateRef.current = time;
      setServerDate(time);
    });

    app.service("users").on("online", ({ users, pid }) => {
      setChatUsers((prev) => ({
        ...prev,
        [pid]: [...new Set(users)],
      }));
    });

    app.service("users").on("onlineLocal", ({ users, pid }) => {
      setChatUsers((prev) => ({
        ...prev,
        [pid]: [...new Set(users)],
      }));
    });

    app.service("users").on("shareMessagesAll", (data) => {
      if (data.type === "all") {
        setMessagesAll((prev) => [...prev, data]);
      }
      if (data.type === "help") {
        setMessagesHelp((prev) => [...prev, data]);
      }
    });

    app.service("users").on("shareMessagesOrden", (data) => {
      setMessagesOrden((prev) => [...prev, data]);
    });

    return () => {
      if (
        platform.current === "html5_mobile" ||
        platform.current === "html5_android"
      ) {
        document.removeEventListener("visibilitychange", changeVisible);
      }

      app.service("users").removeListener("timer");
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (user) {
      if (activePanel !== "userRoom") {
        app
          .service("users")
          .patch(
            user._id,
            {
              lastPlace: activePanel,
              common: true,
            },
            {
              query: {
                $select: ["_id", "email", "lastPlace"],
              },
            }
          )
          .then((data) => {
            setLastPlace(data.lastPlace);
          })
          .catch((e) => {
            setModalError(e);
            console.log(e);
          });
      }
    }
  }, [activePanel]);

  useEffect(() => {
    if (allUsers.length > 0) {
      setGameFriends(
        allUsers.filter((user) => allFriends.includes(user.email))
      );
    }
  }, [allUsers]);

  useEffect(() => {
    if (user && timeShift.current !== null) {
      setEnergyTimeString(
        secondsToMs(user.damageTalents.simpleTalents.timerEnergy - energyTime)
      );
      if (energyTime > user.damageTalents.simpleTalents.timerEnergy - 1) {
        timeShift.current = 0;
        changeEnergy({ energy: 1, isGet: true });
        setEnergyTime(0);
      }
    }
  }, [energyTime, user]);

  useEffect(() => {
    if (energy !== null && timeShift.current !== null) {
      app
        .service("users")
        .patch(
          user._id,
          {
            endEnergyTime: serverDateRef.current - timeShift.current * 1000,
            common: true,
          },
          {
            query: {
              $select: ["_id", "email", "endEnergyTime"],
            },
          }
        )
        .catch((e) => {
          setModalError(e);
          console.log(e);
        });
    }
  }, [energy]);

  useEffect(() => {
    if (adminInfo) {
      checkOrden();
      updateMessages();
    }
  }, [adminInfo]);

  const updateMessages = useCallback(() => {
    const userLocal = user || userRef.current;
    setMessagesAll(adminInfo.messagesAll);
    setMessagesHelp(adminInfo.messagesHelp);
    if (adminInfo.ordens && userLocal) {
      const orden = adminInfo.ordens.find((elem) =>
        elem.members.map((el) => el.email).includes(userLocal.email)
      );
      if (orden) {
        setMessagesOrden(orden.messagesOrden);
      }
    }
  }, [user, adminInfo]);

  const checkOrden = useCallback(() => {
    const userLocal = user || userRef.current;
    if (adminInfo.ordens && userLocal) {
      let newOrden = userLocal.orden;
      if (adminInfo.ordens.length > 0) {
        const orden = adminInfo.ordens.find((elem) =>
          elem.members.map((el) => el.email).includes(userLocal.email)
        );
        if (orden) {
          const isOrden = orden.members.findIndex(
            (elem) => elem.email === userLocal.email
          );
          if (isOrden >= 0) {
            setMyOrden(orden);
            if (!userLocal.orden.name) {
              newOrden = { name: orden.name, tag: orden.tag };
            }
          } else {
            setMyOrden(null);
            newOrden = { name: "", tag: "" };
          }
        } else {
          setMyOrden(null);
          newOrden = { name: "", tag: "" };
        }
      } else {
        setMyOrden(null);
        newOrden = { name: "", tag: "" };
      }
      if (newOrden?.name !== userLocal.orden?.name) {
        setIsFetching(true);
        app
          .service("users")
          .patch(
            userLocal._id,
            {
              orden: newOrden,
              common: true,
            },
            {
              query: {
                $select: ["_id", "email", "orden", "rooms"],
              },
            }
          )
          .then((data) => {
            setUser((prev) => ({ ...prev, ...data }));
            setIsFetching(false);
          })
          .catch((e) => {
            setIsFetching(false);
            setModalError(e);
            console.log(e);
          });
      }
    }
  }, [user, adminInfo]);

  function timerEnergyFunc(user) {
    if (
      user &&
      timerEnergy.current !== null &&
      serverDateRef.current !== null
    ) {
      const currEnergy = user.currentEnergy;

      let diffTime = Math.floor(
        (serverDateRef.current - timerEnergy.current) / 1000
      );

      const startEnergyShift = Math.floor(
        diffTime / user.damageTalents.simpleTalents.timerEnergy
      );

      const startEnergyAll =
        startEnergyShift + currEnergy >
        user.damageTalents.simpleTalents.maxValueEnergy +
          grindData["owl"].bonus[user.grind["owl"] - 1]
          ? user.damageTalents.simpleTalents.maxValueEnergy +
            grindData["owl"].bonus[user.grind["owl"] - 1]
          : startEnergyShift + currEnergy;

      if (timeShift.current === null) {
        if (
          startEnergyAll ===
          user.damageTalents.simpleTalents.maxValueEnergy +
            grindData["owl"].bonus[user.grind["owl"] - 1]
        ) {
          setEnergyTimeString(
            secondsToMs(user.damageTalents.simpleTalents.timerEnergy)
          );
          timeShift.current = 0;
        } else {
          timeShift.current =
            diffTime % user.damageTalents.simpleTalents.timerEnergy;
          setEnergyTime(timeShift.current);
        }
        app
          .service("users")
          .patch(
            user._id,
            {
              currentEnergy: startEnergyAll,
              endEnergyTime: serverDateRef.current - timeShift.current * 1000,
              common: true,
            },
            {
              query: {
                $select: ["_id", "email", "currentEnergy", "endEnergyTime"],
              },
            }
          )
          .then((data) => {
            setUser((prev) => ({ ...user, ...data }));
          })
          .catch((e) => {
            setModalError(e);
            console.log(e);
          });
      } else {
        timeShift.current =
          diffTime % user.damageTalents.simpleTalents.timerEnergy;
        setEnergyTime((prev) => prev + 1);
      }
    }
  }

  const changeEnergy = useCallback(
    ({ energy, isGet }) => {
      if (user) {
        timerEnergy.current = serverDateRef.current;
        if (
          isGet &&
          user.currentEnergy <
            user.damageTalents.simpleTalents.maxValueEnergy +
              grindData["owl"].bonus[user.grind["owl"] - 1]
        ) {
          app
            .service("users")
            .patch(
              user._id,
              {
                $inc: {
                  currentEnergy: energy,
                },
                endEnergyTime: serverDateRef.current,
                common: true,
              },
              {
                query: {
                  $select: ["_id", "email", "currentEnergy", "endEnergyTime"],
                },
              }
            )
            .then((data) => {
              setIsFetching(false);
              setUser((prev) => ({ ...prev, ...data }));
            })
            .catch((e) => {
              setIsFetching(false);
              setModalError(e);
              console.log(e);
            });
        } else if (!isGet && user.currentEnergy - energy >= 0) {
          app
            .service("users")
            .patch(
              user._id,
              {
                common: true,
                $inc: {
                  currentEnergy: -energy,
                },
              },
              {
                query: {
                  $select: ["_id", "email", "currentEnergy"],
                },
              }
            )
            .then((data) => {
              setIsFetching(false);
              setUser((prev) => ({ ...prev, ...data }));
            })
            .catch((e) => {
              setIsFetching(false);
              setModalError(e);
              console.log(e);
            });
        } else {
          setIsFetching(false);
        }
      }
    },
    [user]
  );

  useEffect(() => {
    if (gameFriends.length) {
      changeFilters({ userFilter: userFilter, propertyFilter: sortedProperty });
    }
  }, [gameFriends]);

  const getCharacterSum = useCallback(
    (obj) => {
      const scales = {};
      const characteristic = obj.characteristic;
      const grindValue = grindData["book"].bonus[obj.grind["book"] - 1] / 100;
      obj.damageTalents.characteristicTalents.forEach((elem) => {
        scales[elem.property] =
          1 + Math.round(elem.step * elem.countDone * 100) / 10000;
      });

      return Object.entries(characteristic).reduce((acc, cur) => {
        return (
          acc +
          Math.floor(
            Object.values(cur[1]).reduce((acc, curr, i) => {
              if (i < 2) {
                return acc + curr * (scales[cur[0]] + grindValue);
              }
              return acc + curr;
            }, 0)
          )
        );
      }, 0);
    },
    [user]
  );

  const getMaxCharacter = useCallback(
    (obj) => {
      const characteristic = obj.characteristic;
      return Object.entries(characteristic).reduce((acc, cur) => {
        return (
          acc +
          Math.floor(
            Object.values(cur[1]).reduce((acc, curr, i) => {
              if (i < 3) {
                return acc + curr;
              }
              return acc;
            }, 0)
          )
        );
      }, 0);
    },
    [user]
  );

  const changeFilters = useCallback(
    ({ userFilter, propertyFilter, userFilterName = null }) => {
      const sortedUsers = [];
      if (userFilter === "friends") {
        sortedUsers.push(...gameFriends, user);
      }
      if (userFilter === "all") {
        sortedUsers.push(...allUsers);
      }
      if (propertyFilter === "lvl") {
        sortedUsers.sort((a, b) => b.allExp - a.allExp);
      }
      if (propertyFilter === "character") {
        sortedUsers.sort((a, b) => getCharacterSum(b) - getCharacterSum(a));
      }
      if (propertyFilter === "damage") {
        sortedUsers.sort((a, b) => b.allDamage - a.allDamage);
      }

      const array =
        userFilterName !== null
          ? sortedUsers.filter(
              (elem) =>
                elem.nickName
                  .toLowerCase()
                  .includes(userFilterName.toLowerCase()) ||
                `${elem.name?.firstName} ${elem.name?.lastName}`
                  .toLowerCase()
                  .includes(userFilterName.toLowerCase())
            )
          : sortedUsers;

      setSortedArray(array);
      const arrayFiveLength = [...array];

      if (arrayFiveLength.length > 5) {
        arrayFiveLength.length = 5;
      }

      leftNumber.current = 0;
      setUserFilter(userFilter);
      setSortedProperty(propertyFilter);
      setFriendsArray(arrayFiveLength);
    },
    [allUsers, gameFriends]
  );

  const moveToUserRoom = useCallback((user) => {
    setIsFetching(true);
    moveLocation("userRoom");
    app
      .service("users")
      .find({
        query: {
          email: user.email,
        },
      })
      .then(({ data }) => {
        setOwnerRoom(data[0]);
        setIsFetching(false);
      })
      .catch((e) => {
        setIsFetching(false);
        setModalError(e);
        console.log(e);
      });
  }, []);

  function getGroupPrizes() {
    setIsFetching(true);
    app
      .service("users")
      .patch(
        user._id,
        {
          "isGroupInfo.getReward":
            user.isGroupInfo.getReward && user.isGroupInfo.close
              ? true
              : user.isMemberGroup,
          $inc: {
            gold:
              user.isGroupInfo.getReward && user.isGroupInfo.close
                ? 0
                : user.isMemberGroup
                ? 100
                : 0,
            silver:
              user.isGroupInfo.getReward && user.isGroupInfo.close
                ? 0
                : user.isMemberGroup
                ? 5000
                : 0,
          },

          common: true,
        },
        {
          query: {
            $select: ["_id", "email", "isGroupInfo", "gold", "silver"],
          },
        }
      )
      .then((data) => {
        setUser((prev) => ({ ...prev, ...data }));
        setIsFetching(false);
      })
      .catch((e) => {
        setIsFetching(false);
        setModalError(e);
        console.log(e);
      });
  }

  function getPromoPrizes() {
    if (activePromo !== null) {
      if (serverDate < activePromo.startTime) {
        setIsPromoOpen(false);
        setModalError("Нельзя использовать промокод раньше времени");
        setIsFetching(false);
      } else {
        if (serverDate - activePromo.startTime < activePromo.time * 3600000) {
          setIsFetching(true);
          let soulNumber = 0;
          const prizes = activePromo.prizes.reduce((acc, cur) => {
            if (cur.property === "soul") {
              soulNumber = cur.number;
            }
            acc[cur.property] = acc[cur.property]
              ? acc[cur.property] + Number(cur.count)
              : Number(cur.count);
            return acc;
          }, {});

          app
            .service("users")
            .patch(
              user._id,
              {
                field: serverDate,
                isGetPromo: activePromo.isDonate ? user.isGetPromo : true,
                isGetPromoDonate: activePromo.isDonate
                  ? true
                  : user.isGetPromoDonate,
                $inc: {
                  gold: prizes?.gold ? prizes.gold : 0,
                  silver: prizes?.silver ? prizes.silver : 0,
                  essence: prizes?.essence ? prizes.essence : 0,
                  "spells.points": prizes?.magic ? prizes.magic : 0,
                  "spells.pointsAll": prizes?.magic ? prizes.magic : 0,
                  mana: prizes?.mana ? prizes.mana : 0,
                  [`bosses.${soulNumber}.souls`]: prizes?.soul
                    ? prizes?.soul
                    : 0,
                  "game21.isFree": prizes?.game21 ? prizes?.game21 : 0,
                  "diceGame.isFree": prizes?.diceGame ? prizes?.diceGame : 0,
                },
              },
              {
                query: {
                  $select: [
                    "_id",
                    "email",
                    "isGetPromo",
                    "isGetPromoDonate",
                    "gold",
                    "silver",
                    "essence",
                    "mana",
                    "bosses",
                    "diceGame",
                    "game21",
                    "spells",
                  ],
                },
              }
            )
            .then((data) => {
              setUser((prev) => ({ ...prev, ...data }));
            })
            .catch((e) => {
              setModalError(e);
              console.log(e);
            })
            .finally(() => {
              setIsPromoOpen(false);
              setIsFetching(false);
            });
        } else {
          setIsPromoOpen(false);
          setModalError("Время на использование истекло");
          setIsFetching(false);
        }
      }
    }
  }

  const getDayPrizes = useCallback(() => {
    setIsFetching(true);

    const index = user.dayPrizes.prizes.findIndex((elem) => elem === false);

    const prize = dayPrizes[index].reduce((acc, cur) => {
      acc[cur.property] = {
        count: cur.count,
        number: cur.number,
      };
      return acc;
    }, {});

    const isLast = user.dayPrizes.prizes.length === index + 1;

    let query = `dayPrizes.prizes.${index}`;

    if (isLast) {
      query = `dayPrizes.prizes.$[]`;
    }

    app
      .service("users")
      .patch(
        user._id,
        {
          field: serverDate,

          "dayPrizes.todayIsGet": true,
          [query]: !isLast,

          $inc: {
            gold: prize.gold ? prize.gold.count : 0,
            silver: prize.silver ? prize.silver.count : 0,
            essence: prize.essence ? prize.essence.count : 0,
            mana: prize.mana ? prize.mana.count : 0,
            "spells.points": prize.magic ? prize.magic.count : 0,
            "spells.pointsAll": prize.magic ? prize.magic.count : 0,
            "game21.isFree": prize.game21 ? prize.game21.count : 0,
            "diceGame.isFree": prize.diceGame ? prize.diceGame.count : 0,
            [`bosses.${prize.soul ? prize.soul.number : 0}.souls`]: prize.soul
              ? prize.soul.count
              : 0,
          },
        },
        {
          query: {
            $select: [
              "_id",
              "email",
              "dayPrizes",
              "gold",
              "silver",
              "essence",
              "mana",
              "bosses",
              "game21",
              "diceGame",
              "spells",
            ],
          },
        }
      )
      .then((data) => {
        setUser((prev) => ({ ...prev, ...data }));
      })
      .catch((e) => {
        setModalError(e);
        console.log(e);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, [user, serverDate]);

  const closeGroupInfo = useCallback(() => {
    setIsFetching(true);
    app
      .service("users")
      .patch(
        user._id,
        {
          "isGroupInfo.close": true,
          field: serverDate,
        },
        {
          query: {
            $select: ["_id", "email", "isGroupInfo"],
          },
        }
      )
      .then((data) => {
        setUser((prev) => ({ ...prev, ...data }));
      })
      .catch((e) => {
        setModalError(e);
        console.log(e);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, [user, serverDate]);

  const useItem = useCallback(
    (item, count, setIsPopupOpen) => {
      count = Math.floor(count);
      if (count === 0) {
        return 0;
      }
      if (count < 0) {
        count = 1;
      }
      let newCount = count;
      let health = 0;
      let energy = 0;
      let isUse = true;
      if (item.count < 1) {
        isUse = false;
      }
      if (item.category === "hp") {
        if (user.health.current === user.health.max) {
          isUse = false;
          setModalError("У Вас уже максимальное количество здоровья");
          console.log("У Вас уже максимальное количество здоровья");
        }
        if (item.percent) {
          const healthNeed = user.health.max - user.health.current;

          health = Math.ceil((item.value / 100) * user.health.max) * newCount;

          if (health > healthNeed) {
            newCount = 0;
            let newHealth = 0;
            while (newHealth < healthNeed) {
              newCount++;
              newHealth += Math.ceil((item.value / 100) * user.health.max);
            }
          }
        } else {
          const healthNeed = user.health.max - user.health.current;

          health = item.value * newCount;

          if (health > healthNeed) {
            newCount = 0;
            let newHealth = 0;
            while (newHealth < healthNeed) {
              newCount++;
              newHealth += item.value * newCount;
            }
          }
        }
      }
      if (item.category === "energy") {
        if (item.percent) {
          energy =
            Math.floor(
              (item.value / 100) *
                (user.damageTalents.simpleTalents.maxValueEnergy +
                  grindData["owl"].bonus[user.grind["owl"] - 1])
            ) * newCount;
        } else {
          energy = item.value * newCount;
        }
      }
      if (isUse) {
        const inventoryInfo = [];
        user.inventory.forEach((elem) => {
          if (elem.name === item.name && elem.category === item.category) {
            if (elem.count > newCount) {
              inventoryInfo.push({ ...elem, count: elem.count - newCount });
            }
            return 0;
          }
          inventoryInfo.push(elem);
        });
        setIsFetching(true);
        app
          .service("users")
          .patch(
            user._id,
            {
              field: serverDate,
              inventory: inventoryInfo,
              "health.current":
                user.health.current + health > user.health.max
                  ? user.health.max
                  : user.health.current + health,
              $inc: {
                currentEnergy: energy,
              },
            },
            {
              query: {
                $select: [
                  "_id",
                  "email",
                  "inventory",
                  "health",
                  "currentEnergy",
                ],
              },
            }
          )
          .then((data) => {
            setUser((prev) => ({ ...prev, ...data }));
            setIsFetching(false);
            setIsPopupOpen && setIsPopupOpen(false);
          })
          .catch((e) => {
            setIsFetching(false);
            setModalError(e);
            console.log(e);
          });
      }
    },
    [user, adminInfo, serverDate]
  );

  const changeActiveMenuModal = useCallback(() => {
    setIsMoving(true);
    setTimeout(() => {
      setIsMoving(false);
    }, 900);
  }, []);

  const moveLocation = useCallback(
    (name) => {
      if (activePanel !== name) {
        changeActiveMenuModal();
        setActivePanel(name);
      } else {
        setModalError("Вы уже находитесь в этой локации");
      }
    },
    [activePanel]
  );

  const changeOptionsLocation = useCallback((name) => {}, [user, adminInfo]);

  const reloadUsersInfo = useCallback(() => {
    setIsFetching(true);
    // app
    //   .service("users")
    //   .find({
    //     paginate: false,
    //     query: {
    //       $limit: 300000,
    //       $select: [
    //         "_id",
    //         "email",
    //         "sex",
    //         "name",
    //         "nickName",
    //         "lvl",
    //         "allExp",
    //         "characteristic",
    //         "damageTalents",
    //         "allDamage",
    //         "dayDamage",
    //         "raidDamage",
    //         "photo",
    //         "orden",
    //         "grind",
    //         "faculty",
    //         "weakTopWins",
    //         "weakDamage",
    //         "weakEssence",
    //         "weakEnergy",
    //         "weakBossesPoints",
    //         "weakGamesPoints",
    //         "game21",
    //         "diceGame",
    //         "arena",
    //         "classrooms",
    //         "field",
    //         "bosses",
    //         "soloBosses",
    //       ],
    //       $and: [
    //         {
    //           name: {
    //             $ne: null,
    //           },
    //           faculty: {
    //             $ne: "",
    //           },
    //         },
    //       ],
    //     },
    //   })

    //
    // axios("http://localhost:3031/getAll")

    // app
    //   .service("users")
    //   .getAllUsers({})

    axios(`${serverUrl}/getAll`, {})
      .then(({ data }) => {
        setAllUsers(
          data.map((user) => ({ ...user, allCharac: getCharacterSum(user) }))
        );
        setIsFetching(false);

        // if (ownerRoom) {
        //   app
        //     .service("users")
        //     .find({
        //       query: {
        //         email: ownerRoom.email,
        //       },
        //     })
        //     .then(({ data }) => {
        //       setOwnerRoom(data[0]);
        //     })
        //     .catch((e) => {
        //       setModalError(e);
        //       console.log(e);
        //     });
        // }
        app
          .service("users")
          .get(userRef.current._id)
          .then((userData) => {
            setUser(userData);
            app
              .service("admin")
              .get(userData.admin)
              .then((adminData) => {
                setAdminInfo(adminData);
                setIsFetching(false);
              })
              .catch((e) => {
                console.log(e);
                setModalError(e);
                setIsFetching(false);
              });
          })
          .catch((e) => {
            console.log(e);
            setModalError(e);
            setIsFetching(false);
          });
      })
      .catch((e) => {
        setIsFetching(false);
        setModalError(e);
        console.log(e);
      });
  }, [ownerRoom, user]);

  const closeSomeElements = useCallback((e) => {
    setIsFirstClick(true);
    setClickName(e);
  }, []);

  function menuScroll(e) {
    if (e.deltaY < 0) {
      menuButtonClick("top");
    } else {
      menuButtonClick("bottom");
    }
  }

  function menuButtonClick(direction) {
    if (direction === "top") {
      leftMenuNumber.current =
        leftMenuNumber.current - 1 >= 0 ? leftMenuNumber.current - 1 : 0;
      setLeftMenu([
        ...leftMenuArray.slice(
          leftMenuNumber.current,
          leftMenuNumber.current + 4
        ),
      ]);
    }
    if (direction === "bottom") {
      leftMenuNumber.current =
        leftMenuNumber.current + 1 <= leftMenuArray.length - 4
          ? leftMenuNumber.current + 1
          : leftMenuArray.length - 4;
      setLeftMenu([
        ...leftMenuArray.slice(
          leftMenuNumber.current,
          leftMenuNumber.current + 4
        ),
      ]);
    }
  }

  function getOrdenResources() {
    setIsFetching(true);
    app
      .service("users")
      .patch(
        user._id,
        {
          $inc: {
            "ordenResources.gold": -user.ordenResources?.gold,
            "ordenResources.essence": -user.ordenResources?.essence,
            "ordenResources.silver": -user.ordenResources?.silver,
            "ordenResources.talents": -user.ordenResources?.talents,

            gold: user.ordenResources?.gold || 0,
            essence: user.ordenResources?.essence || 0,
            silver: user.ordenResources?.silver || 0,
            "damageTalents.pointsHave": user.ordenResources?.talents || 0,
            "damageTalents.allPoints": user.ordenResources?.talents || 0,
          },

          common: true,
        },
        {
          query: {
            $select: [
              "_id",
              "email",
              "gold",
              "silver",
              "essence",
              "damageTalents",
              "ordenResources",
            ],
          },
        }
      )
      .then((data) => {
        setUser((prev) => ({ ...prev, ...data }));
        setIsFetching(false);
      })
      .catch((e) => {
        setModalError(e);
        console.log(e);
        setIsFetching(false);
      });
  }

  function getWeakDamageReward() {
    setIsFetching(true);

    const gold =
      (weakDamagePrizes[user.weakInfo?.place[1]]?.gold || 0) *
      Math.pow(user.weakInfo?.place[0] + 1, 1);
    const silver =
      (weakDamagePrizes[user.weakInfo?.place[1]]?.silver || 0) *
      Math.pow(user.weakInfo?.place[0] + 1, 1);
    const mana =
      (weakDamagePrizes[user.weakInfo?.place[1]]?.mana || 0) *
      Math.pow(user.weakInfo?.place[0] + 1, 1);

    app
      .service("users")
      .patch(
        user._id,
        {
          $inc: {
            gold: gold,
            silver: silver,
            mana: mana,
          },
          weakInfo: {
            damage: 0,
            place: [],
          },

          common: true,
        },
        {
          query: {
            $select: ["_id", "email", "weakInfo", "gold", "silver", "mana"],
          },
        }
      )
      .then((data) => {
        setUser((prev) => ({ ...prev, ...data }));
        setIsFetching(false);
      })
      .catch((e) => {
        setModalError(e);
        console.log(e);
        setIsFetching(false);
      });
  }

  function getTournament21Reward() {
    setIsFetching(true);
    const gold = tournamentPrizes[user.tournament21Info?.place]?.gold || 0;
    const openClothes = [...user.clothes.isOpen];
    let prizes = [];

    if (user.tournament21Info?.place === 0) {
      const clothes = Object.entries(allClothes).filter(
        (elem) =>
          elem[1]?.event === "Турнир кости/'21'" &&
          user.clothes.isOpen.findIndex((el) => el === elem[0]) === -1
      );
      const randomArray = clothes.length > 0 ? getMultipleRandom(clothes) : [];
      let prize = null;

      randomArray.map((elem) => {
        prize = elem;
      });

      if (prize) {
        prizes.push(prize[1]);
        openClothes.push(prize[0]);
      }
    }

    if (
      user.tournament21Info?.place === 1 ||
      user.tournament21Info?.place === 2
    ) {
      const clothes = Object.entries(allClothes).filter(
        (elem) =>
          (elem[1]?.diceGameTournament || elem[1]?.game21Tournament) &&
          user.clothes.isOpen.findIndex((el) => el === elem[0]) === -1
      );
      const randomArray = clothes.length > 0 ? getMultipleRandom(clothes) : [];
      let prize = null;

      prize = randomArray.pop();

      if (prize) {
        openClothes.push(prize[0]);
        prizes.push(prize[1]);
      }

      if (user.tournament21Info?.place === 1) {
        prize = null;

        prize = randomArray.pop();

        if (prize) {
          prizes.push(prize[1]);
          openClothes.push(prize[0]);
        }
      }
    }

    app
      .service("users")
      .patch(
        user._id,
        {
          $inc: {
            gold: gold,
          },
          tournament21Info: {
            points: 0,
            place: -1,
            name: "",
          },
          "clothes.isOpen": openClothes,
          common: true,
        },
        {
          query: {
            $select: ["_id", "email", "tournament21Info", "gold", "clothes"],
          },
        }
      )
      .then((data) => {
        setUser((prev) => ({ ...prev, ...data }));
        setIsFetching(false);
        setBossRandomReward(prizes);
      })
      .catch((e) => {
        setModalError(e);
        console.log(e);
        setIsFetching(false);
      });
  }

  function getTournamentDiceReward() {
    setIsFetching(true);
    const gold = tournamentPrizes[user.tournamentDiceInfo?.place]?.gold || 0;
    const openClothes = [...user.clothes.isOpen];
    let prizes = [];

    if (user.tournamentDiceInfo?.place === 0) {
      const clothes = Object.entries(allClothes).filter(
        (elem) =>
          elem[1]?.event === "Турнир кости/'21'" &&
          user.clothes.isOpen.findIndex((el) => el === elem[0]) === -1
      );
      const randomArray = clothes.length > 0 ? getMultipleRandom(clothes) : [];
      let prize = null;

      randomArray.map((elem) => {
        prize = elem;
      });

      if (prize) {
        prizes.push(prize[1]);
        openClothes.push(prize[0]);
      }
    }

    if (
      user.tournamentDiceInfo?.place === 1 ||
      user.tournamentDiceInfo?.place === 2
    ) {
      const clothes = Object.entries(allClothes).filter(
        (elem) =>
          (elem[1]?.diceGameTournament || elem[1]?.game21Tournament) &&
          user.clothes.isOpen.findIndex((el) => el === elem[0]) === -1
      );
      const randomArray = clothes.length > 0 ? getMultipleRandom(clothes) : [];
      let prize = null;

      prize = randomArray.pop();

      if (prize) {
        openClothes.push(prize[0]);
        prizes.push(prize[1]);
      }

      if (user.tournamentDiceInfo?.place === 1) {
        prize = null;

        prize = randomArray.pop();

        if (prize) {
          prizes.push(prize[1]);
          openClothes.push(prize[0]);
        }
      }
    }

    app
      .service("users")
      .patch(
        user._id,
        {
          $inc: {
            gold: gold,
          },
          tournamentDiceInfo: {
            points: 0,
            place: -1,
            name: "",
          },
          "clothes.isOpen": openClothes,
          common: true,
        },
        {
          query: {
            $select: ["_id", "email", "tournamentDiceInfo", "gold", "clothes"],
          },
        }
      )
      .then((data) => {
        setUser((prev) => ({ ...prev, ...data }));
        setIsFetching(false);
        setBossRandomReward(prizes);
      })
      .catch((e) => {
        setModalError(e);
        console.log(e);
        setIsFetching(false);
      });
  }

  function getTournamentArenaReward() {
    setIsFetching(true);
    const gold = tournamentPrizes[user.tournamentArenaInfo?.place]?.gold || 0;

    app
      .service("users")
      .patch(
        user._id,
        {
          $inc: {
            gold: gold,
          },
          tournamentArenaInfo: {
            points: 0,
            place: -1,
            name: "",
          },
          common: true,
        },
        {
          query: {
            $select: ["_id", "email", "tournamentArenaInfo", "gold"],
          },
        }
      )
      .then((data) => {
        setUser((prev) => ({ ...prev, ...data }));
        setIsFetching(false);
      })
      .catch((e) => {
        setModalError(e);
        console.log(e);
        setIsFetching(false);
      });
  }

  function getWeakReward(name) {
    setIsFetching(true);

    const exp =
      (topsInfo[name].rewards[user[topsInfo[name].info]?.place[1]]?.exp || 0) *
      Math.pow(
        user[topsInfo[name].info]?.place[0] + 1,
        user[topsInfo[name].info]?.place[0] > 0 ? 3 : 1
      );
    const gold =
      (topsInfo[name].rewards[user[topsInfo[name].info]?.place[1]]?.gold || 0) *
      Math.pow(user[topsInfo[name].info]?.place[0] + 1, 1);
    const silver =
      (topsInfo[name].rewards[user[topsInfo[name].info]?.place[1]]?.silver ||
        0) * Math.pow(user[topsInfo[name].info]?.place[0] + 1, 1);
    const essence =
      (topsInfo[name].rewards[user[topsInfo[name].info]?.place[1]]?.essence ||
        0) *
      Math.pow(
        user[topsInfo[name].info]?.place[0] + 1,
        user[topsInfo[name].info]?.place[0] > 0 ? 2 : 1
      );

    const mana =
      (topsInfo[name].rewards[user[topsInfo[name].info]?.place[1]]?.mana || 0) *
      Math.pow(user[topsInfo[name].info]?.place[0] + 1, 1);

    let isUp = 0;
    let currentExpOnLvl = user.currentExpOnLvl;
    let onLvlExpNeed = user.onLvlExpNeed;

    if (currentExpOnLvl + Math.floor(exp) >= onLvlExpNeed) {
      currentExpOnLvl += Math.floor(exp) - onLvlExpNeed;
      onLvlExpNeed = roundLvlExp(
        onLvlExpNeed *
          (user.lvl > 100
            ? 1.1
            : user.lvl > 150
            ? 1.05
            : user.lvl > 200
            ? 1.02
            : 1.2)
      );
      isUp = 1;
      while (currentExpOnLvl >= onLvlExpNeed) {
        currentExpOnLvl -= onLvlExpNeed;
        onLvlExpNeed = roundLvlExp(
          onLvlExpNeed *
            (user.lvl > 100
              ? 1.1
              : user.lvl > 150
              ? 1.05
              : user.lvl > 200
              ? 1.02
              : 1.2)
        );
        isUp++;
      }
      app.service("users").removeListener("timer");
    } else {
      currentExpOnLvl += Math.floor(exp);
    }

    const characteristicInfo = {};
    Object.entries(user.characteristic).forEach((elem) => {
      characteristicInfo[elem[0]] = {
        ...elem[1],
        lvl: (user.lvl + isUp) * 5,
      };
    });

    const scales = {};
    const grindValue = grindData["book"].bonus[user.grind["book"] - 1] / 100;
    user.damageTalents.characteristicTalents.forEach((elem) => {
      scales[elem.property] =
        1 + grindValue + Math.round(elem.step * elem.countDone * 100) / 10000;
    });

    const hp = Math.floor(
      Object.values(characteristicInfo.health).reduce((acc, cur, i) => {
        if (i < 2) {
          return acc + cur * 5 * scales.health;
        }
        return acc + cur * 5;
      }, 0)
    );

    let goldUp = 0;
    for (let step = 0; step < isUp; step++) {
      goldUp += user.lvl + step + 1;
    }

    const query = topsInfo[name].info;

    app
      .service("users")
      .patch(
        user._id,
        {
          $inc: {
            allExp: exp,
            lvl: isUp,
            currentEnergy:
              isUp &&
              user.currentEnergy <
                user.damageTalents.simpleTalents.maxValueEnergy +
                  grindData["owl"].bonus[user.grind["owl"] - 1]
                ? user.damageTalents.simpleTalents.maxValueEnergy +
                  grindData["owl"].bonus[user.grind["owl"] - 1] -
                  user.currentEnergy
                : 0,
            gold: goldUp + gold,
            silver: silver,
            essence: essence,
            mana: mana,
          },
          currentExpOnLvl: currentExpOnLvl,
          onLvlExpNeed: onLvlExpNeed,
          health: {
            current: isUp ? hp : user.health.current,
            max: hp,
          },
          characteristic: characteristicInfo,
          [query]: {
            points: 0,
            place: [],
          },

          common: true,
        },
        {
          query: {
            $select: [
              "_id",
              "email",
              "weakBossesInfo",
              "weakClassroomsInfo",
              "weakGamesInfo",
              "weakArenaInfo",
              "gold",
              "silver",
              "essence",
              "mana",
              "currentExpOnLvl",
              "onLvlExpNeed",
              "health",
              "characteristic",
              "currentEnergy",
              "allExp",
              "lvl",
            ],
          },
        }
      )
      .then((data) => {
        setUser((prev) => ({ ...prev, ...data }));
        setIsFetching(false);
      })
      .catch((e) => {
        setModalError(e);
        console.log(e);
        setIsFetching(false);
      });
  }

  function getRaidBossReward(isWin) {
    setIsFetching(true);
    app
      .service("users")
      .patch(
        user._id,
        {
          $inc: {
            gold: isWin ? user.raidBossInfo.rewards?.gold || 0 : 0,
            silver: isWin ? user.raidBossInfo.rewards?.silver || 0 : 0,
          },

          common: true,
          raidBossInfo: {
            rewards: {},
            damage: 0,
            percent: 0,
            isWin: false,
            isGet: true,
          },
        },
        {
          query: {
            $select: ["_id", "email", "raidBossInfo", "gold", "silver"],
          },
        }
      )
      .then((data) => {
        setUser((prev) => ({ ...prev, ...data }));
        setIsFetching(false);
      })
      .catch((e) => {
        setModalError(e);
        console.log(e);
        setIsFetching(false);
      });
  }

  const addItem = useCallback((count, item, inventoryInfo) => {
    if (inventoryInfo.length > 0) {
      const itemIndex = inventoryInfo.findIndex(
        (elem) => elem.name + elem.category === item.name + item.category
      );
      if (itemIndex !== -1) {
        inventoryInfo[itemIndex].count += count;
      } else {
        inventoryInfo.push({
          name: item.name,
          category: item.category,
          value: item.value,
          percent: item.percent,
          time: item.time,
          count: count,
        });
      }
    } else {
      inventoryInfo.push({
        name: item.name,
        category: item.category,
        value: item.value,
        percent: item.percent,
        time: item.time,
        count: count,
      });
    }
    return inventoryInfo;
  }, []);

  const getOurInfo = useCallback(() => {
    setIsFetching(true);

    let inventoryInfo = [...user.inventory];

    inventoryInfo = addItem(
      5,
      {
        name: "+50%",
        category: "hp",
        value: 50,
        percent: true,
        time: 0,
      },
      inventoryInfo
    );

    app
      .service("users")
      .patch(
        user._id,
        {
          hourInfo: true,
          inventory: inventoryInfo,
          common: true,
        },
        {
          query: {
            $select: ["_id", "email", "hourInfo", "inventory"],
          },
        }
      )
      .then((data) => {
        setUser((prev) => ({ ...prev, ...data }));
        setIsFetching(false);
      })
      .catch((e) => {
        setIsAdsPlay(false);
        setIsFetching(false);
        console.log(e);
      });
  }, [user]);

  return (
    <UserContext.Provider value={user}>
      {!user ||
      alreadyIsOpen ||
      !isConnected ||
      isLoadingData ||
      !isAuth ||
      !isVk ||
      !adminInfo ||
      (user && user.isBan) ||
      (adminInfo.version !== clientVersion && user.email !== 168191289) ||
      (adminInfo?.isTech && user.email !== 168191289) ||
      serverDate === null ||
      !allUsers.length ? (
        <S.LoadingWrapper scale={scaleHeight}>
          <S.StartVideo
            preload="metadata"
            poster={loadImg}
            width={900}
            height={700}
            autoPlay={true}
            muted={true}
            loop={true}
            playsInline={true}
            webkit-playsinline={true}
            controls={false}
            src={loadVideo}
          >
            <source src={loadVideo} type="video/mp4" />
          </S.StartVideo>
          <S.InfoError
            isOpen={
              alreadyIsOpen ||
              !isConnected ||
              !isAuth ||
              (user && user?.isBan) ||
              (adminInfo && adminInfo?.isTech) ||
              (adminInfo && adminInfo.version !== clientVersion)
            }
            w={317}
            h={152}
          >
            <div>
              {alreadyIsOpen ? (
                <span>Приложение уже открыто в другой вкладке</span>
              ) : !isConnected ? (
                <span>Нет подключения к серверу</span>
              ) : !isAuth ? (
                <span>Ошибка авторизации</span>
              ) : !isVk ? (
                <span>Ошибка загрузки ВК</span>
              ) : user && user?.isBan ? (
                <span>Аккаунт заблокирован</span>
              ) : adminInfo && adminInfo?.isTech ? (
                <span>На сервере ведутся технические работы</span>
              ) : adminInfo && adminInfo.version !== clientVersion ? (
                <span>Клиент игры устарел, требуется перезагрузка</span>
              ) : (
                <span>Загрузка данных...</span>
              )}
            </div>
          </S.InfoError>
        </S.LoadingWrapper>
      ) : (
        <>
          <S.AppRootWrapper onClick={closeSomeElements} scale={scaleHeight}>
            <S.Location
              width={840}
              height={570}
              isBlur={additionalPanel === "blur"}
              src={
                additionalPanel && !ownerRoom
                  ? location[additionalPanel]?.img
                    ? location[additionalPanel]?.img
                    : location[activePanel]?.img
                  : activePanel === "userRoom" && ownerRoom?.faculty
                  ? location[activePanel]?.img[ownerRoom.faculty]
                  : activePanel === "home" && user?.faculty
                  ? location[activePanel]?.img[user.faculty]
                  : user.activeBoss.number > -1 &&
                    activePanel === "bosses" &&
                    user.activeBoss.type === "dungeon"
                  ? location[bossesArray[user.activeBoss.number][0].name]?.img
                  : user.activeBoss.number > -1 &&
                    !user.activeBoss.isShyt &&
                    activePanel === "soloBoss" &&
                    user.activeBoss.type === "location"
                  ? location[soloBossArray[user.activeBoss.number][0].name]?.img
                  : user.activeBoss.number > -1 &&
                    user.activeBoss.isShyt &&
                    user.activeBoss.classType &&
                    activePanel === "shyt"
                  ? location[shytArray[0][0].name]?.img
                  : user.activeBoss.number > -1 &&
                    activePanel === "eventBoss" &&
                    user.activeBoss.type === "location"
                  ? location[eventBossArray[user.activeBoss.number].name]?.img
                  : activePanel === "raidBoss" &&
                    adminInfo.raidBoss.isActive > -1 &&
                    user.raidBoss.isAttack
                  ? location[raidBossesInfo[adminInfo.raidBoss.isActive].name]
                      ?.img
                  : !user?.faculty
                  ? location["test"]?.img
                  : location[activePanel]?.img
              }
              alt="локация"
            />
            {!user?.faculty && (
              <S.Location
                width={840}
                height={570}
                src={testStage === 1 ? testStage1Img : testStage2Img}
                alt="тест"
              />
            )}

            {!user?.faculty && testStage === 3 && (
              <S.Location
                isAnim={true}
                width={840}
                height={570}
                src={testStage3Img}
                alt="тест"
              />
            )}

            {(activePanel === "home" || activePanel === "userRoom") &&
              user.faculty && <Lounge user={!ownerRoom ? user : ownerRoom} />}

            {activePanel === "garderob" && user.faculty && (
              <LoungeGarderob
                weakTopWins={user.weakTopWins}
                clothes={user.clothes}
                sex={user.sex}
              />
            )}

            {(activePanel === "greenHouse" || activePanel === "potionLab") && (
              <PracticRoom
                greenHouse={user.greenHouse}
                potionLab={user.potionLab}
                activePanel={activePanel}
              />
            )}

            {/*{((activePanel === "bosses" &&*/}
            {/*  user.activeBoss.number > -1 &&*/}
            {/*  user.activeBoss.type === "dungeon") ||*/}
            {/*  ((activePanel === "soloBoss" || activePanel === "eventBoss") &&*/}
            {/*    user.activeBoss.number > -1 &&*/}
            {/*    user.activeBoss.type === "location") ||*/}
            {/*  (activePanel === "raidBoss" && user.raidBoss.isAttack)) && (*/}
            {/*  <BossInterface bossLog={bossLog} />*/}
            {/*)}*/}

            {user.faculty === "" ? (
              <FacultyTest
                _id={user._id}
                name={user.name}
                app={app}
                setIsFetching={setIsFetching}
                setUser={setUser}
                serverTime={serverDate}
                setModalError={setModalError}
                testStage={testStage}
                setTestStage={setTestStage}
              />
            ) : (
              <View id="view" activePanel={activePanel}>
                <Home
                  id="home"
                  user={user}
                  app={app}
                  setUser={setUser}
                  setIsFetching={setIsFetching}
                  isFetching={isFetching}
                  changeOptionsLocation={changeOptionsLocation}
                  setModalError={setModalError}
                  serverDate={serverDate}
                  adminInfo={adminInfo}
                  getCharacterSum={getCharacterSum}
                  moveLocation={moveLocation}
                  setActiveMenuModal={setActiveMenuModal}
                  addItem={addItem}
                  bridge={bridge}
                  activePanel={activePanel}
                  setActivePanel={setActivePanel}
                />

                <GreenHouse
                  id="greenHouse"
                  userId={user._id}
                  {...user.greenHouse}
                  app={app}
                  bridge={bridge}
                  setUser={setUser}
                  setIsFetching={setIsFetching}
                  isFetching={isFetching}
                  changeOptionsLocation={changeOptionsLocation}
                  setModalError={setModalError}
                  serverTime={serverDate}
                  classroomDone={user.classrooms[1].done}
                  userEnergy={user.currentEnergy}
                  gold={user.gold}
                  helpers={user.helpers}
                />
                <PotionLab
                  id="potionLab"
                  userId={user._id}
                  {...user.potionLab}
                  inventory={user.inventory}
                  app={app}
                  bridge={bridge}
                  setUser={setUser}
                  setIsFetching={setIsFetching}
                  isFetching={isFetching}
                  changeOptionsLocation={changeOptionsLocation}
                  setModalError={setModalError}
                  serverTime={serverDate}
                  classroomDone={user.classrooms[0].done}
                  userEnergy={user.currentEnergy}
                  gold={user.gold}
                  addItem={addItem}
                  helpers={user.helpers}
                />
                <Tops
                  id="topsRoom"
                  me={user}
                  allUsers={allUsers}
                  changeOptionsLocation={changeOptionsLocation}
                  moveToUserRoom={moveToUserRoom}
                  serverTime={serverDate}
                  stage={stageTop}
                  setStage={setStageTop}
                  setAdditionalPanel={setAdditionalPanel}
                  admin={adminInfo}
                  reloadUsersInfo={reloadUsersInfo}
                />
                {ownerRoom && (
                  <UserRoom
                    id="userRoom"
                    app={app}
                    me={user}
                    ownerRoom={ownerRoom}
                    setOwnerRoom={setOwnerRoom}
                    moveLocation={moveLocation}
                    isModalOpen={isModalOpen}
                    isFetching={isFetching}
                    setIsFetching={setIsFetching}
                    setModalError={setModalError}
                    lastPlace={lastPlace}
                  />
                )}
                <GameRoom
                  id="gameRoom"
                  user={user}
                  npm
                  app={app}
                  setUser={setUser}
                  admin={adminInfo}
                  setAdmin={setAdminInfo}
                  setIsModalOpen={setIsModalOpen}
                  isModalOpen={isModalOpen}
                  setIsFetching={setIsFetching}
                  isFetching={isFetching}
                  changeOptionsLocation={changeOptionsLocation}
                  setAdditionalPanel={setAdditionalPanel}
                  setModalError={setModalError}
                  changeActiveMenuModal={changeActiveMenuModal}
                  serverTime={serverDate}
                  allUsers={allUsers}
                  moveToUserRoom={moveToUserRoom}
                />
                <Classroom
                  id="potionRoom"
                  changeOptionsLocation={changeOptionsLocation}
                  setAdditionalPanel={setAdditionalPanel}
                  setIsPopupCollection={setIsPopupCollection}
                  setCollectionElem={setCollectionElem}
                  setIsLvlUpOpen={setIsLvlUpOpen}
                  setIsModalOpen={setIsModalOpen}
                  setIsFetching={setIsFetching}
                  serverTime={serverDate}
                  isModalOpen={isModalOpen}
                  classRoomIndex={0}
                  classRoom={user.classrooms[0]}
                  {...classRoomsArray[0]}
                  isFetching={isFetching}
                  setUser={setUser}
                  setModalError={setModalError}
                  user={user}
                  app={app}
                />
                <Classroom
                  id="grassRoom"
                  changeOptionsLocation={changeOptionsLocation}
                  setAdditionalPanel={setAdditionalPanel}
                  setIsPopupCollection={setIsPopupCollection}
                  setCollectionElem={setCollectionElem}
                  setIsLvlUpOpen={setIsLvlUpOpen}
                  setIsModalOpen={setIsModalOpen}
                  setIsFetching={setIsFetching}
                  serverTime={serverDate}
                  isModalOpen={isModalOpen}
                  classRoomIndex={1}
                  classRoom={user.classrooms[1]}
                  {...classRoomsArray[1]}
                  isFetching={isFetching}
                  setUser={setUser}
                  setModalError={setModalError}
                  user={user}
                  app={app}
                />
                <Bosses
                  id="bosses"
                  serverTime={serverDate}
                  user={user}
                  setUser={setUser}
                  app={app}
                  friends={allFriends}
                  isFetching={isFetching}
                  isModalOpen={isModalOpen}
                  setIsModalOpen={setIsModalOpen}
                  setIsFetching={setIsFetching}
                  setIsLvlUpOpen={setIsLvlUpOpen}
                  changeOptionsLocation={changeOptionsLocation}
                  setModalError={setModalError}
                  bossesLimitScale={adminInfo.bossesLimitScale}
                  setAdditionalPanel={setAdditionalPanel}
                  allUsers={allUsers}
                  useItem={useItem}
                  setBossLog={setBossLog}
                  bossLog={bossLog}
                  bossesInfo={bossesInfo}
                  setBossesInfo={setBossesInfo}
                  setIsAltarOpen={setIsAltarOpen}
                />

                <Shyt
                  id="shyt"
                  serverTime={serverDate}
                  user={user}
                  setUser={setUser}
                  app={app}
                  friends={allFriends}
                  bridge={bridge}
                  isFetching={isFetching}
                  isModalOpen={isModalOpen}
                  setIsModalOpen={setIsModalOpen}
                  setIsFetching={setIsFetching}
                  setIsLvlUpOpen={setIsLvlUpOpen}
                  changeOptionsLocation={changeOptionsLocation}
                  setModalError={setModalError}
                  getCharacterSum={getCharacterSum}
                  setAdditionalPanel={setAdditionalPanel}
                  allUsers={allUsers}
                  useItem={useItem}
                  setBossLog={setBossLog}
                  bossLog={bossLog}
                  bossesInfo={bossesInfo}
                  setBossesInfo={setBossesInfo}
                  number={0}
                  admin={adminInfo}
                />

                <SoloBoss
                  id="soloBoss"
                  serverTime={serverDate}
                  user={user}
                  setUser={setUser}
                  app={app}
                  friends={allFriends}
                  bridge={bridge}
                  isFetching={isFetching}
                  isModalOpen={isModalOpen}
                  setIsModalOpen={setIsModalOpen}
                  setIsFetching={setIsFetching}
                  setIsLvlUpOpen={setIsLvlUpOpen}
                  changeOptionsLocation={changeOptionsLocation}
                  setModalError={setModalError}
                  getCharacterSum={getCharacterSum}
                  setAdditionalPanel={setAdditionalPanel}
                  allUsers={allUsers}
                  useItem={useItem}
                  setBossLog={setBossLog}
                  bossLog={bossLog}
                  bossesInfo={bossesInfo}
                  setBossesInfo={setBossesInfo}
                />

                {user.eventBossNumber > -1 && (
                  <EventBoss
                    id="eventBoss"
                    serverTime={serverDate}
                    user={user}
                    setUser={setUser}
                    app={app}
                    friends={allFriends}
                    isFetching={isFetching}
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    setIsFetching={setIsFetching}
                    setIsLvlUpOpen={setIsLvlUpOpen}
                    changeOptionsLocation={changeOptionsLocation}
                    setModalError={setModalError}
                    bossesLimitScale={adminInfo.bossesLimitScale}
                    getCharacterSum={getCharacterSum}
                    setAdditionalPanel={setAdditionalPanel}
                    allUsers={allUsers}
                    useItem={useItem}
                    setBossLog={setBossLog}
                    bossLog={bossLog}
                    bossesInfo={bossesInfo}
                    setBossesInfo={setBossesInfo}
                    number={user.eventBossNumber}
                    adminInfo={adminInfo}
                  />
                )}

                <RaidBoss
                  id="raidBoss"
                  {...adminInfo.raidBoss}
                  {...raidBossesInfo[adminInfo.raidBoss.isActive]}
                  {...user.raidBoss}
                  serverTime={serverDate}
                  user={user}
                  setUser={setUser}
                  app={app}
                  friends={allFriends}
                  isFetching={isFetching}
                  isModalOpen={isModalOpen}
                  setIsModalOpen={setIsModalOpen}
                  setIsFetching={setIsFetching}
                  setIsLvlUpOpen={setIsLvlUpOpen}
                  changeOptionsLocation={changeOptionsLocation}
                  setAdminInfo={setAdminInfo}
                  setModalError={setModalError}
                  allUsers={allUsers}
                  useItem={useItem}
                  moveLocation={moveLocation}
                  setBossLog={setBossLog}
                  bossLog={bossLog}
                  bossesInfo={bossesInfo}
                  setBossesInfo={setBossesInfo}
                  getCharacterSum={getCharacterSum}
                />

                <Arena
                  id="arena"
                  user={user}
                  bridge={bridge}
                  setDisableMove={setDisableMove}
                  allUsers={allUsers}
                  setUser={setUser}
                  app={app}
                  admin={adminInfo}
                  setAdmin={setAdminInfo}
                  isFetching={isFetching}
                  setIsFetching={setIsFetching}
                  setIsModalOpen={setIsModalOpen}
                  isModalOpen={isModalOpen}
                  serverTime={serverDate}
                  changeOptionsLocation={changeOptionsLocation}
                  setModalError={setModalError}
                  getCharacterSum={getCharacterSum}
                  moveToUserRoom={moveToUserRoom}
                  setAdditionalPanel={setAdditionalPanel}
                />
                <Bank
                  id="bank"
                  serverTime={serverDate}
                  adminInfo={adminInfo}
                  setIsOpen={setActiveMenuModal}
                  isFetching={isFetching}
                  setIsFetching={setIsFetching}
                  setModalError={setModalError}
                  app={app}
                  setUser={setUser}
                  user={user}
                  getCharacterSum={getCharacterSum}
                  changeOptionsLocation={changeOptionsLocation}
                  bridge={bridge}
                />
                <Garderob
                  id="garderob"
                  serverDate={serverDate}
                  setIsOpen={setActiveMenuModal}
                  isFetching={isFetching}
                  setIsFetching={setIsFetching}
                  setModalError={setModalError}
                  app={app}
                  setUser={setUser}
                  user={user}
                  changeOptionsLocation={changeOptionsLocation}
                  getCharacterSum={getCharacterSum}
                  moveLocation={moveLocation}
                />
              </View>
            )}

            <S.InterfaceTop
              width={900}
              height={77}
              src={interfaceTop}
              alt="интерфейс"
            />

            <S.InterfaceBottom
              width={900}
              height={111}
              src={interfaceBottom}
              alt="интерфейс"
            />

            <S.InterfaceLeft
              width={60}
              height={515}
              src={interfaceLeft}
              alt="интерфейс"
            />

            <S.InterfaceRight
              width={60}
              height={515}
              src={interfaceLeft}
              alt="интерфейс"
            />

            <LvlInfo
              currentExpOnLvl={user.currentExpOnLvl}
              onLvlExpNeed={user.onLvlExpNeed}
              lvl={user.lvl}
              health={user.health}
            />

            <NickName userName={user.name} nickName={user.nickName} />

            <LocationInfo
              ownerRoom={ownerRoom}
              name={
                activePanel === "bosses" &&
                user.activeBoss.number > -1 &&
                user.activeBoss.type === "dungeon"
                  ? bossesArray[user.activeBoss.number][0].name
                  : activePanel === "soloBoss" &&
                    user.activeBoss.number > -1 &&
                    !user.activeBoss.isShyt &&
                    user.activeBoss.type === "location"
                  ? soloBossArray[user.activeBoss.number][0].name
                  : user.activeBoss.number > -1 &&
                    user.activeBoss.isShyt &&
                    activePanel === "shyt"
                  ? shytArray[0][0].name
                  : activePanel === "eventBoss" &&
                    user.activeBoss.number > -1 &&
                    user.activeBoss.type === "location"
                  ? eventBossArray[user.activeBoss.number].name
                  : activePanel === "raidBoss" &&
                    adminInfo.raidBoss.isActive > -1
                  ? raidBossesInfo[adminInfo.raidBoss.isActive].name
                  : additionalPanel === "game21"
                  ? `Игра в "21"`
                  : additionalPanel === "diceGame"
                  ? "Игра в кости"
                  : additionalPanel === "diceGameTournament"
                  ? `Турнир "кости"`
                  : additionalPanel === "game21Tournament"
                  ? `Турнир "21"`
                  : additionalPanel === "arenaTournament"
                  ? `Турнир Дуэлей`
                  : activePanel
              }
            />

            <MoneyInfo
              gold={user.gold}
              silver={user.silver}
              essence={user.essence}
              mana={user.mana}
            />

            <EnergyInfo
              energy={user.currentEnergy}
              maxEnergy={
                user.damageTalents.simpleTalents.maxValueEnergy +
                grindData["owl"].bonus[user.grind["owl"] - 1]
              }
              energyTime={energyTimeString}
            />

            {user.faculty && (
              <Chat
                chatMute={user.chatMute}
                email={user.email}
                orden={user.orden}
                isChatAdmin={user.isChatAdmin}
                app={app}
                messagesAll={messagesAll}
                messagesHelp={messagesHelp}
                messagesOrden={messagesOrden}
                chatUsers={chatUsers}
                allUsers={allUsers}
                moveToUserRoom={moveToUserRoom}
                serverTime={serverDate}
                clickName={clickName}
              />
            )}

            {user.faculty && <MenuBottom changeFilters={changeFilters} />}

            {user.faculty && <FriendsPhoto friendsArray={friendsArray} />}

            {user.faculty && (
              <Friends
                isFaculty={user.faculty}
                disableMove={disableMove}
                me={user}
                isFetching={isFetching}
                isModalOpen={isModalOpen}
                friendsArray={friendsArray}
                moveToUserRoom={moveToUserRoom}
                sortedProperty={sortedProperty}
                sortedArray={sortedArray}
                getCharacterSum={getCharacterSum}
                leftNumber={leftNumber}
                setFriendsArray={setFriendsArray}
                setModalError={setModalError}
              />
            )}

            {user.faculty && activePanel !== "garderob" && (
              <S.Menu>
                <S.MenuWrapper onWheel={menuScroll}>
                  {leftMenu.map((elem) => {
                    return (
                      <S.MenuElem key={elem.name} name={elem.name}>
                        <img
                          width={80}
                          height={80}
                          src={elem.src}
                          alt={elem.name}
                        />
                        <div
                          onClick={
                            !isModalOpen && !isFetching && !disableMove
                              ? elem.property === "home" ||
                                elem.property === "bank" ||
                                elem.property === "topsRoom"
                                ? moveLocation.bind(null, elem.property)
                                : () => setActiveMenuModal(elem.property)
                              : null
                          }
                        />
                      </S.MenuElem>
                    );
                  })}
                </S.MenuWrapper>
                <S.ButtonUp
                  onClick={menuButtonClick.bind(null, "top")}
                  limit={leftMenuNumber.current === 0}
                >
                  <img width={30} src={scrollButtonImg} alt="вверх" />
                </S.ButtonUp>
                <S.ButtonDown
                  onClick={menuButtonClick.bind(null, "bottom")}
                  limit={leftMenuNumber.current === leftMenuArray.length - 4}
                >
                  <img width={30} src={scrollButtonImg} alt="вверх" />
                </S.ButtonDown>
              </S.Menu>
            )}

            <div id={"menuRight"} />

            <Sound
              mute={mute}
              isSound={user.isSound}
              setIsFetching={setIsFetching}
              setUser={setUser}
              userId={user._id}
              serverTime={serverDate}
              setModalError={setModalError}
              app={app}
            />

            {user.faculty && (
              <Ads
                setIsFetching={setIsFetching}
                isFetching={isFetching}
                setUser={setUser}
                isGetAds={user.isGetAds}
                inventory={user.inventory}
                _id={user._id}
                app={app}
                setModalError={setModalError}
                setIsAdsPlay={setIsAdsPlay}
                addItem={addItem}
              />
            )}
            {user.faculty && (
              <RefUsers
                email={user.email}
                _id={user._id}
                refCount={user.refCount}
                refString={`https://vk.com/app51651233#${user.email}`}
                isFetching={isFetching}
                setIsFetching={setIsFetching}
                setModalError={setModalError}
                allUsers={allUsers}
                moveToUserRoom={moveToUserRoom}
                app={app}
              />
            )}
            {user.faculty && (
              <Rating
                email={user.email}
                orden={user.orden}
                app={app}
                chatUsers={chatUsers}
                allUsers={allUsers}
                moveToUserRoom={moveToUserRoom}
                allFriends={allFriends}
              />
            )}

            {(user.email === 828041844 ||
              user.email === 168191289 ||
              user.email === 134581594 ||
              user.email === 478818039 ||
              user.email === 561754297 ||
              user.email === 249764978 ||
              user.email === 262991826 ||
              user.email === 698660694) && (
              <DayDamagePanel
                me={user}
                allUsers={allUsers}
                moveToUserRoom={moveToUserRoom}
                reloadUsersInfo={reloadUsersInfo}
              />
            )}

            {user.email === 168191289 && <AdminPanel app={app} />}

            <Map
              isOpen={activeMenuModal === "map" && !isModalOpen}
              activePanel={activePanel}
              setIsOpen={setActiveMenuModal}
              isFetching={isFetching}
              changeActiveMenuModal={changeActiveMenuModal}
              setActivePanel={setActivePanel}
              setModalError={setModalError}
              disableMove={disableMove}
              admin={adminInfo}
              setUser={setUser}
              serverTime={serverDate}
              app={app}
              _id={user._id}
              lvl={user.lvl}
              soloBossNumber={user.soloBossNumber}
              activeBoss={user.activeBoss}
              raidBoss={user.raidBoss}
              arenaTournamentRegister={user.arenaTournament.isRegister}
            />
            <Book
              currentEnergy={user.currentEnergy}
              dayQuest={user.dayQuest}
              currentExpOnLvl={user.currentExpOnLvl}
              onLvlExpNeed={user.onLvlExpNeed}
              lvl={user.lvl}
              characteristic={user.characteristic}
              grind={user.grind}
              damageTalents={user.damageTalents}
              spells={user.spells}
              stockLvl={user.stockLvl}
              _id={user._id}
              gold={user.gold}
              silver={user.silver}
              essence={user.essence}
              changeSilverScale={user.changeSilverScale}
              characClear={user.characClear}
              health={user.health}
              spellsClear={user.spellsClear}
              isOpen={activeMenuModal === "book" && !isModalOpen}
              setIsOpen={setActiveMenuModal}
              setUser={setUser}
              app={app}
              isFetching={isFetching}
              setIsFetching={setIsFetching}
              setIsModalOpen={setIsModalOpen}
              setModalError={setModalError}
              setIsLvlUpOpen={setIsLvlUpOpen}
              serverTime={serverDate}
              getCharacterSum={getCharacterSum}
              activeTabBook={activeTabBook}
              setActiveTabBook={setActiveTabBook}
            />
            <Shop
              _id={user._id}
              treasures={user.treasures}
              gold={user.gold}
              silver={user.silver}
              maxCharacCount={user.maxCharacCount}
              damageTalents={user.damageTalents}
              inventory={user.inventory}
              isOpen={activeMenuModal === "shop" && !isModalOpen}
              setIsOpen={setActiveMenuModal}
              setUser={setUser}
              app={app}
              isFetching={isFetching}
              setIsFetching={setIsFetching}
              setIsModalOpen={setIsModalOpen}
              isModalOpen={isModalOpen}
              setModalError={setModalError}
              useItem={useItem}
              serverTime={serverDate}
            />
            <Inventory
              _id={user._id}
              sex={user.sex}
              inventory={user.inventory}
              clothes={user.clothes}
              bosses={user.bosses}
              treasures={user.treasures}
              characteristic={user.characteristic}
              grind={user.grind}
              damageTalents={user.damageTalents}
              gold={user.gold}
              candy={user.candy}
              isOpen={activeMenuModal === "inventory" && !isModalOpen}
              allUsers={allUsers}
              setIsOpen={setActiveMenuModal}
              setUser={setUser}
              app={app}
              isFetching={isFetching}
              setIsFetching={setIsFetching}
              setIsModalOpen={setIsModalOpen}
              isModalOpen={isModalOpen}
              setModalError={setModalError}
              useItem={useItem}
              moveToUserRoom={moveToUserRoom}
              serverTime={serverDate}
              addItem={addItem}
              handleAltarOpen={handleAltarOpen}
            />
            <Collections
              setIsModal={setIsModalCollection}
              isOpen={activeMenuModal === "collections" && !isModalOpen}
              collectionName={collectionName}
              setCollectionName={setCollectionName}
              setIsOpen={setActiveMenuModal}
              user={user}
              setUser={setUser}
              app={app}
              isFetching={isFetching}
              setIsFetching={setIsFetching}
              setIsModalOpen={setIsModalOpen}
              isModalOpen={isModalOpen}
              setIsLvlUpOpen={setIsLvlUpOpen}
              setModalError={setModalError}
              allUsers={allUsers}
              moveToUserRoom={moveToUserRoom}
              serverTime={serverDate}
            />
            <Ordens
              _id={user._id}
              adminId={user.admin}
              gold={user.gold}
              email={user.email}
              isOpen={activeMenuModal === "ordens" && !isModalOpen}
              setIsOpen={setActiveMenuModal}
              admin={adminInfo}
              setAdmin={setAdminInfo}
              setUser={setUser}
              app={app}
              isFetching={isFetching}
              setIsFetching={setIsFetching}
              setModalError={setModalError}
              serverTime={serverDate}
              myOrden={myOrden}
              allUsers={allUsers}
              getCharacterSum={getCharacterSum}
              moveToUserRoom={moveToUserRoom}
              reloadUsersInfo={reloadUsersInfo}
              setMyOrden={setMyOrden}
            />

            {([
              "bosses",
              "potionLab",
              "greenHouse",
              "arena",
              "potionRoom",
              "grassRoom",
            ].includes(lastPlace) ||
              activeMenuModal === "book") &&
              !user.guides?.[
                activeMenuModal === "book" ? activeMenuModal : lastPlace
              ] && (
                <Guides
                  user={user}
                  setUser={setUser}
                  app={app}
                  isFetching={isFetching}
                  setIsFetching={setIsFetching}
                  setModalError={setModalError}
                  serverTime={serverDate}
                  location={
                    activeMenuModal === "book" && !user.guides?.["book"]
                      ? activeMenuModal
                      : lastPlace
                  }
                  setActiveTabBook={setActiveTabBook}
                />
              )}

            <S.CollectionPopup
              isOpen={isPopupCollection}
              w={600}
              h={178}
              back={frame5}
            >
              {isPopupCollection && (
                <div>
                  <Close
                    onClick={() => {
                      setIsPopupCollection(false);
                    }}
                  />
                  {collectionElem && (
                    <CollectionFind
                      app={app}
                      user={user}
                      collectionsNeed={user.collectionsNeed}
                      isFetching={isFetching}
                      collection={
                        user.collections[collectionElem.collectionIndex]
                      }
                      {...collectionsArray[collectionElem.collectionIndex]}
                      collectionIndex={collectionElem.collectionIndex}
                      setUser={setUser}
                      setCollectionName={setCollectionName}
                      setIsFetching={setIsFetching}
                      setIsLvlUpOpen={setIsLvlUpOpen}
                      setIsModal={setIsModalCollection}
                      activeElemIndex={collectionElem.elemIndex}
                      count={collectionElem.count}
                      setModalError={setModalError}
                      serverTime={serverDate}
                      setActiveMenuModal={setActiveMenuModal}
                      setIsPopupCollection={setIsPopupCollection}
                    />
                  )}
                </div>
              )}
            </S.CollectionPopup>

            <Popup
              isOpen={
                !user.hourInfo && user.isGuide && user.dayPrizes.todayIsGet
              }
              w={312}
              h={302}
              back={frame2}
            >
              <b>Игровой час подошёл к концу:</b>
              <S.Rewards>
                <span>Уменьшены лимиты на Боссов</span>
                <span>Сброшен лимит на дуэли</span>
                <span>Реклама доступна к просмотру</span>

                <RewardWrapper light={false} w={65}>
                  <div>
                    <Reward
                      name={"potion"}
                      category={"hp"}
                      potionName={"+50%"}
                      count={5}
                      w={28}
                      showCount={true}
                      round={true}
                      font={13}
                      reverseColumn={true}
                      color={"#fdbb54"}
                    />
                  </div>
                </RewardWrapper>
              </S.Rewards>

              <Button width={100} onClick={!isFetching ? getOurInfo : null}>
                <div>Забрать</div>
              </Button>
            </Popup>

            <Popup
              isOpen={user.tournament21Info?.place > -1}
              w={312}
              h={302}
              back={frame2}
            >
              {user.tournament21Info?.place > -1 && (
                <>
                  <b>
                    Вы заняли {user.tournament21Info?.place + 1} место в турнире{" "}
                    {user.tournament21Info?.name}!
                  </b>

                  <span>Набрано очков: {user.tournament21Info?.points}</span>
                  <S.Rewards>
                    {user.tournament21Info?.place === 0 && (
                      <RewardWrapper light={false} w={65}>
                        <div>
                          <Reward
                            count={"1"}
                            name={"rareClothes"}
                            w={28}
                            showCount={true}
                            round={true}
                            font={13}
                            reverseColumn={true}
                            color={"#fdbb54"}
                          />
                        </div>
                      </RewardWrapper>
                    )}

                    {user.tournament21Info?.place === 1 && (
                      <RewardWrapper light={false} w={65}>
                        <div>
                          <Reward
                            count={"2"}
                            name={"commonClothes"}
                            w={28}
                            showCount={true}
                            round={true}
                            font={13}
                            reverseColumn={true}
                            color={"#fdbb54"}
                          />
                        </div>
                      </RewardWrapper>
                    )}

                    {user.tournament21Info?.place === 2 && (
                      <RewardWrapper light={false} w={65}>
                        <div>
                          <Reward
                            count={"1"}
                            name={"commonClothes"}
                            w={28}
                            showCount={true}
                            round={true}
                            font={13}
                            reverseColumn={true}
                            color={"#fdbb54"}
                          />
                        </div>
                      </RewardWrapper>
                    )}

                    <RewardWrapper light={false} w={65}>
                      <div>
                        <Reward
                          name={"gold"}
                          count={
                            tournamentPrizes[user.tournament21Info?.place]?.gold
                          }
                          w={28}
                          showCount={true}
                          round={true}
                          font={13}
                          reverseColumn={true}
                          color={"#fdbb54"}
                        />
                      </div>
                    </RewardWrapper>
                  </S.Rewards>

                  <Button
                    width={100}
                    onClick={!isFetching ? getTournament21Reward : null}
                  >
                    <div>Забрать</div>
                  </Button>
                </>
              )}
            </Popup>

            <Popup
              isOpen={user.tournamentDiceInfo?.place > -1}
              w={312}
              h={302}
              back={frame2}
            >
              {user.tournamentDiceInfo?.place > -1 && (
                <>
                  <b>
                    Вы заняли {user.tournamentDiceInfo?.place + 1} место в
                    турнире {user.tournamentDiceInfo?.name}!
                  </b>

                  <span>Набрано очков: {user.tournamentDiceInfo?.points}</span>
                  <S.Rewards>
                    {user.tournamentDiceInfo?.place === 0 && (
                      <RewardWrapper light={false} w={65}>
                        <div>
                          <Reward
                            count={"1"}
                            name={"rareClothes"}
                            w={28}
                            showCount={true}
                            round={true}
                            font={13}
                            reverseColumn={true}
                            color={"#fdbb54"}
                          />
                        </div>
                      </RewardWrapper>
                    )}

                    {user.tournamentDiceInfo?.place === 1 && (
                      <RewardWrapper light={false} w={65}>
                        <div>
                          <Reward
                            count={"2"}
                            name={"commonClothes"}
                            w={28}
                            showCount={true}
                            round={true}
                            font={13}
                            reverseColumn={true}
                            color={"#fdbb54"}
                          />
                        </div>
                      </RewardWrapper>
                    )}

                    {user.tournamentDiceInfo?.place === 2 && (
                      <RewardWrapper light={false} w={65}>
                        <div>
                          <Reward
                            count={"1"}
                            name={"commonClothes"}
                            w={28}
                            showCount={true}
                            round={true}
                            font={13}
                            reverseColumn={true}
                            color={"#fdbb54"}
                          />
                        </div>
                      </RewardWrapper>
                    )}

                    <RewardWrapper light={false} w={65}>
                      <div>
                        <Reward
                          name={"gold"}
                          count={
                            tournamentPrizes[user.tournamentDiceInfo?.place]
                              ?.gold
                          }
                          w={28}
                          showCount={true}
                          round={true}
                          font={13}
                          reverseColumn={true}
                          color={"#fdbb54"}
                        />
                      </div>
                    </RewardWrapper>
                  </S.Rewards>

                  <Button
                    width={100}
                    onClick={!isFetching ? getTournamentDiceReward : null}
                  >
                    <div>Забрать</div>
                  </Button>
                </>
              )}
            </Popup>

            <Popup
              isOpen={user.tournamentArenaInfo?.place > -1}
              w={312}
              h={302}
              back={frame2}
            >
              {user.tournamentArenaInfo?.place > -1 && (
                <>
                  <b>
                    Вы заняли {user.tournamentArenaInfo?.place + 1} место в
                    турнире {user.tournamentArenaInfo?.name} в уровневой группе{" "}
                    {(user.tournamentArenaInfo?.stage - 1) * 20}-
                    {user.tournamentArenaInfo?.stage * 20 - 1}!
                  </b>

                  <span>Набрано очков: {user.tournamentArenaInfo?.points}</span>
                  <S.Rewards>
                    <RewardWrapper light={false} w={65}>
                      <div>
                        <Reward
                          name={"gold"}
                          count={
                            tournamentPrizes[user.tournamentArenaInfo?.place]
                              ?.gold
                          }
                          w={28}
                          showCount={true}
                          round={true}
                          font={13}
                          reverseColumn={true}
                          color={"#fdbb54"}
                        />
                      </div>
                    </RewardWrapper>
                  </S.Rewards>

                  <Button
                    width={100}
                    onClick={!isFetching ? getTournamentArenaReward : null}
                  >
                    <div>Забрать</div>
                  </Button>
                </>
              )}
            </Popup>

            <Popup
              isOpen={user.weakInfo.place.length}
              w={312}
              h={302}
              back={frame2}
            >
              {user.weakInfo.place.length && (
                <>
                  <b>
                    Вы заняли {user.weakInfo?.place[1] + 1} место по урону в
                    уровневой группе{" "}
                    {user.weakInfo?.place[0] === 0
                      ? 1
                      : user.weakInfo?.place[0] * 20}
                    -{(user.weakInfo?.place[0] + 1) * 20 - 1}!
                  </b>

                  <span>
                    Ваш урон за неделю:{" "}
                    {roundProperty(user.weakInfo?.damage).toLocaleString("ru")}
                  </span>
                  <S.Rewards>
                    <RewardWrapper light={false} w={65}>
                      <div>
                        <Reward
                          name={"gold"}
                          count={
                            weakDamagePrizes[user.weakInfo?.place[1]].gold *
                            (user.weakInfo?.place[0] + 1)
                          }
                          w={28}
                          showCount={true}
                          round={true}
                          font={13}
                          reverseColumn={true}
                          color={"#fdbb54"}
                        />
                      </div>
                    </RewardWrapper>
                    <RewardWrapper light={false} w={65}>
                      <div>
                        <Reward
                          name={"silver"}
                          count={
                            weakDamagePrizes[user.weakInfo?.place[1]].silver *
                            (user.weakInfo?.place[0] + 1)
                          }
                          w={28}
                          showCount={true}
                          round={true}
                          font={13}
                          reverseColumn={true}
                          color={"#fdbb54"}
                        />
                      </div>
                    </RewardWrapper>
                    <RewardWrapper light={false} w={65}>
                      <div>
                        <Reward
                          name={"mana"}
                          count={
                            weakDamagePrizes[user.weakInfo?.place[1]].mana *
                            (user.weakInfo?.place[0] + 1)
                          }
                          w={28}
                          showCount={true}
                          round={true}
                          font={13}
                          reverseColumn={true}
                          color={"#fdbb54"}
                        />
                      </div>
                    </RewardWrapper>
                  </S.Rewards>

                  <Button
                    width={100}
                    onClick={!isFetching ? getWeakDamageReward : null}
                  >
                    <div>Забрать</div>
                  </Button>
                </>
              )}
            </Popup>

            <Popup
              isOpen={user.weakBossesInfo.place.length}
              w={312}
              h={302}
              back={frame2}
            >
              {user.weakBossesInfo.place.length && (
                <>
                  <b>
                    Вы заняли {user.weakBossesInfo?.place[1] + 1} место по
                    убийству Боссов в уровневой группе{" "}
                    {user.weakBossesInfo?.place[0] === 0
                      ? 1
                      : user.weakBossesInfo?.place[0] * 20}
                    -{(user.weakBossesInfo?.place[0] + 1) * 20 - 1}!
                  </b>

                  <span>
                    Ваши очки за неделю:{" "}
                    {roundProperty(user.weakBossesInfo?.points).toLocaleString(
                      "ru"
                    )}
                  </span>
                  <S.Rewards>
                    <RewardWrapper light={false} w={65}>
                      <div>
                        <Reward
                          name={"gold"}
                          count={
                            topsInfo["bosses"].rewards[
                              user.weakBossesInfo?.place[1]
                            ].gold *
                            (user.weakBossesInfo?.place[0] + 1)
                          }
                          w={28}
                          showCount={true}
                          round={true}
                          font={13}
                          reverseColumn={true}
                          color={"#fdbb54"}
                        />
                      </div>
                    </RewardWrapper>
                    <RewardWrapper light={false} w={65}>
                      <div>
                        <Reward
                          name={"exp"}
                          count={
                            topsInfo["bosses"].rewards[
                              user.weakBossesInfo?.place[1]
                            ].exp *
                            Math.pow(
                              user.weakBossesInfo?.place[0] + 1,
                              user.weakBossesInfo?.place[0] > 0 ? 3 : 1
                            )
                          }
                          w={28}
                          showCount={true}
                          round={true}
                          font={13}
                          reverseColumn={true}
                          color={"#fdbb54"}
                        />
                      </div>
                    </RewardWrapper>
                  </S.Rewards>

                  <Button
                    width={100}
                    onClick={
                      !isFetching ? getWeakReward.bind(null, "bosses") : null
                    }
                  >
                    <div>Забрать</div>
                  </Button>
                </>
              )}
            </Popup>

            <Popup
              isOpen={user.weakGamesInfo.place.length}
              w={312}
              h={302}
              back={frame2}
            >
              {user.weakGamesInfo.place.length && (
                <>
                  <b>
                    Вы заняли {user.weakGamesInfo?.place[1] + 1} место по Играм
                    в уровневой группе{" "}
                    {user.weakGamesInfo?.place[0] === 0
                      ? 1
                      : user.weakGamesInfo?.place[0] * 20}
                    -{(user.weakGamesInfo?.place[0] + 1) * 20 - 1}!
                  </b>

                  <span>
                    Ваши очки за неделю:{" "}
                    {roundProperty(user.weakGamesInfo?.points).toLocaleString(
                      "ru"
                    )}
                  </span>
                  <S.Rewards>
                    <RewardWrapper light={false} w={65}>
                      <div>
                        <Reward
                          name={"silver"}
                          count={
                            topsInfo["games"].rewards[
                              user.weakGamesInfo?.place[1]
                            ].silver *
                            (user.weakGamesInfo?.place[0] + 1)
                          }
                          w={28}
                          showCount={true}
                          round={true}
                          font={13}
                          reverseColumn={true}
                          color={"#fdbb54"}
                        />
                      </div>
                    </RewardWrapper>
                    <RewardWrapper light={false} w={65}>
                      <div>
                        <Reward
                          name={"exp"}
                          count={
                            topsInfo["games"].rewards[
                              user.weakGamesInfo?.place[1]
                            ].exp *
                            Math.pow(
                              user.weakGamesInfo?.place[0] + 1,
                              user.weakGamesInfo?.place[0] > 0 ? 3 : 1
                            )
                          }
                          w={28}
                          showCount={true}
                          round={true}
                          font={13}
                          reverseColumn={true}
                          color={"#fdbb54"}
                        />
                      </div>
                    </RewardWrapper>
                  </S.Rewards>

                  <Button
                    width={100}
                    onClick={
                      !isFetching ? getWeakReward.bind(null, "games") : null
                    }
                  >
                    <div>Забрать</div>
                  </Button>
                </>
              )}
            </Popup>

            <Popup
              isOpen={user.weakArenaInfo.place.length}
              w={312}
              h={302}
              back={frame2}
            >
              {user.weakArenaInfo.place.length && (
                <>
                  <b>
                    Вы заняли {user.weakArenaInfo?.place[1] + 1} место на Арене
                    в уровневой группе{" "}
                    {user.weakArenaInfo?.place[0] === 0
                      ? 1
                      : user.weakArenaInfo?.place[0] * 20}
                    -{(user.weakArenaInfo?.place[0] + 1) * 20 - 1}!
                  </b>

                  <span>
                    Ваши очки за неделю:{" "}
                    {roundProperty(user.weakArenaInfo?.points).toLocaleString(
                      "ru"
                    )}
                  </span>
                  <S.Rewards>
                    <RewardWrapper light={false} w={65}>
                      <div>
                        <Reward
                          name={"mana"}
                          count={
                            topsInfo["arena"].rewards[
                              user.weakArenaInfo?.place[1]
                            ].mana *
                            (user.weakArenaInfo?.place[0] + 1)
                          }
                          w={28}
                          showCount={true}
                          round={true}
                          font={13}
                          reverseColumn={true}
                          color={"#fdbb54"}
                        />
                      </div>
                    </RewardWrapper>
                    <RewardWrapper light={false} w={65}>
                      <div>
                        <Reward
                          name={"exp"}
                          count={
                            topsInfo["arena"].rewards[
                              user.weakArenaInfo?.place[1]
                            ].exp *
                            Math.pow(
                              user.weakArenaInfo?.place[0] + 1,
                              user.weakArenaInfo?.place[0] > 0 ? 3 : 1
                            )
                          }
                          w={28}
                          showCount={true}
                          round={true}
                          font={13}
                          reverseColumn={true}
                          color={"#fdbb54"}
                        />
                      </div>
                    </RewardWrapper>
                  </S.Rewards>

                  <Button
                    width={100}
                    onClick={
                      !isFetching ? getWeakReward.bind(null, "arena") : null
                    }
                  >
                    <div>Забрать</div>
                  </Button>
                </>
              )}
            </Popup>

            <Popup
              isOpen={user.weakClassroomsInfo.place.length}
              w={312}
              h={302}
              back={frame2}
            >
              {user.weakClassroomsInfo.place.length && (
                <>
                  <b>
                    Вы заняли {user.weakClassroomsInfo?.place[1] + 1} место на
                    Уроках в уровневой группе{" "}
                    {user.weakClassroomsInfo?.place[0] === 0
                      ? 1
                      : user.weakClassroomsInfo?.place[0] * 20}
                    -{(user.weakClassroomsInfo?.place[0] + 1) * 20 - 1}!
                  </b>
                  <span>
                    Ваши очки за неделю:{" "}
                    {roundProperty(
                      user.weakClassroomsInfo?.points
                    ).toLocaleString("ru")}
                  </span>
                  <S.Rewards>
                    <RewardWrapper light={false} w={65}>
                      <div>
                        <Reward
                          name={"essence"}
                          count={
                            topsInfo["classrooms"].rewards[
                              user.weakClassroomsInfo?.place[1]
                            ].essence *
                            Math.pow(
                              user.weakClassroomsInfo?.place[0] + 1,
                              user.weakClassroomsInfo?.place[0] > 0 ? 2 : 1
                            )
                          }
                          w={28}
                          showCount={true}
                          round={true}
                          font={13}
                          reverseColumn={true}
                          color={"#fdbb54"}
                        />
                      </div>
                    </RewardWrapper>
                    <RewardWrapper light={false} w={65}>
                      <div>
                        <Reward
                          name={"exp"}
                          count={
                            topsInfo["classrooms"].rewards[
                              user.weakClassroomsInfo?.place[1]
                            ].exp *
                            Math.pow(
                              user.weakClassroomsInfo?.place[0] + 1,
                              user.weakClassroomsInfo?.place[0] > 0 ? 3 : 1
                            )
                          }
                          w={28}
                          showCount={true}
                          round={true}
                          font={13}
                          reverseColumn={true}
                          color={"#fdbb54"}
                        />
                      </div>
                    </RewardWrapper>
                  </S.Rewards>

                  <Button
                    width={100}
                    onClick={
                      !isFetching
                        ? getWeakReward.bind(null, "classrooms")
                        : null
                    }
                  >
                    <div>Забрать</div>
                  </Button>
                </>
              )}
            </Popup>

            <Popup
              isOpen={
                user.ordenResources?.gold ||
                user.ordenResources?.silver ||
                user.ordenResources?.essence ||
                user.ordenResources?.talents
              }
              w={312}
              h={302}
              back={frame2}
            >
              <b>Вам выделили ресурсы из казны Вашего Ордена</b>
              <S.Rewards>
                {user.ordenResources?.gold > 0 && (
                  <RewardWrapper light={false} w={65}>
                    <div>
                      <Reward
                        name={"gold"}
                        count={user.ordenResources?.gold}
                        w={28}
                        showCount={true}
                        round={true}
                        font={13}
                        reverseColumn={true}
                        color={"#fdbb54"}
                      />
                    </div>
                  </RewardWrapper>
                )}

                {user.ordenResources?.silver > 0 && (
                  <RewardWrapper light={false} w={65}>
                    <div>
                      <Reward
                        name={"silver"}
                        count={user.ordenResources?.silver}
                        w={28}
                        showCount={true}
                        round={true}
                        font={13}
                        reverseColumn={true}
                        color={"#fdbb54"}
                      />
                    </div>
                  </RewardWrapper>
                )}

                {user.ordenResources?.essence > 0 && (
                  <RewardWrapper light={false} w={65}>
                    <div>
                      <Reward
                        name={"essence"}
                        count={user.ordenResources?.essence}
                        w={28}
                        showCount={true}
                        round={true}
                        font={13}
                        reverseColumn={true}
                        color={"#fdbb54"}
                      />
                    </div>
                  </RewardWrapper>
                )}

                {user.ordenResources?.talents > 0 && (
                  <RewardWrapper light={false} w={65}>
                    <div>
                      <Reward
                        name={"talent"}
                        count={user.ordenResources?.talents}
                        w={28}
                        showCount={true}
                        round={true}
                        font={13}
                        reverseColumn={true}
                        color={"#fdbb54"}
                      />
                    </div>
                  </RewardWrapper>
                )}
              </S.Rewards>

              <Button
                width={100}
                onClick={!isFetching ? getOrdenResources : null}
              >
                <div>Забрать</div>
              </Button>
            </Popup>

            <Popup
              isOpen={!user.raidBossInfo.isGet}
              w={312}
              h={302}
              back={frame2}
            >
              {!user.raidBossInfo.isWin && (
                <Close onClick={getRaidBossReward.bind(null, false)} />
              )}

              <b>
                {user.raidBossInfo.isWin
                  ? "Общими силами враг был повержен!"
                  : "Ученики не справились с врагом за отведённое время."}
              </b>
              <span>
                Вы нанесли{" "}
                {roundProperty(user.raidBossInfo.damage).toLocaleString("ru")}{" "}
                урона ({(user.raidBossInfo.percent * 100).toFixed(3)}%)
              </span>
              {user.raidBossInfo.isWin && (
                <>
                  <S.Rewards>
                    <RewardWrapper light={false} w={65}>
                      <div>
                        <Reward
                          name={"gold"}
                          count={user.raidBossInfo.rewards.gold}
                          w={28}
                          showCount={true}
                          round={true}
                          font={13}
                          reverseColumn={true}
                          color={"#fdbb54"}
                        />
                      </div>
                    </RewardWrapper>

                    <RewardWrapper light={false} w={65}>
                      <div>
                        <Reward
                          name={"silver"}
                          count={user.raidBossInfo.rewards.silver}
                          w={28}
                          showCount={true}
                          round={true}
                          font={13}
                          reverseColumn={true}
                          color={"#fdbb54"}
                        />
                      </div>
                    </RewardWrapper>
                  </S.Rewards>
                </>
              )}

              {user.raidBossInfo.isWin ? (
                <Button
                  width={100}
                  onClick={
                    !isFetching ? getRaidBossReward.bind(null, true) : null
                  }
                >
                  <div>Забрать</div>
                </Button>
              ) : (
                <Button
                  width={100}
                  onClick={
                    !isFetching ? getRaidBossReward.bind(null, false) : null
                  }
                >
                  <div>Закрыть</div>
                </Button>
              )}
            </Popup>

            <Popup
              isOpen={isAltarOpen}
              setIsOpen={setIsAltarOpen}
              w={550}
              h={320}
              back={frame8}
            >
              <Close onClick={handleAltarOpen.bind(null, false)} />
              <Altar
                user={user}
                isFetching={isFetching}
                setIsFetching={setIsFetching}
                setIsModalOpen={setIsModalOpen}
                serverTime={serverDate}
                setUser={setUser}
                setModalError={setModalError}
                app={app}
              />
            </Popup>

            <Popup
              isOpen={candyNameSent}
              setIsOpen={setCandyNameSent}
              w={281}
              h={152}
              back={frame1}
            >
              <Close
                onClick={() => {
                  setCandyNameSent("");
                }}
              />
              <b>Вы отправили Драже Сюрприз со вкусом "{candyNameSent}"</b>
            </Popup>

            <Popup
              isOpen={isModalCollection}
              setIsOpen={setIsModalCollection}
              w={317}
              h={217}
              back={frame3}
            >
              <Close
                onClick={() => {
                  setIsModalCollection(false);
                }}
              />
              {collectionName && (
                <>
                  <b>
                    Вы собрали коллекцию: "{collectionName?.name}" (
                    {collectionName?.count})
                  </b>
                  <S.Rewards>
                    <RewardWrapper light={false} w={65}>
                      <div>
                        <Reward
                          name={"exp"}
                          count={collectionName?.exp * collectionName?.count}
                          w={28}
                          showCount={true}
                          round={true}
                          font={13}
                          reverseColumn={true}
                          color={"#fdbb54"}
                        />
                      </div>
                    </RewardWrapper>

                    <RewardWrapper light={false} w={65}>
                      <div>
                        <Reward
                          name={"silver"}
                          count={collectionName?.silver * collectionName?.count}
                          w={28}
                          showCount={true}
                          round={true}
                          font={13}
                          reverseColumn={true}
                          color={"#fdbb54"}
                        />
                      </div>
                    </RewardWrapper>

                    <RewardWrapper light={false} w={65}>
                      <div>
                        <Reward
                          name={"mana"}
                          count={collectionName?.mana * collectionName?.count}
                          w={28}
                          showCount={true}
                          round={true}
                          font={13}
                          reverseColumn={true}
                          color={"#fdbb54"}
                        />
                      </div>
                    </RewardWrapper>
                  </S.Rewards>
                </>
              )}
            </Popup>

            <Popup
              isOpen={bossRandomReward?.length > 0}
              setIsOpen={setBossRandomReward}
              w={317}
              h={217}
              back={frame3}
            >
              <Close onClick={changeBossReward.bind(null, null)} />
              <b>Разблокированы предметы</b>
              <ButtonsWrapper>
                {bossRandomReward?.length > 0 &&
                  bossRandomReward.map((elem, i) => {
                    return elem.category === "broom" ||
                      elem.category === "wand" ? (
                      <img
                        key={i}
                        width={90}
                        height={90}
                        src={require(`./img/clothes/${elem.category}${
                          elem.lvl >= 1 ? `${elem.lvl + 1}` : ""
                        }/${elem.src}`)}
                        alt={elem.category}
                      />
                    ) : (
                      <img
                        key={i}
                        width={90}
                        height={90}
                        src={require(`./img/clothes/${elem.category}${
                          elem.lvl >= 1 ? `${elem.lvl + 1}` : ""
                        }/${user.sex}/${elem.src}`)}
                        alt={elem.category}
                      />
                    );
                  })}
              </ButtonsWrapper>
            </Popup>

            <Popup
              isOpen={isLvlUpOpen}
              setIsOpen={setIsLvlUpOpen}
              w={317}
              h={217}
              back={frame3}
            >
              <Close onClick={() => setIsLvlUpOpen(false)} />
              <b>Вы достигли {user.lvl} уровня</b>
              <span>Восполнены энергия и здоровье</span>
              <span>+5 ко всем характеристикам</span>
              <S.Rewards>
                <RewardWrapper light={false} w={65}>
                  <div>
                    <Reward
                      name={"gold"}
                      count={user.lvl}
                      w={28}
                      showCount={true}
                      round={true}
                      font={13}
                      reverseColumn={true}
                      color={"#fdbb54"}
                    />
                  </div>
                </RewardWrapper>

                <RewardWrapper light={false} w={65}>
                  <div>
                    <Reward
                      name={"magic"}
                      count={1}
                      w={28}
                      showCount={true}
                      round={true}
                      font={13}
                      reverseColumn={true}
                      color={"#fdbb54"}
                    />
                  </div>
                </RewardWrapper>
              </S.Rewards>
            </Popup>

            <Popup
              isOpen={isPromoOpen && user.isGuide}
              w={312}
              h={302}
              back={frame2}
            >
              <b>Вы активировали промокод</b>
              <S.Rewards>
                {activePromo !== null &&
                  activePromo.prizes.map((el, i) => {
                    return (
                      <RewardWrapper key={i} light={false} w={65}>
                        <Reward
                          name={el.property}
                          count={el.count}
                          soul={el.number}
                          w={28}
                          showCount={true}
                          round={true}
                          font={13}
                          reverseColumn={true}
                          color={"#fdbb54"}
                        />
                      </RewardWrapper>
                    );
                  })}
              </S.Rewards>
              <Button width={100} onClick={!isFetching ? getPromoPrizes : null}>
                <div>Забрать</div>
              </Button>
            </Popup>

            <Popup
              isOpen={
                !user.isGroupInfo.getReward &&
                user.isGroupInfo.close &&
                user.isMemberGroup
              }
              w={317}
              h={217}
              back={frame3}
            >
              <b>Награда за подписку на группу</b>
              <S.Rewards>
                <RewardWrapper light={false} w={65}>
                  <div>
                    <Reward
                      name={"gold"}
                      count={100}
                      w={28}
                      showCount={true}
                      round={true}
                      font={13}
                      reverseColumn={true}
                      color={"#fdbb54"}
                    />
                  </div>
                </RewardWrapper>

                <RewardWrapper light={false} w={65}>
                  <div>
                    <Reward
                      name={"silver"}
                      count={5000}
                      w={28}
                      showCount={true}
                      round={true}
                      font={13}
                      reverseColumn={true}
                      color={"#fdbb54"}
                    />
                  </div>
                </RewardWrapper>
              </S.Rewards>
              <Button width={100} onClick={!isFetching ? getGroupPrizes : null}>
                <div>Забрать</div>
              </Button>
            </Popup>

            <GroupInfo
              isOpen={!user.isGroupInfo.close && user.isGuide}
              setModalError={setModalError}
              isFetching={isFetching}
              app={app}
              setUser={setUser}
              user={user}
              closeGroupInfo={closeGroupInfo}
            />

            <DayReward
              isOpen={
                !user.dayPrizes.todayIsGet &&
                user.isGuide &&
                user.isGroupInfo.close
              }
              setModalError={setModalError}
              isFetching={isFetching}
              setIsFetching={setIsFetching}
              app={app}
              setUser={setUser}
              user={user}
              serverTime={serverDate}
              getDayPrizes={getDayPrizes}
            />

            <Popup
              isOpen={modalError}
              setIsOpen={setModalError}
              w={281}
              h={152}
              back={frame1}
              z={2}
            >
              <Close
                onClick={() => {
                  setModalError("");
                }}
              />
              <b>{modalError.toString()}</b>
            </Popup>

            <div id="modals" />

            <S.MoveVideo
              ref={moveVideoRef}
              preload="metadata"
              isOpen={isMoving}
              poster={movePoster}
              width={900}
              height={700}
              autoPlay={true}
              muted={true}
              loop={true}
              playsInline={true}
              webkit-playsinline={true}
              controls={false}
              src={moveVideo}
            >
              <source src={moveVideo} type="video/mp4" />
            </S.MoveVideo>

            <Guide
              app={app}
              userId={user._id}
              setUser={setUser}
              isOpen={!user.isGuide && user.faculty}
              setLeftMenu={setLeftMenu}
              leftMenuArray={leftMenuArray}
              setIsFetching={setIsFetching}
              setModalError={setModalError}
              serverTime={serverDate}
              isFetching={isFetching}
            />

            <Loader isFetching={isFetching} />
          </S.AppRootWrapper>
        </>
      )}
    </UserContext.Provider>
  );
};

export default App;
