import React, { useEffect, useRef, useState } from "react";
import * as S from "./Tops.styles";
import { CentredWrapper, Close } from "../../App.styles";
import { roundPropertyLow9, roundProperty, secondsToDhm } from "../../helpers";
import { topsInfo } from "../../info/data";
import Reward from "../../components/Reward/Reward";
import imgCenter from "../../img/boss/CenterPart.png";
import imgTop from "../../img/boss/TopPart.png";
import imgPlace1 from "../../img/Place1.png";
import imgPlace2 from "../../img/Place2.png";
import imgPlace3 from "../../img/Place3.png";

import imgPlaceIcon1 from "../../img/menuIcons/Place1Icon.png";
import imgPlaceIcon2 from "../../img/menuIcons/Place2Icon.png";
import imgPlaceIcon3 from "../../img/menuIcons/Place3Icon.png";

import BossesIcon from "../../img/menuIcons/BossesIcon.png";
import GamesIcon from "../../img/menuIcons/GamesIcon.png";
import ClassroomsIcon from "../../img/menuIcons/ClassroomsIcon.png";
import ArenaIcon from "../../img/menuIcons/ArenaIcon.png";

const places = [imgPlace1, imgPlace2, imgPlace3];

import {
  CenterPart,
  TopPart,
} from "../../components/BossInterface/BossInterface.styles";
import scrollButtonImg from "../../img/ScrollButton.png";
import { Button } from "../Arena/Arena.styles";
import { MenuRight, MenuElemRight } from "../Home/Home.styles";
import { RewardWrapper } from "../../components/DayReward/DayReward.styles";
import Popup from "../../components/Popup/Popup";
import frame7 from "../../img/Frame7.png";
import place1 from "../../img/Place1.png";
import place2 from "../../img/Place2.png";
import place3 from "../../img/Place3.png";
import { createPortal } from "react-dom";

const step = 20;

const placesNames = ["Золото", "Серебро", "Бронза"];

const cups = [
  {
    type: "bosses",
    img: "Bosses.png",
    width: 67,
    height: 291,
    left: 280,
    top: 144,

    widthHover: 67,
    heightHover: 298,
    leftHover: 280,
    topHover: 137,

    leftAvatar: 190,
    topAvatar: 240,
  },
  {
    type: "games",
    img: "Games.png",
    width: 90,
    height: 90,
    left: 405,
    top: 116,

    widthHover: 90,
    heightHover: 92,
    leftHover: 405,
    topHover: 114,

    leftAvatar: 535,
    topAvatar: 120,
  },
  {
    type: "classrooms",
    img: "Classrooms.png",
    width: 90,
    height: 90,
    left: 412,
    top: 232,

    widthHover: 90,
    heightHover: 90,
    leftHover: 411,
    topHover: 232,

    leftAvatar: 535,
    topAvatar: 240,
  },
  {
    type: "arena",
    img: "Arena.png",
    width: 90,
    height: 90,
    left: 410,
    top: 354,

    widthHover: 90,
    heightHover: 92,
    leftHover: 410,
    topHover: 354,

    leftAvatar: 535,
    topAvatar: 362,
  },
];

function Tops({
  me,
  changeOptionsLocation,
  moveToUserRoom,
  serverTime,
  stage,
  setStage,
  setAdditionalPanel,
  allUsers,
  admin,
  reloadUsersInfo,
}) {
  const [leftTimeString, setLeftTimeString] = useState("0д:00ч:00м");
  const [lastWeakTop, setLastWeakTop] = useState({});
  const [topInfo, setTopInfo] = useState("");
  const [meTop, setMeTop] = useState({ place: -1 });
  const [activeTop, setActiveTop] = useState("");
  const [winPlace, setWinPlace] = useState(0);
  const [topUsers, setTopUsers] = useState([]);
  const [domReady, setDomReady] = React.useState(false);

  const stages = useRef(null);
  const maxLvl = useRef(null);

  useEffect(() => {
    setDomReady(true);
    changeOptionsLocation("topsRoom");
    setStage(Math.floor(me.lvl / 20) + 1);

    return () => {
      setAdditionalPanel("");
    };
  }, []);

  useEffect(() => {
    if (admin) {
      const top = {
        arena: [{}, {}, {}],
        bosses: [{}, {}, {}],
        classrooms: [{}, {}, {}],
        games: [{}, {}, {}],
      };

      Object.entries({ ...admin.lastWeakTop }).forEach((elem) => {
        elem[1].map((el, i) => {
          Object.entries(el).map((topEl) => {
            top[elem[0]][i][topEl[0]] = allUsers.find(
              (user) => user.email == topEl[1]
            );
          });
        });
      });

      // let topString = "";
      //
      // Object.entries({ ...top }).forEach((elem) => {
      //   elem[1].forEach((el, i) => {
      //     Object.entries(el).forEach((topEl) => {
      //       if (i < 1) {
      //         topString += `\n ${elem[0]}, ${
      //           step * topEl[0] < 1 ? 1 : step * topEl[0]
      //         }-${(+topEl[0] + 1) * step - 1}: @id${topEl[1].email}(${
      //           topEl[1].name?.firstName
      //         } ${topEl[1].name?.lastName})`;
      //       }
      //     });
      //   });
      // });
      //
      // console.log(topString);

      setLastWeakTop(top);
    }
  }, [admin, allUsers]);

  useEffect(() => {
    if (allUsers.length) {
      maxLvl.current = allUsers.sort((a, b) => b.lvl - a.lvl)[0].lvl;
      stages.current =
        Math.floor(maxLvl.current % step) === 0
          ? Math.floor(maxLvl.current / step)
          : Math.floor(maxLvl.current / step) + 1;

      if (activeTop) {
        const array = allUsers
          .filter(
            (user) =>
              user.lvl < step * stage &&
              user.lvl >= step * (stage - 1) &&
              user[topsInfo[activeTop].property] > 0
          )
          .sort((a, b) => {
            if (
              b[topsInfo[activeTop].property] ===
              a[topsInfo[activeTop].property]
            ) {
              if (activeTop === "bosses") {
                return b.weakDamage - a.weakDamage;
              }
              if (activeTop === "arena") {
                return (
                  b.arena.countWins +
                  b.arena.countDefWins -
                  (a.arena.countWins + a.arena.countDefWins)
                );
              }
              if (activeTop === "games") {
                return (
                  b.game21.countWins +
                  b.diceGame.lvl -
                  (a.game21.countWins + a.diceGame.lvl)
                );
              }
              if (activeTop === "classrooms") {
                return (
                  b.classrooms.reduce((acc, cur) => acc + cur.done, 0) -
                  a.classrooms.reduce((acc, cur) => acc + cur.done, 0)
                );
              }
            }
            return (
              b[topsInfo[activeTop].property] - a[topsInfo[activeTop].property]
            );
          });

        const index = array.findIndex((elem, i) => me.email === elem.email);

        setMeTop({
          place: index,
        });

        setTopUsers(array.slice(0, 100));
      }
    }
  }, [allUsers, stage, activeTop]);

  useEffect(() => {
    if (serverTime) {
      const d1 = new Date(serverTime);
      const offsetOld = d1.getTimezoneOffset();
      const offsetMoscow = -180;
      const d2 = new Date(
        serverTime + offsetOld * 60 * 1000 - offsetMoscow * 60 * 1000
      );

      const time = getNextDayOfTheWeek(d2);
      setLeftTimeString(
        secondsToDhm(
          (time -
            serverTime -
            offsetOld * 60 * 1000 +
            offsetMoscow * 60 * 1000) /
            1000
        )
      );
    }
  }, [serverTime]);

  useEffect(() => {
    if (activeTop) {
      setAdditionalPanel("blur");
    } else {
      setAdditionalPanel("");
    }
  }, [activeTop]);

  function changeTopInfo(name) {
    setTopInfo(name);
  }

  function changeTop(name) {
    setActiveTop(name);
  }

  function getNextDayOfTheWeek(now) {
    const result = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() + ((7 + 1 - now.getDay()) % 7),
      0,
      0
    );
    if (result < now) result.setDate(result.getDate() + 7);

    return result.getTime();
  }

  function handleClickUser(user) {
    moveToUserRoom(user);
  }

  return (
    <>
      <CenterPart>
        <img src={imgCenter} alt="интерфейс" />
      </CenterPart>

      {activeTop && (
        <TopPart>
          <img src={imgTop} alt="интерфейс" />
        </TopPart>
      )}

      <CentredWrapper>
        {activeTop ? (
          <S.Wrapper>
            <S.InfoTop>
              <div>
                <Button onClick={reloadUsersInfo} width={80}>
                  <div>Обновить</div>
                </Button>

                <Button onClick={changeTopInfo.bind(null, "info")} width={80}>
                  <div>Инфо</div>
                </Button>
                <span>{topsInfo[activeTop].name}</span>
              </div>
              <div>До завершения: {leftTimeString}</div>
            </S.InfoTop>
            <S.TopsContent>
              <S.Names>
                <span></span>
                <span>Игрок</span>
                <span>Ник</span>
                <span>Ур.</span>
                <span>Очки</span>
                <span>Награда</span>
              </S.Names>

              <S.Tops>
                {topUsers.length > 0 &&
                  topUsers.map((user, i) => {
                    return (
                      <S.Top isWin={i < 3} key={user._id}>
                        <S.Place>
                          {i < 3 ? (
                            <img
                              src={places[i]}
                              width={38}
                              height={38}
                              alt="место"
                            />
                          ) : (
                            i + 1
                          )}
                        </S.Place>
                        <S.Avatar
                          isMe={user.email === me.email}
                          onClick={
                            user.email !== me.email
                              ? handleClickUser.bind(null, user)
                              : null
                          }
                        >
                          <img
                            src={user.photo}
                            width={35}
                            height={35}
                            alt="аватар"
                          />
                        </S.Avatar>
                        <div>
                          <span>
                            {user.orden?.tag ? `[${user.orden?.tag}] ` : ""}
                          </span>
                          <span>
                            {user.nickName
                              ? user.nickName
                              : `${user.name?.firstName} ${user.name?.lastName}`}
                          </span>
                        </div>
                        <span>{user.lvl}</span>
                        <span>
                          {roundPropertyLow9(
                            user[topsInfo[activeTop].property]
                          )?.toLocaleString("ru")}
                        </span>
                        <span>
                          {i < 10 ? (
                            <S.Rewards>
                              {Object.entries(
                                topsInfo[activeTop].rewards[i]
                              ).map((prize, i) => {
                                return (
                                  <Reward
                                    button={true}
                                    key={i}
                                    count={roundProperty(
                                      prize[1] *
                                        Math.pow(
                                          stage,
                                          prize[0] === "exp"
                                            ? stage > 1
                                              ? 3
                                              : 1
                                            : prize[0] === "essence"
                                            ? stage > 1
                                              ? 2
                                              : 1
                                            : 1
                                        )
                                    )}
                                    name={prize[0]}
                                    w={15}
                                  />
                                );
                              })}
                            </S.Rewards>
                          ) : (
                            "—"
                          )}
                        </span>
                      </S.Top>
                    );
                  })}
              </S.Tops>
            </S.TopsContent>
            <S.MeTop>
              <S.Top meTop={true}>
                <S.Place>
                  {meTop.place > -1 ? (
                    meTop.place < 3 ? (
                      <img
                        src={places[meTop.place]}
                        width={38}
                        height={38}
                        alt="место"
                      />
                    ) : meTop.place >= 100 ? (
                      "100+"
                    ) : (
                      meTop.place + 1
                    )
                  ) : (
                    "—"
                  )}
                </S.Place>
                <S.Avatar isme={true}>
                  <img src={me.photo} width={35} height={35} alt="аватар" />
                </S.Avatar>
                <div>
                  <span>{me.orden?.tag ? `[${me.orden?.tag}] ` : ""}</span>
                  <span>
                    {me.nickName
                      ? me.nickName
                      : `${me.name?.firstName} ${me.name?.lastName}`}
                  </span>
                </div>
                <span>{me.lvl}</span>
                <span>
                  {roundPropertyLow9(
                    me[topsInfo[activeTop].property]
                  )?.toLocaleString("ru")}
                </span>
                <span>
                  {meTop.place < 10 && meTop.place > -1 ? (
                    <S.Rewards>
                      {Object.entries(
                        topsInfo[activeTop].rewards[meTop.place]
                      ).map((prize, i) => {
                        return (
                          <Reward
                            key={i}
                            button={true}
                            count={roundProperty(
                              prize[1] *
                                Math.pow(
                                  stage,
                                  prize[0] === "exp"
                                    ? stage > 1
                                      ? 3
                                      : 1
                                    : prize[0] === "essence"
                                    ? stage > 1
                                      ? 2
                                      : 1
                                    : 1
                                )
                            )}
                            name={prize[0]}
                            w={15}
                          />
                        );
                      })}
                    </S.Rewards>
                  ) : (
                    "—"
                  )}
                </span>
              </S.Top>
            </S.MeTop>
          </S.Wrapper>
        ) : (
          cups.map((elem, i) => {
            return (
              <React.Fragment key={i}>
                <S.Cup
                  back={require(`../../img/tops/${winPlace + 1}/${elem.img}`)}
                  {...elem}
                />

                <S.CupHover
                  onClick={() => {
                    setActiveTop(elem.type);
                  }}
                  back={require(`../../img/tops/hover/${elem.img}`)}
                  {...elem}
                />

                <S.AvatarTop
                  left={elem.leftAvatar}
                  top={elem.topAvatar}
                  isMe={
                    me.email ===
                      lastWeakTop[elem.type]?.[winPlace]?.[stage - 1]?.email ||
                    !lastWeakTop[elem.type]?.[winPlace]?.[stage - 1]
                  }
                  onClick={
                    lastWeakTop[elem.type]?.[winPlace]?.[stage - 1] &&
                    me.email !==
                      lastWeakTop[elem.type]?.[winPlace]?.[stage - 1]?.email
                      ? handleClickUser.bind(
                          null,
                          lastWeakTop[elem.type]?.[winPlace]?.[stage - 1]
                        )
                      : null
                  }
                >
                  {lastWeakTop[elem.type]?.[winPlace]?.[stage - 1] && (
                    <img
                      src={
                        lastWeakTop[elem.type]?.[winPlace]?.[stage - 1]?.photo
                      }
                      width={70}
                      height={70}
                      alt="аватар"
                    />
                  )}
                  <div>
                    <img
                      src={
                        winPlace === 0
                          ? place1
                          : winPlace === 1
                          ? place2
                          : place3
                      }
                      width={34}
                      height={34}
                      alt={"медаль"}
                    />
                  </div>
                </S.AvatarTop>
              </React.Fragment>
            );
          })
        )}

        {activeTop && (
          <S.TopButton>
            <Button
              width={130}
              onClick={() => {
                setActiveTop("");
              }}
            >
              <div>Назад к наградам</div>
            </Button>
          </S.TopButton>
        )}

        <S.Stage>
          <S.ButtonLeft
            limit={stage <= 1}
            onClick={() => {
              stage > 1 ? setStage((prev) => prev - 1) : null;
            }}
          >
            <img width={25} height={25} src={scrollButtonImg} alt="лево" />
          </S.ButtonLeft>

          <S.ButtonValue>
            <div>
              {step * (stage - 1) < 1 ? 1 : step * (stage - 1)} -{" "}
              {step * stage - 1} ур.
            </div>
          </S.ButtonValue>

          <S.ButtonRight
            limit={stage >= stages.current}
            onClick={() => {
              stage < stages.current ? setStage((prev) => prev + 1) : null;
            }}
          >
            <img width={25} height={25} src={scrollButtonImg} alt="право" />
          </S.ButtonRight>
        </S.Stage>
      </CentredWrapper>

      {domReady
        ? createPortal(
            activeTop ? (
              <MenuRight>
                <MenuElemRight active={activeTop === "bosses"} name={"Боссы"}>
                  <img width={80} height={80} src={BossesIcon} alt={"боссы"} />
                  <div onClick={changeTop.bind(null, "bosses")} />
                </MenuElemRight>

                <MenuElemRight active={activeTop === "arena"} name={"Арена"}>
                  <img width={80} height={80} src={ArenaIcon} alt={"арена"} />
                  <div onClick={changeTop.bind(null, "arena")} />
                </MenuElemRight>

                <MenuElemRight
                  active={activeTop === "classrooms"}
                  name={"Уроки"}
                >
                  <img
                    width={80}
                    height={80}
                    src={ClassroomsIcon}
                    alt={"уроки"}
                  />
                  <div onClick={changeTop.bind(null, "classrooms")} />
                </MenuElemRight>

                <MenuElemRight active={activeTop === "games"} name={"Игры"}>
                  <img width={80} height={80} src={GamesIcon} alt={"игры"} />
                  <div onClick={changeTop.bind(null, "games")} />
                </MenuElemRight>
              </MenuRight>
            ) : (
              <MenuRight>
                <MenuElemRight active={winPlace === 0} name={placesNames[0]}>
                  <img
                    width={80}
                    height={80}
                    src={imgPlaceIcon1}
                    alt={"золото"}
                  />
                  <div
                    onClick={() => {
                      setWinPlace(0);
                    }}
                  />
                </MenuElemRight>

                <MenuElemRight active={winPlace === 1} name={placesNames[1]}>
                  <img
                    width={80}
                    height={80}
                    src={imgPlaceIcon2}
                    alt={"серебро"}
                  />
                  <div
                    onClick={() => {
                      setWinPlace(1);
                    }}
                  />
                </MenuElemRight>

                <MenuElemRight active={winPlace === 2} name={placesNames[2]}>
                  <img
                    width={80}
                    height={80}
                    src={imgPlaceIcon3}
                    alt={"бронза"}
                  />
                  <div
                    onClick={() => {
                      setWinPlace(2);
                    }}
                  />
                </MenuElemRight>
              </MenuRight>
            ),
            document.querySelector("#menuRight")
          )
        : null}

      <Popup
        isOpen={topInfo && activeTop}
        setIsOpen={setTopInfo}
        w={700}
        h={410}
        back={frame7}
        justify={"start"}
      >
        <Close onClick={changeTopInfo.bind(null, "")} />

        {activeTop && (
          <>
            <S.ButtonsWrapperInfo>
              <Button
                disabled={topInfo === "info"}
                onClick={changeTopInfo.bind(null, "info")}
              >
                <div>Правила</div>
              </Button>

              <Button
                disabled={topInfo === "reward"}
                onClick={changeTopInfo.bind(null, "reward")}
              >
                <div>Награды</div>
              </Button>
            </S.ButtonsWrapperInfo>

            <S.Line />

            {topInfo === "info" && (
              <S.InfoText>
                <S.InfoName>
                  <span>{topsInfo[activeTop].name}</span>
                </S.InfoName>

                {topsInfo[activeTop].strings.map((elem, i) => {
                  return <span key={i}>{elem}</span>;
                })}
              </S.InfoText>
            )}

            {topInfo === "reward" && (
              <>
                <S.TopRewardWrapper>
                  {topsInfo[activeTop].rewards.map((elem, i) => {
                    if (i < 3) {
                      return (
                        <S.TopRewardInfo key={i}>
                          <span>{i + 1} место</span>

                          <div>
                            <RewardWrapper light={false} w={65}>
                              <div>
                                <Reward
                                  name={topsInfo[activeTop].rewardName}
                                  count={
                                    elem[topsInfo[activeTop].rewardName] *
                                    Math.pow(
                                      stage,
                                      topsInfo[activeTop].rewardName ===
                                        "essence"
                                        ? stage > 1
                                          ? 2
                                          : 1
                                        : 1
                                    )
                                  }
                                  w={28}
                                  showCount={true}
                                  round={true}
                                  font={13}
                                  reverseColumn={true}
                                  color={"#fdbb54"}
                                />
                              </div>
                            </RewardWrapper>

                            <RewardWrapper light={false} w={65}>
                              <div>
                                <Reward
                                  name={"exp"}
                                  count={elem.exp * Math.pow(stage, 3)}
                                  w={28}
                                  showCount={true}
                                  round={true}
                                  font={13}
                                  reverseColumn={true}
                                  color={"#fdbb54"}
                                />
                              </div>
                            </RewardWrapper>
                          </div>
                        </S.TopRewardInfo>
                      );
                    }
                  })}
                </S.TopRewardWrapper>

                <S.TopRewardWrapper>
                  {topsInfo[activeTop].rewards.map((elem, i) => {
                    if (i >= 3) {
                      return (
                        <S.TopRewardInfo key={i}>
                          <span>{i + 1} место</span>

                          <div>
                            <RewardWrapper light={false} w={65}>
                              <div>
                                <Reward
                                  name={topsInfo[activeTop].rewardName}
                                  count={
                                    elem[topsInfo[activeTop].rewardName] *
                                    Math.pow(
                                      stage,
                                      topsInfo[activeTop].rewardName ===
                                        "essence"
                                        ? stage > 1
                                          ? 2
                                          : 1
                                        : 1
                                    )
                                  }
                                  w={28}
                                  showCount={true}
                                  round={true}
                                  font={13}
                                  reverseColumn={true}
                                  color={"#fdbb54"}
                                />
                              </div>
                            </RewardWrapper>

                            <RewardWrapper light={false} w={65}>
                              <div>
                                <Reward
                                  name={"exp"}
                                  count={
                                    elem.exp *
                                    Math.pow(stage, stage > 1 ? 3 : 1)
                                  }
                                  w={28}
                                  showCount={true}
                                  round={true}
                                  font={13}
                                  reverseColumn={true}
                                  color={"#fdbb54"}
                                />
                              </div>
                            </RewardWrapper>
                          </div>
                        </S.TopRewardInfo>
                      );
                    }
                  })}
                </S.TopRewardWrapper>
              </>
            )}
          </>
        )}
      </Popup>
    </>
  );
}

export default React.memo(Tops);
