import React, { useState } from "react";
import bridge from "@vkontakte/vk-bridge";
import * as S from "./Ads.styles";
import adsImg from "../../img/miniIcons/Ads.png";
import Popup from "../Popup/Popup";
import frame2 from "../../img/Frame2.png";
import { Close } from "../../App.styles";
import { Button } from "../../panels/Arena/Arena.styles";
import { ImgWrapper } from "../RefUsers/RefUsers.styles";

function Ads({
  isGetAds,
  inventory,
  _id,
  isFetching,
  setUser,
  app,
  setIsFetching,
  setModalError,
  setIsAdsPlay,
  addItem,
}) {
  const [isInfoOpen, setIsInfoOpen] = useState(false);

  function openPopup() {
    setIsInfoOpen(true);
  }

  function closePopup() {
    setIsInfoOpen(false);
  }

  function showAds() {
    if (isGetAds < 10) {
      setIsFetching(true);
      setIsAdsPlay(true);
      bridge
        .send("VKWebAppShowNativeAds", { ad_format: "reward" })
        .then((data) => {
          if (data.result) {
            console.log("Реклама показана");

            let inventoryInfo = [...inventory];

            inventoryInfo = addItem(
              1,
              {
                name: "+50%",
                category: "hp",
                value: 50,
                percent: true,
                time: 0,
              },
              inventoryInfo
            );

            app
              .service("users")
              .patch(
                _id,
                {
                  $inc: {
                    isGetAds: 1,
                    "dayQuest.ads": 1,
                  },
                  inventory: inventoryInfo,
                  common: true,
                },
                {
                  query: {
                    $select: [
                      "_id",
                      "email",
                      "isGetAds",
                      "inventory",
                      "dayQuest",
                    ],
                  },
                }
              )
              .then((data) => {
                setIsAdsPlay(false);
                setUser((prev) => ({ ...prev, ...data }));
                setIsFetching(false);
                setModalError("Зелье здоровья добавлено в инвентарь");
              })
              .catch((e) => {
                setIsAdsPlay(false);
                setIsFetching(false);
                console.log(e);
              });
          } else {
            setIsFetching(false);
            setIsAdsPlay(false);
            setModalError("Ошибка при просмотре рекламы");
            console.log("Ошибка при показе");
          }
        })
        .catch((error) => {
          setIsFetching(false);
          setIsAdsPlay(false);
          setModalError("Ошибка при загрузке рекламы");
          console.log(error);
        });
    } else {
      setIsFetching(false);
      setIsAdsPlay(false);
      setModalError("Рекламный ролик будет доступен в следующем часу");
    }
  }

  return (
    <S.Wrapper>
      <ImgWrapper onClick={openPopup}>
        <img width={15} height={15} src={adsImg} alt="реклама" />
      </ImgWrapper>

      <Popup
        isOpen={isInfoOpen}
        setIsOpen={closePopup}
        w={312}
        h={302}
        back={frame2}
      >
        {isInfoOpen && (
          <>
            <Close onClick={closePopup} />
            <b>Рекламные ролики:</b>
            <span>
              Каждый час можно просматривать до 10 рекламных роликов и получать
              зелья 50% восстановления здоровья
            </span>
            <span>
              Для получения рекламы следует отключить блокировщик рекламы
            </span>
            <Button onClick={!isFetching ? showAds : null}>
              <div>Смотреть рекламу</div>
            </Button>
          </>
        )}
      </Popup>
    </S.Wrapper>
  );
}

export default React.memo(Ads);
