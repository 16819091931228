import React from "react";
import * as S from "./BossInterface.styles";
import imgBottom from "../../img/boss/BottomPart.png";
import imgLog from "../../img/boss/Log.png";

function BossInterface({ bossLog }) {
  return (
    <S.Wrapper>
      <S.LeftPart>
        <img src={imgBottom} alt="интерфейс" width={300} height={62} />
      </S.LeftPart>
      <S.LogPart isActive={bossLog}>
        <img src={imgLog} alt="интерфейс" width={296} height={302} />
      </S.LogPart>
    </S.Wrapper>
  );
}

export default React.memo(BossInterface);
