import React, { useEffect, useState } from "react";
import * as S from "./CollectionsHistory.styles";
import { collectionsArray } from "../../info/data";
import { RewardWrapper } from "../DayReward/DayReward.styles";
import { CollectionElem } from "../Collection/Collection.styles";

function CollectionsHistory({
  me,
  allUsers,
  collectionsHistory,
  moveToUserRoom,
  handleClose,
}) {
  const [history, setHistory] = useState([]);

  useEffect(() => {
    if (collectionsHistory.length) {
      setHistory([...collectionsHistory].reverse());
    }
  }, [collectionsHistory]);

  function handleClickUser(user) {
    moveToUserRoom(user);
    handleClose();
  }

  return (
    <S.Wrapper>
      {history.length > 0 &&
        history.map((elem, i) => {
          const user = allUsers.find((user) => user._id === elem.userId);
          return (
            user && (
              <div key={history.length - i}>
                <S.Avatar
                  isMe={user.email === me.email}
                  onClick={
                    user.email !== me.email
                      ? handleClickUser.bind(null, user)
                      : null
                  }
                >
                  <img src={user.photo} width={34} height={34} alt="аватар" />
                </S.Avatar>
                <div>
                  <span>{user.orden?.tag ? `[${user.orden?.tag}] ` : ""}</span>
                  <span>
                    {user.nickName
                      ? user.nickName
                      : `${user.name?.firstName} ${user.name?.lastName}`}
                  </span>
                </div>
                <span>
                  {
                    collectionsArray[elem.collectionIndex].counts[
                      elem.elemIndex
                    ].name
                  }
                </span>
                <span>
                  <RewardWrapper key={i} light={false} w={38}>
                    <CollectionElem>
                      <img
                        src={require(`../../img/collection/${
                          elem.collectionIndex
                        }/${elem.elemIndex + 1}.png`)}
                        width={16}
                        height={16}
                        alt="коллекция"
                      />
                      <span />
                    </CollectionElem>
                  </RewardWrapper>
                </span>

                <span> {new Date(elem.time).toLocaleString()}</span>
              </div>
            )
          );
        })}
    </S.Wrapper>
  );
}

export default CollectionsHistory;
