import React, { useEffect, useState } from "react";

import * as S from "./GreenHouse.styles";
import { CentredWrapper, Close } from "../../App.styles";
import waterImg from "../../img/greenHouse/Water.png";
import Popup from "../../components/Popup/Popup";
import frame7 from "../../img/Frame7.png";
import Reward from "../../components/Reward/Reward";
import {
  roundConsumablesFixed,
  secondsToDhm,
  secondsToHmShort,
} from "../../helpers";
import { Icon16InfoOutline } from "@vkontakte/icons";
import frame1 from "../../img/Frame1.png";
import shovel from "../../img/greenHouse/Shovel.png";
import waterNonActive from "../../img/greenHouse/WaterNonActive.png";
import waterActive from "../../img/greenHouse/WaterActive.png";
import imgInfo from "../../img/greenHouse/Info.png";
import { ButtonsWrapper } from "../../components/ActiveBoss/ActiveBoss.styles";
import frame8 from "../../img/Frame8.png";
import { InfoWater } from "../PotionLab/PotionLab.styles";
import { BottomPrice, BottomPrices, Button } from "../Arena/Arena.styles";
import frame6 from "../../img/Frame6.png";
import { MenuRight, MenuElemRight } from "../Home/Home.styles";
import helperIcon from "../../img/menuIcons/Helper.png";
import { createPortal } from "react-dom";

const places = [
  {
    x: 682,
    y: 370,
    w: 95,
    h: 100,
    src: "2.png",
  },
  {
    x: 654,
    y: 157,
    w: 105,
    h: 63,
    src: "1.png",
  },
  {
    x: 344,
    y: 156,
    w: 76,
    h: 70,
    src: "8.png",
  },
  {
    x: 482,
    y: 132,
    w: 155,
    h: 52,
    src: "7.png",
  },
  {
    x: 372,
    y: 311,
    w: 102,
    h: 49,
    src: "3.png",
  },
  {
    x: 273,
    y: 310,
    w: 105,
    h: 47,
    src: "4.png",
  },
  {
    x: 178,
    y: 334,
    w: 46,
    h: 47,
    src: "6.png",
  },
  {
    x: 127,
    y: 340,
    w: 52,
    h: 55,
    src: "5.png",
  },
  {
    x: 319,
    y: 403,
    w: 38,
    h: 57,
    src: "10.png",
  },
  {
    x: 244,
    y: 422,
    w: 46,
    h: 71,
    src: "9.png",
  },
];

const lvlOnLvlNeed = [1000, 2000, 4000, 8000, 16000, 32000, 64000];

const goldUp = 5;

const plantsInfo = [
  {
    name: "Мухомор",
    mana: 8,
    water: 4,
    goldChance: 6,
    learnNeed: 0,
    energy: 0,
    gold: 1,
  },
  {
    name: "Прыгающая луковица",
    mana: 12,
    water: 6,
    goldChance: 8,
    learnNeed: 10,
    energy: 1,
    gold: 1,
  },
  {
    name: "Слива-цеппелин",
    mana: 24,
    water: 12,
    goldChance: 10,
    learnNeed: 20,
    energy: 2,
    gold: 1,
  },
  {
    name: "Водоросли",
    mana: 40,
    water: 20,
    goldChance: 12,
    learnNeed: 40,
    energy: 3,
    gold: 2,
  },
  {
    name: "Аконит",
    mana: 50,
    water: 25,
    goldChance: 14,
    learnNeed: 80,
    energy: 4,
    gold: 2,
  },
  {
    name: "Мимбулус мимблетония",
    mana: 60,
    water: 30,
    goldChance: 16,
    learnNeed: 160,
    energy: 5,
    gold: 2,
  },
  {
    name: "Дъявольские силки",
    mana: 70,
    water: 35,
    goldChance: 18,
    learnNeed: 320,
    energy: 6,
    gold: 3,
  },
  {
    name: "Мандрагора",
    mana: 100,
    water: 50,
    goldChance: 20,
    learnNeed: 640,
    energy: 7,
    gold: 3,
  },
];

function GreenHouse({
  gold,
  userId,
  plants,
  lvl,
  growOnLvl,
  setUser,
  app,
  isModalOpen,
  isFetching,
  setIsFetching,
  changeOptionsLocation,
  setModalError,
  serverTime,
  classroomDone,
  userEnergy,
  helpers,
  bridge,
}) {
  const [helperOpen, setHelperOpen] = useState(false);
  const [timeHelp, setTimeHelp] = useState(null);
  const [helpX, setHelpX] = useState(1);
  const [plantsIsOpen, setPlantsIsOpen] = useState(false);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [plantNumber, setPlantNumber] = useState(-1);
  const [upgradeOpen, setUpgradeOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [domReady, setDomReady] = React.useState(false);

  useEffect(() => {
    setDomReady(true);
    changeOptionsLocation("greenHouse");
  }, []);

  useEffect(() => {
    if ((helpers?.greenHouse || 0) >= serverTime) {
      setHelpX(24);
      setTimeHelp(
        secondsToDhm(((helpers?.greenHouse || 0) - serverTime) / 1000)
      );
    } else {
      setHelpX(1);
      setTimeHelp(null);
    }
  }, [serverTime, helpers]);

  function handleUpgradeOpen() {
    setUpgradeOpen((prev) => !prev);
  }

  function openPlants(number = -1) {
    setPlantNumber(number);
    setPlantsIsOpen((prev) => !prev);
  }

  function changeInfoOpen(info) {
    setIsInfoOpen(info);
  }

  function changeHelperOpen(open) {
    setHelperOpen(open);
  }

  function buyHelper({ days, price }) {
    setIsFetching(true);

    bridge
      .send("VKWebAppShowOrderBox", {
        type: "item",
        item: JSON.stringify({
          helpersGreenHouse: days,
          g: price,
        }),
      })
      .then((data) => {
        if (data.success) {
          console.log("успех", data);
        }
        setIsFetching(false);
      })
      .catch((error) => {
        console.log(error);
        setIsFetching(false);
      });
  }

  function getPrize(number) {
    setIsFetching(true);
    setIsLoading(true);
    const isLvlUp =
      growOnLvl + 1 * (plants[number].helpX || 1) >= lvlOnLvlNeed[lvl - 1];
    const points = isLvlUp
      ? growOnLvl + 1 * (plants[number].helpX || 1) - lvlOnLvlNeed[lvl - 1]
      : growOnLvl + 1 * (plants[number].helpX || 1);
    app
      .service("users")
      .patch(
        userId,
        {
          [`greenHouse.plants.${number}.helpX`]: 1,
          [`greenHouse.plants.${number}.activePlant`]: -1,
          [`greenHouse.plants.${number}.isWater`]: false,
          [`greenHouse.plants.${number}.isGold`]: false,
          "greenHouse.growOnLvl": points,
          $inc: {
            mana: plants[number].isGold
              ? 0
              : plants[number].isWater
              ? plantsInfo[plants[number].activePlant].mana *
                  (plants[number].helpX || 1) +
                plantsInfo[plants[number].activePlant].water *
                  (plants[number].helpX || 1)
              : plantsInfo[plants[number].activePlant].mana *
                (plants[number].helpX || 1),
            gold: plants[number].isGold
              ? plantsInfo[plants[number].activePlant].gold *
                (plants[number].helpX || 1)
              : 0,
            "greenHouse.lvl": isLvlUp ? 1 : 0,
            "dayQuest.plantsWater":
              plants[number].helpX > 1 ? plants[number].helpX : 0,
          },
          field: serverTime,
        },
        {
          query: {
            $select: ["_id", "email", "greenHouse", "mana", "gold", "dayQuest"],
          },
        }
      )
      .then((data) => {
        setPlantsIsOpen(false);
        setIsLoading(false);
        setUser((prev) => ({ ...prev, ...data }));
        setIsFetching(false);
      })
      .catch((e) => {
        setModalError(e);
        console.log(e);
        setPlantsIsOpen(false);
        setIsFetching(false);
        setIsLoading(false);
      });
  }

  function getPlant({ activePlant, number }) {
    if (userEnergy >= plantsInfo[activePlant].energy * helpX) {
      setIsFetching(true);
      setIsLoading(true);
      const rand = Math.floor(Math.random() * 100);
      const isGold = plantsInfo[activePlant].goldChance - rand > 0;
      app
        .service("users")
        .patch(
          userId,
          {
            [`greenHouse.plants.${number}.isWater`]: helpX > 1,
            [`greenHouse.plants.${number}.activePlant`]: activePlant,
            [`greenHouse.plants.${number}.endTime`]:
              serverTime + 3600000 * helpX,
            [`greenHouse.plants.${number}.waterTime`]:
              helpX > 1 ? 0 : serverTime + 1800000,
            [`greenHouse.plants.${number}.isGold`]: isGold,
            [`greenHouse.plants.${number}.helpX`]: helpX,
            $inc: {
              currentEnergy: -plantsInfo[activePlant].energy * helpX,
              "newQuest.results.grasses": helpX,
              "summerSave.results.grasses": helpX,
              "dayQuest.plantsSet": helpX,
            },
            field: serverTime,
          },
          {
            query: {
              $select: [
                "_id",
                "email",
                "greenHouse",
                "currentEnergy",
                "newQuest",
              ],
            },
          }
        )
        .then((data) => {
          setPlantsIsOpen(false);
          setIsLoading(false);
          setUser((prev) => ({ ...prev, ...data }));
          setIsFetching(false);
        })
        .catch((e) => {
          setModalError(e);
          console.log(e);
          setPlantsIsOpen(false);
          setIsFetching(false);
          setIsLoading(false);
        });
    } else {
      setModalError("Недостаточно энергии");
      console.log("Недостаточно энергии");
    }
  }

  function getWater(number) {
    setIsFetching(true);
    setIsLoading(true);
    app
      .service("users")
      .patch(
        userId,
        {
          [`greenHouse.plants.${number}.isWater`]: true,
          field: serverTime,
          $inc: {
            "dayQuest.plantsWater": 1,
          },
        },
        {
          query: {
            $select: ["_id", "email", "greenHouse", "dayQuest"],
          },
        }
      )
      .then((data) => {
        setIsLoading(false);
        setUser((prev) => ({ ...prev, ...data }));
        setIsFetching(false);
      })
      .catch((e) => {
        setModalError(e);
        console.log(e);
        setIsFetching(false);
        setIsLoading(false);
      });
  }

  function gradeLvl() {
    const price = goldUp * (lvlOnLvlNeed[lvl - 1] - growOnLvl);
    if (gold >= price) {
      setIsLoading(true);
      setIsFetching(true);
      app
        .service("users")
        .patch(
          userId,
          {
            "greenHouse.lvl": lvl + 1,
            "greenHouse.growOnLvl": 0,
            $inc: {
              gold: -price,
            },
            field: serverTime,
          },
          {
            query: {
              $select: ["_id", "email", "greenHouse", "gold"],
            },
          }
        )
        .then((data) => {
          setIsLoading(false);
          setUpgradeOpen(false);
          setUser((prev) => ({ ...prev, ...data }));
          setIsFetching(false);
        })
        .catch((e) => {
          setModalError(e);
          console.log(e);
          setIsFetching(false);
          setIsLoading(false);
        });
    } else {
      setModalError("Недостаточно золота");
      console.log("Недостаточно золота");
    }
  }

  return (
    <>
      <CentredWrapper>
        <S.Info
          top={248}
          left={502}
          width={80}
          height={90}
          onClick={changeInfoOpen.bind(null, true)}
        >
          <img src={imgInfo} alt="книга" />
        </S.Info>

        {places.map((elem, i) => {
          return (
            <React.Fragment key={i}>
              {plants[i].activePlant >= 0 ? (
                <S.Place
                  isActive={serverTime > plants[i].endTime}
                  x={elem.x}
                  y={elem.y}
                  onClick={
                    !isLoading && !isFetching && serverTime > plants[i].endTime
                      ? getPrize.bind(null, i)
                      : null
                  }
                >
                  <img
                    src={require(`../../img/greenHouse/plant${
                      plants[i].activePlant + 1
                    }Hover/${elem.src}`)}
                    alt=""
                    width={elem.w}
                    height={elem.h}
                  />
                </S.Place>
              ) : (
                <S.Place
                  x={elem.x}
                  y={elem.y}
                  isActive={true}
                  onClick={openPlants.bind(null, i)}
                >
                  <img
                    src={require(`../../img/greenHouse/hover/${elem.src}`)}
                    alt=""
                    width={elem.w}
                    height={elem.h}
                  />
                </S.Place>
              )}

              <S.InfoPlace x={elem.x} y={elem.y} width={elem.w} height={elem.h}>
                <div>
                  {plants[i].activePlant > -1 ? (
                    serverTime > plants[i].endTime ? (
                      plants[i].isGold ? (
                        <Reward
                          reverseColumn={true}
                          g={1}
                          button={true}
                          name={"gold"}
                          count={
                            plantsInfo[plants[i].activePlant].gold *
                            (plants[i].helpX || 1)
                          }
                          w={15}
                        />
                      ) : (
                        <Reward
                          reverseColumn={true}
                          g={1}
                          button={true}
                          name={"mana"}
                          count={
                            plants[i].isWater
                              ? plantsInfo[plants[i].activePlant].mana *
                                  (plants[i].helpX || 1) +
                                plantsInfo[plants[i].activePlant].water *
                                  (plants[i].helpX || 1)
                              : plantsInfo[plants[i].activePlant].mana *
                                (plants[i].helpX || 1)
                          }
                          w={12}
                        />
                      )
                    ) : (
                      <>
                        {secondsToHmShort(
                          Math.floor((plants[i].endTime - serverTime) / 1000)
                        )}
                      </>
                    )
                  ) : (
                    <img src={shovel} width={20} height={20} alt="лопата" />
                  )}
                  {plants[i].activePlant > -1 &&
                    !plants[i].isWater &&
                    serverTime <= plants[i].endTime && (
                      <S.WaterPlace
                        isActive={serverTime >= plants[i].waterTime}
                        onClick={
                          !isLoading &&
                          !isFetching &&
                          serverTime >= plants[i].waterTime
                            ? getWater.bind(null, i)
                            : null
                        }
                        percent={
                          plants[i].waterTime - serverTime <= 0
                            ? 100
                            : (
                                100 -
                                (plants[i].waterTime - serverTime) / 18000
                              ).toFixed(2)
                        }
                      >
                        <img
                          src={waterNonActive}
                          width={28}
                          height={28}
                          alt="вода"
                        />
                        <img
                          src={waterActive}
                          width={28}
                          height={28}
                          alt="вода"
                        />
                        <InfoWater>
                          {serverTime >= plants[i].waterTime ? (
                            <span>Полить растение</span>
                          ) : (
                            <>
                              <span>Полить растение:</span>
                              <span>
                                {secondsToHmShort(
                                  Math.floor(
                                    (plants[i].waterTime - serverTime) / 1000
                                  )
                                )}
                              </span>
                            </>
                          )}
                        </InfoWater>
                      </S.WaterPlace>
                    )}
                </div>
              </S.InfoPlace>
            </React.Fragment>
          );
        })}
      </CentredWrapper>

      {domReady
        ? createPortal(
            <MenuRight>
              <MenuElemRight name={"Помощник"}>
                <img width={80} height={80} src={helperIcon} alt={"помощник"} />
                <div onClick={changeHelperOpen.bind(null, true)} />
              </MenuElemRight>
            </MenuRight>,
            document.querySelector("#menuRight")
          )
        : null}

      <Popup
        w={700}
        h={410}
        back={frame7}
        isOpen={plantsIsOpen}
        setIsOpen={setPlantsIsOpen}
      >
        <Close
          onClick={() => {
            setPlantsIsOpen(false);
          }}
        />
        <S.PlantsGetWrapper>
          {plantsInfo.map((elem, i) => {
            return (
              <div key={i}>
                <S.InfoWrapper>
                  <span>{elem.name}</span>
                  <S.InfoPlant>
                    <Icon16InfoOutline />
                    <div>
                      <span>Необходимо:</span>
                      <span>Уровень: {i + 1}</span>
                      <span>
                        Уроков: {roundConsumablesFixed(classroomDone)}/
                        {elem.learnNeed}
                      </span>
                      <span>Награда:</span>
                      <span>
                        Мана: {elem.mana} (+{elem.water}{" "}
                        <img width={12} src={waterImg} alt="вода" />)
                      </span>
                      <span>
                        Золото: {elem.gold} ({elem.goldChance}%)
                      </span>
                    </div>
                  </S.InfoPlant>
                </S.InfoWrapper>
                <div>
                  <img
                    width={80}
                    height={80}
                    src={require(`../../img/greenHouse/Plant${i + 1}.png`)}
                    alt="растение"
                  />
                  <Button
                    disabled={lvl < i + 1 || classroomDone < elem.learnNeed}
                    onClick={
                      !isLoading &&
                      !isFetching &&
                      lvl >= i + 1 &&
                      classroomDone >= elem.learnNeed
                        ? getPlant.bind(null, {
                            activePlant: i,
                            number: plantNumber,
                          })
                        : null
                    }
                  >
                    <Reward
                      button={true}
                      name={"energy"}
                      count={`Посадить ${elem.energy * helpX}`}
                      w={15}
                    />
                  </Button>
                </div>
              </div>
            );
          })}
        </S.PlantsGetWrapper>
      </Popup>

      <Popup
        isOpen={isInfoOpen}
        setIsOpen={setIsInfoOpen}
        w={550}
        h={320}
        back={frame8}
      >
        <Close onClick={changeInfoOpen.bind(null, false)} />

        <b>Практика: Растениеводство {lvl} уровень</b>

        <span>
          Растения можно поливать через 30 минут после посадки, тем самым
          увеличив награду на 50%.
        </span>

        <span>
          С повышением уровня Вы сможете выращивать новые растения, а также
          прогрессировать на уроке Растениеводства, который находится на втором
          этаже замка.
        </span>

        {lvl <= lvlOnLvlNeed.length ? (
          <>
            <span>
              Выращиваний на уровень: {growOnLvl}/{lvlOnLvlNeed[lvl - 1]}
            </span>
            <Button
              width={140}
              onClick={!isFetching ? handleUpgradeOpen : null}
            >
              <div>Повысить уровень</div>
            </Button>
          </>
        ) : (
          <Button width={140} disabled={true}>
            <div>Максимальный уровень</div>
          </Button>
        )}
      </Popup>

      <Popup
        isOpen={upgradeOpen}
        setIsOpen={setUpgradeOpen}
        w={281}
        h={152}
        back={frame1}
      >
        <Close onClick={handleUpgradeOpen} />
        <b>Желаете повысить уровень растениеводства до {lvl + 1}?</b>
        <ButtonsWrapper>
          <Button
            disabled={isLoading || isFetching}
            onClick={!isLoading && !isFetching ? gradeLvl : null}
          >
            <Reward
              button={true}
              name={"gold"}
              count={`Повысить ${goldUp * (lvlOnLvlNeed[lvl - 1] - growOnLvl)}`}
              w={15}
            />
          </Button>
        </ButtonsWrapper>
      </Popup>

      <Popup
        isOpen={helperOpen}
        setIsOpen={setHelperOpen}
        w={436}
        h={320}
        back={frame6}
      >
        <Close onClick={changeHelperOpen.bind(null, false)} />
        <b>Помощник в Теплице</b>
        <span>Помощник вырастит 24 растения в горшке (24 часа)</span>
        <span>Помощник сам польёт все растения</span>
        {timeHelp && (
          <>
            <span>Оставшееся время помощника:</span>
            <span>{timeHelp}</span>
          </>
        )}
        <BottomPrice>
          {timeHelp ? (
            <span>Желаете продлить помощника?</span>
          ) : (
            <span>Доступные варианты для помощника:</span>
          )}
          <BottomPrices>
            <div>
              <span>3 суток</span>
              <Button
                width={100}
                onClick={buyHelper.bind(null, { days: 3, price: 7 })}
              >
                <div>7 голосов</div>
              </Button>
            </div>
            <div>
              <span>7 суток</span>
              <Button
                width={100}
                onClick={buyHelper.bind(null, { days: 7, price: 10 })}
              >
                <div>10 голосов</div>
              </Button>
            </div>
            <div>
              <span>30 суток</span>
              <Button
                width={100}
                onClick={buyHelper.bind(null, { days: 30, price: 30 })}
              >
                <div>30 голосов</div>
              </Button>
            </div>
          </BottomPrices>
        </BottomPrice>
      </Popup>
    </>
  );
}

export default React.memo(GreenHouse);
