import styled from "styled-components";
import { MarkerButton } from "../../components/Quests/Quests.styles";
import imgMarker3 from "../../img/Marker3Long.png";
import imgMarker2 from "../../img/Marker2.png";

export const Content = styled.div`
  position: relative;
  width: 640px;
  height: 420px;
  transform: translate(-50%, -50%);
  left: calc(50% - 2px);
  top: calc(50% - 5px);
`;

export const Tongue = styled.img`
  position: absolute;
  transform: translate(-50%, 0%);
  left: calc(50% + -6px);
  top: -25px;
`;

export const BookPages = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 100%;
  width: 100%;
  height: 100%;
  padding: 25px 20px 25px;
  & > div:nth-child(1) {
    padding-right: 20px;
  }
  & > div:nth-child(2) {
    padding-left: 20px;
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }
`;

export const ButtonsWrapper = styled.div`
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;
`;

export const Wrapper = styled.div`
  background-color: rgba(255, 255, 255, 0.75);
  width: 100%;
  padding: 10px 20px;
  color: black;
  text-align: center;
`;

export const MarkerButtonStyled = styled(MarkerButton)`
  color: #ab8168;
`;

export const Text = styled.div`
  display: flex;
  justify-content: center;
  font-family: "Bellota-Regular";
  text-align: center;
  color: ${(props) => (props.color ? props.color : "#19130d")};
  font-size: ${(props) => (props.size ? props.size : "12")}px;
  line-height: ${(props) => (props.size ? props.size : "14")}px;
  font-weight: ${(props) => (props.weight ? props.weight : "600")};
  padding-bottom: ${(props) => (props.bot ? props.bot : "0")}px;
`;

export const TextBlock = styled.div`
  width: 100%;
  padding: 4px;
  display: flex;
  justify-content: center;
  font-family: "Bellota-Regular";
  text-align: center;
  color: ${(props) => (props.color ? props.color : "#19130d")};
  font-size: ${(props) => (props.size ? props.size : "12")}px;
  line-height: ${(props) => (props.size ? props.size : "14")}px;
  font-weight: ${(props) => (props.weight ? props.weight : "600")};
  background-image: url("${(props) =>
    props.isBlock ? imgMarker3 : imgMarker2}");
  background-size: contain;
`;

export const Columns2 = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  color: #19130d;
  font-family: "Bellota-Regular";
  font-size: 12px;
  line-height: 12px;
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 2px;

    font-weight: 600;
  }
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #281707;
  margin: 2px 0;
`;

export const Picture = styled.img`
  margin: 6px 0;
`;

export const Block = styled.div`
  width: 100%;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Rewards = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: center;
  align-items: center;
  font-family: "Bellota-Regular";
  font-size: 12px;
  line-height: 12px;
`;
