import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

function Damage({ damageInfo, allUsers, scrollRef }) {
  const [arrayDamage, setArrayDamage] = useState([]);

  useEffect(() => {
    if (damageInfo.length) {
      setArrayDamage([...damageInfo].reverse().slice(0, 30));
    }
  }, [damageInfo]);

  return arrayDamage.length
    ? arrayDamage.map(({ damage, email }, i) => {
        const user = allUsers.find((user) => user.email === email);
        return (
          user && (
            <motion.div
              key={arrayDamage.length - i}
              initial={{ opacity: 0.3 }}
              whileInView={{ opacity: 1 }}
              viewport={{ root: scrollRef }}
            >
              <img
                width={22}
                height={22}
                src={user.photo}
                alt={"аватар"}
                style={{ borderRadius: "50%" }}
              />

              <div>
                <span>{user.orden?.tag ? `[${user.orden?.tag}] ` : ""}</span>
                <span>
                  {damage.evasion
                    ? user.nickName
                      ? user.nickName +
                        " промахивается и наносит " +
                        damage.value.toLocaleString("ru")
                      : user.name?.firstName +
                        " " +
                        user.name?.lastName +
                        " промахивается и наносит " +
                        damage.value.toLocaleString("ru")
                    : damage.isKrit
                    ? user.nickName
                      ? user.nickName +
                        " наносит крит " +
                        damage.value.toLocaleString("ru")
                      : user.name?.firstName +
                        " " +
                        user.name?.lastName +
                        " наносит крит " +
                        damage.value.toLocaleString("ru")
                    : user.nickName
                    ? user.nickName +
                      " наносит " +
                      damage.value.toLocaleString("ru")
                    : user.name?.firstName +
                      " " +
                      user.name?.lastName +
                      " наносит " +
                      damage.value.toLocaleString("ru")}
                </span>
              </div>
            </motion.div>
          )
        );
      })
    : null;
}

export default React.memo(Damage);
