import styled from "styled-components";
import { MarkerButton } from "../Quests/Quests.styles";

export const DiceGameWrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

export const DicesArea = styled.div`
  position: absolute;
  left: 120px;
  top: 140px;
`;

export const DiceBackArrow = styled.div`
  display: flex;
  position: absolute;
  left: 15px;
  top: 15px;
  & > div {
    & img {
      transition: 0.15s linear;
      cursor: pointer;
    }
    & img:hover {
      filter: brightness(1.2);
    }
    & img:active {
      filter: brightness(1);
    }
  }
`;

export const DiceInfo = styled.div`
  display: flex;
  position: absolute;
  right: 15px;
  top: 15px;
  & > div {
    & img {
      transition: 0.15s linear;
      cursor: pointer;
    }
    & img:hover {
      filter: brightness(1.2);
    }
    & img:active {
      filter: brightness(1);
    }
  }
`;

export const Rewards = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
`;

export const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  gap: 10px;
  align-items: start;

  & > button {
    display: flex;
    gap: 5px;
    line-height: 15px;
  }
`;

export const PrizesWrapper = styled.div`
  font-family: "Inkverse-BWDRx";
  color: #413423;
  position: relative;
  height: 370px;
  width: 350px;
  left: 282px;
  top: 53px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > span:nth-child(1) {
    font-size: 26px;
  }
  & > span:nth-child(2) {
    font-size: 20px;
  }
  & > span:nth-child(3) {
    font-size: 20px;
  }
`;

export const TopInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  & > span:nth-child(1) {
    font-size: 26px;
  }
  & > span:nth-child(2) {
    font-size: 20px;
  }
  & > span:nth-child(3) {
    font-size: 20px;
  }
`;

export const Prizes = styled.div`
  display: grid;
  width: 100%;
  padding: 10px;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  & > div {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
`;

export const Prize = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  position: relative;
  word-spacing: -3px;
  & > img,
  div {
    z-index: 2;
  }

  &::before {
    position: absolute;
    content: "";
    inset: 0;
    background-image: ${(props) =>
      props.isActive
        ? "linear-gradient(90deg, rgb(12 142 37 / 94%) 0%, rgb(10 182 42 / 85%) 15%, rgb(4 229 49 / 20%) 100%);"
        : "none"};
    z-index: 1;
    opacity: ${(props) => (props.isActive ? "1" : "0")};
    clip-path: ${(props) =>
      props.isActive
        ? "polygon(0 0, 100% 0, 100% 100%, 0 100%);"
        : " polygon(0 0, 0 0, 0 100%, 0% 100%);"};
    transition: 0.5s ease;
  }
`;

export const MarkerButtonStyled = styled(MarkerButton)`
  font-size: 14px;
  line-height: 15px;
  color: #cab189;
`;
