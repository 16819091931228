import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: end;
  align-items: center;
  & button {
    width: 100%;
  }
  & input {
    width: 100%;
  }
`;

export const Rewards = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 3px;
  justify-content: center;
  align-items: center;
`;

export const BottomButton = styled.div`
  display: flex;
  flex-direction: ${(props) => props.flex};
  gap: ${(props) => props.gap}px;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
