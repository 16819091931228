import React, { useCallback, useEffect, useRef, useState } from "react";
import * as S from "./ActiveRaidBoss.styles";
import {
  roundLvlExp,
  roundProperty,
  roundPropertyLow,
  secondsToHm,
} from "../../helpers";
import questsImg from "../../img/menuIcons/NewQuest.png";
import imgBars from "../../img/boss/HpFrame.png";
import imgHpBar from "../../img/HealthBar.png";
import imgHpBack from "../../img/boss/HpBack.png";
import Damage from "../../components/Dagame/Damage";
import DamageTop from "../../components/DamageTop/DamageTop";
import Spell from "../../components/Spell/Spell";
import bridge from "@vkontakte/vk-bridge";
import { grindData, raidBossesInfo, spellsArray } from "../../info/data";
import DamageShadow from "../../components/DagameShadow/DamageShadow";
import Healls from "../../components/Healls/Healls";
import Popup from "../Popup/Popup";
import frame3 from "../../img/Frame3.png";
import {
  Avatar,
  BarsHp,
  BarsHpBack,
  BarsPart,
  BossName,
  Button,
  ButtonsWrapper,
  ButtonValue,
  DamageLog,
  EndBossInfo,
  EndButtonsWrapper,
  EndFightWrapper,
  EndLeftPart,
  EndRightPart,
  FirstSpell,
  Hand,
  Heal,
  InfoBoss,
  LogOpen,
  LogUsers,
  LogUsersDamage,
  LoseText,
  MeInfo,
  Rewards,
  SpellWrapper,
  Time,
  TopInfo,
} from "../ActiveBoss/ActiveBoss.styles";
import Reward from "../Reward/Reward";
import { Close } from "../../App.styles";
import frame8 from "../../img/Frame8.png";
import frame6 from "../../img/Frame6.png";
import { RewardWrapper } from "../DayReward/DayReward.styles";
import frame7 from "../../img/Frame7.png";
import winRibon from "../../img/boss/WinRibon.png";
import loseRibon from "../../img/boss/LoseRibon.png";
import handImg from "../../img/boss/Hand.png";
import FinishTopBosses from "../FinishTopBosses/FinishTopBosses";
import { MenuRight, MenuElemRight } from "../../panels/Home/Home.styles";
import { ButtonLeft, ButtonRight } from "../Healls/Healls.styles";
import scrollButtonImg from "../../img/ScrollButton.png";
import timeIcon from "../../img/miniIcons/Time.png";
import { createPortal } from "react-dom";

const types = {
  entity: "Существо",
  man: "Человек",
  ghost: "Призрак",
};

function ActiveRaidBoss({
  isActive,
  isShare,
  quests,
  countAttacks,
  serverTime,
  user,
  currentHp,
  allHp,
  damage,
  percentDamage,
  bossKritChance,
  bossEvasionChance,
  damageInfo,
  prizes,
  endTime,
  damageTop,
  setUser,
  setAdminInfo,
  app,
  isFetching,
  isModalOpen,
  setIsModalOpen,
  setIsFetching,
  setIsLvlUpOpen,
  changeOptionsLocation,
  moveLocation,
  setModalError,
  allUsers,
  useItem,
  setBossLog,
  bossLog,
  damageLog = [],
  classType,
  bossesInfo,
  setBossesInfo,
  name,
  photo,
  photos,
}) {
  const [leftTimeString, setLeftTimeString] = useState("00ч:00м");
  const [leaveBossOpen, setLeaveBossOpen] = useState(false);
  const [isEndLocal, setIsEndLocal] = useState(false);
  const [questsOpen, setQuestsOpen] = useState(false);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [endTopInfo, setEndTopInfo] = useState(false);
  const [bossAuto, setBossAuto] = useState(false);
  const [leftTime, setLeftTime] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isWin, setIsWin] = useState(false);
  const [currentBossHp, setCurrentBossHp] = useState(
    currentHp <= 0 ? 0 : currentHp
  );
  const [activePanel, setActivePanel] = useState("log");
  const [spellNumber, setSpellNumber] = useState(0);

  const currentHpRef = useRef(currentHp);
  const currentSpell = useRef(null);
  const currentHeal = useRef(null);

  const isEndRef = useRef(false);
  const scrollRef = useRef(null);
  const [domReady, setDomReady] = React.useState(false);

  useEffect(() => {
    setDomReady(true);
    changeOptionsLocation("raidBoss");
  }, []);

  useEffect(() => {
    if (isActive === -1) {
      setIsModalOpen(false);
      moveLocation("home");
    }
  }, [isActive]);

  useEffect(() => {
    if (bossAuto) {
      if (!isEndLocal && user.health.current > 0 && currentSpell.current) {
        currentSpell.current?.click();
      } else if (
        !isEndLocal &&
        user.health.current <= 0 &&
        currentHeal.current
      ) {
        currentHeal.current?.click();
      } else if (!isEndLocal && !currentHeal.current) {
        setBossAuto(false);
      }
    }
  }, [serverTime, user, isEndLocal, bossAuto]);

  useEffect(() => {
    if (!isEndRef.current && currentHp > 0 && serverTime) {
      timerFunc(serverTime);
    }
  }, [serverTime]);

  useEffect(() => {
    currentHpRef.current = currentHp;
    if (currentHp <= 0) {
      setCurrentBossHp(0);
      setIsWin(true);
      setIsEndLocal(true);
    } else {
      setCurrentBossHp(currentHp);
    }
  }, [currentHp]);

  useEffect(() => {
    isEndRef.current = isEndLocal;
    if (isEndLocal) {
      setLoading(false);
      setIsFetching(false);
      setIsModalOpen(true);
    }
  }, [isEndLocal]);

  useEffect(() => {
    if (leftTime <= 0 && leftTime !== null) {
      setIsEndLocal(true);
      setIsWin(false);
    }
  }, [leftTime]);

  useEffect(() => {
    app.service("users").on("raidBoss", () => {
      app
        .service("users")
        .get(user._id, {
          query: {
            $select: [
              "raidBoss",
              "damageTalents",
              "health",
              "mana",
              "allDamage",
              "weakDamage",
              "dayDamage",
              "raidDamage",
            ],
          },
        })
        .then((data) => {
          app
            .service("admin")
            .get(user.admin, {
              query: {
                $select: ["raidBoss"],
              },
            })
            .then((admin) => {
              setAdminInfo((prev) => ({ ...prev, ...admin }));
              setUser((prev) => ({ ...prev, ...data }));
              setIsFetching(false);
            })
            .catch((e) => {
              setIsFetching(false);
              console.log(e);
            })
            .finally(() => {
              setTimeout(() => {
                setLoading(false);
              }, 700);
            });
        })
        .catch((e) => {
          setLoading(false);
          setIsFetching(false);
          console.log(e);
        });
    });

    return () => {
      app.service("users").removeListener("raidBoss");
    };
  }, []);

  function changeInfoOpen(info) {
    setIsInfoOpen(info);
  }

  function changeEndTop(info) {
    setEndTopInfo(info);
  }

  function changeAuto() {
    setBossAuto((prev) => !prev);
  }

  function dealDamage({
    damage: userDamage,
    mana,
    countAttacks,
    deffence,
    bossDamage,
  }) {
    const maxDamage = Math.round(allHp * 0.05);
    const myDamage = damageTop[user.email] || 0;
    const randEvasion = (Math.random() * 100).toFixed(1);
    const isEvasionBoss =
      100 -
        bossEvasionChance *
          (1 - user.damageTalents.simpleTalents.accuracyChance / 100) -
        randEvasion <=
      0;

    const rand = (Math.random() * 100).toFixed(1);
    const isKritBoss = 100 - bossKritChance - rand <= 0;

    if (myDamage >= maxDamage) {
      console.log("Вы достигли лимита по урону");
      setModalError("Вы достигли лимита по урону");
      setLoading(false);
      setIsFetching(false);
    } else {
      app
        .service("users")
        .dealRaidDamage({
          damage: {
            ...userDamage,
            value:
              userDamage.isKrit && !isEvasionBoss
                ? Math.floor(userDamage.value * userDamage.krit)
                : userDamage.value,
          },
          countAttacks: countAttacks,
          user: {
            _id: user._id,
            email: user.email,
          },
          mana: mana,
          bossDamage: {
            isKrit: isKritBoss,
            damage: Math.floor(
              bossDamage * (isKritBoss && !isEvasionBoss ? 1.5 : 1)
            ),
          },
          isEvasionBoss: isEvasionBoss,
          deffence: deffence,
        })
        .catch((e) => {
          setModalError(e);
          setLoading(false);
          setIsFetching(false);
          console.log(e);
        });
    }
  }

  const changeNumber = useCallback((number) => {
    setSpellNumber(number);
  }, []);

  function handleQuests() {
    setQuestsOpen((prevState) => !prevState);
  }

  function changeLog() {
    setBossLog((prev) => {
      if (!prev) {
        return "log";
      } else {
        return "";
      }
    });
  }

  function updateBosses() {
    setIsFetching(true);
    app
      .service("admin")
      .get(user.admin, {
        query: {
          $select: ["raidBoss"],
        },
      })
      .then((data) => {
        setAdminInfo((prev) => ({ ...prev, ...data }));
        setIsFetching(false);
      })
      .catch((e) => {
        setIsFetching(false);
        console.log(e);
      });
  }

  async function addWallPost() {
    setIsFetching(true);
    bridge
      .send("VKWebAppShowWallPostBox", {
        message: `Помогай бить Боссов, переходи по ссылке https://vk.com/app51651233#${user.email}`,
        attachments: photo,
      })
      .then((data) => {
        if (data.post_id) {
          setIsFetching(true);
          app
            .service("users")
            .patch(
              user._id,
              {
                "raidBoss.isShare": true,
                $inc: {
                  mana: 50,
                },
                common: true,
              },
              {
                query: {
                  $select: ["_id", "email", "raidBoss"],
                },
              }
            )
            .then((data) => {
              setUser((prev) => ({ ...prev, ...data }));
              setIsFetching(false);
            })
            .catch((e) => {
              setIsFetching(false);
              console.log(e);
            });
        } else {
          setIsFetching(false);
        }
      })
      .catch((e) => {
        setIsFetching(false);
        console.log(e);
      });
  }

  function handleLeaveBoss({ isWin, isLeave = false }) {
    app
      .service("users")
      .patch(
        user._id,
        {
          "raidBoss.isEnd": true,
          field: serverTime,
        },
        {
          query: {
            $select: ["_id", "email", "raidBoss"],
          },
        }
      )
      .then((data) => {
        setUser((prev) => ({ ...prev, ...data }));
        setIsFetching(false);
        setIsModalOpen(false);
        moveLocation("home");
      })
      .catch((e) => {
        setIsModalOpen(false);
        setIsFetching(false);
        console.log(e);
      });
  }

  function handleGetQuest(index) {
    setIsFetching(true);
    setLoading(true);

    let isUp = 0;
    let currentExpOnLvl = user.currentExpOnLvl;
    let onLvlExpNeed = user.onLvlExpNeed;

    if (
      currentExpOnLvl + raidBossesInfo[isActive].quests[index].rewards.exp >=
      onLvlExpNeed
    ) {
      currentExpOnLvl +=
        raidBossesInfo[isActive].quests[index].rewards.exp - onLvlExpNeed;
      onLvlExpNeed = roundLvlExp(
        onLvlExpNeed *
          (user.lvl > 100
            ? 1.1
            : user.lvl > 150
            ? 1.05
            : user.lvl > 200
            ? 1.02
            : 1.2)
      );
      isUp = 1;
      while (currentExpOnLvl >= onLvlExpNeed) {
        currentExpOnLvl -= onLvlExpNeed;
        onLvlExpNeed = roundLvlExp(
          onLvlExpNeed *
            (user.lvl > 100
              ? 1.1
              : user.lvl > 150
              ? 1.05
              : user.lvl > 200
              ? 1.02
              : 1.2)
        );
        isUp++;
      }
    } else {
      currentExpOnLvl += raidBossesInfo[isActive].quests[index].rewards.exp;
    }

    const characteristicInfo = {};
    Object.entries(user.characteristic).forEach((elem) => {
      characteristicInfo[elem[0]] = {
        ...elem[1],
        lvl: (user.lvl + isUp) * 5,
      };
    });

    const scales = {};
    const grindValue = grindData["book"].bonus[user.grind["book"] - 1] / 100;
    user.damageTalents.characteristicTalents.forEach((elem) => {
      scales[elem.property] =
        1 + grindValue + Math.round(elem.step * elem.countDone * 100) / 10000;
    });

    const hp = Math.floor(
      Object.values(characteristicInfo.health).reduce((acc, cur, i) => {
        if (i < 2) {
          return acc + cur * 5 * scales.health;
        }
        return acc + cur * 5;
      }, 0)
    );

    let goldUp = 0;
    for (let step = 0; step < isUp; step++) {
      goldUp += user.lvl + step + 1;
    }

    app
      .service("users")
      .patch(
        user._id,
        {
          [`raidBoss.quests.${index}`]: true,
          currentExpOnLvl: currentExpOnLvl,
          onLvlExpNeed: onLvlExpNeed,
          health: {
            current: isUp ? hp : user.health.current,
            max: hp,
          },
          characteristic: characteristicInfo,
          $inc: {
            allExp: raidBossesInfo[isActive].quests[index].rewards.exp,
            lvl: isUp,
            gold: raidBossesInfo[isActive].quests[index].rewards.gold + goldUp,
            currentEnergy:
              isUp &&
              user.currentEnergy <
                user.damageTalents.simpleTalents.maxValueEnergy +
                  grindData["owl"].bonus[user.grind["owl"] - 1]
                ? user.damageTalents.simpleTalents.maxValueEnergy +
                  grindData["owl"].bonus[user.grind["owl"] - 1] -
                  user.currentEnergy
                : 0,
            "spells.points": isUp,
            "spells.pointsAll": isUp,
          },
          field: serverTime,
        },
        {
          query: {
            $select: [
              "_id",
              "email",
              "raidBoss",
              "currentEnergy",
              "gold",
              "lvl",
              "allExp",
              "health",
              "onLvlExpNeed",
              "currentExpOnLvl",
              "spells",
            ],
          },
        }
      )
      .then((data) => {
        setUser((prev) => ({ ...prev, ...data }));
        setIsFetching(false);
        setLoading(false);
        if (isUp) {
          setIsLvlUpOpen(true);
        }
      })
      .catch((e) => {
        setIsFetching(false);
        setLoading(false);
        console.log(e);
      });
  }

  function timerFunc(serverTime) {
    let secs = Math.floor((endTime - serverTime) / 1000);
    if (secs <= 0) {
      secs = 0;
    }
    setLeftTime(secs);
    setLeftTimeString(secondsToHm(secs));
  }

  function changePanel(name) {
    setActivePanel(name);
  }

  return (
    <>
      <BarsPart>
        <img src={imgBars} alt="BossBars" width={358} height={55} />
      </BarsPart>
      <BarsHpBack>
        <img src={imgHpBack} alt="HpBar" width={271} height={22} />
      </BarsHpBack>
      <BarsHp value={(currentBossHp / allHp) * 100}>
        <img src={imgHpBar} alt="HpBar" width={193} height={18} />
        <span>
          {roundProperty(currentBossHp).toLocaleString("ru")}/
          {roundProperty(allHp).toLocaleString("ru")}
        </span>
      </BarsHp>

      <Time>
        <img src={timeIcon} alt="время" width={14} />
        <span>{leftTimeString}</span>
      </Time>

      <LogOpen>
        <Button
          disabled={bossAuto}
          active={true}
          onClick={changeAuto}
          minW={80}
        >
          <div>Автобой</div>
        </Button>
        <Button onClick={changeLog} minW={80}>
          <div>{bossLog ? "Бой" : "Лог"}</div>
        </Button>
      </LogOpen>

      <LogUsers bossLog={bossLog}>
        <Button
          disabled={activePanel === "log"}
          onClick={changePanel.bind(null, "log")}
          minW={80}
        >
          <div>Лог</div>
        </Button>
        <Button
          disabled={activePanel === "top"}
          onClick={changePanel.bind(null, "top")}
          minW={80}
        >
          <div>Топ</div>
        </Button>
      </LogUsers>

      <DamageLog bossLog={bossLog}>
        <DamageShadow damageInfo={damageLog} />
      </DamageLog>

      <LogUsersDamage bossLog={bossLog}>
        {activePanel === "log" && (
          <Damage
            damageInfo={damageInfo}
            allUsers={allUsers}
            scrollRef={scrollRef}
          />
        )}
        {activePanel === "top" && (
          <DamageTop
            damageTop={damageTop}
            allUsers={allUsers}
            name={user.name}
            nickName={user.nickName}
            orden={user.orden}
            email={user.email}
            allHp={allHp}
          />
        )}
      </LogUsersDamage>

      <InfoBoss>
        <Button
          minW={80}
          onClick={
            !loading && !isFetching && !isModalOpen ? updateBosses : null
          }
        >
          <div>Обновить</div>
        </Button>
        <Button minW={80} onClick={changeInfoOpen.bind(null, true)}>
          <div>Инфо</div>
        </Button>
        {isEndLocal && !isWin ? (
          <Button
            minW={90}
            onClick={
              !loading && !isFetching && !isModalOpen
                ? () => setLeaveBossOpen(true)
                : null
            }
          >
            <div>Выход</div>
          </Button>
        ) : isEndLocal && isWin ? (
          <Button
            minW={90}
            onClick={
              !loading && !isFetching
                ? handleLeaveBoss.bind(null, { isWin })
                : null
            }
          >
            <div>Выход</div>
          </Button>
        ) : (
          <Button
            minW={90}
            onClick={
              !loading && !isFetching && !isModalOpen
                ? () => setLeaveBossOpen(true)
                : null
            }
          >
            <div>Сбежать</div>
          </Button>
        )}
      </InfoBoss>

      <FirstSpell>
        <SpellWrapper>
          <div>
            <ButtonLeft
              onClick={changeNumber.bind(null, 0)}
              limit={spellNumber === 0}
            >
              <img width={20} src={scrollButtonImg} alt="вперёд" />
            </ButtonLeft>
            <ButtonRight
              onClick={changeNumber.bind(null, 1)}
              limit={spellNumber === 1}
            >
              <img width={20} src={scrollButtonImg} alt="назад" />
            </ButtonRight>
          </div>
        </SpellWrapper>

        {user.spells.active[classType].map((elem, i) => {
          return (
            elem > -1 &&
            i === spellNumber && (
              <Spell
                currentSpell={currentSpell}
                number={i + 1}
                key={i}
                grind={user.grind}
                useItem={useItem}
                {...spellsArray[classType][elem]}
                lvl={user.spells.lvl[classType]}
                mana={Math.floor(
                  spellsArray[classType][elem].mana *
                    (1 - user.damageTalents.simpleTalents.manaScale / 100)
                )}
                userHp={user.health.current}
                userMana={user.mana}
                bossDamage={Math.floor(
                  (percentDamage / 100) * user.health.max > damage
                    ? (percentDamage / 100) *
                        user.health.max *
                        (1 -
                          user.damageTalents.simpleTalents.bossDefenceScale /
                            100)
                    : damage *
                        (1 -
                          user.damageTalents.simpleTalents.bossDefenceScale /
                            100)
                )}
                damage={
                  spellsArray[classType][elem].damage *
                  (1 + user.damageTalents.simpleTalents.bossDamageScale / 100) *
                  (user.spells.done[classType][elem] /
                    spellsArray[classType][elem].countHave) *
                  spellsArray[classType][elem].raidDamage
                }
                kritChance={user.damageTalents.simpleTalents.kritChance}
                currentHp={currentHp}
                isFetching={isFetching || loading || isEndLocal}
                isModalOpen={isModalOpen}
                dealDamage={dealDamage}
                force={user.characteristic.force}
                power={user.characteristic.power}
                accuracy={user.characteristic.accuracy}
                setIsFetching={setIsFetching}
                setLoading={setLoading}
                setModalError={setModalError}
                damageTalents={user.damageTalents}
                stockLvl={user.stockLvl}
                bossesInfo={bossesInfo}
                setBossesInfo={setBossesInfo}
                serverTime={serverTime}
                isRaid={true}
              />
            )
          );
        })}
      </FirstSpell>

      <Heal>
        <SpellWrapper>
          <div />
        </SpellWrapper>
      </Heal>

      <Heal>
        <Healls
          currentHeal={currentHeal}
          inventory={user.inventory}
          useItem={useItem}
          isFetching={isFetching}
          bossesInfo={bossesInfo}
          setBossesInfo={setBossesInfo}
        />
      </Heal>

      <Popup
        isOpen={isInfoOpen}
        setIsOpen={setIsInfoOpen}
        w={436}
        h={320}
        back={frame6}
      >
        <Close onClick={changeInfoOpen.bind(null, false)} />
        <b>Информация о Боссе</b>
        <span>Тип: Рейдовый Босс, Класс: {types[classType]}</span>
        <span>
          Урон:{" "}
          {roundProperty(
            Math.floor(
              (percentDamage / 100) * user.health.max > damage
                ? (percentDamage / 100) *
                    user.health.max *
                    (1 -
                      user.damageTalents.simpleTalents.bossDefenceScale / 100)
                : damage *
                    (1 -
                      user.damageTalents.simpleTalents.bossDefenceScale / 100)
            )
          ).toLocaleString("ru")}
          , Шанс крита: {Math.floor(bossKritChance)}%, Шанс уклонения:{" "}
          {Math.floor(bossEvasionChance)}%
        </span>

        <span>Лимит на урон: 5%</span>

        <span>Общая награда:</span>

        <Rewards>
          <RewardWrapper light={false} w={65}>
            <div>
              <Reward
                name={"gold"}
                count={prizes.gold}
                w={28}
                showCount={true}
                round={true}
                font={13}
                reverseColumn={true}
                color={"#fdbb54"}
              />
            </div>
          </RewardWrapper>

          <RewardWrapper light={false} w={65}>
            <div>
              <Reward
                name={"silver"}
                count={prizes.silver}
                w={28}
                showCount={true}
                round={true}
                font={13}
                reverseColumn={true}
                color={"#fdbb54"}
              />
            </div>
          </RewardWrapper>
        </Rewards>
      </Popup>

      <Popup
        isOpen={isModalOpen}
        w={700}
        h={410}
        back={frame7}
        justify={"start"}
      >
        <EndFightWrapper>
          <img
            src={isWin ? winRibon : loseRibon}
            width={430}
            height={80}
            alt="лента"
          />
          <EndLeftPart>
            <img src={photos[isActive]} width={310} height={214} alt="фото" />

            {isWin && (
              <Hand src={handImg} width={140} height={154} alt="кровь" />
            )}

            <LoseText>{isWin ? "" : "Сбежал"}</LoseText>

            <BossName>
              <div>{name}</div>
            </BossName>

            <EndBossInfo>Рейдовый Босс</EndBossInfo>
          </EndLeftPart>
          <EndRightPart>
            <EndButtonsWrapper>
              <Button
                minW={120}
                disabled={!endTopInfo}
                onClick={changeEndTop.bind(null, false)}
              >
                <div>Мои результаты</div>
              </Button>
              <Button
                minW={120}
                disabled={endTopInfo}
                onClick={changeEndTop.bind(null, true)}
              >
                <div>Топ урона</div>
              </Button>
            </EndButtonsWrapper>

            {endTopInfo ? (
              <TopInfo>
                <FinishTopBosses
                  isRaid={true}
                  damageTop={damageTop}
                  allUsers={allUsers}
                  nickName={user.nickName}
                  photo={user.photo}
                  orden={user.orden}
                  name={user.name}
                  email={user.email}
                  allHp={allHp}
                />
              </TopInfo>
            ) : (
              <MeInfo>
                <div>Нанесённый урон:</div>

                <Avatar>
                  <img src={user.photo} width={70} height={70} alt={"аватар"} />
                </Avatar>

                <ButtonValue minW={70}>
                  <div>
                    {roundPropertyLow(
                      damageTop?.[user.email] || 0
                    ).toLocaleString("ru")}
                  </div>
                </ButtonValue>

                {isWin && (
                  <div>Ожидайте, награды будут розданы в ближайшее время</div>
                )}

                <Button minW={120} onClick={handleQuests}>
                  <div>Личные задания</div>
                </Button>

                <EndButtonsWrapper>
                  {isWin && !isShare && (
                    <Button
                      minW={120}
                      onClick={!loading && !isFetching ? addWallPost : null}
                    >
                      <Rewards>
                        <span>Поделиться</span>
                        <Reward
                          name={"mana"}
                          count={"+50"}
                          w={14}
                          button={true}
                        />
                      </Rewards>
                    </Button>
                  )}

                  <Button
                    minW={120}
                    onClick={
                      !loading && !isFetching
                        ? handleLeaveBoss.bind(null, { isWin })
                        : null
                    }
                  >
                    <div>Выход</div>
                  </Button>
                </EndButtonsWrapper>
              </MeInfo>
            )}
          </EndRightPart>
        </EndFightWrapper>
      </Popup>

      <Popup
        isOpen={leaveBossOpen}
        setIsOpen={setLeaveBossOpen}
        w={317}
        h={217}
        back={frame3}
      >
        <Close
          onClick={() => {
            setLeaveBossOpen(false);
          }}
        />
        <span>
          {isEndLocal ? <b>Вы хотите выйти?</b> : <b>Вы хотите сбежать?</b>}
        </span>
        <span>Удостоверьтесь что забрали награду за личные задания.</span>
        {!isEndLocal && <span>Вы не сможете больше зайти!</span>}
        <ButtonsWrapper>
          {isEndLocal ? (
            <Button
              onClick={
                !loading && !isFetching
                  ? handleLeaveBoss.bind(null, { isWin })
                  : null
              }
            >
              <div>Выйти</div>
            </Button>
          ) : (
            <Button
              onClick={
                !loading && !isFetching
                  ? handleLeaveBoss.bind(null, { isWin, isLeave: true })
                  : null
              }
            >
              <div>Сбежать</div>
            </Button>
          )}
        </ButtonsWrapper>
      </Popup>

      <Popup
        w={550}
        h={320}
        back={frame8}
        isOpen={questsOpen}
        setIsOpen={setQuestsOpen}
      >
        <Close onClick={handleQuests} />
        <S.StepperWrapper>
          <b>Личные задания на поединок с рейдовым Боссом</b>
          <span>
            Нанесено ударов по Боссу: {countAttacks.toLocaleString("ru")}
          </span>
          <S.Stepper>
            {quests.map((elem, i) => {
              return (
                <S.Step
                  active={
                    countAttacks >=
                    raidBossesInfo[isActive].quests[i].countAttackNeed
                  }
                  isGet={elem}
                  key={i}
                >
                  {raidBossesInfo[isActive].quests[
                    i
                  ].countAttackNeed.toLocaleString("ru")}
                </S.Step>
              );
            })}
          </S.Stepper>
          <S.QuestsReward>
            {raidBossesInfo[isActive].quests.map((elem, i) => {
              return (
                <div key={i}>
                  <Reward name={"gold"} count={elem.rewards.gold} w={15} />
                  <Reward name={"exp"} count={elem.rewards.exp} w={15} />
                </div>
              );
            })}
          </S.QuestsReward>
          <S.QuestsButtons>
            {quests.map((elem, i) => {
              return (
                <div key={i}>
                  <Button
                    width={100}
                    disabled={
                      loading ||
                      isFetching ||
                      countAttacks <
                        raidBossesInfo[isActive].quests[i].countAttackNeed ||
                      elem
                    }
                    onClick={
                      !loading &&
                      !isFetching &&
                      countAttacks >=
                        raidBossesInfo[isActive].quests[i].countAttackNeed &&
                      !elem
                        ? handleGetQuest.bind(null, i)
                        : null
                    }
                  >
                    <div>Забрать</div>
                  </Button>
                </div>
              );
            })}
          </S.QuestsButtons>
        </S.StepperWrapper>
      </Popup>

      {domReady
        ? createPortal(
            <MenuRight>
              <MenuElemRight name={"Задания"}>
                <img width={80} height={80} src={questsImg} alt={"задания"} />
                <div onClick={handleQuests} />
              </MenuElemRight>
            </MenuRight>,
            document.querySelector("#menuRight")
          )
        : null}
    </>
  );
}

export default React.memo(ActiveRaidBoss);
