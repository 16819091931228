import React, { useEffect, useState } from "react";
import * as S from "./Lounge.styles";
import { allClothes } from "../../info/data";
import bossesImg from "../../img/tops/lounge/Bosses.png";
import arenaImg from "../../img/tops/lounge/Arena.png";
import classroomsImg from "../../img/tops/lounge/Classrooms.png";
import gamesImg from "../../img/tops/lounge/Games.png";

const body = ["Legs", "Pelvis", "Torso", "Head", "Hair"];

function Lounge({ user }) {
  const [tops, setTops] = useState({
    arena: [0, 0, 0],
    classrooms: [0, 0, 0],
    bosses: [0, 0, 0],
    games: [0, 0, 0],
  });
  const [topsSum, setTopsSum] = useState({
    arena: 0,
    classrooms: 0,
    bosses: 0,
    games: 0,
  });

  useEffect(() => {
    setTops({
      arena: user.weakTopWins.arena.map((elem) =>
        Object.values(elem).reduce((acc, cur) => acc + cur, 0)
      ),
      classrooms: user.weakTopWins.classrooms.map((elem) =>
        Object.values(elem).reduce((acc, cur) => acc + cur, 0)
      ),
      bosses: user.weakTopWins.bosses.map((elem) =>
        Object.values(elem).reduce((acc, cur) => acc + cur, 0)
      ),
      games: user.weakTopWins.games.map((elem) =>
        Object.values(elem).reduce((acc, cur) => acc + cur, 0)
      ),
    });
  }, [user]);

  useEffect(() => {
    setTopsSum({
      arena: tops.arena.reduce((acc, cur) => acc + cur, 0),
      classrooms: tops.classrooms.reduce((acc, cur) => acc + cur, 0),
      bosses: tops.bosses.reduce((acc, cur) => acc + cur, 0),
      games: tops.games.reduce((acc, cur) => acc + cur, 0),
    });
  }, [tops]);

  return (
    <S.Wrapper>
      <S.InnerWrapper>
        <S.Body
          onClick={() => {
            console.log("qq");
          }}
        >
          {!user.clothes.isGet[0].value ||
          (user.clothes.isGet[0].value &&
            !Object.values(allClothes).find(
              (elem) =>
                elem.src === user.clothes.isGet[0].value &&
                elem.lvl === user.clothes.isGet[0].lvl &&
                elem.category === "shoes"
            )?.swap?.[user.sex]) ? (
            <img
              width={221}
              height={358}
              src={require(`../../img/character/body/${user.sex}/${body[0]}.png`)}
              alt={"тело"}
            />
          ) : null}

          <img
            width={207}
            height={335}
            src={require(`../../img/character/body/${user.sex}/${body[1]}.png`)}
            alt={"тело"}
          />

          {!user.clothes.isGet[2].value && (
            <img
              width={207}
              height={335}
              src={require(`../../img/character/body/${user.sex}/${body[2]}.png`)}
              alt={"тело"}
            />
          )}

          {user.clothes.isGet[0].value && (
            <img
              width={207}
              height={335}
              src={require(`../../img/character/shoes${
                user.clothes.isGet[0].lvl >= 1
                  ? `${user.clothes.isGet[0].lvl + 1}`
                  : ""
              }/${user.sex}/${user.clothes.isGet[0].value}`)}
              alt={"одежда"}
            />
          )}

          {user.clothes.isGet[1].value && (
            <img
              width={207}
              height={335}
              src={require(`../../img/character/pants${
                user.clothes.isGet[1].lvl >= 1
                  ? `${user.clothes.isGet[1].lvl + 1}`
                  : ""
              }/${user.sex}/${user.clothes.isGet[1].value}`)}
              alt={"одежда"}
            />
          )}

          {user.clothes.isGet[2].value && (
            <img
              width={207}
              height={335}
              src={require(`../../img/character/shirt${
                user.clothes.isGet[2].lvl >= 1
                  ? `${user.clothes.isGet[2].lvl + 1}`
                  : ""
              }/${user.sex}/${user.clothes.isGet[2].value}`)}
              alt={"одежда"}
            />
          )}

          <img
            width={207}
            height={335}
            src={require(`../../img/character/body/${user.sex}/${body[3]}.png`)}
            alt={"тело"}
          />

          {!user.clothes.isGet[3].value ||
          (user.clothes.isGet[3].value &&
            !Object.values(allClothes).find(
              (elem) =>
                elem.src === user.clothes.isGet[3].value &&
                elem.lvl === user.clothes.isGet[3].lvl &&
                elem.category === "head"
            )?.swap?.[user.sex]) ? (
            <img
              width={207}
              height={335}
              src={require(`../../img/character/body/${user.sex}/${body[4]}.png`)}
              alt={"тело"}
            />
          ) : null}

          {user.clothes.isGet[6].value && (
            <img
              width={207}
              height={335}
              src={require(`../../img/character/hand${
                user.clothes.isGet[6].lvl >= 1
                  ? `${user.clothes.isGet[6].lvl + 1}`
                  : ""
              }/${user.sex}/${user.clothes.isGet[6].value}`)}
              alt={"руки"}
            />
          )}

          {user.clothes.isGet[3].value && (
            <img
              width={207}
              height={335}
              src={require(`../../img/character/head${
                user.clothes.isGet[3].lvl >= 1
                  ? `${user.clothes.isGet[3].lvl + 1}`
                  : ""
              }/${user.sex}/${user.clothes.isGet[3].value}`)}
              alt={"одежда"}
            />
          )}

          {user.clothes.isGet[4].value && (
            <img
              width={207}
              height={335}
              src={require(`../../img/character/wand${
                user.clothes.isGet[4].lvl >= 1
                  ? `${user.clothes.isGet[4].lvl + 1}`
                  : ""
              }/${user.clothes.isGet[4].value}`)}
              alt={"палочка"}
            />
          )}
        </S.Body>

        <S.Broom>
          {user.clothes.isGet[5].value && (
            <img
              width={265}
              height={53}
              src={require(`../../img/character/broom${
                user.clothes.isGet[5].lvl >= 1
                  ? `${user.clothes.isGet[5].lvl + 1}`
                  : ""
              }/${user.clothes.isGet[5].value}`)}
              alt={"метла"}
            />
          )}
        </S.Broom>

        <S.BossesCup>
          {topsSum.bosses > 0 && (
            <img width={182} height={60} src={bossesImg} alt={"кубок"} />
          )}
        </S.BossesCup>

        <S.ClassroomsCup>
          {topsSum.classrooms > 0 && (
            <img width={58} height={50} src={classroomsImg} alt={"кубок"} />
          )}
        </S.ClassroomsCup>

        <S.ArenaCup>
          {topsSum.arena > 0 && (
            <img width={63} height={60} src={arenaImg} alt={"кубок"} />
          )}
        </S.ArenaCup>

        <S.GamesCup>
          {topsSum.games > 0 && (
            <img width={52} height={42} src={gamesImg} alt={"кубок"} />
          )}
        </S.GamesCup>

        <S.Owl>
          <img
            width={127}
            height={178}
            src={require(`../../img/character/owl/${user.grind.owl}.png`)}
            alt={"сова"}
          />
        </S.Owl>
        <S.Book>
          <img
            width={161}
            height={155}
            src={require(`../../img/character/book/${user.grind.book}.png`)}
            alt={"книга"}
          />
        </S.Book>

        <S.Cup>
          <img
            width={63}
            height={75}
            src={require(`../../img/character/cup/${user.grind.cup}.png`)}
            alt={"кубок"}
          />
        </S.Cup>
      </S.InnerWrapper>
    </S.Wrapper>
  );
}

export default React.memo(Lounge);
