import React, { useCallback, useEffect, useRef, useState } from "react";

import * as S from "./Shop.styles";
import ShopElem from "../ShopElem/ShopElem";
import Popup from "../Popup/Popup";
import InventoryElem from "../InventoryElem/InventoryElem";
import backImg from "../../img/ShopBack.png";
import { Close, Header } from "../../App.styles";
import frame3 from "../../img/Frame3.png";
import { bossesArray, shopItems } from "../../info/data";
import { ButtonsWrapper } from "../../panels/GameRoom/GameRoom.styles";
import { Button } from "../../panels/Arena/Arena.styles";
import Reward from "../Reward/Reward";
import frame2 from "../../img/Frame2.png";
import InputNumber from "../InputNumber/InputNumber";

function Shop({
  _id,
  treasures,
  gold,
  silver,
  maxCharacCount,
  damageTalents,
  inventory,
  isOpen,
  setIsOpen,
  isFetching,
  isModalOpen,
  setIsFetching,
  setUser,
  app,
  setModalError,
  useItem,
  serverTime,
}) {
  const [treasureChange, setTreasureChange] = useState(0);
  const [countTreasure, setCountTreasure] = useState(1);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([...shopItems]);
  const [tab, setTab] = useState("all");
  const itemRef = useRef(null);

  useEffect(() => {
    if (tab === "all") {
      setItems(shopItems);
    } else {
      setItems(shopItems.filter((elem) => elem.category === tab));
    }
  }, [tab]);

  function treasureOpen(number) {
    setTreasureChange(number);
  }

  function handleTab(name) {
    setTab(name);
  }

  function handleClose() {
    setIsOpen(false);
  }

  function handleChange() {
    if (
      treasures.countHave[treasureChange - 1] < countTreasure * 10 ||
      !countTreasure
    ) {
      setModalError("Недостаточно сундуков для обмена");
      console.log("Недостаточно сундуков для обмена");
    } else {
      if (gold < 5 * countTreasure) {
        setModalError("Недостаточно золота для обмена");
        console.log("Недостаточно золота для обмена");
      } else {
        setIsFetching(true);
        setIsLoading(true);
        app
          .service("users")
          .patch(
            _id,
            {
              $inc: {
                [`treasures.countHave.${treasureChange - 1}`]:
                  -countTreasure * 10,
                [`treasures.countHave.${treasureChange}`]: countTreasure,
                gold: 5 * countTreasure,
              },
              field: serverTime,
            },
            {
              query: {
                $select: ["_id", "email", "treasures", "gold"],
              },
            }
          )
          .then((data) => {
            setTimeout(() => {
              setIsLoading(false);
            }, 250);
            setIsFetching(false);
            setUser((prev) => ({ ...prev, ...data }));
          })
          .catch((e) => {
            setModalError(e);
            console.log(e);
            setIsLoading(false);
            setIsFetching(false);
          });
      }
    }
  }

  const buyItem = useCallback(
    (item, count) => {
      const hpPriceScale = 1 + Math.floor(maxCharacCount / 1000) * 0.5;
      const hpPrice = Math.floor(
        item.price.count *
          (damageTalents.simpleTalents.hpSilverDiscount > 0
            ? 1 - damageTalents.simpleTalents.hpSilverDiscount / 100
            : 1) *
          hpPriceScale
      );
      count = Math.floor(count);
      if (isNaN(count)) {
        return 0;
      }
      if (count === 0) {
        return 0;
      }
      if (count < 1) {
        count = 1;
      }
      let transaction = true;
      if (item.price.property === "gold" && item.price.count * count > gold) {
        transaction = false;
        setModalError("Недостаточно золота");
        console.log("Недостаточно золота");
      }

      if (
        item.category === "hp" &&
        item.price.property === "silver" &&
        hpPrice * count > silver
      ) {
        transaction = false;
        setModalError("Недостаточно серебра");
        console.log("Недостаточно серебра");
      }
      if (
        item.category !== "hp" &&
        item.price.property === "silver" &&
        item.price.count * count > silver
      ) {
        transaction = false;
        setModalError("Недостаточно серебра");
        console.log("Недостаточно серебра");
      }

      if (item.category === "mana") {
        transaction = false;
        let localTransaction = true;
        if (item.price.property === "gold" && gold < item.price.count * count) {
          setModalError("Недостаточно золота");
          console.log("Недостаточно золота");
          localTransaction = false;
        }

        if (
          item.price.property === "silver" &&
          silver < item.price.count * count
        ) {
          setModalError("Недостаточно серебра");
          console.log("Недостаточно серебра");
          localTransaction = false;
        }

        if (localTransaction) {
          setLoading(true);
          setIsFetching(true);
          app
            .service("users")
            .patch(
              _id,
              {
                $inc: {
                  gold:
                    item.price.property === "gold"
                      ? -item.price.count * count
                      : 0,
                  silver:
                    item.price.property === "silver"
                      ? -item.price.count * count
                      : 0,
                  mana: item.value * count,
                },
                field: serverTime,
              },
              {
                query: {
                  $select: ["_id", "email", "gold", "silver", "mana"],
                },
              }
            )
            .then((data) => {
              setLoading(false);
              setUser((prev) => ({ ...prev, ...data }));
              setIsFetching(false);
            })
            .catch((e) => {
              setLoading(false);
              setIsFetching(false);
              setModalError(e);
              console.log(e);
            });
        }
      }

      if (item.category === "soul") {
        transaction = false;
        if (gold < item.price.count * count) {
          setModalError("Недостаточно золота");
          console.log("Недостаточно золота");
        } else {
          setLoading(true);
          setIsFetching(true);
          app
            .service("users")
            .patch(
              _id,
              {
                $inc: {
                  gold:
                    item.price.property === "gold"
                      ? -item.price.count * count
                      : 0,
                  [`bosses.${item.value}.souls`]: count,
                },
                field: serverTime,
              },
              {
                query: {
                  $select: ["_id", "email", "gold", "bosses"],
                },
              }
            )
            .then((data) => {
              setLoading(false);
              setUser((prev) => ({ ...prev, ...data }));
              setIsFetching(false);
            })
            .catch((e) => {
              setLoading(false);
              setIsFetching(false);
              setModalError(e);
              console.log(e);
            });
        }
      }

      if (transaction) {
        setLoading(true);
        setIsFetching(true);
        const inventoryInfo = [...inventory];

        if (inventoryInfo.length > 0) {
          const itemIndex = inventoryInfo.findIndex(
            (elem) =>
              elem.name + elem.category + elem.value ===
              item.name + item.category + item.value
          );
          if (itemIndex !== -1) {
            inventoryInfo[itemIndex].count += count;
          } else {
            inventoryInfo.push({
              name: item.name,
              category: item.category,
              value: item.value,
              percent: item.percent,
              time: item.time,
              count: count,
            });
          }
        } else {
          inventoryInfo.push({
            name: item.name,
            category: item.category,
            value: item.value,
            percent: item.percent,
            time: item.time,
            count: count,
          });
        }

        itemRef.current =
          inventoryInfo[
            inventoryInfo.findIndex(
              (elem) =>
                elem.name + elem.category + elem.value ===
                item.name + item.category + item.value
            )
          ];

        app
          .service("users")
          .patch(
            _id,
            {
              inventory: inventoryInfo,
              $inc: {
                gold:
                  item.price.property === "gold"
                    ? -item.price.count * count
                    : 0,
                silver:
                  item.price.property === "silver" && item.category === "hp"
                    ? -hpPrice * count
                    : item.price.property === "silver"
                    ? -item.price.count * count
                    : 0,
              },
              field: serverTime,
            },
            {
              query: {
                $select: ["_id", "email", "inventory", "gold", "silver"],
              },
            }
          )
          .then((data) => {
            setLoading(false);
            setUser((prev) => ({ ...prev, ...data }));
            setIsFetching(false);
            setIsPopupOpen(true);
          })
          .catch((e) => {
            setLoading(false);
            setIsFetching(false);
            setModalError(e);
            console.log(e);
          });
      }
    },
    [inventory, serverTime, gold, silver]
  );

  return (
    <S.Wrapper isOpen={isOpen} onClick={handleClose}>
      {isOpen && (
        <S.Content onClick={(e) => e.stopPropagation()} back={backImg}>
          <div>
            <Close onClick={handleClose} />
            <Header>Лавка товаров</Header>

            <ButtonsWrapper>
              <Button
                disabled={tab === "all"}
                onClick={handleTab.bind(null, "all")}
                width={90}
              >
                <div>Все</div>
              </Button>

              <Button
                disabled={tab === "hp"}
                onClick={handleTab.bind(null, "hp")}
                width={90}
              >
                <div>Здоровье</div>
              </Button>

              <Button
                disabled={tab === "energy"}
                onClick={handleTab.bind(null, "energy")}
                width={90}
              >
                <div>Энергия</div>
              </Button>

              <Button
                disabled={tab === "soul"}
                onClick={handleTab.bind(null, "soul")}
                width={90}
              >
                <div>Души</div>
              </Button>

              <Button
                disabled={tab === "treasures"}
                onClick={handleTab.bind(null, "treasures")}
                width={90}
              >
                <div>Сундуки</div>
              </Button>
            </ButtonsWrapper>

            <S.ShopElements>
              {tab !== "treasures" &&
                items.map((elem, i) => {
                  return (
                    <ShopElem
                      gold={gold}
                      silver={silver}
                      maxCharacCount={maxCharacCount}
                      damageTalents={damageTalents}
                      key={i}
                      elem={elem}
                      loading={loading || isFetching}
                      isModalOpen={isModalOpen}
                      buyItem={buyItem}
                    />
                  );
                })}
              {(tab === "all" || tab === "treasures") && (
                <S.Treasure>
                  <Reward button={true} name={"treasure"} treasure={0} w={56} />

                  <button onClick={treasureOpen.bind(null, 1)}>Обменять</button>
                </S.Treasure>
              )}
            </S.ShopElements>
          </div>
          <Popup
            isOpen={isPopupOpen}
            setIsOpen={setIsPopupOpen}
            w={317}
            h={217}
            back={frame3}
          >
            <Close
              onClick={() => {
                setIsPopupOpen(false);
              }}
            />

            <b>Желаете сразу использовать?</b>

            <InventoryElem
              useItem={useItem}
              loading={loading || isFetching}
              isModalOpen={isModalOpen}
              elem={itemRef.current}
              setIsPopupOpen={setIsPopupOpen}
            />
          </Popup>

          <Popup
            isOpen={treasureChange}
            setIsOpen={setTreasureChange}
            w={312}
            h={302}
            back={frame2}
          >
            <Close onClick={treasureOpen.bind(null, 0)} />
            {treasureChange > 0 && (
              <>
                <span>
                  <h3>
                    <b>Обмен сундуков 10 к 1</b>
                  </h3>
                </span>
                <span>
                  Сундук {treasureChange} (
                  {treasures.countHave[treasureChange - 1]} шт)
                </span>
                <Reward button={true} name={"treasure"} treasure={0} w={40} />
                <span>
                  Сундук {treasureChange + 1} (
                  {treasures.countHave[treasureChange]} шт)
                </span>
                <Reward button={true} name={"treasure"} treasure={1} w={40} />

                <InputNumber
                  width={80}
                  min={1}
                  max={Math.floor(treasures.countHave[treasureChange - 1] / 10)}
                  setCount={setCountTreasure}
                />
                <Button
                  width={90}
                  disabled={isFetching || isLoading}
                  onClick={!isFetching && !isLoading ? handleChange : null}
                >
                  <Reward
                    button={true}
                    name={"gold"}
                    count={5 * countTreasure}
                    w={15}
                  />
                </Button>
              </>
            )}
          </Popup>
        </S.Content>
      )}
    </S.Wrapper>
  );
}

export default React.memo(Shop);
