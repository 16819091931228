import React, { useEffect, useState } from "react";

import * as S from "./Ordens.styles";

import backImg from "../../img/OrdensBack.png";
import { Close, Header } from "../../App.styles";
import Popup from "../Popup/Popup";
import frame1 from "../../img/Frame1.png";
import Reward from "../Reward/Reward";
import SumCharacter from "../SumCharacter/SumCharacter";
import RaidDamagePanel from "../RaidDamagePanel/RaidDamagePanel";
import frame3 from "../../img/Frame3.png";
import SumCharacters from "../SumCharacters/SumCharacters";
import { roundProperty } from "../../helpers";
import { ButtonsWrapper } from "../NickName/NickName.styles";
import { Button } from "../../panels/Arena/Arena.styles";

const limitMembers = 25;

function Ordens({
  _id,
  adminId,
  gold,
  email,
  isOpen,
  setIsOpen,
  admin,
  setAdmin,
  setUser,
  app,
  isFetching,
  setIsFetching,
  getCharacterSum,
  setModalError,
  serverTime,
  allUsers,
  myOrden,
  moveToUserRoom,
  reloadUsersInfo,
}) {
  const [ordensSort, setOrdensSort] = useState([]);
  const [isRaid, setIsRaid] = useState(false);
  const [resources, setResources] = useState({
    points: 0,
    essence: 0,
    silver: 0,
    gold: 0,
  });
  const [createOpen, setCreateOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [resourcesUser, setResourcesUser] = useState(null);
  const [leaveOpen, setLeaveOpen] = useState(false);
  const [deleteUser, setDeleteUser] = useState(null);
  const [memberInfo, setMemberInfo] = useState(null);
  const [anyOrden, setAnyOrden] = useState(null);
  const [tab, setTab] = useState("all");
  const [name, setName] = useState("");
  const [tag, setTag] = useState("");

  useEffect(() => {
    if (myOrden) {
      setMemberInfo(myOrden.members.find((elem) => elem.email === email));
    } else {
      setTab("all");
    }
  }, [myOrden]);

  useEffect(() => {
    if (tab !== "any") {
      setAnyOrden(null);
    }
  }, [tab]);

  useEffect(() => {
    if (admin.ordens.length > 0) {
      if (tab === "all") {
        setOrdensSort(admin.ordens.sort((a, b) => b.score - a.score));
      }
      if (tab === "raid") {
        const ordensInfo = admin.ordens.map((orden) => {
          const damage = allUsers
            .filter((elem) => elem.orden.name === orden.name)
            .reduce((sum, cur) => sum + cur.raidDamage, 0);
          return { ...orden, raidDamage: damage };
        });
        setOrdensSort(ordensInfo.sort((a, b) => b.raidDamage - a.raidDamage));
      }
    } else {
      setOrdensSort([]);
    }
  }, [admin, allUsers, tab]);

  useEffect(() => {
    timerFunc();
  }, [serverTime, admin]);

  function handleClose() {
    setIsOpen("");
  }

  function timerFunc() {
    const secsRaid =
      admin.isRaid?.time * 3600 -
      Math.floor((serverTime - admin?.isRaid?.startTime) / 1000);
    if (secsRaid > 0 && admin?.isRaid?.startTime <= serverTime) {
      setIsRaid(true);
    } else {
      setIsRaid(false);
    }
  }

  function handleChangeGold(e) {
    let gold = e.target.value;
    if (gold > myOrden?.treasury.gold) {
      gold = myOrden?.treasury.gold;
    }
    if (gold < 0) {
      gold = 0;
    }
    setResources((prevState) => ({ ...prevState, gold: Math.floor(gold) }));
  }

  function handleChangeSilver(e) {
    let silver = e.target.value;
    if (silver > myOrden?.treasury.silver) {
      silver = myOrden?.treasury.silver;
    }
    if (silver < 0) {
      silver = 0;
    }
    setResources((prevState) => ({ ...prevState, silver: Math.floor(silver) }));
  }

  function handleChangeEssence(e) {
    let essence = e.target.value;
    if (essence > myOrden?.treasury.essence) {
      essence = myOrden?.treasury.essence;
    }
    if (essence < 0) {
      essence = 0;
    }
    setResources((prevState) => ({
      ...prevState,
      essence: Math.floor(essence),
    }));
  }

  function handleChangePoints(e) {
    let points = e.target.value;
    if (points > myOrden?.treasury.talents) {
      points = myOrden?.treasury.talents;
    }
    if (points < 0) {
      points = 0;
    }
    setResources((prevState) => ({ ...prevState, points: Math.floor(points) }));
  }

  function handleResources({ email, id }) {
    setResourcesUser({
      email: email,
      id: id,
    });
  }

  function handleCreate() {
    setCreateOpen((prev) => !prev);
  }

  function handleLeave() {
    setLeaveOpen((prev) => !prev);
  }

  function handleDelete() {
    setDeleteOpen((prev) => !prev);
  }

  function handleDeleteUser({ email, id }) {
    setDeleteUser({
      email: email,
      id: id,
    });
  }

  function handleClickUser(user) {
    moveToUserRoom(user);
    handleClose();
  }

  function handleAnyOrden(name) {
    if (admin.ordens.length > 0) {
      const orden = admin.ordens.find((elem) => name === elem.name);
      if (orden) {
        setAnyOrden(orden);
        setTab("any");
      } else {
        setAnyOrden(null);
      }
    } else {
      setAnyOrden(null);
    }
  }

  function handleCreateOrden() {
    if (isRaid) {
      setModalError("Создание Ордена во время рейда запрещено");
      console.log("Создание Ордена во время рейда запрещено");
    } else {
      if (gold >= 500) {
        if (tag.trim().length > 0 && name.trim().length > 0) {
          app
            .service("admin")
            .get(adminId)
            .then((adminData) => {
              if (
                adminData.ordens.findIndex((elem) => elem.name === name) === -1
              ) {
                app
                  .service("admin")
                  .patch(
                    adminId,
                    {
                      $push: {
                        ordens: {
                          $each: [
                            {
                              name: name,
                              tag: tag,
                              lvl: 1,
                              onLvlExp: 0,
                              score: 0,
                              members: [
                                {
                                  email: email,
                                  isHead: true,
                                  title: "",
                                  raidDamage: 0,
                                },
                              ],
                              applications: [],
                              treasury: {
                                gold: 0,
                                silver: 0,
                                essence: 0,
                                talents: 0,
                              },
                            },
                          ],
                        },
                      },
                    },
                    {
                      query: {
                        $select: ["ordens"],
                      },
                    }
                  )
                  .then((adminData) => {
                    setAdmin((prev) => ({ ...prev, ...adminData }));
                    app
                      .service("users")
                      .patch(
                        _id,
                        {
                          $inc: {
                            gold: -500,
                          },
                          orden: {
                            name: name,
                            tag: tag,
                          },
                          field: serverTime,
                        },
                        {
                          query: {
                            $select: ["_id", "email", "orden", "rooms", "gold"],
                          },
                        }
                      )
                      .then((data) => {
                        setCreateOpen(false);
                        setUser((prev) => ({ ...prev, ...data }));

                        setIsFetching(false);
                      })
                      .catch((e) => {
                        setModalError(e);
                        console.log(e);
                        setIsFetching(false);
                      });
                  })
                  .catch((e) => {
                    setModalError(e);
                    console.log(e);
                    setIsFetching(false);
                  });
              } else {
                console.log("Орден с таким названием уже существует");
                setModalError("Орден с таким названием уже существует");
              }
            });
        } else {
          console.log("Введите Название и Тег");
          setModalError("Введите Название и Тег");
        }
      } else {
        console.log("Недостаточно золота");
        setModalError("Недостаточно золота");
      }
    }
  }

  function handleRemoveOrden() {
    app
      .service("admin")
      .patch(
        adminId,
        {
          $pull: {
            ordens: {
              name: myOrden.name,
            },
          },
        },
        {
          query: {
            $select: ["ordens"],
          },
        }
      )
      .then((adminData) => {
        setDeleteOpen(false);
        setAdmin((prev) => ({ ...prev, ...adminData }));
      })
      .catch((e) => {
        setModalError(e);
        console.log(e);
        setIsFetching(false);
      });
  }

  function changeTag(e) {
    const string = e.target.value;
    if (string.length > 5) {
      string.length = 5;
      setTag(string);
    } else {
      setTag(string);
    }
  }

  function changeName(e) {
    const string = e.target.value;
    if (string.length > 40) {
      string.length = 40;
      setName(string);
    } else {
      setName(string);
    }
  }

  function handleTab(name) {
    setTab(name);
  }

  function handleEnter(name) {
    const orden = admin.ordens.find((elem) => elem.name === name);
    const isEnter = orden.applications.findIndex(
      (elem) => elem.email === email
    );
    if (isEnter === -1) {
      setIsFetching(true);
      app
        .service("admin")
        .patch(
          adminId,
          {
            $push: {
              [`ordens.$.applications`]: {
                $each: [
                  {
                    email: email,
                  },
                ],
                $slice: -50,
              },
            },
          },

          {
            query: {
              ["ordens.name"]: name,
            },
          }
        )
        .then((adminData) => {
          setAdmin(adminData);

          setIsFetching(false);
        })
        .catch((e) => {
          setModalError(e);
          console.log(e);
          setIsFetching(false);
        });
    } else {
      setModalError("Вы уже отправили заявку в этот Орден");
      console.log("Вы уже отправили заявку в этот Орден");
    }
  }

  function handleMember({ name, tag, userEmail, headEmail, userId, isGet }) {
    if (isGet) {
      if (isRaid) {
        setModalError("Приём в Орден на время рейда закрыт");
        console.log("Приём в Орден на время рейда закрыт");
      } else {
        if (myOrden.members.length < limitMembers) {
          setIsFetching(true);
          app
            .service("users")
            .changeOrden({
              userId: userId,
              userEmail: userEmail,
              headEmail: headEmail,
              isGet: true,
              name: name,
              tag: tag,
            })
            .then(() => {
              setIsFetching(false);
            })
            .catch((e) => {
              setIsFetching(false);
              setModalError(e);
              console.log(e);
            });
        } else {
          setModalError("В Ордене нет своободных мест");
          console.log("В Ордене нет своободных мест");
        }
      }
    } else {
      setIsFetching(true);
      app
        .service("admin")
        .patch(
          adminId,
          {
            $pull: {
              [`ordens.$.applications`]: { email: userEmail },
            },
          },
          {
            query: {
              ["ordens.name"]: name,
              $select: ["ordens"],
            },
          }
        )
        .then((adminData) => {
          setAdmin((prev) => ({ ...prev, ...adminData }));

          setIsFetching(false);
        })
        .catch((e) => {
          setModalError(e);
          console.log(e);
          setIsFetching(false);
        });
    }
  }

  function handleResourcesChange() {
    if (
      resources.gold + resources.essence + resources.silver + resources.points >
      0
    ) {
      let isTransaction = true;
      if (myOrden.treasury.gold < resources.gold) {
        isTransaction = false;
        console.log(name, "Недостаточно золота");
        setModalError("Недостаточно золота");
      }
      if (myOrden.treasury.essence < resources.essence) {
        isTransaction = false;
        console.log(name, "Недостаточно эссенций");
        setModalError("Недостаточно эссенций");
      }
      if (myOrden.treasury.silver < resources.silver) {
        isTransaction = false;
        console.log(name, "Недостаточно серебра");
        setModalError("Недостаточно серебра");
      }
      if (myOrden.treasury.talents < resources.points) {
        isTransaction = false;
        console.log(name, "Недостаточно навыков");
        setModalError("Недостаточно навыков");
      }

      if (isTransaction) {
        setIsFetching(true);
        app
          .service("users")
          .sendResources({
            headEmail: email,
            userId: resourcesUser.id,
            name: myOrden?.name,
            gold: resources.gold,
            silver: resources.silver,
            essence: resources.essence,
            points: resources.points,
          })
          .then(() => {
            setIsFetching(false);
            setResourcesUser(null);
            setResources({
              points: 0,
              essence: 0,
              silver: 0,
              gold: 0,
            });
          })
          .catch((e) => {
            setIsFetching(false);
            setModalError(e);
            console.log(e);
          });
      }
    } else {
      setModalError("Недостаточно ресурсов для отправки");
      console.log("Недостаточно ресурсов для отправки");
    }
  }

  function handleLeaveMember({ name, userEmail, headEmail, userId }) {
    if (isRaid) {
      setModalError("Выход из Ордена на время рейда закрыт");
      console.log("Выход из Ордена на время рейда закрыт");
    } else {
      setIsFetching(true);
      app
        .service("users")
        .changeOrden({
          userId: userId,
          headEmail: headEmail,
          userEmail: userEmail,
          isGet: false,
          name: name,
        })
        .then(() => {
          setIsFetching(false);
          setDeleteUser(null);
          setLeaveOpen(false);
        })
        .catch((e) => {
          setIsFetching(false);
          setModalError(e);
          console.log(e);
        });
    }
  }

  return (
    <S.Wrapper isOpen={isOpen} onClick={handleClose}>
      <S.Content onClick={(e) => e.stopPropagation()} back={backImg}>
        {isOpen && (
          <>
            <div>
              <Close onClick={handleClose} />
              <S.InnerWrapper>
                <Header>Ордена</Header>
                <ButtonsWrapper>
                  <Button
                    disabled={tab === "all"}
                    onClick={handleTab.bind(null, "all")}
                    width={90}
                  >
                    <div>Все Ордена</div>
                  </Button>

                  {myOrden ? (
                    <>
                      <Button
                        disabled={tab === "my"}
                        onClick={handleTab.bind(null, "my")}
                        width={90}
                      >
                        <div>Мой Орден</div>
                      </Button>
                    </>
                  ) : (
                    <Button onClick={handleCreate} width={90}>
                      <div>Создать Орден</div>
                    </Button>
                  )}
                  {(isRaid || email === 168191289) && (
                    <Button
                      disabled={tab === "raid"}
                      onClick={handleTab.bind(null, "raid")}
                      width={90}
                    >
                      <div>Рейд</div>
                    </Button>
                  )}
                  <Button onClick={reloadUsersInfo} width={90}>
                    <div>Обновить</div>
                  </Button>
                </ButtonsWrapper>

                <S.OrdensWrapper>
                  {tab === "raid" && (
                    <>
                      <S.Header2>Список Орденов Школы Магии</S.Header2>
                      <S.OrdensNamesRaid>
                        <span>№</span>
                        <span>Название</span>
                        <span>Тег</span>
                        <span>Члены</span>
                        <span>Рейдовый урон</span>
                      </S.OrdensNamesRaid>
                      <S.Ordens>
                        {ordensSort.length > 0 &&
                          ordensSort.map((elem, i) => {
                            return (
                              <S.OrdenRaid
                                isMy={myOrden?.name === elem.name}
                                key={i}
                                onClick={
                                  myOrden?.name !== elem.name
                                    ? handleAnyOrden.bind(null, elem.name)
                                    : null
                                }
                              >
                                <span>{i + 1}.</span>
                                <span>{elem.name}</span>
                                <span>{elem.tag}</span>
                                <span>
                                  {elem.members.length}/{limitMembers}
                                </span>
                                <span>
                                  {roundProperty(
                                    elem?.raidDamage || 0
                                  ).toLocaleString("ru")}
                                </span>
                              </S.OrdenRaid>
                            );
                          })}
                      </S.Ordens>
                    </>
                  )}

                  {tab === "all" && (
                    <>
                      <S.Header2>Список Орденов Школы Магии</S.Header2>
                      <S.OrdensNames>
                        <span>№</span>
                        <span>Название</span>
                        <span>Тег</span>
                        <span>Члены</span>
                        <span>Очки</span>
                        <span>Ур.</span>
                      </S.OrdensNames>
                      <S.Ordens>
                        {ordensSort.length > 0 &&
                          ordensSort.map((elem, i) => {
                            return (
                              <S.Orden
                                isMy={myOrden?.name === elem.name}
                                key={i}
                                onClick={
                                  myOrden?.name !== elem.name
                                    ? handleAnyOrden.bind(null, elem.name)
                                    : null
                                }
                              >
                                <span>{i + 1}.</span>
                                <span>{elem.name}</span>
                                <span>{elem.tag}</span>
                                <span>
                                  {elem.members.length}/{limitMembers}
                                </span>
                                <span>{elem.score}</span>
                                <span>{elem.lvl}</span>
                                <Button
                                  disabled={myOrden || myOrden?.name === name}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    !isFetching &&
                                    !myOrden &&
                                    myOrden?.name !== name
                                      ? handleEnter(elem.name)
                                      : null;
                                  }}
                                >
                                  <div>
                                    {myOrden || myOrden?.name === name
                                      ? "—"
                                      : "Вступить"}
                                  </div>
                                </Button>
                              </S.Orden>
                            );
                          })}
                      </S.Ordens>
                    </>
                  )}

                  {tab === "any" && anyOrden && [168191289].includes(email) && (
                    <>
                      <S.MenuBottom>
                        <RaidDamagePanel
                          email={email}
                          name={anyOrden.name}
                          allUsers={allUsers}
                        />
                      </S.MenuBottom>
                    </>
                  )}

                  {(tab === "my" || tab === "applications") && myOrden && (
                    <>
                      <S.MenuBottom>
                        <RaidDamagePanel
                          email={email}
                          name={myOrden.name}
                          allUsers={allUsers}
                        />
                        {memberInfo && (
                          <>
                            {(memberInfo.isHead ||
                              memberInfo.title === "Заместитель") && (
                              <Button
                                width={120}
                                disabled={tab === "applications"}
                                onClick={handleTab.bind(null, "applications")}
                              >
                                <div>Заявки</div>
                              </Button>
                            )}
                            {memberInfo.isHead && (
                              <Button width={120} onClick={handleDelete}>
                                <div>Расформировать</div>
                              </Button>
                            )}
                            {!memberInfo.isHead && (
                              <Button width={120} onClick={handleLeave}>
                                <div>Покинуть</div>
                              </Button>
                            )}
                          </>
                        )}
                      </S.MenuBottom>
                      <S.MenuLeftBottom>
                        <span>Казна: </span>
                        <Reward
                          round={true}
                          showCount={true}
                          name={"gold"}
                          count={myOrden.treasury.gold}
                          w={15}
                        />
                        <Reward
                          round={true}
                          showCount={true}
                          name={"silver"}
                          count={myOrden.treasury.silver}
                          w={15}
                        />
                        <Reward
                          round={true}
                          showCount={true}
                          name={"essence"}
                          count={myOrden.treasury.essence}
                          w={15}
                        />
                        <Reward
                          round={true}
                          showCount={true}
                          name={"talent"}
                          count={myOrden.treasury.talents}
                          w={15}
                        />
                      </S.MenuLeftBottom>
                    </>
                  )}

                  {tab === "my" && myOrden && (
                    <>
                      <S.HeaderName>
                        {myOrden.name} [{myOrden.tag}]
                      </S.HeaderName>
                      <S.Header2>
                        {myOrden.lvl} уровень, средние хар-ки:{" "}
                        <SumCharacters
                          users={allUsers}
                          ordenMembers={myOrden.members}
                        />
                        , {myOrden.members.length}/{limitMembers} участников
                      </S.Header2>
                      <S.MyOrdenNames>
                        <span>№</span>
                        <span>Никнейм</span>
                        <span>Должность</span>
                        <span>Ур.</span>
                        <span>Хар-ки</span>
                      </S.MyOrdenNames>
                      <S.Members>
                        {myOrden.members.map((elem, i) => {
                          const userLocal = allUsers.find(
                            (user) => user.email === elem.email
                          );
                          return (
                            userLocal && (
                              <S.Member key={i} isMe={email === elem.email}>
                                <span>{i + 1}.</span>
                                <span
                                  onClick={
                                    email !== elem.email
                                      ? handleClickUser.bind(null, userLocal)
                                      : null
                                  }
                                >
                                  {userLocal.nickName
                                    ? userLocal.nickName
                                    : `${userLocal.name?.firstName} ${userLocal.name?.lastName}`}
                                </span>
                                <span>
                                  {elem.title
                                    ? elem.title
                                    : elem.isHead
                                    ? "Магистр"
                                    : "Маг"}
                                </span>
                                <span>{userLocal.lvl}</span>
                                <span>
                                  <SumCharacter
                                    user={userLocal}
                                    getCharacterSum={getCharacterSum}
                                  />
                                </span>
                                <>
                                  <Button
                                    disabled={
                                      !(
                                        memberInfo.isHead ||
                                        memberInfo.title === "Заместитель"
                                      ) || memberInfo.email === userLocal.email
                                    }
                                    onClick={
                                      !isFetching &&
                                      (memberInfo.isHead ||
                                        memberInfo.title === "Заместитель") &&
                                      memberInfo.email !== userLocal.email
                                        ? handleDeleteUser.bind(null, {
                                            email: userLocal.email,
                                            id: userLocal._id,
                                          })
                                        : null
                                    }
                                  >
                                    <div>
                                      {!(
                                        memberInfo.isHead ||
                                        memberInfo.title === "Заместитель"
                                      ) || memberInfo.email === userLocal.email
                                        ? "—"
                                        : "Исключ."}
                                    </div>
                                  </Button>
                                  <Button
                                    disabled={
                                      !(
                                        memberInfo.isHead ||
                                        memberInfo.title === "Заместитель"
                                      )
                                    }
                                    onClick={
                                      memberInfo.isHead ||
                                      memberInfo.title === "Заместитель"
                                        ? handleResources.bind(null, {
                                            email: userLocal.email,
                                            id: userLocal._id,
                                          })
                                        : null
                                    }
                                  >
                                    <div>
                                      {!(
                                        memberInfo.isHead ||
                                        memberInfo.title === "Заместитель"
                                      )
                                        ? "—"
                                        : "Ресурсы"}
                                    </div>
                                  </Button>
                                </>
                              </S.Member>
                            )
                          );
                        })}
                      </S.Members>
                    </>
                  )}

                  {tab === "any" && anyOrden && (
                    <>
                      <S.HeaderName>
                        {anyOrden.name} [{anyOrden.tag}]
                      </S.HeaderName>
                      <S.Header2>
                        {anyOrden.lvl} уровень, средние хар-ки:{" "}
                        <SumCharacters
                          users={allUsers}
                          ordenMembers={anyOrden.members}
                        />
                        , {anyOrden.members.length}/{limitMembers} участников
                      </S.Header2>
                      <S.MyOrdenNames>
                        <span>№</span>
                        <span>Никнейм</span>
                        <span>Должность</span>
                        <span>Ур.</span>
                        <span>Хар-ки</span>
                      </S.MyOrdenNames>
                      <S.Members>
                        {anyOrden.members.map((elem, i) => {
                          const userLocal = allUsers.find(
                            (user) => user.email === elem.email
                          );
                          return (
                            userLocal && (
                              <S.Member key={i} isMe={email === elem.email}>
                                <span>{i + 1}.</span>
                                <span
                                  onClick={
                                    email !== elem.email
                                      ? handleClickUser.bind(null, userLocal)
                                      : null
                                  }
                                >
                                  {userLocal.nickName
                                    ? userLocal.nickName
                                    : `${userLocal.name?.firstName} ${userLocal.name?.lastName}`}
                                </span>
                                <span>
                                  {elem.title
                                    ? elem.title
                                    : elem.isHead
                                    ? "Магистр"
                                    : "Маг"}
                                </span>
                                <span>{userLocal.lvl}</span>
                                <span>
                                  <SumCharacter
                                    user={userLocal}
                                    getCharacterSum={getCharacterSum}
                                  />
                                </span>
                                <>
                                  <Button disabled={true}>
                                    <div>—</div>
                                  </Button>
                                  <Button disabled={true}>
                                    <div>—</div>
                                  </Button>
                                </>
                              </S.Member>
                            )
                          );
                        })}
                      </S.Members>
                    </>
                  )}

                  {tab === "applications" && myOrden && (
                    <>
                      <S.Header2>Заявки</S.Header2>

                      <S.ApplicationsNames>
                        <span>№</span>
                        <span>Никнейм</span>
                        <span>Ур.</span>
                        <span>Хар-ки</span>
                      </S.ApplicationsNames>

                      <S.Applications>
                        {myOrden &&
                          myOrden.applications.map((elem, i) => {
                            const userLocal = allUsers.find(
                              (user) => user.email === elem.email
                            );
                            return (
                              userLocal && (
                                <div key={i}>
                                  <span>{i + 1}.</span>
                                  <span
                                    onClick={handleClickUser.bind(
                                      null,
                                      userLocal
                                    )}
                                  >
                                    {userLocal.nickName
                                      ? userLocal.nickName
                                      : `${userLocal.name?.firstName} ${userLocal.name?.lastName}`}
                                  </span>
                                  <span>{userLocal.lvl}</span>
                                  <span>
                                    <SumCharacter
                                      user={userLocal}
                                      getCharacterSum={getCharacterSum}
                                    />
                                  </span>
                                  <Button
                                    onClick={
                                      !isFetching
                                        ? handleMember.bind(null, {
                                            isGet: true,
                                            userEmail: userLocal.email,
                                            headEmail: email,
                                            userId: userLocal._id,
                                            name: myOrden.name,
                                            tag: myOrden.tag,
                                          })
                                        : null
                                    }
                                  >
                                    <div>Принять</div>
                                  </Button>
                                  <Button
                                    onClick={
                                      !isFetching
                                        ? handleMember.bind(null, {
                                            isGet: false,
                                            userEmail: userLocal.email,
                                            headEmail: email,
                                            userId: userLocal._id,
                                            name: myOrden.name,
                                            tag: myOrden.tag,
                                          })
                                        : null
                                    }
                                  >
                                    <div>Отклонить</div>
                                  </Button>
                                </div>
                              )
                            );
                          })}
                      </S.Applications>
                    </>
                  )}
                </S.OrdensWrapper>
              </S.InnerWrapper>
            </div>

            <Popup
              isOpen={createOpen}
              setIsOpen={handleCreate}
              w={317}
              h={217}
              back={frame3}
            >
              <Close onClick={handleCreate} />
              <b>Создать свой Орден</b>
              <S.InputOrden
                value={name}
                onChange={changeName}
                type="text"
                maxLength={40}
                placeholder={"Введите название"}
              />
              <S.InputOrden
                value={tag}
                onChange={changeTag}
                type="text"
                maxLength={5}
                placeholder={"Введите тег (без скобок)"}
              />

              <Button onClick={handleCreateOrden}>
                <Reward
                  name={"gold"}
                  count={`Создать 500`}
                  w={15}
                  button={true}
                />
              </Button>
            </Popup>

            <Popup
              isOpen={deleteOpen}
              setIsOpen={handleDelete}
              w={281}
              h={152}
              back={frame1}
            >
              <Close onClick={handleDelete} />
              {myOrden && (
                <>
                  <b>Желаете расформировать Орден?</b>
                  <Button onClick={handleRemoveOrden}>
                    <div>Расформировать</div>
                  </Button>
                </>
              )}
            </Popup>

            <Popup
              isOpen={leaveOpen}
              setIsOpen={handleLeave}
              w={281}
              h={152}
              back={frame1}
            >
              <Close onClick={handleLeave} />
              {myOrden && (
                <>
                  <b>Желаете покинуть Орден?</b>
                  <ButtonsWrapper>
                    <Button
                      onClick={
                        !isFetching
                          ? handleLeaveMember.bind(null, {
                              userEmail: email,
                              userId: _id,
                              name: myOrden?.name,
                            })
                          : null
                      }
                    >
                      <div>Покинуть</div>
                    </Button>
                  </ButtonsWrapper>
                </>
              )}
            </Popup>

            <Popup
              isOpen={deleteUser}
              setIsOpen={setDeleteUser}
              w={281}
              h={152}
              back={frame1}
            >
              <Close
                onClick={() => {
                  setDeleteUser(null);
                }}
              />
              {deleteUser && myOrden && (
                <>
                  <b>Желаете исключить мага из Ордена?</b>
                  <ButtonsWrapper>
                    <Button
                      onClick={
                        !isFetching
                          ? handleLeaveMember.bind(null, {
                              headEmail: email,
                              userEmail: deleteUser.email,
                              userId: deleteUser.id,
                              name: myOrden?.name,
                            })
                          : null
                      }
                    >
                      <div>Исключить</div>
                    </Button>
                  </ButtonsWrapper>
                </>
              )}
            </Popup>

            <Popup
              isOpen={resourcesUser}
              setIsOpen={setResourcesUser}
              w={317}
              h={217}
              back={frame3}
            >
              <Close
                onClick={() => {
                  setResourcesUser(null);
                }}
              />
              <b>Распределить ресурсы</b>
              {resourcesUser && (
                <>
                  <S.Resources>
                    <S.Resource>
                      <span>Навыки: </span>
                      <S.InputOrden
                        value={resources.points}
                        onChange={handleChangePoints}
                        min={0}
                        max={myOrden?.treasury.talents}
                        type={"number"}
                        placeholder={"Навыки"}
                      />
                    </S.Resource>
                    <S.Resource>
                      <span>Золото: </span>
                      <S.InputOrden
                        value={resources.gold}
                        onChange={handleChangeGold}
                        min={0}
                        max={myOrden?.treasury.gold}
                        type={"number"}
                        placeholder={"Золото"}
                      />
                    </S.Resource>
                    <S.Resource>
                      <span>Серебро: </span>
                      <S.InputOrden
                        value={resources.silver}
                        onChange={handleChangeSilver}
                        min={0}
                        max={myOrden?.treasury.silver}
                        type={"number"}
                        placeholder={"Серебро"}
                      />
                    </S.Resource>
                    <S.Resource>
                      <span>Эссенции: </span>
                      <S.InputOrden
                        value={resources.essence}
                        onChange={handleChangeEssence}
                        min={0}
                        max={myOrden?.treasury.essence}
                        type={"number"}
                        placeholder={"Эссенции"}
                      />
                    </S.Resource>
                  </S.Resources>
                  <Button onClick={!isFetching ? handleResourcesChange : null}>
                    <div>Отправить</div>
                  </Button>
                </>
              )}
            </Popup>
          </>
        )}
      </S.Content>
    </S.Wrapper>
  );
}

export default React.memo(Ordens);
