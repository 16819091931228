import * as S from "./Home.styles";
import React, { useEffect, useState } from "react";

import { CentredWrapper, Close } from "../../App.styles";

import sexImg from "../../img/menuIcons/Sex.png";
import questImg from "../../img/menuIcons/NewQuest.png";
import clothesImg from "../../img/menuIcons/Clothes.png";
import frame4 from "../../img/Frame4.png";
import ArrowImg from "../../img/RightArrow.png";

import manSex from "../../img/look/Man.png";
import womanSex from "../../img/look/Woman.png";

import Reward from "../../components/Reward/Reward";
import Popup from "../../components/Popup/Popup";
import { grindData, newYearPrize } from "../../info/data";
import newYearPrizeImg from "../../img/NewYearPrize.png";
import { RewardWrapper } from "../../components/DayReward/DayReward.styles";
import frame2 from "../../img/Frame2.png";
import bossesImg from "../../img/tops/lounge/BossesHover.png";
import place1 from "../../img/Place1.png";
import place2 from "../../img/Place2.png";
import place3 from "../../img/Place3.png";
import classroomsImg from "../../img/tops/lounge/ClassroomsHover.png";
import arenaImg from "../../img/tops/lounge/ArenaHover.png";
import gamesImg from "../../img/tops/lounge/GamesHover.png";
import { Button } from "../../components/ActiveBoss/ActiveBoss.styles";
import { ButtonValue } from "../Arena/Arena.styles";
import NewUserQuests from "../../components/NewUserQuests/NewUserQuests";
import frame9 from "../../img/Frame9.png";
import frameSummer from "../../img/FrameSummer.png";
import { secondsToDh } from "../../helpers";
import frame8 from "../../img/Frame8.png";
import frame1 from "../../img/Frame1.png";
import SummerSaveQuests from "../../components/SummerSaveQuests/SummerSaveQuests";
import { TopPart } from "../../components/BossInterface/BossInterface.styles";
import imgTop from "../../img/boss/TopPart.png";
import { TopButton } from "../Tops/Tops.styles";
import { createPortal } from "react-dom";

const Home = ({
  user,
  setUser,
  app,
  isModalOpen,
  isFetching,
  setIsFetching,
  changeOptionsLocation,
  setModalError,
  serverDate,
  adminInfo,
  getCharacterSum,
  moveLocation,
  setActiveMenuModal,
  addItem,
  bridge,
  activePanel,
  setActivePanel,
}) => {
  const [activeGrind, setActiveGrind] = useState("");
  const [newQuestOpen, setNewQuestOpen] = useState(false);
  const [summerQuestOpen, setSummerQuestOpen] = useState(false);
  const [sexIsOpen, setSexIsOpen] = useState(false);
  const [gridResult, setGridResult] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isNewYear, setIsNewYear] = useState(false);
  const [isPrizeOpen, setIsPrizeOpen] = useState(false);
  const [tops, setTops] = useState({
    arena: [0, 0, 0],
    classrooms: [0, 0, 0],
    bosses: [0, 0, 0],
    games: [0, 0, 0],
  });
  const [topsSum, setTopsSum] = useState({
    arena: 0,
    classrooms: 0,
    bosses: 0,
    games: 0,
  });
  const [value, setValue] = useState(
    user.nickName
      ? user.nickName
      : `${user.name?.firstName} ${user.name?.lastName}`
  );
  const [timeQuestString, setTimeQuestString] = useState("");
  const [timeSummerString, setTimeSummerString] = useState("");
  const [timeQuest, setTimeQuest] = useState(false);
  const [timeSummerSave, setTimeSummerSave] = useState(false);
  const [summerSaveDay, setSummerSaveDay] = useState(-1);
  const [domReady, setDomReady] = React.useState(false);

  useEffect(() => {
    setDomReady(true);
    changeOptionsLocation("home");
  }, []);

  useEffect(() => {
    if (
      user.registerTime !== 0 &&
      604800 - (serverDate - user.registerTime) / 1000 <= 0
    ) {
      setTimeQuest(false);
      setTimeQuestString("");
    } else {
      setTimeQuest(true);
      if (user.registerTime === 0) {
        setTimeQuestString("");
      } else {
        setTimeQuestString(
          secondsToDh(604800 - (serverDate - user.registerTime) / 1000)
        );
      }
    }

    if (
      serverDate > adminInfo.summerSave.startTime &&
      adminInfo.summerSave.time * 3600 -
        (serverDate - adminInfo.summerSave.startTime) / 1000 >
        0
    ) {
      setSummerSaveDay(
        6 -
          Math.floor(
            (adminInfo.summerSave.time * 3600 -
              (serverDate - adminInfo.summerSave.startTime) / 1000) /
              (3600 * 24)
          )
      );
      setTimeSummerSave(true);
      setTimeSummerString(
        secondsToDh(
          adminInfo.summerSave.time * 3600 -
            (serverDate - adminInfo.summerSave.startTime) / 1000
        )
      );
    } else {
      setTimeSummerSave(false);
      setTimeSummerString("");
      setSummerSaveDay(-1);
    }
  }, [adminInfo, serverDate]);

  useEffect(() => {
    setTops({
      arena: user.weakTopWins.arena.map((elem) =>
        Object.values(elem).reduce((acc, cur) => acc + cur, 0)
      ),
      classrooms: user.weakTopWins.classrooms.map((elem) =>
        Object.values(elem).reduce((acc, cur) => acc + cur, 0)
      ),
      bosses: user.weakTopWins.bosses.map((elem) =>
        Object.values(elem).reduce((acc, cur) => acc + cur, 0)
      ),
      games: user.weakTopWins.games.map((elem) =>
        Object.values(elem).reduce((acc, cur) => acc + cur, 0)
      ),
    });
  }, [user]);

  useEffect(() => {
    setTopsSum({
      arena: tops.arena.reduce((acc, cur) => acc + cur, 0),
      classrooms: tops.classrooms.reduce((acc, cur) => acc + cur, 0),
      bosses: tops.bosses.reduce((acc, cur) => acc + cur, 0),
      games: tops.games.reduce((acc, cur) => acc + cur, 0),
    });
  }, [tops]);

  useEffect(() => {
    timerFunc();
  }, [serverDate, adminInfo]);

  function handleChangeNick() {
    if (
      (user.nickName && value !== user.nickName) ||
      (!user.nickName &&
        value !== `${user.name?.firstName} ${user.name?.lastName}`)
    ) {
      if (!user.nickName || (user.nickName && user.gold >= 20)) {
        setIsFetching(true);
        app
          .service("users")
          .patch(
            user._id,
            {
              $inc: {
                gold: user.nickName ? -20 : 0,
              },
              nickName: value.trim(),
              field: serverDate,
            },
            {
              query: {
                $select: ["_id", "email", "nickName", "gold"],
              },
            }
          )
          .then((data) => {
            setUser((prev) => ({ ...prev, ...data }));

            setIsFetching(false);
          })
          .catch((e) => {
            setModalError(e);
            console.log(e);
            setIsFetching(false);
          });
      } else {
        console.log("Недостаточно золота");
        setModalError("Недостаточно золота");
      }
    }
  }

  function handleInput(e) {
    const string = e.target.value;
    if (string.length > 25) {
      string.length = 25;
      setValue(string);
    } else {
      setValue(string);
    }
  }

  function changeGrind(name) {
    setActiveGrind(name);
  }

  function timerFunc() {
    const secs =
      adminInfo.newYear?.time * 3600 -
      Math.floor((serverDate - adminInfo?.newYear?.startTime) / 1000);
    if (secs > 0 && adminInfo?.newYear?.startTime <= serverDate) {
      setIsNewYear(true);
    } else {
      setIsNewYear(false);
    }
  }

  function getNewYearPrize() {
    setIsFetching(true);

    app
      .service("users")
      .patch(
        user._id,
        {
          isGetNewYearPrize: true,
          field: serverDate,
          $inc: {
            gold: newYearPrize?.gold ? newYearPrize?.gold?.count : 0,
            "game21.isFree": newYearPrize?.game21
              ? newYearPrize?.game21?.count
              : 0,
            "diceGame.isFree": newYearPrize?.diceGame
              ? newYearPrize?.diceGame?.count
              : 0,
          },
        },
        {
          query: {
            $select: [
              "_id",
              "email",
              "isGetNewYearPrize",
              "gold",
              "game21",
              "diceGame",
            ],
          },
        }
      )
      .then((data) => {
        setUser((prev) => ({ ...prev, ...data }));
        setIsFetching(false);
        setIsPrizeOpen(true);
      })
      .catch((e) => {
        setIsFetching(false);
        setModalError(e);
        console.log(e);
      });
  }

  function openModal(name) {
    if (name === "sex") {
      setSexIsOpen((prev) => !prev);
    }
    if (name === "quest") {
      setNewQuestOpen((prev) => !prev);
    }
    if (name === "summer") {
      setSummerQuestOpen((prev) => !prev);
    }
  }

  function changeSex(name) {
    if (user.sex !== name) {
      setIsFetching(true);
      app
        .service("users")
        .patch(
          user._id,
          {
            sex: name,
            field: serverDate,
          },
          {
            query: {
              $select: ["_id", "email", "sex"],
            },
          }
        )
        .then((data) => {
          setUser((prev) => ({ ...prev, ...data }));
          setIsFetching(false);
        })
        .catch((e) => {
          setIsFetching(false);
          setModalError(e);
          console.log(e);
        });
    }
  }

  function upgradeGrind() {
    if (
      (activeGrind === "owl" && user.lvl >= 20) ||
      (activeGrind === "book" && user.lvl >= 30) ||
      (activeGrind === "cup" && user.lvl >= 40)
    ) {
      if (
        user[grindData[activeGrind].price.type] >=
        grindData[activeGrind].price.count[user.grind[activeGrind] - 1]
      ) {
        if (user.grind[activeGrind] < 15) {
          setIsFetching(true);
          setIsLoading(true);

          let isUp = false;
          const grindLvl = user.grind[activeGrind];
          const loses = user.grindLose[activeGrind]?.[grindLvl] || 0;

          const chance = Math.floor(Math.random() * 1000);

          if (
            chance <
            Math.floor(
              (grindData[activeGrind].chance[grindLvl - 1] + loses * 0.1) * 10
            )
          ) {
            isUp = true;
          } else {
            isUp = false;
          }

          const scales = {};
          const grindValue =
            grindData["book"].bonus[
              activeGrind !== "book"
                ? user.grind["book"] - 1
                : isUp
                ? grindLvl
                : grindLvl > 1
                ? grindLvl - 2
                : 0
            ] / 100;
          user.damageTalents.characteristicTalents.forEach((elem) => {
            scales[elem.property] =
              1 +
              grindValue +
              Math.round(elem.step * elem.countDone * 100) / 10000;
          });

          const hp = Math.floor(
            Object.values(user.characteristic.health).reduce((acc, cur, i) => {
              if (i < 2) {
                return acc + cur * 5 * scales.health;
              }
              return acc + cur * 5;
            }, 0)
          );

          app
            .service("users")
            .patch(
              user._id,
              {
                $inc: {
                  [`grind.${activeGrind}`]: isUp ? 1 : grindLvl > 1 ? -1 : 0,
                  [`grindLose.${activeGrind}.${grindLvl}`]: isUp ? 0 : 1,
                  gold:
                    grindData[activeGrind].price.type === "gold"
                      ? -grindData[activeGrind].price.count[
                          user.grind[activeGrind] - 1
                        ]
                      : 0,
                  silver:
                    grindData[activeGrind].price.type === "silver"
                      ? -grindData[activeGrind].price.count[
                          user.grind[activeGrind] - 1
                        ]
                      : 0,
                },
                "health.current":
                  user.health.current > hp ? hp : user.health.current,
                "health.max": hp,
                field: serverDate,
              },
              {
                query: {
                  $select: [
                    "_id",
                    "email",
                    "grind",
                    "grindLose",
                    "gold",
                    "silver",
                    "health",
                  ],
                },
              }
            )
            .then((data) => {
              if (isUp) {
                setGridResult("Успех");
              } else {
                setGridResult("Провал");
              }
              setIsFetching(false);
              setTimeout(() => {
                setIsLoading(false);
              }, 250);
              setUser((prev) => ({ ...prev, ...data }));
            })
            .catch((e) => {
              setIsFetching(false);
              setIsLoading(false);
              setModalError(e);
              console.log(e);
            });
        } else {
          console.log("Максимальный уровень зачарования");
          setModalError("Максимальный уровень зачарования");
        }
      } else {
        console.log("Недостаточно ресурсов");
        setModalError("Недостаточно ресурсов");
      }
    } else {
      console.log("Низкий уровень");
      setModalError("Низкий уровень");
    }
  }

  function upgradeGrind2() {
    if (
      (activeGrind === "owl" && user.lvl >= 20) ||
      (activeGrind === "book" && user.lvl >= 30) ||
      (activeGrind === "cup" && user.lvl >= 40)
    ) {
      if (
        user[grindData[activeGrind].price.type] >=
        grindData[activeGrind].price.count[user.grind[activeGrind] - 1]
      ) {
        if (user.grind[activeGrind] < 15) {
          setIsFetching(true);
          setIsLoading(true);

          let isUp = false;
          let isBack = false;
          const grindLvl = user.grind[activeGrind];
          const loses = user.grindLose[activeGrind]?.[grindLvl] || 0;

          const chance = Math.floor(Math.random() * 10000);

          const chanceBack = Math.floor(Math.random() * 1000);

          if (
            chance <
            Math.floor(
              (grindData[activeGrind].chance[grindLvl - 1] + loses * 0.01) * 100
            )
          ) {
            isUp = true;
          } else {
            isUp = false;
            if (
              chanceBack <
              Math.floor(grindData[activeGrind].chance[grindLvl - 1] * 10)
            ) {
              isBack = true;
            } else {
              isBack = false;
            }
          }

          const scales = {};
          const grindValue =
            grindData["book"].bonus[user.grind["book"] - 1] / 100;
          user.damageTalents.characteristicTalents.forEach((elem) => {
            scales[elem.property] =
              1 +
              grindValue +
              Math.round(elem.step * elem.countDone * 100) / 10000;
          });

          const hp = Math.floor(
            Object.values(user.characteristic.health).reduce((acc, cur, i) => {
              if (i < 2) {
                return acc + cur * 5 * scales.health;
              }
              return acc + cur * 5;
            }, 0)
          );

          app
            .service("users")
            .patch(
              user._id,
              {
                $inc: {
                  [`grind.${activeGrind}`]: isUp
                    ? 1
                    : grindLvl > 1 && isBack
                    ? -1
                    : 0,
                  [`grindLose.${activeGrind}.${grindLvl}`]: isUp ? 0 : 1,
                  gold:
                    grindData[activeGrind].price.type === "gold"
                      ? -grindData[activeGrind].price.count[
                          user.grind[activeGrind] - 1
                        ]
                      : 0,
                  silver:
                    grindData[activeGrind].price.type === "silver"
                      ? -grindData[activeGrind].price.count[
                          user.grind[activeGrind] - 1
                        ]
                      : 0,
                },
                "health.max": hp,
                field: serverDate,
              },
              {
                query: {
                  $select: [
                    "_id",
                    "email",
                    "grind",
                    "grindLose",
                    "gold",
                    "silver",
                    "health",
                  ],
                },
              }
            )
            .then((data) => {
              if (isUp) {
                setGridResult("Успех");
              } else {
                if (isBack) {
                  setGridResult("Провал");
                } else {
                  setGridResult("Не повезло");
                }
              }
              setIsFetching(false);
              setTimeout(() => {
                setIsLoading(false);
              }, 250);
              setUser((prev) => ({ ...prev, ...data }));
            })
            .catch((e) => {
              setIsFetching(false);
              setIsLoading(false);
              setModalError(e);
              console.log(e);
            });
        } else {
          console.log("Максимальный уровень зачарования");
          setModalError("Максимальный уровень зачарования");
        }
      } else {
        console.log("Недостаточно ресурсов");
        setModalError("Недостаточно ресурсов");
      }
    } else {
      console.log("Низкий уровень");
      setModalError("Низкий уровень");
    }
  }

  return (
    <>
      {timeSummerSave && (
        <TopPart>
          <img src={imgTop} alt="интерфейс" />
        </TopPart>
      )}
      <CentredWrapper>
        {timeSummerSave && (
          <TopButton>
            <Button minW={130} onClick={openModal.bind(null, "summer")}>
              <div>Спаси лето</div>
            </Button>
          </TopButton>
        )}

        <S.BossesCup>
          {topsSum.bosses > 0 && (
            <>
              <img width={179} height={60} src={bossesImg} alt={"кубок"} />
              <div>
                <div>
                  <span>{tops.bosses[0]}</span>
                  <img src={place1} width={28} height={28} alt="медаль" />
                </div>
                <div>
                  <span>{tops.bosses[1]}</span>
                  <img src={place2} width={28} height={28} alt="медаль" />
                </div>
                <div>
                  <span>{tops.bosses[2]}</span>
                  <img src={place3} width={28} height={28} alt="медаль" />
                </div>
              </div>
            </>
          )}
        </S.BossesCup>

        <S.ClassroomsCup>
          {topsSum.classrooms > 0 && (
            <>
              <img width={58} height={50} src={classroomsImg} alt={"кубок"} />
              <div>
                <div>
                  <span>{tops.classrooms[0]}</span>
                  <img src={place1} width={28} height={30} alt="медаль" />
                </div>
                <div>
                  <span>{tops.classrooms[1]}</span>
                  <img src={place2} width={28} height={30} alt="медаль" />
                </div>
                <div>
                  <span>{tops.classrooms[2]}</span>
                  <img src={place3} width={28} height={30} alt="медаль" />
                </div>
              </div>
            </>
          )}
        </S.ClassroomsCup>

        <S.ArenaCup>
          {topsSum.arena > 0 && (
            <>
              <img width={58} height={60} src={arenaImg} alt={"кубок"} />
              <div>
                <div>
                  <span>{tops.arena[0]}</span>
                  <img src={place1} width={28} height={30} alt="медаль" />
                </div>
                <div>
                  <span>{tops.arena[1]}</span>
                  <img src={place2} width={28} height={30} alt="медаль" />
                </div>
                <div>
                  <span>{tops.arena[2]}</span>
                  <img src={place3} width={28} height={30} alt="медаль" />
                </div>
              </div>
            </>
          )}
        </S.ArenaCup>

        <S.GamesCup>
          {topsSum.games > 0 && (
            <>
              <img width={42} height={45} src={gamesImg} alt={"кубок"} />
              <div>
                <div>
                  <span>{tops.games[0]}</span>
                  <img src={place1} width={28} height={30} alt="медаль" />
                </div>
                <div>
                  <span>{tops.games[1]}</span>
                  <img src={place2} width={28} height={30} alt="медаль" />
                </div>
                <div>
                  <span>{tops.games[2]}</span>
                  <img src={place3} width={28} height={30} alt="медаль" />
                </div>
              </div>
            </>
          )}
        </S.GamesCup>

        <S.OwlHover
          onClick={user.lvl >= 20 ? changeGrind.bind(null, "owl") : null}
          lvl={user.lvl >= 20}
        >
          <img
            width={134}
            height={197}
            src={require(`../../img/character/owlHover/${user.grind.owl}.png`)}
            alt={"owl"}
          />
          <div>
            <span>Необходимо:</span>
            <span>20 уровень</span>
          </div>
        </S.OwlHover>
        <S.BookHover
          onClick={user.lvl >= 40 ? changeGrind.bind(null, "book") : null}
          lvl={user.lvl >= 40}
        >
          <img
            width={111}
            height={104}
            src={require(`../../img/character/bookHover/${user.grind.book}.png`)}
            alt={"book"}
          />
          <div>
            <span>Необходимо:</span>
            <span>40 уровень</span>
          </div>
        </S.BookHover>
        <S.CupHover
          onClick={user.lvl >= 60 ? changeGrind.bind(null, "cup") : null}
          lvl={user.lvl >= 60}
        >
          <img
            width={93}
            height={96}
            src={require(`../../img/character/cupHover/${user.grind.cup}.png`)}
            alt={"cup"}
          />
          <div>
            <span>Необходимо:</span>
            <span>60 уровень</span>
          </div>
        </S.CupHover>

        {isNewYear && !user.isGetNewYearPrize && (
          <S.NewYearPrize
            onClick={!isModalOpen && !isFetching ? getNewYearPrize : null}
          >
            <img width={65} height={72} src={newYearPrizeImg} alt={"приз"} />
          </S.NewYearPrize>
        )}
      </CentredWrapper>
      <Popup
        isOpen={activeGrind}
        setIsOpen={setActiveGrind}
        w={550}
        h={320}
        back={frame8}
      >
        <Close onClick={changeGrind.bind(null, "")} />
        {activeGrind && (
          <>
            <span>
              {activeGrind === "cup" ? (
                <b>
                  В случае успеха уровень магического предмета повышается, если
                  не повезло - остаётся прежним, шансы на успеха немного
                  возрастают,а в случае провала - уровень понижается.
                </b>
              ) : (
                <b>
                  В случае успеха уровень магического предмета повышается, в
                  противном случае - уменьшается на один, шансы на успех немного
                  возрастают
                </b>
              )}
            </span>

            <span>Максимальный и несгораемый уровень зачарования: 15</span>

            {user.grind[activeGrind] < 15 ? (
              <S.GrindWrapper>
                <div>
                  <img
                    src={require(`../../img/character/${activeGrind}/${user.grind[activeGrind]}.png`)}
                    alt={activeGrind}
                  />
                  <S.CharacBook>
                    <span>{grindData[activeGrind].string}</span>{" "}
                    <span>
                      (+
                      {
                        grindData[activeGrind].bonus[
                          user.grind[activeGrind] - 1
                        ]
                      }
                      {grindData[activeGrind].percent && "%"})
                    </span>
                  </S.CharacBook>
                </div>
                <div>
                  <span>
                    Успех:{" "}
                    {(grindData[activeGrind].chance[
                      user.grind[activeGrind] - 1
                    ] +
                      (user.grindLose[activeGrind]?.[user.grind[activeGrind]] *
                        (activeGrind === "cup" ? 0.01 : 0.1) || 0) >=
                    100
                      ? 100
                      : grindData[activeGrind].chance[
                          user.grind[activeGrind] - 1
                        ] +
                        (user.grindLose[activeGrind]?.[
                          user.grind[activeGrind]
                        ] * (activeGrind === "cup" ? 0.01 : 0.1) || 0)
                    ).toFixed(2)}
                    %
                  </span>
                  {activeGrind === "cup" ? (
                    <span>
                      Провал:{" "}
                      {
                        grindData[activeGrind].chance[
                          user.grind[activeGrind] - 1
                        ]
                      }
                      %
                    </span>
                  ) : (
                    <span>
                      Провал:{" "}
                      {(
                        100 -
                        (grindData[activeGrind].chance[
                          user.grind[activeGrind] - 1
                        ] +
                          (user.grindLose[activeGrind]?.[
                            user.grind[activeGrind]
                          ] * (activeGrind === "cup" ? 0.01 : 0.1) || 0) >=
                        100
                          ? 100
                          : grindData[activeGrind].chance[
                              user.grind[activeGrind] - 1
                            ] +
                            (user.grindLose[activeGrind]?.[
                              user.grind[activeGrind]
                            ] * (activeGrind === "cup" ? 0.01 : 0.1) || 0))
                      ).toFixed(2)}
                      %
                    </span>
                  )}
                  <img width={80} height={48} src={ArrowImg} alt={"стрелка"} />
                </div>
                <div>
                  <img
                    src={require(`../../img/character/${activeGrind}/${
                      user.grind[activeGrind] + 1
                    }.png`)}
                    alt={activeGrind}
                  />

                  <S.CharacBook>
                    <span>{grindData[activeGrind].string}</span>
                    <span>
                      (+{grindData[activeGrind].bonus[user.grind[activeGrind]]}
                      {grindData[activeGrind].percent && "%"})
                    </span>
                  </S.CharacBook>
                </div>
              </S.GrindWrapper>
            ) : (
              <S.MaxLvl>
                <img
                  src={require(`../../img/character/${activeGrind}/${user.grind[activeGrind]}.png`)}
                  alt={activeGrind}
                />

                <S.CharacBook>
                  <span>{grindData[activeGrind].string}</span>{" "}
                  <span>
                    (+
                    {grindData[activeGrind].bonus[user.grind[activeGrind] - 1]}
                    {grindData[activeGrind].percent && "%"})
                  </span>
                </S.CharacBook>
              </S.MaxLvl>
            )}

            {user.grind[activeGrind] < 15 && (
              <Button
                disabled={isFetching || isLoading}
                onClick={
                  !isFetching && !isLoading
                    ? activeGrind === "cup"
                      ? upgradeGrind2
                      : upgradeGrind
                    : null
                }
              >
                <Reward
                  button={true}
                  name={grindData[activeGrind].price.type}
                  count={`Зачаровать ${
                    grindData[activeGrind].price.count[
                      user.grind[activeGrind] - 1
                    ]
                  }`}
                  w={14}
                />
              </Button>
            )}
          </>
        )}
      </Popup>
      <Popup
        isOpen={gridResult}
        setIsOpen={setGridResult}
        w={281}
        h={152}
        back={frame1}
      >
        <Close
          onClick={() => {
            setGridResult("");
          }}
        />
        <b>Результат зачарования</b>
        <S.GridResult result={gridResult}>{gridResult}</S.GridResult>
      </Popup>
      <Popup
        isOpen={isPrizeOpen}
        setIsOpen={setIsPrizeOpen}
        w={312}
        h={302}
        back={frame2}
      >
        <Close onClick={() => setIsPrizeOpen(false)} />
        <b>Вы открыли новогодний подарок</b>
        <S.Rewards>
          {Object.entries(newYearPrize).map((el, i) => {
            return (
              <RewardWrapper key={i} light={false} w={65}>
                <div>
                  <Reward
                    name={el[0]}
                    count={el[1].count}
                    soul={el[1].number}
                    w={18}
                    showCount={true}
                    round={true}
                    font={13}
                    reverseColumn={true}
                    color={"#fdbb54"}
                  />
                </div>
              </RewardWrapper>
            );
          })}
        </S.Rewards>
      </Popup>
      <Popup
        isOpen={newQuestOpen && timeQuest}
        setIsOpen={setNewQuestOpen}
        w={768}
        h={557}
        back={frame9}
        justify={"start"}
      >
        <Close
          onClick={() => {
            setNewQuestOpen(false);
          }}
        />
        <NewUserQuests
          app={app}
          setUser={setUser}
          setModalError={setModalError}
          setIsFetching={setIsFetching}
          isFetching={isFetching}
          isOpen={newQuestOpen && timeQuest}
          time={timeQuestString}
          user={user}
          moveLocation={moveLocation}
          setOpen={setNewQuestOpen}
          setActiveMenuModal={setActiveMenuModal}
          addItem={addItem}
          getCharacterSum={getCharacterSum}
          bridge={bridge}
        />
      </Popup>
      <Popup
        isOpen={summerQuestOpen && timeSummerSave}
        setIsOpen={setSummerQuestOpen}
        w={768}
        h={557}
        back={frameSummer}
        justify={"start"}
      >
        <Close
          onClick={() => {
            setSummerQuestOpen(false);
          }}
        />
        <SummerSaveQuests
          app={app}
          setUser={setUser}
          setModalError={setModalError}
          setIsFetching={setIsFetching}
          isFetching={isFetching}
          isOpen={summerQuestOpen && timeSummerSave}
          time={timeSummerString}
          dayActive={summerSaveDay}
          user={user}
          moveLocation={moveLocation}
          setOpen={setNewQuestOpen}
          setActiveMenuModal={setActiveMenuModal}
          addItem={addItem}
          getCharacterSum={getCharacterSum}
          bridge={bridge}
          activePanel={activePanel}
          setActivePanel={setActivePanel}
        />
      </Popup>
      <Popup
        isOpen={sexIsOpen}
        setIsOpen={setSexIsOpen}
        w={506}
        h={502}
        back={frame4}
        justify="start"
      >
        <Close
          onClick={() => {
            setSexIsOpen(false);
          }}
        />

        <S.LookWrapper>
          <S.LookHeader>Смена псевдонима</S.LookHeader>

          <S.NickNameWrapper>
            <S.InputNick>
              <ButtonValue width={200}>
                <div>
                  <input
                    size={value.length}
                    type="text"
                    value={value}
                    onChange={handleInput}
                    maxLength={25}
                  />
                </div>
              </ButtonValue>
              <S.MaxSize>Максимальная длина: 25 символов</S.MaxSize>
            </S.InputNick>

            {user.nickName ? (
              <Reward name={"gold"} count={"Цена: 20"} w={14} button={true} />
            ) : (
              <span>Первый раз бесплатно</span>
            )}

            <S.NicknameButton>
              <Button
                onClick={
                  value.length > 0 &&
                  value.length <= 25 &&
                  value !== user.nickName
                    ? handleChangeNick
                    : null
                }
                minW={140}
                disabled={
                  value.length <= 0 ||
                  value.length > 25 ||
                  value === user.nickName
                }
              >
                <div>Подтвердить</div>
              </Button>
            </S.NicknameButton>
          </S.NickNameWrapper>

          <S.LookHeader>Смена внешности</S.LookHeader>

          <S.SexWrapper>
            <div>
              <S.LookHeader>Девушка</S.LookHeader>

              <img src={womanSex} width={120} height={192} alt="пол" />

              <S.NicknameButton>
                <Button
                  minW={140}
                  disabled={user.sex === "woman"}
                  onClick={changeSex.bind(null, "woman")}
                >
                  <div>{user.sex === "woman" ? "Выбрано" : "Выбрать"}</div>
                </Button>
              </S.NicknameButton>
            </div>
            <div>
              <S.LookHeader>Парень</S.LookHeader>
              <img src={manSex} width={120} height={192} alt="пол" />
              <S.NicknameButton>
                <Button
                  minW={140}
                  disabled={user.sex === "man"}
                  onClick={changeSex.bind(null, "man")}
                >
                  <div>{user.sex === "man" ? "Выбрано" : "Выбрать"}</div>
                </Button>
              </S.NicknameButton>
            </div>
          </S.SexWrapper>
        </S.LookWrapper>
      </Popup>

      {domReady
        ? createPortal(
            <S.MenuRight>
              {timeQuest && (
                <S.MenuElemRight time={timeQuestString} name={"Задания"}>
                  <img width={80} height={80} src={questImg} alt={"задания"} />
                  <div
                    onClick={
                      !isModalOpen && !isFetching
                        ? openModal.bind(null, "quest")
                        : null
                    }
                  />
                </S.MenuElemRight>
              )}

              <S.MenuElemRight name={"Гардероб"}>
                <img width={80} height={80} src={clothesImg} alt={"гардероб"} />
                <div
                  onClick={
                    !isModalOpen && !isFetching
                      ? moveLocation.bind(null, "garderob")
                      : null
                  }
                />
              </S.MenuElemRight>

              <S.MenuElemRight name={"Внешность"}>
                <img width={80} height={80} src={sexImg} alt={"пол"} />
                <div
                  onClick={
                    !isModalOpen && !isFetching
                      ? openModal.bind(null, "sex")
                      : null
                  }
                />
              </S.MenuElemRight>
            </S.MenuRight>,
            document.querySelector("#menuRight")
          )
        : null}
    </>
  );
};

export default React.memo(Home);
