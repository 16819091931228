import styled from "styled-components";

export const Wrapper = styled.div`
  position: absolute;
  font-size: 12px;
  top: 70px;
  right: 60px;
`;

export const Tops = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: start;
  text-align: start;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 420px;
  width: 100%;
`;

export const Stages = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
`;

export const User = styled.div`
  & > span:nth-child(3) {
    cursor: ${(props) => (props.isMe ? "default" : "pointer")};
    color: ${(props) => (props.isMe ? "#2dc22d" : "#f7b471")};
  }
`;

export const UserRef = styled.a`
  color: white;
`;
