import React, { useEffect, useState } from "react";

import * as S from "./Inventory.styles";
import InventoryElem from "../InventoryElem/InventoryElem";
import backImg from "../../img/InventoryBack.png";
import { Close, Header } from "../../App.styles";
import Popup from "../Popup/Popup";
import frame1 from "../../img/Frame1.png";
import Reward from "../Reward/Reward";
import {
  bossesArray,
  allClothes,
  grindData,
  treasuresArray,
} from "../../info/data";
import frame3 from "../../img/Frame3.png";
import { ButtonsWrapper } from "../Quests/Quests.styles";
import { RewardWrapper } from "../DayReward/DayReward.styles";
import { Button } from "../../panels/Arena/Arena.styles";

function Inventory({
  _id,
  inventory,
  clothes,
  bosses,
  treasures,
  characteristic,
  grind,
  sex,
  damageTalents,
  gold,
  candy,
  isOpen,
  setIsOpen,
  isFetching,
  isModalOpen,
  useItem,
  setIsFetching,
  setModalError,
  setUser,
  allUsers,
  moveToUserRoom,
  app,
  serverTime,
  addItem,
  handleAltarOpen,
}) {
  const [treasureReward, setTreasureReward] = useState(null);
  const [treasureOpen, setTreasureOpen] = useState(0);
  const [items, setItems] = useState([...inventory]);
  const [candyInfo, setCandyInfo] = useState({});
  const [tab, setTab] = useState("all");

  useEffect(() => {
    if (tab === "all") {
      setItems([...inventory]);
    } else {
      setItems([...inventory].filter((elem) => elem.category === tab));
    }
  }, [tab, inventory]);

  function changeTreasure(number) {
    setTreasureOpen(number);
  }

  function changeTreasureReward(reward) {
    setTreasureReward(reward);
  }

  function openTreasure(lvl) {
    if (treasures.countHave[lvl] > 0) {
      const randomChance = Math.random().toFixed(5);

      if (
        gold <
        (treasures.countOpenDay[lvl] > 10 ? 10 : treasures.countOpenDay[lvl]) *
          10
      ) {
        setModalError("Недостаточно золота");
      } else {
        const clothesInfo = Object.entries(allClothes)
          .filter(
            (elem) =>
              elem[1]?.treasure?.lvl === lvl &&
              clothes.isBuy.findIndex((el) => el === elem[0]) === -1
          )
          .sort((a, b) => b[1].treasure.chance - a[1].treasure.chance);

        let prize = null;

        clothesInfo.map((elem) => {
          if (randomChance <= elem[1].treasure.chance) {
            prize = {
              name: elem[0],
              category: elem[1].category,
              lvl: elem[1].lvl,
              src: elem[1].src,
              isClothe: true,
            };
          }
        });

        setIsFetching(true);
        if (prize) {
          const buyClothes = [...clothes.isBuy, prize.name];

          const newCharacteristic = { ...characteristic };

          for (const key in newCharacteristic) {
            newCharacteristic[key].armor = 0;
          }

          const clothesInfo = Object.entries(allClothes).filter((elem) =>
            buyClothes.includes(elem[0])
          );

          clothesInfo.forEach((elem) => {
            Object.entries(elem[1].characteristic).forEach((el) => {
              newCharacteristic[el[0]].armor += el[1];
            });
          });

          const scales = {};
          const grindValue = grindData["book"].bonus[grind["book"] - 1] / 100;
          damageTalents.characteristicTalents.forEach((elem) => {
            scales[elem.property] =
              1 +
              grindValue +
              Math.round(elem.step * elem.countDone * 100) / 10000;
          });

          const hp = Math.floor(
            Object.values(newCharacteristic.health).reduce((acc, cur, i) => {
              if (i < 2) {
                return acc + cur * 5 * scales.health;
              }
              return acc + cur * 5;
            }, 0)
          );

          app
            .service("users")
            .patch(
              _id,
              {
                $inc: {
                  [`treasures.countHave.${lvl}`]: -1,
                  [`treasures.countOpen.${lvl}`]: 1,
                  [`treasures.countOpenDay.${lvl}`]: 1,
                  gold:
                    -(treasures.countOpenDay[lvl] > 10
                      ? 10
                      : treasures.countOpenDay[lvl]) * 10,
                  "summerSave.results.treasures": 1,
                },
                "health.max": hp,
                "clothes.isBuy": buyClothes,
                characteristic: newCharacteristic,
                field: serverTime,
              },
              {
                query: {
                  $select: [
                    "_id",
                    "email",
                    "health",
                    "clothes",
                    "characteristic",
                    "treasures",
                    "gold",
                    "summerSave",
                  ],
                },
              }
            )
            .then((data) => {
              setUser((prev) => ({ ...prev, ...data }));
              setTreasureReward(prize);
              setIsFetching(false);
              changeTreasure(0);
            })
            .catch((e) => {
              setIsFetching(false);
              setModalError(e);
              console.log(e);
            });
        } else {
          const rewardIndex = Math.floor(
            Math.random() * treasuresArray[lvl].length
          );

          const reward = treasuresArray[lvl][rewardIndex];

          let inventoryInfo = [...inventory];

          if (reward.property === "potion") {
            inventoryInfo = addItem(
              reward.count,
              {
                name: reward.potionName,
                category: reward.category,
                value: reward.value,
                percent: reward.percent,
                time: 0,
              },
              inventoryInfo
            );
          }

          app
            .service("users")
            .patch(
              _id,
              {
                $inc: {
                  [`treasures.countHave.${lvl}`]: -1,
                  [`treasures.countOpen.${lvl}`]: 1,
                  [`treasures.countOpenDay.${lvl}`]: 1,
                  gold:
                    -(
                      (treasures.countOpenDay[lvl] > 10
                        ? 10
                        : treasures.countOpenDay[lvl]) * 10
                    ) + (reward.property === "gold" ? reward.count : 0),
                  silver: reward.property === "silver" ? reward.count : 0,
                  essence: reward.property === "essence" ? reward.count : 0,
                  mana: reward.property === "mana" ? reward.count : 0,
                  "spells.points":
                    reward.property === "magic" ? reward.count : 0,
                  "spells.pointsAll":
                    reward.property === "magic" ? reward.count : 0,
                  "game21.isFree":
                    reward.property === "game21" ? reward.count : 0,
                  "diceGame.isFree":
                    reward.property === "diceGame" ? reward.count : 0,
                  [`bosses.${
                    reward.property === "soul" ? reward.number : 0
                  }.souls`]: reward.property === "soul" ? reward.count : 0,
                  "summerSave.results.treasures": 1,
                },
                inventory: inventoryInfo,
                field: serverTime,
              },
              {
                query: {
                  $select: [
                    "_id",
                    "email",
                    "treasures",
                    "gold",
                    "silver",
                    "essence",
                    "mana",
                    "bosses",
                    "game21",
                    "diceGame",
                    "spells",
                    "inventory",
                    "summerSave",
                  ],
                },
              }
            )
            .then((data) => {
              setUser((prev) => ({ ...prev, ...data }));
              setTreasureReward(reward);
              setIsFetching(false);
              changeTreasure(0);
            })
            .catch((e) => {
              setIsFetching(false);
              setModalError(e);
              console.log(e);
            });
        }
      }
    } else {
      setModalError("Недостаточно сундуков для открытия");
      changeTreasure(0);
    }
  }

  function handleTab(name) {
    setTab(name);
  }

  function handleClose() {
    setIsOpen(false);
  }

  function handleClickUser(user) {
    moveToUserRoom(user);
    setCandyInfo({});
    handleClose();
  }

  function eatCandy() {
    setIsFetching(true);
    const array = [...candy];
    const candyEat = array.shift();
    const userInfo = allUsers.find((user) => user._id === candyEat.userId);
    const manaRandom = Math.floor(Math.random() * 181) + 70;
    setCandyInfo({
      name: candyEat.name,
      user: userInfo,
      mana: manaRandom,
    });
    app
      .service("users")
      .patch(
        _id,
        {
          candy: array,
          field: serverTime,
          $inc: {
            mana: manaRandom,
            "dayQuest.candyGet": 1,
            "summerSave.results.candyGet": 1,
          },
        },
        {
          query: {
            $select: [
              "_id",
              "email",
              "candy",
              "mana",
              "dayQuest",
              "summerSave",
            ],
          },
        }
      )
      .then((data) => {
        setUser((prev) => ({ ...prev, ...data }));

        setIsFetching(false);
      })
      .catch((e) => {
        setModalError(e);
        console.log(e);
        setIsFetching(false);
      });
  }

  return (
    <S.Wrapper isOpen={isOpen} onClick={handleClose}>
      <S.Content onClick={(e) => e.stopPropagation()} back={backImg}>
        {isOpen && (
          <>
            <div>
              <Close onClick={handleClose} />
              <Header>Инвентарь</Header>

              <ButtonsWrapper>
                <Button
                  disabled={tab === "all"}
                  onClick={handleTab.bind(null, "all")}
                  width={90}
                >
                  <div>Все</div>
                </Button>

                <Button
                  disabled={tab === "hp"}
                  onClick={handleTab.bind(null, "hp")}
                  width={90}
                >
                  <div>Здоровье</div>
                </Button>

                <Button
                  disabled={tab === "energy"}
                  onClick={handleTab.bind(null, "energy")}
                  width={90}
                >
                  <div>Энергия</div>
                </Button>

                <Button
                  disabled={tab === "soul"}
                  onClick={handleTab.bind(null, "soul")}
                  width={90}
                >
                  <div>Души</div>
                </Button>

                <Button
                  disabled={tab === "treasures"}
                  onClick={handleTab.bind(null, "treasures")}
                  width={90}
                >
                  <div>Сундуки</div>
                </Button>
              </ButtonsWrapper>

              <S.ShopElements>
                {(tab === "all" || tab === "energy" || tab === "hp") &&
                  items.map((elem, i) => {
                    return (
                      <InventoryElem
                        key={`${elem.name}${elem.category}${elem.value}`}
                        elem={elem}
                        loading={isFetching}
                        isModalOpen={isModalOpen}
                        useItem={useItem}
                      />
                    );
                  })}

                {candy.length > 0 && tab === "all" && (
                  <S.Candy>
                    <span>Драже Сюрприз</span>
                    <span>({candy.length} шт)</span>
                    <Reward button={true} name={"candy"} w={30} />

                    <button onClick={!isFetching ? eatCandy : null}>
                      Съесть
                    </button>
                  </S.Candy>
                )}

                {(tab === "all" || tab === "treasures") &&
                  treasures.countHave.map((elem, i) => {
                    return (
                      elem > 0 && (
                        <S.Treasure key={i}>
                          <span>Сундук {i + 1}</span>
                          <span>({elem} шт)</span>

                          <Reward
                            button={true}
                            name={"treasure"}
                            treasure={i}
                            w={30}
                          />

                          <button
                            onClick={
                              !isFetching
                                ? changeTreasure.bind(null, i + 1)
                                : null
                            }
                          >
                            Открыть
                          </button>
                        </S.Treasure>
                      )
                    );
                  })}

                {(tab === "all" || tab === "soul") &&
                  bosses.map((elem, i) => {
                    return (
                      (elem?.souls || 0) > 0 && (
                        <S.Soul key={i}>
                          <span>{bossesArray[i][0].name}</span>
                          <span>({elem?.souls || 0} шт)</span>
                          <Reward button={true} name={"soul"} soul={i} w={30} />
                          <button onClick={handleAltarOpen.bind(null, true)}>
                            Обмен
                          </button>
                        </S.Soul>
                      )
                    );
                  })}
              </S.ShopElements>
            </div>

            <Popup
              isOpen={treasureOpen}
              setIsOpen={setTreasureOpen}
              w={317}
              h={217}
              back={frame3}
            >
              <Close onClick={changeTreasure.bind(null, 0)} />
              <b>Желаете открыть сундук {treasureOpen}?</b>
              <span>
                Каждое последующие открытие дороже на 10 золотых (не более 100),
                цена сбрасывается в конце дня
              </span>
              <Button
                disabled={isFetching}
                onClick={
                  !isFetching ? openTreasure.bind(null, treasureOpen - 1) : null
                }
              >
                <Reward
                  name={"gold"}
                  count={`Открыть ${
                    (treasures.countOpenDay[treasureOpen - 1] > 10
                      ? 10
                      : treasures.countOpenDay[treasureOpen - 1]) * 10
                  }`}
                  w={14}
                  button={true}
                />
              </Button>
            </Popup>

            <Popup
              isOpen={treasureReward}
              w={317}
              h={217}
              back={frame3}
              setIsOpen={changeTreasureReward}
            >
              <Close onClick={changeTreasureReward.bind(null, null)} />
              <b>Содержимое сундука</b>
              {treasureReward &&
                (treasureReward.isClothe ? (
                  treasureReward.category === "broom" ||
                  treasureReward.category === "wand" ? (
                    <img
                      width={90}
                      height={90}
                      src={require(`../../img/clothes/${
                        treasureReward.category
                      }${
                        treasureReward.lvl >= 1
                          ? `${treasureReward.lvl + 1}`
                          : ""
                      }/${treasureReward.src}`)}
                      alt={treasureReward.category}
                    />
                  ) : (
                    <img
                      width={90}
                      height={90}
                      src={require(`../../img/clothes/${
                        treasureReward.category
                      }${
                        treasureReward.lvl >= 1
                          ? `${treasureReward.lvl + 1}`
                          : ""
                      }/${sex}/${treasureReward.src}`)}
                      alt={treasureReward.category}
                    />
                  )
                ) : (
                  <RewardWrapper light={false} w={65}>
                    <div>
                      <Reward
                        name={treasureReward.property}
                        potionName={treasureReward.potionName}
                        category={treasureReward.category}
                        count={treasureReward.count}
                        soul={treasureReward.number}
                        w={28}
                        showCount={true}
                        round={true}
                        font={13}
                        reverseColumn={true}
                        color={"#fdbb54"}
                      />
                    </div>
                  </RewardWrapper>
                ))}
            </Popup>

            <Popup
              isOpen={candyInfo.name}
              setIsOpen={setCandyInfo}
              w={281}
              h={152}
              back={frame1}
            >
              <Close
                onClick={() => {
                  setCandyInfo({});
                }}
              />
              {candyInfo.name && (
                <>
                  <b>
                    {candyInfo.user ? (
                      <S.UserSpan
                        onClick={handleClickUser.bind(null, candyInfo.user)}
                      >
                        {candyInfo.user.nickName
                          ? candyInfo.user.nickName
                          : `${candyInfo.user.name?.firstName} ${candyInfo.user.name?.lastName}`}
                      </S.UserSpan>
                    ) : (
                      "Маг"
                    )}{" "}
                    прислал Вам Драже Сюрприз со вкусом "{candyInfo.name}"
                  </b>
                  <S.CandyMana>
                    Вы получили:
                    <Reward name={"mana"} count={candyInfo.mana} w={15} />
                  </S.CandyMana>
                </>
              )}
            </Popup>
          </>
        )}
      </S.Content>
    </S.Wrapper>
  );
}

export default React.memo(Inventory);
