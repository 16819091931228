import styled from "styled-components";
import buttonConnect from "../../img/ButtonConnect.png";
import imgMarker2 from "../../img/Marker2Small.png";
import imgMarker5 from "../../img/Marker5Small.png";
import imgMarker3 from "../../img/Marker3.png";
import imgMarker4 from "../../img/Marker4.png";
import imgMarker6 from "../../img/Marker6.png";

export const Page = styled.div`
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  font-family: "Bellota-Regular";
  color: #413423;
`;

export const ButtonValue = styled.div`
  position: relative;
  width: 90%;
  &::after {
    width: 12px;
    height: 8px;
    transform: translate(50%, -50%);
    position: absolute;
    right: 1px;
    top: 50%;
    content: "";
    background-image: url(${buttonConnect});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  &::before {
    z-index: 1;
    width: 12px;
    height: 8px;
    transform: translate(-50%, -50%);
    position: absolute;
    left: 1px;
    top: 50%;
    content: "";
    background-image: url(${buttonConnect});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  border-radius: 10px;
  box-shadow: 0px 0.5px 0px 1px #281707;

  & > div {
    box-shadow: 0px 0px 0px 1px #281707 inset;
    border: 2px solid #e98101;
    border-radius: 10px;
    padding: 2px 8px 2px;
    color: #281707;
    font-family: "Bellota-Regular";
    font-size: 12px;
    line-height: 1.25;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    text-align: center;
    width: 100%;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  border-radius: 3px;
  border-style: hidden;
  box-shadow: 0 0 0 2px #281707;
  margin-bottom: ${(props) => props.bottom};
  table-layout: fixed;

  & tr > td {
    vertical-align: top;
    width: 100%;
    max-width: 50%;
    border: 2px solid #281707;

    padding: 0;
    margin: 0;
    padding-bottom: 6px;
    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 6px;
    }
  }
`;

export const TopName = styled.div`
  margin-top: -1px;
  width: 100%;
  padding: 4px;
  background-image: ${(props) =>
    props.isDone ? `url("${imgMarker2}")` : `url("${imgMarker5}")`};
  background-size: contain;
  display: flex;
  justify-content: center;
  text-align: center;
  font-weight: 600;
  font-size: 12px;
  z-index: -1;
`;

export const ButtonPercent = styled.div`
  position: relative;
  width: 90%;
  &::after {
    width: 12px;
    height: 8px;
    transform: translate(50%, -50%);
    position: absolute;
    right: 1px;
    top: 50%;
    content: "";
    background-image: url(${buttonConnect});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  &::before {
    z-index: 1;
    width: 12px;
    height: 8px;
    transform: translate(-50%, -50%);
    position: absolute;
    left: 1px;
    top: 50%;
    content: "";
    background-image: url(${buttonConnect});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  border-radius: 10px;
  box-shadow: 0px 0.5px 0px 1px #281707;

  & > div {
    box-shadow: 0px 0px 0px 1px #281707 inset;
    border: 2px solid #e98101;
    border-radius: 10px;
    color: #281707;
    font-family: "Bellota-Regular";
    font-size: 12px;
    line-height: 1.5;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    text-align: center;
    width: 100%;
    height: 14px;
    display: flex;
    gap: 0;
  }
`;

export const InfoCount = styled.span`
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
    width: max-content;
    top: 0px;
    left: 50%;
    transform: translate(-50%, -80%);
    position: absolute;
    visibility: hidden;
    opacity: 0;
    color: #f7b471;
    font-size: 12px;
    transition: all 0.1s linear;
    padding: 4px 8px;
    background: rgba(17, 12, 9, 0.8);
    border-radius: 10px;
    outline: 1px solid #281707;
    box-shadow: inset 0px 0px 0px 1px #281707;
    border: 2px solid #fc9800;
    z-index: 2;
  }
  &:hover > div {
    visibility: visible;
    opacity: 1;
    transform: translate(-50%, -100%);
  }
`;

export const Percent = styled.div`
  width: ${(props) => props.width}%;

  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 75.98%,
      rgba(0, 0, 0, 0.2) 100%
    ),
    linear-gradient(
      180deg,
      rgba(255, 234, 234, 0) 19.47%,
      rgba(255, 234, 234, 0.2) 34.88%,
      rgba(255, 234, 234, 0) 60.57%
    ),
    linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%),
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0) 42.19%
    ),
    ${(props) => props.color};
  opacity: 0.7;
`;

export const MarkerRed = styled.div`
  padding: 3px;
  background-image: url(${imgMarker3});
  background-size: contain;
  display: flex;
  justify-content: center;
  text-align: center;
  color: #281707;
  font-weight: 600;
`;

export const MarkerOrange = styled(MarkerRed)`
  background-image: url(${imgMarker6});
`;

export const MarkerBrown = styled(MarkerRed)`
  background-image: url(${imgMarker5});
`;

export const MarkerBlue = styled(MarkerRed)`
  background-image: url(${imgMarker4});
`;

export const MarkerGreen = styled(MarkerRed)`
  background-image: url(${imgMarker2});
`;

export const Markers = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 6px;
  margin-bottom: 14px;
  font-size: 10px;
`;

export const Markers5 = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1.5fr;
  gap: 2px;
  font-size: 10px;
`;

export const Text = styled.div`
  width: 100%;
  text-align: start;
  font-size: 8px;
  font-weight: 600;
`;
